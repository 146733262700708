import '../App.scss';
import React, { useContext } from 'react';
import Button from './common/buttons/Button';
import { useNavigate } from 'react-router-dom';
import { renderChildren, renderComponents, UserContext } from '../helpers/common';
import classnames from 'classnames';
import Loader from './Loader';
import DropdownMenu from './DropdownMenu';
const _ = require('lodash');

function Section(props) {
	const userContext = useContext(UserContext);
	const { isMapleUser } = !_.isNil(userContext) ? userContext : { isMapleUser: false };

	const navigate = useNavigate();

	const handleClick = (action) => {
		if (action.onClick) {
			action.onClick();
		} else if (action.link) {
			navigate(action.link);
		}
	};

	const getTitleVariantSize = (variant) => {
		switch (variant) {
			case 'page':
				return 'text-title';
			default:
				return 'text-xl';
		}
	};

	const renderUpdatedSection = () => {
		return (
			<div className='mb-2 min-h-10'>
				<div className='flex flex-row items-center gap-4 min-h-10 justify-end'>
					<div className='grow-0'>
						<div
							className={classnames(
								{
									'leading-6': props.variant !== 'page',
								},
								getTitleVariantSize(props.variant),
								'font-semibold text-dark-gray',
							)}
						>
							{props.title}
						</div>
					</div>
					<div className='ml-0 mr-0 sm:mt-0 grow shrink-0 flex flex-row justify-end'>
						{props.right && renderComponents(props.right)}
						{props.actions && !props.isActionsDropdown && (
							<>
								{_.map(
									_.filter(props.actions, (a) => !!a),
									(action, i) => (
										<Button
											key={i}
											variant={action.variant}
											onClick={() => handleClick(action)}
											disabled={action.disabled}
											style={action.style}
										>
											{action.icon && (
												<span>
													<i className={classnames('fa', action.icon)} />
													&nbsp;
												</span>
											)}
											{action.label}
										</Button>
									),
								)}
							</>
						)}
						{props.actions && props.isActionsDropdown && (
							<DropdownMenu
								className='p-2'
								items={props.actions.options}
								onClick={props.actions.onClick}
								hideCaret
							>
								<div className='flex flex-row gap-1 items-center'>
									<Button variant={'primary'}>
										{props.actions.icon && (
											<span>
												<i className={classnames('fa', props.actions.icon)} />
												&nbsp;
											</span>
										)}
										{props.actions.label}
									</Button>
								</div>
							</DropdownMenu>
						)}
					</div>
				</div>
				{!props.hideSubtitle && (
					<div className={classnames({ 'mt-2 min-h-5': props.variant === 'page' }, 'text-sm text-gray-700')}>
						{props.subtitle}
					</div>
				)}
			</div>
		);
	};

	const renderSection = () => {
		return (
			<div className='flex flex-row items-center gap-4 pb-2 justify-end'>
				<div className='grow-0'>
					<div className='text-lg font-semibold leading-6 text-gray-900'>{props.title}</div>
					{props.subtitle && <div className='mt-1 text-sm text-gray-700'>{props.subtitle}</div>}
				</div>
				<div className='ml-0 mr-0 sm:mt-0 grow shrink-0 flex flex-row justify-end'>
					{props.right && renderComponents(props.right)}
					{props.actions && !props.isActionsDropdown && (
						<>
							{_.map(
								_.filter(props.actions, (a) => !!a),
								(action, i) => (
									<Button
										key={i}
										variant={action.variant}
										onClick={() => handleClick(action)}
										disabled={action.disabled}
										style={action.style}
									>
										{action.icon && (
											<span>
												<i className={classnames('fa', action.icon)} />
												&nbsp;
											</span>
										)}
										{action.label}
									</Button>
								),
							)}
						</>
					)}
					{props.actions && props.isActionsDropdown && (
						<DropdownMenu
							className='p-2'
							items={props.actions.options}
							onClick={props.actions.onClick}
							hideCaret
						>
							<div className='flex flex-row gap-1 items-center'>
								<Button variant={'primary'}>
									{props.actions.icon && (
										<span>
											<i className={classnames('fa', props.actions.icon)} />
											&nbsp;
										</span>
									)}
									{props.actions.label}
								</Button>
							</div>
						</DropdownMenu>
					)}
				</div>
			</div>
		);
	};

	return (
		<div className={props.className}>
			{(props.title || !_.isEmpty(props.actions)) && (
				<>
					{isMapleUser && renderUpdatedSection()}
					{!isMapleUser && renderSection()}
				</>
			)}
			<div className={classnames('content-section-content', props.contentClassName)}>
				<Loader loading={props.loading}>{renderChildren(props)}</Loader>
			</div>
		</div>
	);
}

export default Section;

Section.defaultProps = {
	loading: false,
};
