import '../../App.scss';
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {serverPost, serverDelete, serverFetch} from '../../helpers/server';
import {
    BaseContext,
    getPaymentLinkUrl,
} from '../../helpers/common';
import CopyableComponent from '../../components/CopyableComponent';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import DeleteButton from '../../components/DeleteButton';
import MapleTable from '../../components/MapleTable';
import EmptyState from '../../components/EmptyState';
import Label from '../../components/Label';
import Button from '../../components/common/buttons/Button';
import InfiniteScroll from "react-infinite-scroll-component";
import moment from 'moment';
const _ = require('lodash');

function PaymentLinks() {
    const navigate = useNavigate();
    const { company, getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const [paymentLinks, setPaymentLinks] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [fromKey, setFromKey] = useState(null);
    const [sort, setSort] = useState("createdAtDesc");
    const [loading, setLoading] = useState(true);
    const [query, setQuery] = useState(null)
    const [paymentEnabled, setPaymentEnabled] = useState(false);
    const [settings, setSettings] = useState({});

    useEffect(() => {
        setPageTitle(`Payment Links`);
    }, []);

    const onSearch = (restart = true) => {
        const limit = 50;
        const params = {
            company_id: company.id,
            sort_key: sort,
            pagination: {
                from_key: restart ? null: fromKey,
                limit: limit
            },
            query: {
                search: query,
                type: "CHECKOUT"
            }
        }
        serverPost(getApiUrl("/proposals/find"), params).then((res) => {
            if (res) {
                const results = _.filter(res.results || [], (r) => r.id.startsWith("pl_")) || [];
                _.each(results, (r) => {
                    if (r.expiration_date) {
                        r.isExpired = moment(r.expiration_date).isBefore(moment());
                    }
                })
                if (restart) {
                    setPaymentLinks(results);
                } else {
                    setPaymentLinks(_.concat(paymentLinks, results));
                }
                setLoading(false);
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
        });
    };

    useEffect(() => {
        onSearch(true);
    }, [sort, query]);

    useEffect(() => {
        serverFetch(getApiUrl("/settings")).then((res) => {
            setSettings(res);
            setPaymentEnabled(settings.payment_config)
        });
    })

    const onFieldChange = (name, value) => {
        if (name === "sort") {
            setSort(value);
        } else if (name === "query") {
            setQuery(value);
        }
    }

    const onActionSelected = (action, paymentLink) => {
        if (action === "edit") {
            navigate(getCompanySpecificUrl(`/contract/edit/${paymentLink.id}`))
        }
    }

    const deletePaymentLink = (paymentLink) => {
        serverDelete(getApiUrl(`/proposals/${paymentLink.id}`)).then(res => {
            onSearch(true);
        })
    }

    const sortOptions = [
        { value: "createdAtAsc", label: 'Created At, Asc' },
        { value: "createdAtDesc", label: 'Created At, Desc' },
        { value: "updatedAtAsc", label: 'Updated At, Asc' },
        { value: "updatedAtDesc", label: 'Updated At, Desc' },
    ]

    const moreActions = [
        { id: "edit", label: "Edit" },
    ]

    const getUrl = (link) => {
        return getPaymentLinkUrl(link, getCompanySpecificUrl);
    }

    const renderStatus = (link) => {
        if (link.status === "PENDING_PAYMENT") {
            return (<Label.Success>Active</Label.Success>)
        }
    }

    return (
        <BaseContainer>
            <ContentContainer>
                <InfiniteScroll
                    dataLength={paymentLinks.length}
                    next={() => onSearch(false)}
                    hasMore={hasMore}
                    scrollableTarget="content-wrapper"
                >
                    <Section
                        title="Payment Links"
                        variant="page"
                        loading={loading}
                        actions={!loading && !_.isEmpty(paymentLinks) && [{
                            variant: "primary",
                            icon: "fa-plus",
                            label: "Create Payment Link",
                            link: getCompanySpecificUrl("/payment_link/create")
                        }]}>
                        {!paymentEnabled &&
                            <Section>
                                <Label.Danger>Payment Provider Not Enabled</Label.Danger>
                            </Section>
                        }
                        {
                            _.isEmpty(paymentLinks) ?
                                <EmptyState
                                    title={"No payment links."}
                                    subtitle={"Get started by creating a new one."}
                                    buttonLabel={"New Payment Link"}
                                    onClick={(event) => navigate(getCompanySpecificUrl("/payment_link/create"))}
                                />
                            : <MapleTable className="mt-2">
                                <MapleTable.Content>
                                    <thead>
                                        <tr>
                                            <MapleTable.TH>Link</MapleTable.TH>
                                            <MapleTable.TH></MapleTable.TH>
                                            <MapleTable.TH className="whitespace-nowrap hidden md:table-cell">Created At</MapleTable.TH>
                                            <MapleTable.TH></MapleTable.TH>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                    {
                                        _.map(paymentLinks, (row, i) =>
                                             <MapleTable.TR key={i} className="cursor-pointer" href={getCompanySpecificUrl(`/payment_link/${row.id}`)}>
                                                <td className="text-xs">
                                                    <CopyableComponent
                                                        value={<a className="">{getUrl(row)}</a>}
                                                        copyableValue={getUrl(row)}
                                                    />
                                                </td>
                                                <td className="min-w-md">
                                                    <div>
                                                        {
                                                            row.bundle_pricing && _.map(row.bundle_pricing.bundle_product_pricings, (bpp, j) =>
                                                                <span key={j} className="flex flex-col">
                                                                    <span className="body2">{ bpp.product_pricing.product.name }</span>
                                                                    {
                                                                        bpp.product_pricing.name &&
                                                                            <span>{ bpp.product_pricing.name }</span>
                                                                    }
                                                                </span>
                                                            )
                                                        }
                                                        {
                                                            row.one_time_billables && _.map(row.one_time_billables, (otb, k) =>
                                                                <span key={k} className="flex flex-col">
                                                                    <span className="body2">{ otb.product_pricing.product.name }</span>
                                                                    {
                                                                        otb.product_pricing.name &&
                                                                        <span>{ otb.product_pricing.name }</span>
                                                                    }
                                                                </span>
                                                            )
                                                        }
                                                    </div>
                                                </td>
                                                <td className="px-3 py-2.5 text-sm whitespace-nowrap text-gray-700 hidden md:table-cell">{ moment(row.created_at).format("MMM D, YYYY") }</td>
                                                <td className="px-3 py-2.5 text-right text-sm font-medium">
                                                    <Link className={"hide"} to={getCompanySpecificUrl(`/payment_link/edit/${row.id}`)}>
                                                        <Button variant="text-primary"><i className="fa fa-edit"/></Button>
                                                    </Link>
                                                    <DeleteButton size="sm" onDelete={() => deletePaymentLink(row)} />
                                                </td>
                                             </MapleTable.TR>
                                        )
                                    }
                                    {
                                        hasMore &&
                                            <tr>
                                                <td colSpan="10" className="text-center">
                                                    <div className="spinner-border text-secondary"/>
                                                </td>
                                            </tr>
                                    }
                                    </tbody>
                                </MapleTable.Content>
                            </MapleTable>
                        }
                    </Section>
                </InfiniteScroll>
            </ContentContainer>
        </BaseContainer>
    );
}

export default PaymentLinks;
