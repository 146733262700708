import '../../App.scss';
import '../../css/modals.scss';
import React, {useEffect, useState} from 'react';
import BaseModal from '../modals/BaseTailwindModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../common/buttons/SubmitButton';
import { useContext } from 'react';
import { BaseContext } from '../../helpers/common';
import { useTranslation } from 'react-i18next';
const _ = require('lodash');

function EditGoalModal(props) {
    const { t } = useTranslation('common');
    const { company, getApiUrl } = useContext(BaseContext);
    const [initialFields, setInitialFields] = useState({});

    useEffect(() => {
        if (props.show) {
            setInitialFields(JSON.parse(localStorage.getItem("goal")) || { 'percent': 10 });
        }
    }, [props.show])

    const onSubmit = (data) => {
        const existingGoal = JSON.parse(localStorage.getItem("goal")) || { 'percent': 10 };
        existingGoal.percent = parseFloat(data.percent);
        localStorage.setItem("goal", JSON.stringify(existingGoal));
        props.onClose();
    }

    return (
        <BaseModal {...props}>
            <BaseModal.Header title={"Edit your goal"}/>
            <BaseForm initialFormFields={initialFields} onSubmit={onSubmit}>
                <BaseModal.Body>
                    <BaseForm.Input colSpan="6" type="number" name="percent" label="Percentage" step="0.01"
                                    description="What's your monthly MRR growth target?" />
                </BaseModal.Body>
                <BaseModal.Footer>
                    <SubmitButton variant={props.buttonVariant || "primary"} className={props.buttonClassName}>{ props.buttonTitle || "Confirm" }</SubmitButton>
                </BaseModal.Footer>
            </BaseForm>
        </BaseModal>
    );
}

export default EditGoalModal;
