import '../../App.scss';
import React, {useEffect, useState, useContext, createRef, useMemo} from 'react';
import { useSearchParams, useParams, useNavigate } from 'react-router-dom';
import {serverDelete, serverPost, serverFetch, serverPatch} from '../../helpers/server';
import {
    currencyFormatFromPrice,
    BaseContext,
    UserContext,
    renderPaymentMethod,
    getFormattedAddressForAddress,
    getNameForProviderType,
    isPaymentMethodExpiring,
    isPaymentMethodExpired,
    downloadBlob,
    renderProductPricing,
    getCustomerNameOrEmail, getLabelForBillingFrequency, getInvoicePaymentTermDescription, renderContractStatusLabel
} from '../../helpers/common';
import { getExternalCustomerReferenceURL } from '../../helpers/integrations';
import { useTranslation } from 'react-i18next';
import AdjustCreditModal from '../../components/modals/AdjustCreditModal';
import SetupPaymentMethodModal from '../../components/modals/SetupPaymentMethodModal';
import AddOneTimeChargesToCustomerModal from '../../components/modals/AddOneTimeChargesToCustomerModal';
import AddOrEditCustomerModal from '../../components/modals/AddOrEditCustomerModal';
import MoreButton from '../../components/common/buttons/MoreButton';
import SubscriptionListSection from '../../components/SubscriptionListSection';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import InvoiceListSection from '../../components/InvoiceListSection';
import PaymentListSection from '../../components/PaymentListSection';
import KeyValueDisplay2 from '../../components/KeyValueDisplay2';
import KeyValueDisplay from '../../components/KeyValueDisplay';
import Section from '../../components/Section';
import DeleteButton from '../../components/DeleteButton';
import BaseForm from '../../components/BaseForm';
import SubmitButton from '../../components/common/buttons/SubmitButton';
import MapleTable from '../../components/MapleTable';
import Label from '../../components/Label';
import ConfirmationButton from '../../components/common/buttons/ConfirmationButton';
import {Table, Row, Col, Alert, Image} from 'react-bootstrap';
import Button from '../../components/common/buttons/Button';
import moment from 'moment';
import DiscountListSection from "../../components/DiscountListSection";
import Link from "../../components/Link";
import Notification from "../../components/Notification";
import {UsersIcon, WrenchScrewdriverIcon} from "@heroicons/react/20/solid";
import DropdownMenu from "../../components/DropdownMenu";
import UploadFileModal from "../../components/modals/UploadFileModal";
import ChangeOwnerModal from "../../components/modals/ChangeOwnerModal";
import {ArrowDownTrayIcon, BuildingOfficeIcon, ExclamationTriangleIcon} from "@heroicons/react/24/outline";
import ChangeParentModal from "../../components/modals/ChangeParentModal";
import EditChildRollupBillingModal from "../../components/modals/EditChildRollupBillingModal";
import SectionNav from "../../components/SectionNav";
import Loader from "../../components/Loader";
import ErrorComponent from "../../components/ErrorComponent";
import UpdateAvalaraCustomerEntityCodeModal from "../../components/modals/UpdateAvalaraCustomerEntityCodeModal";
import CustomerObjectsModal from "../../components/modals/CustomerObjectsModal";
import ShareCustomerPortalLinkModal from "../../components/modals/ShareCustomerPortalLinkModal";
import IntegrationReferenceEntitySummaryModal from "../../components/modals/IntegrationReferenceEntitySummary";
import Tags from "../../components/Tags";
import SimpleModal from "../../components/modals/SimpleModal";
import CreditSummaryTable from '../../components/CreditSummaryTable';
import useGetCreditBalance from '../../helpers/hooks/api/useGetCreditBalance';
import usePostFindCreditsApi from '../../helpers/hooks/api/usePostFindCreditsApi';
import CopyableComponent from "../../components/CopyableComponent";
import CustomerPaymentMethodSetupModal from "../../components/modals/CustomerPaymentMethodSetupModal";
import Columns from "../../components/Columns";
import BaseOverlayTrigger from "../../components/BaseOverlayTrigger";

const _ = require('lodash');

function Customer(props) {
    const getActiveNavFromView = (v) => {
        if (v === "cb") {
            return "CHILD_BILLING"
        } else if (v === "c") {
            return "CHILD"
        } else {
            return "DETAILS"
        }
    }

    const navigate = useNavigate();
    const { t } = useTranslation('common');

    const [ searchParams ] = useSearchParams();
    let redirectStatus = searchParams.get('redirect_status');
    let view = searchParams.get('v');

    const { userInfo, isMapleUser } = useContext(UserContext);
    const { company, getApiUrl, getCompanySpecificUrl, setPageTitle, hasAccess } = useContext(BaseContext);
    const { customerId } = useParams();
    const [showAdjustCreditModal, setShowAdjustCreditModal] = useState(false);
    const [showRequestPaymentMethodModal, setShowRequestPaymentMethodModal] = useState(false);
    const [showSetupPaymentMethodModal, setShowSetupPaymentMethodModal] = useState(false);
    const [showAddOneTimeChargesToCustomerModal, setShowAddOneTimeChargesToCustomerModal] = useState(false);
    const [showAddOrEditCustomerModal, setShowAddOrEditCustomerModal] = useState(false);
    const [showUploadFileModal, setShowUploadFileModal] = useState(false);
    const [showChangeOwnerModal, setShowChangeOwnerModal] = useState(false);
    const [showChangeParentModal, setShowChangeParentModal] = useState(false);
    const [showChildRollupBillingModal, setShowChildRollupBillingModal] = useState(false);
    const [showUpdateAvalaraCustomerEntityCodeModal, setShowUpdateAvalaraCustomerEntityCodeModal] = useState(false);
    const [showCustomerPortalLinkModal, setShowCustomerPortalLinkModal] = useState(false);
    const [showObjectsModal, setShowObjectsModal] = useState(false);
    const [showReferenceSummaryModal, setShowReferenceSummaryModal] = useState(false);
    const [showArchiveCustomerModal, setShowArchiveCustomerModal] = useState(false);
    const [customerLoading, setCustomerLoading] = useState(true);
    const [customerDetails, setCustomerDetails] = useState({});
    const [childCustomers, setChildCustomers] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [taxes, setTaxes] = useState([]);
    const [details, setDetails] = useState([]);
    const [childDetails, setChildDetails] = useState([]);
    const [settings, setSettings] = useState({});
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [showCustomTaxForm, setShowCustomTaxForm] = useState(false);
    const [isEditingMetadata, setIsEditingMetadata] = useState(false);
    const [billableItems, setBillableItems] = useState([]);
    const [oneTimeBillables, setOneTimeBillables] = useState([]);
    const [activeNav, setActiveNav] = useState(getActiveNavFromView(view));
    const [isEditingChildRollupBillingInvoiceDisplayFields, setIsEditingChildRollupBillingInvoiceDisplayFields] = useState(false);
    const [nextChildRollupInvoices, setNextChildRollupInvoices] = useState(null);
    const [avalaraIntegration, setAvalaraIntegration] = useState(null);
    const [requestPaymentMethodLink, setRequestPaymentMethodLink] = useState(null);
    const subscriptionListRef = createRef();
    const invoiceListRef = createRef();
    const paymentListRef = createRef();
    const formRef = createRef();
    const addTaxInitialFields = useMemo(() => {
        return {
            rate: 0
        }
    }, [taxes]);
    const extraQueryFilters = useMemo(() => {
        return { customer_id: customerId }
    }, [customerId]);
    const childSubscriptionExtraQueryFilters = useMemo(() => {
        return { parent_customer_id: customerId }
    }, [customerId]);
    const childSubscriptionParentBilledExtraQueryFilters = useMemo(() => {
        return { parent_customer_id: customerId, parent_billed: true }
    }, [customerId]);
    const childInvoiceExtraQueryFilters = useMemo(() => {
        return { billed_customer_id: customerId }
    }, [customerId]);
    const childInvoiceParentBilledExtraQueryFilters = useMemo(() => {
        return { billed_customer_id: customerId }
    }, [customerId]);

    const hasPaymentsPermission = hasAccess("payments", userInfo, "read");
    const hasInvoicesPermission = hasAccess("invoices", userInfo, "read");
    const hasInvoicesWritePermission = hasAccess("invoices", userInfo, "write") && !customerDetails.managed_externally;
    const hasPricingPermission = hasAccess("pricing", userInfo, "read");
    const hasSubscriptionsPermission = hasAccess("subscriptions", userInfo, "read");
    const hasProposalsPermission = hasAccess("proposal", userInfo, "read");
    const hasProposalsWritePermission = hasAccess("proposal", userInfo, "write");
    const hasWritePermission = hasAccess("customers", userInfo, "write");
    const hasIntegrationsReadPermission = hasAccess("integrations", userInfo, "read");
    const canEditCustomer = hasWritePermission && !customerDetails.managed_externally;
    const canArchiveCustomer = hasWritePermission && !customerDetails.managed_externally && customerDetails.status !== "ARCHIVED";
    const canCreateCheckoutSession = hasWritePermission && !customerDetails.managed_externally && customerDetails.status !== "ARCHIVED";
    const hasCompanyUsersReadPermission = hasAccess("team", userInfo, "read");
    const hasPromotionsWritePermission = hasAccess("promotions", userInfo, "write");
    const hasCreditsReadPermission = hasAccess("credits", userInfo, "read");
    const hasOnetimeWritePermission = hasAccess("onetime", userInfo, "write");

    useEffect(() => {
        setPageTitle(`Customer`);
    }, []);

    useEffect(() => {
        serverFetch(getApiUrl("/settings")).then((res) => {
            setSettings(res);
        })

        serverPost(getApiUrl("/billable/items/find"), {}).then((res) => {
            setBillableItems(_.filter(res, (r) => !r.standard));
        });
    }, [])

    useEffect(() => {
        if (_.isNil(redirectStatus)) {
            return;
        }
        setTimeout(() => {
            let urlParser = new URL(window.location.href);
            urlParser.searchParams.delete('setup_intent');
            urlParser.searchParams.delete('setup_intent_client_secret');
            urlParser.searchParams.delete('redirect_status');
            navigate(urlParser.pathname + urlParser.search, { replace: true });
        }, 100);
    }, [redirectStatus])

    const changeNav = (key) => {
        let p = null;
        if (key === "CHILD_BILLING") {
            p = "cb"
        } else if (key === "CHILD") {
            p = "c"
        }
        let urlParser = new URL(window.location.href);
        if (p) {
            urlParser.searchParams.set('v', p);
        } else {
            urlParser.searchParams.delete('v');
        }
        navigate(urlParser.pathname + urlParser.search, { replace: true });
        setActiveNav(key)
    }

    const fetchChildCustomers = () => {
        const params = {
            sort_key: "createdAtDesc",
            pagination: {
                from_key: null,
                limit: 100
            },
            query: {
                parent_customer_id: customerDetails.id
            }
        }
        serverPost(getApiUrl("/customers/find"), params).then((res) => {
            setChildCustomers(res.results);
        });
    }

    const { creditBalance, isCreditBalanceLoading, onCreditBalanceSearch } =
        useGetCreditBalance({
            customerID: customerId,
            options: { skipCache: true },
        });

    const {totalUnpaginatedCreditsCount, isCreditsLoading, onCreditsSearch } = usePostFindCreditsApi({
        query: {
            customer_id: customerId,
        },
        limit: 1,
    })
        
    const fetchPaymentMethods = (skipCache=false) => {
        serverFetch(getApiUrl(`/customers/${customerId}/payment_methods`), { skipCache: skipCache }).then((res) => {
            if (res) {
                setPaymentMethods(res.methods);
            }
        });
    }

    const fetchTaxes = (skipCache=false) => {
        serverPost(getApiUrl(`/taxes/find`), { customer_id: customerId }).then((res) => {
            if (res) {
                setTaxes(res);
            }
        });
    }

    const fetchContracts = (skipCache=false) => {
        if (!hasProposalsPermission) {
            return;
        }
        const contractData = {
            query: {
                customer_id: customerId,
                type: "CONTRACT",
            },
            pagination: {
                from_key: null,
                limit: 50
            },
            sort_key: "createdAtDesc",
        }
        serverPost(getApiUrl(`/proposals/find`), contractData).then((res) => {
            if (res) {
                setContracts(res.results || []);
            }
        });
    }

    const fetchCustomerDetails = (skipCache=false) => {
        serverFetch(getApiUrl(`/customers/${customerId}`), { skipCache }).then((res) => {
            if (res) {
                setCustomerDetails(res);
            }
            setCustomerLoading(false);
        });
    }

    const fetchOneTimeBillables = () => {
        const params = {
            sort_key: "createdAtDesc",
            pagination: {
                from_key: null,
                limit: 100
            },
            query: {
                customer_id: customerId
            }
        }
        serverPost(getApiUrl(`/one_times/find`), params).then((res) => {
            const results = res.results || [];
            setOneTimeBillables(_.filter(results, r => !_.includes(["APPLIED"], r.state
            )));
        });
    }

    const checkAvalaraIntegration = () => {
        if (hasIntegrationsReadPermission) {
            serverPost(getApiUrl("/integrations/installed"), { query : {}}).then((res) => {
                if (res) {
                    setAvalaraIntegration(_.find(res.results, (r) => r.status === "ACTIVE" && r.key === "avalara"));
                }
            });
        }
    }

    useEffect(() => {
        fetchCustomerDetails();
        hasCreditsReadPermission && onCreditBalanceSearch();
        hasCreditsReadPermission && onCreditsSearch({keepPreviousData: false});
        fetchPaymentMethods();
        fetchOneTimeBillables();
        checkAvalaraIntegration();
        fetchContracts();
        setActiveNav(getActiveNavFromView(view));
    }, [customerId, getApiUrl]);

    useEffect(() => {
        if (activeNav === "CHILD_BILLING" && customerDetails.child_rollup_billing) {
            const data = {
                name: "DRAFT",
                customer_id: customerId,
                include_taxes: true,
            }
            serverPost(getApiUrl(`/invoices/next_child_rollup`), data).then((res) => {
                if (res) {
                    setNextChildRollupInvoices(res);
                }
            });
        }
    }, [activeNav, customerId, customerDetails]);

    useEffect(() => {
        if (!_.isEmpty(settings) && settings.collect_taxes_type !== "none") {
            fetchTaxes();
        }
    }, [customerId, settings, getApiUrl]);

    useEffect(() => {
        if (!_.isEmpty(customerDetails)) {
            fetchChildCustomers();
        }
    }, [customerDetails]);

    useEffect(() => {
        if (_.isEmpty(customerDetails)) {
            return;
        }
        let data = {
            'ID': customerDetails.id,
        }
        if (!_.isNil(customerDetails.imported_from)) {
            const externalReferenceUrl = getExternalCustomerReferenceURL(customerDetails);
            if (externalReferenceUrl) {
                data[`${getNameForProviderType(customerDetails.imported_from)} Reference`] =
                    <a href={externalReferenceUrl} target="_blank">{ customerDetails.import_ref }</a>;
            }
        }
        if (customerDetails.parent_customer_id) {
            data['Parent'] = <div className="flex flex-row gap-1 items-center">
                <BuildingOfficeIcon className={"h-5 w-5"}/>
                <Link href={getCompanySpecificUrl(`/customer/${customerDetails.parent_customer_id}`)}>{getCustomerNameOrEmail(customerDetails.parent_customer)}</Link>
            </div>
        }
        data = _.extend(data, {
            'Name': customerDetails.name,
            'Email': customerDetails.email,
            'Billing Emails':
                _.isEmpty(customerDetails.billing_emails) ?
                    <span className="text-sm gray2">None</span>
                : <div>
                    {
                        _.map(customerDetails.billing_emails, (em, j) =>
                            <p key={j} className="text-sm">{ em }</p>
                        )
                    }
                </div>,
            'Title': customerDetails.title,
            'Organization Name': customerDetails.org_name,
            'Phone Number': customerDetails.phone,
            'Address': getFormattedAddressForAddress(customerDetails.address),
            'Tags': <Tags tags={customerDetails.tags} onUpdate={updateTags} tagsUrl={getApiUrl("/customers/tags")} isEditable={hasWritePermission} />,
            'MRR': <Link href={getCompanySpecificUrl(`/mrr_logs?c=${customerDetails.id}`)}>{currencyFormatFromPrice(customerDetails.mrr)}</Link>,
            'Owner': <div className="d-flex flex-row gap-3 align-items-center">
                <div className="grow">
                {
                    customerDetails.owner ?
                        <div className="flex flex-row items-center">
                            <div className="text-start whitespace-nowrap">
                                <p className="text-sm font-normal text-gray-700 group-hover:text-gray-900">{ customerDetails.owner.user.name || customerDetails.owner.user.email }</p>
                            </div>
                        </div>
                        : <span className="gray2">None</span>
                }
                </div>
                {hasCompanyUsersReadPermission && canEditCustomer && <Button onClick={() => setShowChangeOwnerModal(true)} variant="text" size="sm"><i className="fa fa-edit"/></Button>}
            </div>
        });
        data['External Identifier'] = customerDetails.identifier;
        if (customerDetails.exclude_from_metrics) {
            data['Excluded from Metrics'] = "True"
        }
        if (!_.isNil(avalaraIntegration)) {
            let entityCode = null;
            if (customerDetails.customer_integration_metadata && customerDetails.customer_integration_metadata[avalaraIntegration.id] && customerDetails.customer_integration_metadata[avalaraIntegration.id].avalara) {
                entityCode = customerDetails.customer_integration_metadata[avalaraIntegration.id].avalara.entity_code;
            }
            data['Avalara Entity Use Code'] = <div className="d-flex flex-row gap-3 align-items-center">
                <div className="grow">
                    { entityCode ? <span>{ entityCode }</span> : <span className="gray2">None</span> }
                </div>
                {
                    hasWritePermission &&
                        <Button onClick={() => setShowUpdateAvalaraCustomerEntityCodeModal(true)} variant="text" size="sm"><i className="fa fa-edit"/></Button>
                }
            </div>;
        }
        const childData = {};
        if (!_.isEmpty(childCustomers)) {
            let paymentTermDescription = "";
            if (customerDetails.child_rollup_billing_config) {
                paymentTermDescription = getInvoicePaymentTermDescription(customerDetails.child_rollup_billing_config.invoice_due_date_from_creation)
            }
            childData['Child Invoice Rollup'] = <div className="d-flex flex-row gap-3 align-items-center">
                <div className="grow">
                    {
                        customerDetails.child_rollup_billing ?
                            <div className="flex flex-col">
                                <div className="flex flex-row gap-1 items-center">
                                    <span>Roll up Invoices</span>
                                    <span className="text-sm font-normal text-gray-500">({ _.upperFirst(getLabelForBillingFrequency(customerDetails.child_rollup_billing_config.frequency)) })</span>
                                </div>
                                <span className="flex flex-row gap-1 text-gray-500">
                                    <span>Billing: { customerDetails.child_rollup_billing_config.auto_charges ? "Automatic": "Manual" }</span>
                                    {
                                        !_.isEmpty(paymentTermDescription) && !_.isNil(paymentTermDescription) &&
                                        <span className="">({ paymentTermDescription })</span>
                                    }
                                </span>
                                <span className="text-sm font-normal text-gray-500">Next Invoice Date: { moment(customerDetails.child_rollup_next_invoice_date).format("DD MMM, YYYY h:mm:ssa") }</span>
                            </div>
                        : <div>Do not roll up invoices</div>
                    }
                </div>
                <Button onClick={() => setShowChildRollupBillingModal(true)} variant="text" size="sm"><i className="fa fa-edit"/></Button>
            </div>
            if (nextChildRollupInvoices && nextChildRollupInvoices.length > 0) {
                _.each(nextChildRollupInvoices, (cri) => {
                    childData[`Rollup Invoice ${cri.currency}`] = <Link href={getCompanySpecificUrl(`/customer/${customerId}/next_rollup_invoice/${_.toLower(cri.currency)}`)}>{ currencyFormatFromPrice(cri.due) } on { moment(cri.invoice_date).format("MMM D, YYYY h:mm:ss a") }</Link>
                })
            } else {
                childData[`Rollup Invoice`] = null;
            }
        }
        setDetails(data);
        setChildDetails(childData);
    }, [customerDetails, childCustomers, nextChildRollupInvoices]);

    useEffect(() => {
        if (showRequestPaymentMethodModal) {
            serverPost(getApiUrl(`/customers/${customerId}/add_payment_method_link`), {}).then((res) => {
                setRequestPaymentMethodLink(res.link);
            });
        } else {
            setRequestPaymentMethodLink(null);
        }
    }, [showRequestPaymentMethodModal]);

    const updateTags = async (data) => {
        const result = await serverPatch(getApiUrl(`/customers/${customerId}`), data);
        if (result) {
            fetchCustomerDetails(true);
        }
        return result;
    }

    const adjustCredit = () => {
        setShowAdjustCreditModal(true);
    }

    const editCustomer = () => {
        setShowAddOrEditCustomerModal(true);
    }

    const manageCustomer = () => {
        const data = {};
        serverPatch(getApiUrl(`/customers/${customerId}/start_managing`), data).then((res) => {
            if (res) {
                fetchCustomerDetails(true);
            }
        });
    }

    const onModalClose = (didUpdate) => {
        setShowAdjustCreditModal(false);
        setShowSetupPaymentMethodModal(false);
        setShowRequestPaymentMethodModal(false);
        setShowAddOneTimeChargesToCustomerModal(false);
        setShowAddOrEditCustomerModal(false);
        setShowUploadFileModal(false);
        setShowChangeOwnerModal(false);
        setShowChangeParentModal(false);
        setShowChildRollupBillingModal(false);
        setShowUpdateAvalaraCustomerEntityCodeModal(false);
        setShowObjectsModal(false);
        setShowCustomerPortalLinkModal(false);
        setShowReferenceSummaryModal(false);
        setShowArchiveCustomerModal(false);
        if (didUpdate) {
            hasCreditsReadPermission && onCreditBalanceSearch();
            fetchCustomerDetails(true);
            fetchPaymentMethods(true);
            setTimeout(() => {
                fetchOneTimeBillables();
            }, 1000);
            if (subscriptionListRef.current) {
                subscriptionListRef.current.refresh();
            }
            if (invoiceListRef.current) {
                invoiceListRef.current.refresh(1000);
            }
            if (paymentListRef.current) {
                paymentListRef.current.refresh();
            }
        }
    }

    const editMetadata = () => {
        setIsEditingMetadata(true);
    }

    const saveMetadata = (data) => {
        const processedData = {
            metadata: data
        };

        serverPatch(getApiUrl(`/customers/${customerId}`), processedData).then((res) => {
            if (res) {
                fetchCustomerDetails(true);
            }
            setIsEditingMetadata(false);
        });
    }

    const uploadFile = () => {
        setShowUploadFileModal(true);
    }

    const showManageTaxes = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setShowCustomTaxForm(true);
    }

    const addTax = async (data) => {
        data.rate = parseFloat(data.rate)/100;
        data.name = data.type;
        data.state = "ACTIVE";
        data.inclusive = false;
        data.customer_id = customerDetails.id;
        const result = await serverPost(getApiUrl(`/taxes`), data)
        if (result) {
            fetchTaxes(true);
            if (formRef.current) {
                formRef.current.onResetFields({ type: null, rate: 10 })
            }
        }
    }

    const deleteTax = (tax) => {
        serverDelete(getApiUrl(`/taxes/${tax.id}`)).then(res => {
            fetchTaxes(true);
            if (formRef.current) {
                formRef.current.onResetFields({ type: null, rate: 0 })
            }
        });
    }

    const taxTypeOptions = [
        { value: "GST", label: "GST" },
        { value: "HST", label: "HST" },
        { value: "VAT", label: "VAT" },
        { value: "PST", label: "PST" },
        { value: "QST", label: "QST" },
        { value: "SALES", label: "Sales" },
        { value: "CUSTOM", label: "Custom" },
    ]
    const existingTaxTypes = _.map(taxes, (t) => t.type);
    const filteredTaxTypeOptions = _.filter(taxTypeOptions, (op) => !_.includes(existingTaxTypes, op.value));

    const makePaymentMethodDefault = (method) => {
        const updateData = {
            default: true
        }
        serverPatch(getApiUrl(`/customers/${customerId}/payment_methods/${method.id}`), updateData).then(res => {
            if (res) {
                Notification.Success("Updated successfully");
                fetchPaymentMethods(true);
            }
        });
    }

    const paymentActionOptions = [
        { id: "set_default", label: "Set as default" }
    ]

    const onPaymentActionSelected = (action, method) => {
        if (action === "set_default") {
            makePaymentMethodDefault(method);
        }
    }

    const errorHandler = async (errorRes, errorMessage) => {
        const errorResponse = await errorRes.json();
        if (errorResponse && errorResponse.error_message) {
            Notification.Danger(errorResponse.error_message, 10000);
        }
    }

    const archiveCustomer = async () => {
        const result = await serverPatch(getApiUrl(`/customers/${customerId}`), { status: "ARCHIVED" }, null, errorHandler);
        if (result) {
            fetchCustomerDetails(true);
            Notification.Success("Successfully archived customer");
        }
    }

    const onActionSelected = (type) => {
        if (type === "edit") {
            editCustomer();
        } else if (type === "change_owner") {
            setShowChangeOwnerModal(true);
        } else if (type === "change_parent") {
            setShowChangeParentModal(true);
        } else if (type === "exclude_metrics") {
            const data = {
                exclude_from_metrics: true
            };
            serverPatch(getApiUrl(`/customers/${customerId}`), data).then((res) => {
                if (res) {
                    fetchCustomerDetails(true);
                    Notification.Success("Customer successfully excluded from metrics.");
                } else {
                    Notification.Danger("Customer update failed.")
                }
            });
        } else if (type === "include_metrics") {
            const data = {
                exclude_from_metrics: false
            };
            serverPatch(getApiUrl(`/customers/${customerId}`), data).then((res) => {
                if (res) {
                    fetchCustomerDetails(true);
                    Notification.Success("Customer successfully included in metrics.");
                } else {
                    Notification.Danger("Customer update failed.")
                }
            });
        } else if (type === "portal_link") {
            setShowCustomerPortalLinkModal(true);
        } else if (type === "objects") {
            setShowObjectsModal(true);
        } else if (type === "references") {
            setShowReferenceSummaryModal(true);
        } else if (type === "journal_entries") {
            navigate(getCompanySpecificUrl(`/reports/journal_entries?customer_id=${customerDetails.id}`));
        } else if (type === "create_checkout_session") {
            navigate(getCompanySpecificUrl(`/checkout_link/create?cid=${customerDetails.id}`));
        } else if (type === "archive") {
            setShowArchiveCustomerModal(true);
        }
    }

    const actionOptions = [
        canEditCustomer && { id: "edit", label: "Edit", icon: "fa fa-edit" },
        canEditCustomer && hasCompanyUsersReadPermission && { id: "change_owner", label: customerDetails.owner ? "Change Owner": "Assign Owner", icon: "fa fa-user" },
        canEditCustomer && _.isNil(customerDetails.parent_customer_id) && { id: "change_parent", label: customerDetails.parent_customer ? "Change Parent": "Assign Parent", icon: "fa fa-user" },
        !customerDetails.exclude_from_metrics && { id: "exclude_metrics", label: "Exclude From Metrics", icon: "fa fa-x" },
        customerDetails.exclude_from_metrics && { id: "include_metrics", label: "Include In Metrics", icon: "fa fa-check" },
        canCreateCheckoutSession && { divider: true },
        canCreateCheckoutSession && { id: "create_checkout_session", label: "Create Checkout Session", icon: "fa fa-shopping-cart"},
        { divider: true },
        { id: "objects", label: "View Billable Objects", icon: "fa fa-database"},
        settings.customer_portal_enabled && { id: "portal_link", label: "Get Portal Link", icon: "fa fa-link"},
        canArchiveCustomer && { divider: true },
        isMapleUser && { id: "references", label: "Integration References", icon: "fa fa-link"},
        isMapleUser && { id: "journal_entries", label: "Journal Entries", icon: "fa fa-link"},
        canArchiveCustomer && { divider: true },
        canArchiveCustomer && { id: "archive", label: "Archive", icon: "fa fa-trash", className: "error" },
    ];

    const creditsActionOptions = useMemo(() => {
        const options = [];

        if (totalUnpaginatedCreditsCount > 0) {
            options.push({
                variant: "alink",
                label: t("credits.actions.view_details"),
                link: getCompanySpecificUrl(`/credits?customer_id=${customerId}&disableFilterCache=true`)
            });
            options.push({
                variant: "alink",
                label: t("credits.actions.view_usage"),
                link: getCompanySpecificUrl(`/credits_log?customer_id=${customerId}&disableFilterCache=true`)
            });
        }
        
        if (hasPromotionsWritePermission) {
            options.push({
                variant: "primary",
                icon: "fa-plus",
                label: "Adjust Credit",
                onClick: adjustCredit
            });
        }

        return options;
    }, [hasPromotionsWritePermission, totalUnpaginatedCreditsCount])

    const isCreditsSummaryEmpty = creditBalance?.isEmpty && !isCreditBalanceLoading

    const renderCustomerStatus = () => {
        let statusDescription = null;
        if (customerDetails.status === "ACTIVE") {
            statusDescription = <Label.Success className="d-flex align-items-center">Active</Label.Success>
        } else if (customerDetails.status === "ARCHIVED") {
            statusDescription = <Label.Info className="d-flex align-items-center">Archived</Label.Info>
        }
        return statusDescription;
    }

    const renderOneTimeStatus = (oneTime) => {
        let statusDescription = null;
        if (oneTime.state === "APPLIED") {
            statusDescription = <Label.Success>Applied</Label.Success>
        } else if (oneTime.state === "PENDING") {
            statusDescription = <Label.Info>Pending</Label.Info>
        }
        return statusDescription;
    }

    const getCustomerActions = () => {
        if (hasWritePermission) {
            return (<DropdownMenu className="p-2" items={actionOptions} onClick={onActionSelected}>
                <div className="flex flex-row gap-1 items-center">
                    <WrenchScrewdriverIcon className="h-4 w-4"/>
                    <span>Actions</span>
                </div>
            </DropdownMenu>)
        }
        return []
    }

    const downloadFile = (uploadedFile) => {
        serverFetch(getApiUrl(`/files/download/${uploadedFile.file_id}`), {}).then((res) => {
            if (res) {
                fetch(res.url, {headers: res.headers}).then((res2) => {
                    res2.blob().then(value => {
                        downloadBlob(value, res.filename)
                    })
                })
            }
        })
    }

    const deleteFile = (customerUpload) => {
        serverDelete(getApiUrl(`/customers/${customerDetails.id}/customer_uploads/${customerUpload.id}`), {}).then((res) => {
            if (res) {
                Notification.Success("Successfully deleted " + customerUpload.original_filename);
                fetchCustomerDetails(true);
            } else {
                Notification.Danger("Unable to delete " + customerUpload.original_filename);
            }
        })
    }

    const onDeletePaymentMethod = (pm) => {
        const onDeletePaymentMethodError = async (errorRes) => {
            const message = await errorRes.json();
            if (message && message.error_message) {
                Notification.Danger("Unable to delete payment method: " + message.error_message, 5000);
            }
        }
        serverDelete(getApiUrl(`/customers/${customerId}/payment_methods/${pm.id}`), onDeletePaymentMethodError).then((res) => {
            if (res) {
                fetchPaymentMethods(true);
            }
        })
    }

    const downloadContract = (contract) => {
        const filename = `${contract.title || "Contract"}.pdf`;
        serverPost(getApiUrl(`/proposals/${contract.id}/preview`), {}, {noJson: true}).then((res) => {
            downloadBlob(res, filename);
        })
    }

    const tabItems = [{
        'label': 'Details',
        'id': 'DETAILS',
        active: activeNav === "DETAILS"
    }, {
        'label': 'Children',
        'id': 'CHILD',
        active: activeNav === "CHILD"
    }, {
        'label': 'Child Billing',
        'id': 'CHILD_BILLING',
        active: activeNav === "CHILD_BILLING"
    }]

    const hasChildren = !_.isEmpty(childCustomers);
    const renderDetailsTab = () => {
        return (
            <div className="mt-2">
                <Section title={hasChildren? "": "Details"} actions={[]}>
                    <KeyValueDisplay2 items={details} />
                </Section>
                <Section title="Metadata" className="mt-4" actions={canEditCustomer && [
                    {variant: "text-primary", icon: "fa-edit", label: t('common.edit'), onClick: editMetadata}
                ]} subtitle="These are fields that are stored on the customer for internal reference.">
                    {
                        (!isEditingMetadata && (_.isNil(customerDetails.metadata) || _.isEmpty(customerDetails.metadata))) ?
                            <p className="text-gray-500">No metadata</p>
                            : <KeyValueDisplay items={customerDetails.metadata} isEditing={isEditingMetadata}
                                               onCancelEditing={() => setIsEditingMetadata(false)}
                                               onUpdateKeyValues={saveMetadata}/>
                    }
                </Section>
                <Section title="Documents" className="mt-4" actions={canEditCustomer && [
                    {variant: "text-primary", icon: "fa-edit", label: "Upload", onClick: uploadFile}
                ]}>
                    {
                        _.isEmpty(customerDetails.customer_uploads) ?
                            <p className="text-gray-500">No uploaded documents</p>
                            : <MapleTable>
                                <MapleTable.Content>
                                    <tbody className="divide-y divide-gray-200">
                                    {
                                        _.map(customerDetails.customer_uploads, (ufile, i) =>
                                            <tr key={i}>
                                                <td>{ ufile.original_filename }</td>
                                                <td className="text-end">
                                                    <div className="flex flex-row gap-2 justify-end items-center">
                                                        <ArrowDownTrayIcon className="cursor-pointer h-4 w-4" onClick={() => downloadFile(ufile)}/>
                                                        <DeleteButton size="sm" onDelete={() => deleteFile(ufile)} />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </MapleTable.Content>
                            </MapleTable>
                    }
                </Section>
                {
                    hasPricingPermission && !_.isEmpty(settings) && settings.collect_taxes_type !== "none" &&
                    <Section title="Taxes" className="mt-4">
                        {
                            settings.collect_taxes_type === "automatic" && _.isEmpty(taxes) &&
                            <span>Taxes are calculated automatically for this customer based on their address. <a href="#" onClick={showManageTaxes}>Manually manage</a> their taxes instead.</span>
                        }
                        {
                            settings.collect_taxes_type === "automatic" && !_.isEmpty(taxes) &&
                            <span>Taxes are manually managed for this customer. Delete all the taxes listed below to switch to automatic tax collection.</span>
                        }
                        {
                            (showCustomTaxForm || !_.isEmpty(taxes)) &&
                            <>
                                <BaseForm ref={formRef} initialFormFields={addTaxInitialFields} onSubmit={addTax}>
                                    <Row>
                                        <Col lg="6">
                                            <Table borderless className="no-background">
                                                <tbody>
                                                <tr>
                                                    <th width="40%">Type</th>
                                                    <th width="40%">Rate</th>
                                                    <th></th>
                                                </tr>
                                                {
                                                    _.map(taxes, (tax, i) =>
                                                        <tr className="thin" key={i}>
                                                            <td><span className="body2">{ tax.type }</span></td>
                                                            <td><span className="body2">{ tax.rate * 100 }%</span></td>
                                                            <td><DeleteButton onDelete={() => deleteTax(tax)}></DeleteButton></td>
                                                        </tr>
                                                    )
                                                }
                                                {
                                                    !_.isEmpty(filteredTaxTypeOptions) &&
                                                    <tr className="thin-horizontal">
                                                        <td width="40%">
                                                            <BaseForm.Input type="select" name="type" options={filteredTaxTypeOptions} showSearch={false} />
                                                        </td>
                                                        <td width="40%">
                                                            <BaseForm.Input type="number" name="rate" step="0.01" label="Rate" hideLabel required />
                                                        </td>
                                                        <td style={{verticalAlign: "top", paddingBottom: "5px"}}>
                                                            <SubmitButton variant="primary">Add</SubmitButton>
                                                        </td>
                                                    </tr>
                                                }
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </BaseForm>
                            </>
                        }
                    </Section>
                }
                <div className="mt-4"/>
                {
                    hasSubscriptionsPermission &&
                    <SubscriptionListSection
                        ref={subscriptionListRef}
                        customer={customerDetails}
                        hideCancelled={true}
                        extraQueryFilters={extraQueryFilters}
                        limit={10}
                        hideMore={true}
                        includeCustomer={false}
                        includeDescription={true}
                        showTableActions={false}
                        hideTableIfEmpty={true}
                    />
                }
                {
                    hasProposalsPermission && !_.isEmpty(contracts) &&
                    <Section
                        title="Contracts"
                        actions={hasProposalsWritePermission && [{
                            variant: "primary",
                            icon: "fa-plus",
                            label: "Create Contract",
                            link: getCompanySpecificUrl(`/contract/create?customer_id=${customerId}`)
                        }]}>
                        <MapleTable>
                            <MapleTable.Content>
                                <thead>
                                <tr>
                                    <MapleTable.TH className="d-none d-md-table-cell">Title</MapleTable.TH>
                                    <MapleTable.TH>Status</MapleTable.TH>
                                    <MapleTable.TH className="text-end">Contract Value</MapleTable.TH>
                                    <MapleTable.TH></MapleTable.TH>
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                {
                                    _.map(contracts, (row, i) =>
                                        <MapleTable.TR key={i} className="cursor-pointer" href={getCompanySpecificUrl(`/contract/view/${row.id}`)}>
                                            <td className="d-none d-md-table-cell">{ row.title }</td>
                                            <td className="break-words">{ renderContractStatusLabel(row) }</td>
                                            <td className="text-end">
                                                <span>{ currencyFormatFromPrice(row.tcv) }</span><br/>
                                                <span className="caption italic">{ currencyFormatFromPrice(row.acv) }/yr</span><br/>
                                            </td>
                                            <td>
                                            {
                                                _.includes(["EXECUTED", "COMPLETE", "PENDING_PAYMENT"], row.status) &&
                                                    <Link onClick={() => downloadContract(row)}>
                                                        <ArrowDownTrayIcon className="w-5 h-5"/>
                                                    </Link>
                                            }
                                            </td>
                                        </MapleTable.TR>
                                    )
                                }
                                </tbody>
                            </MapleTable.Content>
                        </MapleTable>
                    </Section>
                }
                {
                    hasPricingPermission && !_.isEmpty(oneTimeBillables) &&
                    <Section title="Pending Charges" className="mt-4"
                             subtitle="These pending charges will apply to the next generated invoice for this customer."
                             actions={hasInvoicesWritePermission && [{
                                 variant: "primary",
                                 icon: "fa-plus",
                                 label: "Add Charges",
                                 onClick: () => setShowAddOneTimeChargesToCustomerModal(true)
                             }]}>
                        <MapleTable>
                            <MapleTable.Content>
                                <thead>
                                <tr>
                                    <MapleTable.TH>Name</MapleTable.TH>
                                    <MapleTable.TH>Price</MapleTable.TH>
                                    <MapleTable.TH>State</MapleTable.TH>
                                    <MapleTable.TH>Quantity</MapleTable.TH>
                                    <MapleTable.TH>Created Date</MapleTable.TH>
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                {
                                    _.map(oneTimeBillables, (row, i) =>
                                        <tr key={i}>
                                            <td>{ row.name }</td>
                                            <td>{ renderProductPricing(row.product_pricing, billableItems) }</td>
                                            <td>{ renderOneTimeStatus(row) }</td>
                                            <td>{ row.aggregate }</td>
                                            <td>{ moment(row.created_at).format("DD MMM, YYYY h:mm:ssa") }</td>
                                        </tr>
                                    )
                                }
                                </tbody>
                            </MapleTable.Content>
                        </MapleTable>
                    </Section>
                }
                {
                    hasPaymentsPermission &&
                    <Section title="Payment Methods" className="mt-4" actions={[settings.payment_provider_count > 0 && hasWritePermission && {
                        variant: "primary",
                        icon: "fa-plus",
                        label: "Add Payment Method",
                        onClick: () => setShowSetupPaymentMethodModal(true)
                    }]} right={
                        <span className="inline-flex flex-row gap-3 mr-3 items-center">
                            <Link onClick={() => setShowRequestPaymentMethodModal(true)}>
                                <i className={"fa fa-credit-card"}/> Request from customer
                            </Link>
                        </span>
                    }>
                        {
                            (_.isNil(paymentMethods) || _.isEmpty(paymentMethods)) ?
                                <p className="text-gray-500">No payment methods</p>
                                : <MapleTable>
                                    <MapleTable.Content overflow>
                                        <thead>
                                        <tr>
                                            <MapleTable.TH>Payment Method</MapleTable.TH>
                                            <MapleTable.TH></MapleTable.TH>
                                            <MapleTable.TH></MapleTable.TH>
                                        </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200">
                                        {
                                            _.map(paymentMethods, (row, i) =>
                                                <tr key={i}>
                                                    <td>{ renderPaymentMethod(t, row) }</td>
                                                    <td>
                                                        <div className="d-flex flex-row gap-2">
                                                            {
                                                                row.default &&
                                                                <Label.Success><i className="fa fa-check mr-1"/> Default</Label.Success>
                                                            }
                                                            {
                                                                isPaymentMethodExpiring(row) &&
                                                                <Label.Warning>Expiring</Label.Warning>
                                                            }
                                                            {
                                                                isPaymentMethodExpired(row) &&
                                                                <Label.Danger>Expired</Label.Danger>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td className="text-end">
                                                        <div className="flex flex-row gap-1 justify-content-end items-center">
                                                            {
                                                                !row.default &&
                                                                    <div>
                                                                        <MoreButton items={paymentActionOptions} onSelect={(action) => onPaymentActionSelected(action, row)} />
                                                                    </div>
                                                            }
                                                            <DeleteButton
                                                                size="sm" onDelete={() => onDeletePaymentMethod(row)}
                                                                title="Confirm Delete Payment Method?"
                                                                body={
                                                                <>
                                                                    <p>Are you sure you want to delete the payment method?</p>
                                                                    <span className="caption italic">Note: This action can not be reversed once completed.</span>
                                                                </>}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </MapleTable.Content>
                                </MapleTable>
                        }
                    </Section>
                }
                {hasCreditsReadPermission && (
                    <Section
                        title="Credits"
                        className="mt-4"
                        actions={creditsActionOptions}
                        loading={isCreditBalanceLoading || isCreditsLoading}
                    >
                        { isCreditsSummaryEmpty ? (
                            <p className="text-gray-500">No active credits</p>
                        ) : (
                            <CreditSummaryTable
                                creditBalanceData={{
                                    creditBalance,
                                    isLoading: isCreditBalanceLoading,
                                }}
                            />
                        )}
                    </Section>
                )}
                <div className="mt-4"/>
                <DiscountListSection customer={customerDetails}/>
                {
                    hasInvoicesPermission &&
                    <div className="mt-4"/>
                }
                {
                    hasInvoicesPermission &&
                    <InvoiceListSection
                        ref={invoiceListRef}
                        includeCustomer={false}
                        customer={customerDetails}
                        showTableActions={false}
                        actions={hasInvoicesWritePermission && [{
                            variant: "primary",
                            icon: "fa-plus",
                            label: "Add Charges",
                            onClick: () => setShowAddOneTimeChargesToCustomerModal(true)
                        }]}
                        limit={10}
                        hideMore={true}
                        extraQueryFilters={extraQueryFilters}
                        hideTableIfEmpty
                    />
                }
                {
                    hasPaymentsPermission &&
                    <div className="mt-4"/>
                }
                {
                    hasPaymentsPermission &&
                    <PaymentListSection
                        ref={paymentListRef}
                        includeCustomer={false}
                        customer={customerDetails}
                        showTableActions={false}
                        extraQueryFilters={extraQueryFilters}
                        limit={10}
                        hideMore={true}
                        hideTableIfEmpty
                    />
                }
                {
                    !_.isEmpty(customerDetails.integration_references) &&
                        <Section title="Integration References">
                            <MapleTable>
                                <MapleTable.Content>
                                    <thead>
                                        <tr>
                                            <MapleTable.TH>Integration</MapleTable.TH>
                                            <MapleTable.TH>Reference</MapleTable.TH>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                    {
                                        _.map(customerDetails.integration_references, (rr, m) =>
                                            <tr key={m}>
                                                <td>
                                                    <div className="flex flex-row gap-2 items-center">
                                                        <Image src={ rr.integration_icon } className="w-8 h-8" />
                                                        { rr.integration_name }
                                                    </div>
                                                </td>
                                                <td>{ rr.remote_id }</td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </MapleTable.Content>
                            </MapleTable>
                        </Section>
                }
            </div>
        )
    }

    const renderChildTab = () => {
        return <div className="mt-4">
            <Section title="Child Customers">
                <MapleTable>
                    <MapleTable.Content>
                        <thead>
                        <tr>
                            <MapleTable.TH>Name</MapleTable.TH>
                            <MapleTable.TH className="text-end">MRR</MapleTable.TH>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                        {
                            _.map(childCustomers, (row, i) =>
                                <MapleTable.TR className="cursor-pointer" href={getCompanySpecificUrl(`/customer/${row.id}`)}>
                                    <td key={i}>
                                        <Columns.CustomerName customer={row} />
                                    </td>
                                    <td>
                                        <div className="flex flex-row gap-1 justify-end items-center">
                                            {
                                                row.exclude_from_metrics &&
                                                <div>
                                                    <BaseOverlayTrigger content={"Customer is excluded from metrics calculation"}>
                                                        <ExclamationTriangleIcon className="h-5 w-5"/>
                                                    </BaseOverlayTrigger>
                                                </div>
                                            }
                                            { currencyFormatFromPrice(row.mrr) }
                                        </div>
                                    </td>
                                </MapleTable.TR>
                            )
                        }
                        </tbody>
                    </MapleTable.Content>
                </MapleTable>
            </Section>
            {
                hasSubscriptionsPermission &&
                    <SubscriptionListSection
                        ref={subscriptionListRef}
                        title={"Child Subscriptions"}
                        customer={customerDetails}
                        hideCancelled={false}
                        hideCreation={true}
                        extraQueryFilters={childSubscriptionExtraQueryFilters}
                        limit={10}
                        hideMore={true}
                        includeCustomer={true}
                        includeDescription={true}
                        showTableActions={false}
                        hideTableIfEmpty={true}
                    />
            }
        </div>
    }

    const saveChildRollupBillingInvoiceDisplayFields = (data) => {
        const fieldsData = {
            child_rollup_billing_config: {
                invoice_additional_display_fields: _.map(data, (value, key) => {
                    return {name: key, value: value}
                })
            }
        };
        serverPatch(getApiUrl(`/customers/${customerId}`), fieldsData).then((res) => {
            if (res) {
                fetchCustomerDetails(true);
            }
            setIsEditingChildRollupBillingInvoiceDisplayFields(false);
        });
    }

    const renderChildBillingTab = () => {
        const showOwnerColumn = _.some(childCustomers, (c) => !_.isNil(c.owner))
        return (
            <div className="mt-4">
                <Section>
                    <KeyValueDisplay2 items={childDetails} />
                </Section>
                {
                    customerDetails.child_rollup_billing &&
                        <Section
                            title="Additional Information"
                            subtitle="These are additional fields that will be included on invoices for this subscription such as purchase order or reference numbers."
                            className="my-4" actions={hasWritePermission && [
                            {variant: "text-primary", icon: "fa-edit", label: t('common.edit'), onClick: () => setIsEditingChildRollupBillingInvoiceDisplayFields(true)}
                        ]}>
                            {
                                (!isEditingChildRollupBillingInvoiceDisplayFields && (_.isNil(customerDetails.child_rollup_billing_config) || _.isNil(customerDetails.child_rollup_billing_config.invoice_additional_display_fields) || _.isEmpty(customerDetails.child_rollup_billing_config.invoice_additional_display_fields))) ?
                                    <p className="text-gray-500">No additional information</p>
                                    : <KeyValueDisplay items={_.mapValues(_.keyBy(customerDetails.child_rollup_billing_config.invoice_additional_display_fields, 'name'), 'value')} isEditing={isEditingChildRollupBillingInvoiceDisplayFields}
                                                       onCancelEditing={() => setIsEditingChildRollupBillingInvoiceDisplayFields(false)}
                                                       onUpdateKeyValues={saveChildRollupBillingInvoiceDisplayFields}/>
                            }
                        </Section>
                }
                {
                    hasInvoicesPermission &&
                    <InvoiceListSection
                        ref={invoiceListRef}
                        includeCustomer={false}
                        customer={customerDetails}
                        showTableActions={false}
                        limit={10}
                        hideMore={true}
                        extraQueryFilters={childInvoiceParentBilledExtraQueryFilters}
                        hideTableIfEmpty
                    />
                }
            </div>
        )
    }

    const renderCustomerDetails = () => {
        return <>
            <ContentContainer variant="page" title={
                <div className="d-flex flex-row gap-3">
                    <span>{ getCustomerNameOrEmail(customerDetails) }</span>
                    <div className="d-flex align-items-center gap-3">
                        { renderCustomerStatus() }
                    </div>
                </div>
            } customActions={getCustomerActions()}>
                {
                    customerDetails.managed_externally &&
                    <>
                        <Row className="align-items-center">
                            <Col lg="9">
                                <Alert variant="info">
                                    This customer is currently managed in { getNameForProviderType(customerDetails.imported_from) }.
                                    We will pull in any changes in { getNameForProviderType(customerDetails.imported_from) } for this customer.
                                    However, you will not be able to make any changes to this customer here.
                                </Alert>
                            </Col>
                            <Col lg="6">
                                <div className="d-flex flex-column">
                                    <h5>Start Managing in Maple</h5>
                                    <span className="body1">Please note that once the customer is being managed in Maple, changes
                                            in { getNameForProviderType(customerDetails.imported_from) } will not be copied over here.
                                        You can make changes in Maple going forward.
                                    </span>
                                </div>
                            </Col>
                            <Col lg="3">
                                <ConfirmationButton
                                    body={<>
                                        <p>Are you sure you want to proceed?</p>
                                        <p className="mt-2 text-sm">Please note that once the customer is being managed in Maple,
                                                changes in { getNameForProviderType(customerDetails.imported_from) } will not be
                                                copied over here. You can make changes in Maple going
                                                forward.</p>
                                    </>}
                                    onConfirm={() => manageCustomer()}
                                    buttonVariant="primary"
                                >
                                    Start Managing
                                </ConfirmationButton>
                            </Col>
                        </Row>
                        <br/>
                    </>
                }
                { hasChildren && <SectionNav items={tabItems} onClick={(tabId) => changeNav(tabId)} /> }
                { activeNav === "DETAILS" && renderDetailsTab() }
                { activeNav === "CHILD" && renderChildTab() }
                { activeNav === "CHILD_BILLING" && renderChildBillingTab() }
            </ContentContainer>
            <SimpleModal
                show={showArchiveCustomerModal} onClose={onModalClose} title="Archive Customer?"
                buttonTitle="Archive" buttonVariant="danger" onConfirm={archiveCustomer}
                body={
                    <div>
                        <span>Are you sure you want to archive the customer?</span>
                    </div>
                }
            />
            <SimpleModal
                show={showRequestPaymentMethodModal} onClose={onModalClose} title="Request payment method from customer"
                body={
                    <div>
                        <Loader loading={_.isNil(requestPaymentMethodLink)}>
                        <p>Below is the link to a hosted page for the customer to enter their payment details, which can be used for current or future payments.</p>
                        <p className="my-4">
                            <CopyableComponent
                                value={<a className="">{requestPaymentMethodLink}</a>}
                                copyableValue={requestPaymentMethodLink}
                            />
                        </p>
                        </Loader>
                    </div>
                }
            />
            <AdjustCreditModal show={showAdjustCreditModal} onClose={onModalClose} customer={customerDetails} />
            <SetupPaymentMethodModal show={showSetupPaymentMethodModal && false} onClose={onModalClose}
                                     paymentConfig={settings.payment_config} customer={customerDetails} company={company} />
            <CustomerPaymentMethodSetupModal
                show={showSetupPaymentMethodModal} onClose={onModalClose}
                settings={settings} customer={customerDetails}
            />
            <AddOneTimeChargesToCustomerModal show={showAddOneTimeChargesToCustomerModal} onClose={onModalClose}
                                              customer={customerDetails} />
            <AddOrEditCustomerModal show={showAddOrEditCustomerModal} onClose={onModalClose} itemToEdit={customerDetails} />
            <UploadFileModal show={showUploadFileModal} onClose={onModalClose} customer_id={customerDetails && customerDetails.id} />
            {canEditCustomer && hasCompanyUsersReadPermission && <ChangeOwnerModal show={showChangeOwnerModal} onClose={onModalClose} customer={customerDetails} /> }
            <UpdateAvalaraCustomerEntityCodeModal show={showUpdateAvalaraCustomerEntityCodeModal} onClose={onModalClose} customer={customerDetails} avalaraIntegration={avalaraIntegration} />
            {canEditCustomer && <ChangeParentModal show={showChangeParentModal} onClose={onModalClose} customer={customerDetails} />}
            <EditChildRollupBillingModal show={showChildRollupBillingModal} onClose={onModalClose} customer={customerDetails} />
            <CustomerObjectsModal show={showObjectsModal} onClose={onModalClose} customer={customerDetails} />
            <ShareCustomerPortalLinkModal show={showCustomerPortalLinkModal} onClose={onModalClose} customer={customerDetails} />
            <IntegrationReferenceEntitySummaryModal
                show={showReferenceSummaryModal} onClose={onModalClose} entityKey={"CUSTOMER"}
                referenceID={customerId}
            />
        </>
    }

    return (
        <BaseContainer>
            <Loader loading={customerLoading}>
            {
                _.isEmpty(customerDetails) ?
                    <ErrorComponent
                        title={"Customer Not Found"}
                        description={"Oops, we can't find the customer you are looking for."}
                        primaryAction={{ href:getCompanySpecificUrl("/customers"), label:"Show all customers" }}
                        hideSupport
                    />
                : renderCustomerDetails()
            }
            </Loader>
        </BaseContainer>
    );
}

export default Customer;
