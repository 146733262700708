import '../App.scss';
import '../css/invoice.scss';
import React, {useEffect, useState, useContext, createRef, useRef} from 'react';
import {serverFetch, serverPatch, notifyEvent, serverPost} from '../helpers/server';
import { BaseContext, IntegrationContext } from '../helpers/common';
import {getMappingFromFields, processEntityFieldsFromFields} from "../helpers/integrations";
import IntegrationEntityFieldMapComponent from './IntegrationEntityFieldMapComponent'
import {useParams} from "react-router-dom";
import Button from './common/buttons/Button';
import Notification from '../components/Notification';
import SectionNav from "./SectionNav";
import Link from "./Link";
import Section from "./Section";
const _ = require('lodash');

function IntegrationFieldMapComponent(props) {
    const { getApiUrl } = useContext(BaseContext);
    const { integration } = useContext(IntegrationContext);
    let { feature } = useParams();
    const [entities, setEntities] = useState([]);
    const [availableFeatureEntityFieldMappings, setAvailableFeatureEntityFieldMappings] = useState([]);
    const [initialMapping, setInitialMapping] = useState(null);
    const [isRemoteToMaple, setIsRemoteToMaple] = useState(null);
    const [activeNav, setActiveNav] = useState("push_to_remote");
    const [featureFieldName, setFeatureFieldName] = useState(null);
    const selectedFeature = feature || props.feature
    const fieldMapRefs = useRef({});

    const entitySortOrder = ["CUSTOMER", "CONTRACT", "INVOICE", "PAYMENT"];

    useEffect(() => {
        fetchIntegrationMapping();
    }, [selectedFeature]);

    useEffect(() => {
        if (selectedFeature.toUpperCase() === "ACCOUNTING") {
            setInitialMapping(integration.accounting_data ? integration.accounting_data.remote_to_maple_field_mapping: []);
            setIsRemoteToMaple(true);
            setFeatureFieldName("accounting_data");
        } else if (selectedFeature.toUpperCase() === "CRM") {
            setInitialMapping(integration.crm_data ? integration.crm_data.remote_to_maple_field_mapping: []);
            setIsRemoteToMaple(true);
            setFeatureFieldName("crm_data");
        }
    }, [selectedFeature, integration])

    const fetchIntegrationMapping = () => {
        serverFetch(getApiUrl(`/integrations/${integration.id}/${selectedFeature.toUpperCase()}/available_fields`), { skipCache: true }).then(res => {
            if (res) {
                setAvailableFeatureEntityFieldMappings(res[selectedFeature.toUpperCase()]);
                setEntities(_.map(res[selectedFeature.toUpperCase()], ifef => {
                    return ifef.entity
                }));
            }
        });
    }

    const refreshIntegrationFieldMapping = () => {
        serverPost(getApiUrl(`/integrations/${integration.id}/${selectedFeature.toUpperCase()}/refresh_fields`), {}).then(res => {
            if (res) {
                setAvailableFeatureEntityFieldMappings(res[selectedFeature.toUpperCase()]);
                setEntities(_.map(res[selectedFeature.toUpperCase()], ifef => {
                    return ifef.entity
                }));
                Notification.Success(`Successfully reloaded fields from ${integration.name}`)
            }
        });
    }

    const onUpdateError = (res, entity) => {
        Notification.Danger(`Unable to update field mapping from ${integration.name} - ${_.upperFirst(_.lowerCase(entity))}`)
    }

    const onUpdateFieldMapping = (entity, isRemoteToMaple, mapping) => {
        const updateData = {}
        if (isRemoteToMaple) {
            updateData['remote_to_maple_field_mapping'] = mapping
        } else {
            updateData['maple_to_remote_field_mapping'] = mapping
        }
        serverPost(getApiUrl(`/integrations/${integration.id}/${selectedFeature.toUpperCase()}/${entity.toUpperCase()}/update_field_mapping`), updateData, {}, (res) => onUpdateError(res, entity)).then(res => {
            if (res) {
                setAvailableFeatureEntityFieldMappings(res[selectedFeature.toUpperCase()]);
                setEntities(_.map(res[selectedFeature.toUpperCase()], ifef => {
                    return ifef.entity
                }));
                Notification.Success(`Successfully updated field mapping from ${integration.name} - ${_.upperFirst(_.lowerCase(entity))}`)
            }
        });
    }

    const onUpdateMapping = (entity, mapping) => {
        // if (!initialMapping) {
        //     Notification.Danger("Unable to save field mapping");
        // }

        let updatedFeatureData = {};
        updatedFeatureData[featureFieldName] = {}
        if (isRemoteToMaple) {
            updatedFeatureData[featureFieldName]["remote_to_maple_field_mapping"] = {};
            _.find(entities, (m) => {
                let entityKey = m.entity;
                let updatedMapping = mapping;
                if (entityKey !== entity) {
                    const initialItem = _.find(initialMapping, (im) => im.string === entityKey)
                    if (initialItem) {
                        updatedMapping = getMappingFromFields(initialItem.fields, isRemoteToMaple);
                    } else {
                        updatedMapping = getMappingFromFields(m.available_fields, isRemoteToMaple);
                    }
                }
                updatedFeatureData[featureFieldName]["remote_to_maple_field_mapping"][entityKey] = _.map(updatedMapping, (v, k) => {
                    return {remote_field: k, maple_field: v}
                });
            });
        } else {
            updatedFeatureData[featureFieldName]["maple_to_remote_field_mapping"] = {};
            _.find(entities, (m) => {
                let entityKey = m.entity;
                let updatedMapping = mapping;
                if (entityKey !== entity) {
                    const initialItem = _.find(initialMapping, (im) => im.string === entityKey)
                    if (initialItem) {
                        updatedMapping = getMappingFromFields(initialItem.fields, isRemoteToMaple);
                    } else {
                        updatedMapping = getMappingFromFields(m.available_fields, isRemoteToMaple);
                    }
                }
                updatedFeatureData[featureFieldName]["remote_to_maple_field_mapping"][entityKey] = _.map(updatedMapping, (v, k) => {
                    return {maple_field: k, remote_field: v}
                });
            });
        }

        updateFeatureData(updatedFeatureData)
    }

    const onBulkUpdateMapping = () => {
        let updatedFeatureData = {};
        updatedFeatureData[featureFieldName] = {}
        if (isRemoteToMaple) {
            updatedFeatureData[featureFieldName]["remote_to_maple_field_mapping"] = {};
            _.map(fieldMapRefs.current, (entityRef, key) => {
                updatedFeatureData[featureFieldName]["remote_to_maple_field_mapping"][key] = _.map(entityRef.current.getUpdatedMapping(), (v, k) => {
                    return {remote_field: k, maple_field: v}
                });
            })
        } else {
            updatedFeatureData[featureFieldName]["maple_to_remote_field_mapping"] = {};
            _.map(fieldMapRefs.current, (entityRef, key) => {
                updatedFeatureData[featureFieldName]["remote_to_maple_field_mapping"][key] = _.map(entityRef.current.getUpdatedMapping(), (v, k) => {
                    return {maple_field: k, remote_field: v}
                });
            });
        }

        updateFeatureData(updatedFeatureData)
    }

    const updateFeatureData = (featureData) => {
        const updateData = {
            feature_data: featureData
        }

        serverPatch(getApiUrl(`/integrations/${integration.id}`), updateData).then(res => {
            if (res) {
                notifyEvent('integration_settings');
                fetchIntegrationMapping();
                if (props.onUpdate) {
                    props.onUpdate();
                }
            }
        })
    }

    const renderEntity = (entity, i, showRemoteToMaple) => {
        const availableMappings = _.find(availableFeatureEntityFieldMappings, mapping => mapping.entity === entity)
        if (!availableMappings) {
            return;
        }

        const ref = createRef();
        fieldMapRefs.current[entity.entity] = ref;
        return (
            <div key={i}>
                {
                    !_.isNil(availableMappings.available_remote_to_maple_field_mapping) && showRemoteToMaple &&
                        <IntegrationEntityFieldMapComponent
                            ref={ref} entity={entity}
                            availableFieldMappings={processEntityFieldsFromFields(availableMappings.available_remote_to_maple_field_mapping, true)}
                            initialMapping={availableMappings.remote_to_maple_field_mapping} isRemoteToMaple={true}
                            forceEditing={props.bulkEditing} onUpdate={(data) => onUpdateFieldMapping(entity, true, data)}
                        />
                }
                {
                    !_.isNil(availableMappings.available_maple_to_remote_field_mapping) && !showRemoteToMaple &&
                    <IntegrationEntityFieldMapComponent
                        ref={ref} entity={entity}
                        availableFieldMappings={processEntityFieldsFromFields(availableMappings.available_maple_to_remote_field_mapping, true)}
                        initialMapping={availableMappings.maple_to_remote_field_mapping} isRemoteToMaple={false}
                        forceEditing={props.bulkEditing} onUpdate={(data) => onUpdateFieldMapping(entity, false, data)}
                    />
                }
            </div>
        )
    }

    const tabItems = [{
        'label': `Push to ${integration.name}`,
        'id': 'push_to_remote',
        active: activeNav === "push_to_remote"
    }, {
        'label': `Pull from ${integration.name}`,
        'id': 'pull_from_remote',
        active: activeNav === "pull_from_remote"
    }]

    const sortedEntities = _.sortBy(entities, (e) => _.indexOf(entitySortOrder, e));
    const hasRemoteToMapleFields = _.some(availableFeatureEntityFieldMappings, (m) => !_.isNil(m.available_remote_to_maple_field_mapping))
    const hasMapleToRemoteFields = _.some(availableFeatureEntityFieldMappings, (m) => !_.isNil(m.available_maple_to_remote_field_mapping))
    const showMenu = hasRemoteToMapleFields && hasMapleToRemoteFields;
    return (
        <div className={props.className}>
            {
                showMenu && <SectionNav items={tabItems} onClick={(tabId) => setActiveNav(tabId)}/>
            }
            <div className="flex flex-row justify-end hide">
                <Button onClick={() => refreshIntegrationFieldMapping()}>Re-fetch Fields from { integration.name }</Button>
            </div>
            {
                activeNav === "push_to_remote" &&
                <Section title={`Field Mapping`}>
                    <div className="mb-3">
                        Fields can be mapped between {integration.name} and Maple. The fields from {integration.name} are cached in Maple, so if you do make any
                        changes in {integration.name}, please <Link onClick={() => refreshIntegrationFieldMapping()}>reload the fields</Link>.</div>
                    <div className="mb-2 mt-2">Choose how you would like the fields mapped when Maple is pushing data to { integration.name }</div>
                    { _.map(sortedEntities, (entity, i) => renderEntity(entity, i, true)) }
                </Section>
            }
            {
                activeNav === "pull_from_remote" &&
                <Section title={`Field Mapping`}>
                    <div className="mb-3">
                        Fields can be mapped between {integration.name} and Maple. The fields from {integration.name} are cached in Maple, so if you do make any
                        changes in {integration.name}, please <Link onClick={() => refreshIntegrationFieldMapping()}>reload the fields</Link>.</div>
                    <div className="mb-2 mt-2">Choose how you would like the fields mapped when Maple is pushing data to { integration.name }</div>
                    { _.map(sortedEntities, (entity, i) => renderEntity(entity, i, false)) }
                </Section>
            }
            {
                props.bulkEditing &&
                    <Button onClick={onBulkUpdateMapping}>Update Mapping</Button>
            }
        </div>
    );
}

export default IntegrationFieldMapComponent;
