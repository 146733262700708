import '../../App.scss';
import '../../css/modals.scss';
import BaseModal from './BaseModal';
import { useContext, useState, useEffect } from 'react';
import {BaseContext, currencyFormatFromPrice} from '../../helpers/common';
import { serverPost } from '../../helpers/server';
import Button from '../common/buttons/Button';
import Loader from "../Loader";
import MapleTable from "../MapleTable";
import moment from 'moment';
import BaseSideModal from "./BaseSideModal";
const _ = require("lodash");

function SubscriptionJournalEntriesModal(props) {
    const { getApiUrl, getCompanySpecificUrl } = useContext(BaseContext);
    const [subscription, setSubscription] = useState({});
    const [loading, setLoading] = useState(true);
    const [entries, setEntries] = useState([]);

    useEffect(() => {
        setSubscription(props.subscription);
    }, [props.show, props.subscription])

    useEffect(() => {
        if (!_.isEmpty(subscription)) {
            fetchJournalEntries();
        }
    }, [props.show, subscription]);

    const fetchJournalEntries = async () => {
        const params = {
            query: {
                customer_id: subscription.customer_id,
                subscription_id: subscription.id,
            },
            pagination: {
                from_key: null,
                limit: 100,
            },
        }
        const result = await serverPost(getApiUrl(`/journal_entries/find`), params);
        if (result) {
            setEntries(_.sortBy(result.results, 'entry_time'));
        }
        setLoading(false);
    }

    if (!props.show) {
        return;
    }

    return (
        <BaseSideModal size="lg" {...props}>
            <BaseSideModal.Form>
            <BaseSideModal.Header title={`Journal Entries for Subscription (${subscription && subscription.id})`}/>
            <BaseSideModal.Body>
                <Loader loading={loading}>
                    <p>Journal entries for this subscription.</p>
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                            <tr>
                                <MapleTable.TH>Time</MapleTable.TH>
                                <MapleTable.TH>Account</MapleTable.TH>
                                <MapleTable.TH>Amount</MapleTable.TH>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                        {
                            _.map(entries, (entry, i) =>
                                <tr key={i}>
                                    <td>{ moment(entry.entry_time).utc().format("YYYY-MM-DD") }</td>
                                    <td>{ entry.destination_journal_account && entry.destination_journal_account.account_type }</td>
                                    <td>{ currencyFormatFromPrice(entry.amount) }</td>
                                </tr>
                            )
                        }
                        </tbody>
                    </table>
                </Loader>
            </BaseSideModal.Body>
            <BaseSideModal.Footer>
                <div className="d-flex flex-row align-items-end">
                    <Button variant="primary" onClick={() => props.onClose(false)}>Close</Button>
                </div>
            </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );

}

export default SubscriptionJournalEntriesModal;
