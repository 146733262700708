import '../../../App.scss';
import React, { useEffect, useContext, useState } from 'react';
import { serverPost } from '../../../helpers/server';
import {BaseContext, IntegrationContext, UserContext} from '../../../helpers/common';
import ContentContainer from '../../../components/ContentContainer';
import Label from '../../../components/Label';
import InfiniteScroll from "react-infinite-scroll-component";
import moment from 'moment';
import MapleTable from "../../../components/MapleTable";
import {useParams} from "react-router-dom";
import Loader from "../../../components/Loader";
import {renderMapleEntity, renderRemoteData} from "../../../helpers/integrations";
const _ = require('lodash');

function Sync() {
    const { getApiUrl, getCompanySpecificUrl, setPageTitle } = useContext(BaseContext);
    let { feature, syncId } = useParams();
    feature = _.replace(_.upperCase(feature), " ", "_");
    const { integration, getIntegrationSpecificUrl } = useContext(IntegrationContext);

    const [loading, setLoading] = useState(true);
    const [sort, setSort] = useState("idDesc");
    const [hasMore, setHasMore] = useState(true);
    const [fromKey, setFromKey] = useState(null);
    const [syncLogs, setSyncLogs] = useState([]);

    useEffect(() => {
        setPageTitle(`${integration.name} - Sync Logs`);
    }, [integration]);

    const onSearch = (restart = true) => {
        const limit = 50;
        const params = {
            sort_key: sort || "idDesc",
            pagination: {
                from_key: restart ? null: fromKey,
                limit: limit
            },
            query: {
                feature: feature
            }
        }
        serverPost(getApiUrl(`/integrations/${integration.id}/syncs/${syncId}/logs/find`), params).then((res) => {
            setLoading(false);
            if (res) {
                const results = res.results || [];
                if (restart) {
                    setSyncLogs(results);
                } else {
                    setSyncLogs(_.concat(syncLogs, results));
                }
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
        });
    };

    useEffect(() => {
        onSearch(true);
    }, []);

    const renderStatus = (log) => {
        if (log.status === "SUCCESS") {
            return (<Label.Success>Succeeded</Label.Success>);
        } else if (log.status === "FAILURE") {
            return (<Label.Danger>Failed</Label.Danger>);
        }
    }

    const renderAction = (log) => {
        if (log.action === "CREATE") {
            return <span>Create</span>;
        } else if (log.action === "UPDATE") {
            return <span>Update</span>;
        } else if (log.action === "IGNORED") {
            return <span>Ignore</span>;
        }
    }

    const renderFailureReason = (log) => {
        if (log.status === "FAILURE") {
            return (
                <div className="max-w-sm">
                    <span>{log.failure_data.error_string}</span>
                </div>
            );
        }
    }

    return (
        <ContentContainer>
            <h5>Sync Details</h5>
            <InfiniteScroll
                dataLength={syncLogs.length}
                next={() => onSearch(false)}
                hasMore={hasMore}
                scrollableTarget="content-wrapper"
            >
                <Loader loading={loading}>
                    <MapleTable>
                        <MapleTable.Content>
                        <thead>
                            <tr>
                                <MapleTable.TH>Reference</MapleTable.TH>
                                <MapleTable.TH>Start Time</MapleTable.TH>
                                <MapleTable.TH>Action</MapleTable.TH>
                                <MapleTable.TH>Status</MapleTable.TH>
                                <MapleTable.TH>{ integration.name }</MapleTable.TH>
                                <MapleTable.TH></MapleTable.TH>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                        {
                            _.map(syncLogs, (log, i) =>
                                <tr key={i}>
                                    <td>{ log.integration_ref && renderMapleEntity(log.integration_ref, log.integration_ref.reference_type, getCompanySpecificUrl) }</td>
                                    <td>{ moment(log.created_at).format("MMM D, YYYY h:mm:ssa") }</td>
                                    <td>{ renderAction(log) }</td>
                                    <td>{ renderStatus(log) }</td>
                                    <td>{ log.integration_ref && renderRemoteData(log.integration_ref, integration) }</td>
                                    <td>{ renderFailureReason(log) }</td>
                                </tr>
                            )
                        }
                        </tbody>
                        </MapleTable.Content>
                    </MapleTable>
                </Loader>
            </InfiniteScroll>
        </ContentContainer>
    );
}

export default Sync;
