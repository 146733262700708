import '../../../App.scss';
import React, {useContext, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {BaseContext, IntegrationContext} from '../../../helpers/common';
import {
    getFeatureDescription, isSyncEnabled,
} from '../../../helpers/integrations';
import ContentContainer from '../../../components/ContentContainer';
import IntegrationFieldMapComponent from '../../../components/IntegrationFieldMapComponent';
import { Alert } from 'react-bootstrap';
import Link from '../../../components/Link';
import {serverPost} from "../../../helpers/server";
import IntegrationWorkflowActionsComponent from "../../../components/IntegrationWorkflowActionsComponent";

const _ = require('lodash');

function Feature() {
    const { getApiUrl, setPageTitle } = useContext(BaseContext);
    const { integration, getIntegrationSpecificUrl } = useContext(IntegrationContext);
    let { feature } = useParams();

    useEffect(() => {
        setPageTitle(`Integrations - ${_.upperFirst(feature)}`);
    }, []);

    const featureDescription = getFeatureDescription(feature.toUpperCase());
    return (
        <ContentContainer className="">
            {
                !_.includes(integration.enabled_features, feature.toUpperCase()) &&
                    <Alert variant="info">{ featureDescription.title } feature is current not enabled. Please proceed to the <Link href={getIntegrationSpecificUrl("/settings")}>settings page</Link> to enable it.</Alert>
            }
            <IntegrationFieldMapComponent className="" />
        </ContentContainer>
    );
}

export default Feature;
