import '../../App.scss';
import '../../css/modals.scss';
import React, {useEffect, useState} from 'react';
import BaseModal from '../modals/BaseModal';
import { useTranslation } from 'react-i18next';
import BaseForm from "../BaseForm";
import SubmitButton from "../common/buttons/SubmitButton";
const _ = require('lodash');

function SimpleModal(props) {
    const { t } = useTranslation('common');
    const [confirmationText, setConfirmationText] = useState("");
    const [confirmationTextMatches, setConfirmationTextMatches] = useState(false);

    const onFieldChange = (name, value) => {
        if (name === "confirmationText") {
            setConfirmationText(value);
        }
    }

    useEffect(() => {
        if (!props.confirmationText || _.isNil(props.confirmationText)) {
            setConfirmationTextMatches(true);
        } else {
            setConfirmationTextMatches(props.confirmationText.toUpperCase() === confirmationText);
        }
    }, [confirmationText, props.confirmationText]);

    useEffect(() => {
        if (!props.show && props.confirmationText) {
            setConfirmationTextMatches(false);
            setConfirmationText(null);
        }
    }, [props.show])

    const onConfirm = async () => {
        if (props.onConfirm) {
            await props.onConfirm();
        }
        props.onClose();
    }

    return (
        <BaseModal {...props}>
            <BaseForm onSubmit={onConfirm} onFieldChange={onFieldChange}>
                <BaseModal.Header>
                    <BaseModal.Title>{props.title ? props.title : "Confirm?"}</BaseModal.Title>
                </BaseModal.Header>
                <BaseModal.Body>
                    <div className="body1">
                        {props.body ? props.body : "Are you sure you want to proceed?"}
                    </div>
                    {
                        props.confirmationText &&
                        <>
                            <br/>
                            <div className="body1">
                                {
                                    props.confirmationBody ?
                                        <i>{props.confirmationBody}</i>
                                        : <i>Please type the text "{props.confirmationText.toUpperCase()}" in the box below in order to proceed.</i>
                                }
                            </div>
                            <BaseForm.Input name="confirmationText" type="text" transformations={["uppercase"]} />
                        </>
                    }
                </BaseModal.Body>
                <BaseModal.Footer>
                    <SubmitButton className={props.buttonClassName} variant={props.buttonVariant || "primary"} disabled={!confirmationTextMatches}>{ props.buttonTitle || "Confirm" }</SubmitButton>
                </BaseModal.Footer>
            </BaseForm>
        </BaseModal>
    );
}

export default SimpleModal;
