import '../App.scss';
import '../css/invoice.scss';
import React, {useContext, useEffect, useState} from 'react';
import {
    BaseContext,
    getCustomTaxRulePropertyMap,
    getDescriptionForRule,
    IntegrationContext,
} from '../helpers/common';
import MapleTable from "./MapleTable";
import Label from "./Label";
import Button from './common/buttons/Button';
import {notifyEvent, serverPatch} from "../helpers/server";
import DeleteButton from "./DeleteButton";
import AddOrEditCustomTaxIdentifiersRuleModal from "./modals/AddOrEditCustomTaxIdentifiersRuleModal";
const _ = require('lodash');

function TaxCalcCustomTaxIdentifiers(props) {
    const { getApiUrl } = useContext(BaseContext);
    const { integration } = useContext(IntegrationContext);
    const [showCustomTaxRuleModal, setShowCustomTaxRuleModal] = useState(false);
    const [customTaxRuleIndexToEdit, setCustomTaxRuleIndexToEdit] = useState(null);
    const [customTaxIdentifiersRules, setCustomTaxIdentifiersRules] = useState([]);

    useEffect(() => {
        setCustomTaxIdentifiersRules((integration.tax_data && integration.tax_data.custom_tax_identifiers_rules) || [])
    }, [integration])

    const updateCustomRules = async (newRules, errorHandler) => {
        const updateData = {
            feature_data: {
                tax_data: {
                    custom_tax_identifiers_rules: newRules,
                }
            }
        }
        const result = await serverPatch(getApiUrl(`/integrations/${integration.id}`), updateData, {}, errorHandler);
        if (result) {
            notifyEvent('integration_settings');
            setShowCustomTaxRuleModal(false);
        }
    }

    const onAdded = async (customRule, errorHandler) => {
        const newCustomTaxRules = [...customTaxIdentifiersRules, customRule]
        await updateCustomRules(newCustomTaxRules, errorHandler);
    }

    const onUpdated = async (customRule, errorHandler) => {
        const newCustomTaxRules = [...customTaxIdentifiersRules];
        newCustomTaxRules[customTaxRuleIndexToEdit] = customRule
        await updateCustomRules(newCustomTaxRules, errorHandler);
    }

    const addCustomTaxRule = () => {
        setCustomTaxRuleIndexToEdit(null);
        setShowCustomTaxRuleModal(true);
    }

    const editCustomTaxRule = (index) => {
        setCustomTaxRuleIndexToEdit(index);
        setShowCustomTaxRuleModal(true);
    }

    const onDeleteCustomTaxRule = (index) => {
        let newCustomTaxRules = [...customTaxIdentifiersRules];
        newCustomTaxRules.splice(index, 1)
        updateCustomRules(newCustomTaxRules);
    }

    return (
        <>
        <div>
            <p className="text-base font-semibold text-gray-700">Set tax registration numbers (GST registrations, etc.) to be displayed in invoices based on certain rules.</p>
            <p className="text-sm font-normal text-gray-500">In order to ensure that the right set of tax registration numbers are shown on an invoice, you can set up rules on what taxes to be applied. If an invoice line item matches multiple conditions, all the matching tax registration numbers will be shown.</p>
            <MapleTable>
                <MapleTable.Content>
                    <thead>
                    <tr>
                        <MapleTable.TH>Rule</MapleTable.TH>
                        <MapleTable.TH>Tax Registration Number(s)</MapleTable.TH>
                        <MapleTable.TH></MapleTable.TH>
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                    {
                        _.map(customTaxIdentifiersRules, (taxRule, i) =>
                            <tr key={i}>
                                <td>{ getDescriptionForRule(taxRule.rule, getCustomTaxRulePropertyMap()) }</td>
                                <td>
                                    <div className="flex flex-row gap-1">
                                    {
                                        _.map(taxRule.identifiers, (identifier, i) =>
                                            <Label.Info key={i}>{ identifier.name }: { identifier.value }</Label.Info>
                                        )
                                    }
                                    </div>
                                </td>
                                <td className="text-end">
                                    <div className="flex flex-row items-center justify-end gap-1">
                                        <Button size="sm" variant="text-primary" onClick={() => editCustomTaxRule(i)}>
                                            <i className="fa fa-edit"/>
                                        </Button>
                                        <DeleteButton size="sm" onDelete={() => onDeleteCustomTaxRule(i)}/>
                                    </div>
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </MapleTable.Content>
            </MapleTable>
            <Button onClick={() => addCustomTaxRule()}>Add New Rule</Button>
        </div>
        <AddOrEditCustomTaxIdentifiersRuleModal
            show={showCustomTaxRuleModal}
            itemToEdit={!_.isNil(customTaxRuleIndexToEdit) ? customTaxIdentifiersRules[customTaxRuleIndexToEdit]: null}
            onClose={() => setShowCustomTaxRuleModal(false)}
            onAdded={onAdded}
            onUpdated={onUpdated}
        />
        </>
    );
}

export default TaxCalcCustomTaxIdentifiers;
