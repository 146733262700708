import '../../App.scss';
import React, { useEffect, useState, useContext } from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {serverDelete, serverFetch} from '../../helpers/server';
import {
    BaseContext,
    UserContext,
    getPaymentLinkUrl, formatTerm
} from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../components/BaseContainer';
import KeyValueDisplay2 from '../../components/KeyValueDisplay2';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import CopyableComponent from "../../components/CopyableComponent";
import BundlePricingTable from "../../components/BundlePricingTable";
import DropdownMenu from "../../components/DropdownMenu";
import {WrenchScrewdriverIcon} from "@heroicons/react/20/solid";
import SimpleModal from "../../components/modals/SimpleModal";
import Button from '../../components/common/buttons/Button';
import PaymentLinkQRCodeModal from "../../components/modals/PaymentLinkQRCodeModal";
import {QrCodeIcon} from "@heroicons/react/24/outline";
import Loader from "../../components/Loader";
import ErrorComponent from "../../components/ErrorComponent";
const _ = require('lodash');

function PaymentLinkAdminView(props) {
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const { linkId } = useParams();
    const { userInfo } = useContext(UserContext);
    const { getApiUrl, setPageTitle, hasAccess, getCompanySpecificUrl } = useContext(BaseContext);
    const [loadingPaymentLink, setLoadingPaymentLink] = useState(true);
    const [paymentLink, setPaymentLink] = useState({});
    const [optionDetails, setOptionDetails] = useState({});
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const [showQRCodeModal, setShowQRCodeModal] = useState(false);

    const hasWritePermission = hasAccess("pricing", userInfo, "write")

    useEffect(() => {
        setPageTitle(`Payment Link`);
    }, []);

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl(`/proposals/${linkId}`), { skipCache }).then((res) => {
            if (res) {
                setPaymentLink(res);
            }
            if (res.type === "CONTRACT") {
                navigate(getCompanySpecificUrl(`/contract/view/${res.id}`));
            }
            setLoadingPaymentLink(false);
        });
    }

    useEffect(() => {
        fetchData(true);
    }, []);

    useEffect(() => {
        if (!paymentLink || _.isEmpty(paymentLink)) {
            setOptionDetails({});
        } else {
            const details ={
                'Allow Promo Code': paymentLink.options.allow_promo_code ? "True": "False",
            };
            let completionAction = paymentLink.options.completion_action;
            if (_.isNil(completionAction) || completionAction === "") {
                completionAction = "CONFIRMATION_PAGE"
            }
            let completionActionDescription = "";
            if (completionAction === "CONFIRMATION_PAGE") {
                completionActionDescription = "Show Confirmation Page"
            } else if (completionAction === "REDIRECT") {
                completionActionDescription = "Redirect to a URL"
            }
            details['After Completion'] = completionActionDescription;
            if (completionAction === "CONFIRMATION_PAGE") {
                details['Confirmation Message'] = paymentLink.options && paymentLink.options.confirmation_message
            } else if (completionAction === "REDIRECT") {
                details['Redirect URL'] = paymentLink.options && paymentLink.options.redirect_url
            }
            setOptionDetails(details)
        }
    }, [paymentLink]);

    const onActionSelected = (type) => {
        if (type === "edit") {
            navigate(getCompanySpecificUrl(`/payment_link/edit/${paymentLink.id}`));
        } else if (type === "delete") {
            setShowDeleteConfirmModal(true);
        }
    }

    const onConfirmDelete = () => {
        serverDelete(getApiUrl(`/proposals/${paymentLink.id}`)).then(res => {
            navigate(getCompanySpecificUrl("/payment_links"));
        })
    }

    const getCustomActions = () => {
        if (hasWritePermission) {
            return (<DropdownMenu className="p-2" items={actionOptions} onClick={onActionSelected}>
                <div className="flex flex-row gap-1 items-center">
                    <WrenchScrewdriverIcon className="h-4 w-4"/>
                    <span>Actions</span>
                </div>
            </DropdownMenu>)
        }
        return []
    }

    const actionOptions = [
        { id: "edit", label: "Edit" },
        { divider: true },
        { id: "delete", label: "Delete", className: "error" },
    ];

    return (
        <BaseContainer>
            <Loader loading={loadingPaymentLink}>
                {
                    _.isEmpty(paymentLink) ?
                        <ErrorComponent
                            title={"Payment Link Not Found"}
                            description={"Oops, we can't find the payment link you are looking for."}
                            primaryAction={{ href:getCompanySpecificUrl("/payment_links"), label:"Show all payment links" }}
                            hideSupport
                        />
                    : <>
                        <ContentContainer
                            title={"Payment Link"}
                            subtitle={"Copy and share to start accepting payments with this link."}
                            customActions={getCustomActions()}
                        >
                            <Section>
                                <div className="mt-4 flex flex-row gap-2 items-center">
                                    <span className="text-sm whitespace-nowrap">Buy Link:</span>
                                    <CopyableComponent value={ getPaymentLinkUrl(paymentLink, getCompanySpecificUrl) }/>
                                    <Button size={"sm"} onClick={() => setShowQRCodeModal(true)}>
                                        <div className="flex flex-row gap-1 items-center whitespace-nowrap">
                                            <QrCodeIcon className="h-4 w-4"/>
                                            <span>QR Code</span>
                                        </div>
                                    </Button>
                                </div>
                            </Section>
                            <Section title="Options" className="mt-4">
                                <KeyValueDisplay2 items={optionDetails} />
                            </Section>
                            <Section title="Price Details">
                                {
                                    paymentLink.trial &&
                                        <div className="body1"><strong>Trial:</strong> { formatTerm(paymentLink.trial_term) }</div>
                                }
                                <BundlePricingTable
                                    bundlePricing={paymentLink.bundle_pricing}
                                    oneTimeBillables={paymentLink.one_time_billables}
                                    configItems={paymentLink.config_items}
                                    discounts={paymentLink.discounts}
                                    showPricingLinks={true}
                                    term={paymentLink.term}
                                    minimumSpend={paymentLink.minimum_spend}
                                    displayDiscountExpiration={true}
                                />
                            </Section>
                        </ContentContainer>
                        <SimpleModal
                            show={showDeleteConfirmModal} onClose={setShowDeleteConfirmModal} title="Delete Payment Link?"
                            buttonTitle="Delete" buttonVariant={"danger"} onConfirm={onConfirmDelete}
                            body={"Are you sure you want to delete the payment link?"}
                        />
                        <PaymentLinkQRCodeModal
                            show={showQRCodeModal} onClose={setShowQRCodeModal}
                            value={getPaymentLinkUrl(paymentLink, getCompanySpecificUrl)}
                        />
                    </>
                }
            </Loader>
        </BaseContainer>
    );
}

export default PaymentLinkAdminView;
