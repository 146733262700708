import '../App.scss';
import React, { useState } from 'react';
import {FolderPlusIcon} from "@heroicons/react/24/outline";
import classnames from "classnames";
import Button from './common/buttons/Button';
const _ = require("lodash");

function EmptyState(props) {
    const [hasWritePermissions, setHasWritePermissions] = useState(true);
    const Icon = props.icon || FolderPlusIcon;

    useState(() => {
        if (_.isNil(props.hasWritePermissions)) {
            setHasWritePermissions(true);
        } else {
            setHasWritePermissions(props.hasWritePermissions);
        }
    }, [props.hasWritePermissions])

    return (
        <div className={classnames("text-center m-auto p-5 rounded-md shadow-sm ring-1 ring-black ring-opacity-5 bg-white", props.className)}>
            <Icon className="mx-auto h-12 w-12 text-gray-400"/>
            <h3 className="mt-2 text-sm font-semibold text-gray-900">{ props.title }</h3>
            <p className="mt-1 text-sm text-gray-500">{ props.subtitle }</p>
            <div className="mt-6 flex items-center justify-center">
                {
                    hasWritePermissions &&
                        <Button onClick={props.onClick}>
                            <span><i className={classnames("fa fa-plus")}/>&nbsp;</span>
                            { props.buttonLabel }
                        </Button>
                }
            </div>
        </div>
    );
}

export default EmptyState;
