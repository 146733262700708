import '../App.scss';
import '../css/invoice.scss';
import React, { useContext, useEffect, useState } from 'react';
import { serverDelete, serverFetch } from '../helpers/server';
import { BaseContext, IntegrationContext } from '../helpers/common';
import MapleTable from './MapleTable';
import Notification from './Notification';
import { useTranslation } from 'react-i18next';
import AddOrEditEmailSenderAddressModal from './modals/AddOrEditEmailSenderAddressModal';
import Button from './common/buttons/Button';
import DeleteButton from './DeleteButton';
import classnames from 'classnames';

const _ = require('lodash');

function CustomEmailSenderAddressComponent(props) {
	const { t } = useTranslation('common');
	const { integration } = useContext(IntegrationContext);
	const { getApiUrl } = useContext(BaseContext);
	const [senderAddresses, setSenderAddresses] = useState([]);
	const [loading, setLoading] = useState(true);
	const [showAddOrEditEmailSenderAddressModal, setShowAddOrEditEmailSenderAddressModal] = useState(false);

	const fetchData = (skipCache = false) => {
		setLoading(true);
		serverFetch(getApiUrl(`/integrations/${integration.id}/custom_email_domain/sender_signatures`), {
			skipCache,
		}).then((res) => {
			setLoading(false);
			setSenderAddresses(res);
		});
	};

	useEffect(() => {
		fetchData();
	});

	const onModalClose = (didUpdate) => {
		setShowAddOrEditEmailSenderAddressModal(false);
		if (didUpdate) {
			fetchData(true);
		}
	};

	const onAddEmailSenderAddress = () => {
		setShowAddOrEditEmailSenderAddressModal(true);
	};

	const errorHandler = async (errorRes, errorMessage) => {
		const errorResponse = await errorRes.json();
		if (errorResponse && errorResponse.error_message) {
			Notification.Danger(errorResponse.error_message, 5000);
		}
	};

	const onDeleteEmailSenderAddress = (item) => {
		serverDelete(
			getApiUrl(`/integrations/${integration.id}/custom_email_domain/sender_signatures/${item.id}`),
			errorHandler,
		).then((res) => {
			if (res) {
				fetchData(true);
			}
		});
	};

	return (
		<div>
			<div className='flex flex-row items-center gap-4 pb-2 justify-end'>
				<div className='grow-0'>
					<div className='text-lg font-semibold leading-6 text-gray-900'>Sender Addresses</div>
				</div>
				<div className='ml-0 mr-0 sm:mt-0 grow shrink-0 flex flex-row justify-end'>
					<Button variant='primary' onClick={onAddEmailSenderAddress}>
						<span>
							<i className={classnames('fa', 'fa-plus')} />
							&nbsp;
						</span>
						Add Sender Address
					</Button>
				</div>
			</div>
			<div>
				{!_.isEmpty(senderAddresses) && (
					<MapleTable>
						<MapleTable.Content>
							<thead>
								<tr>
									<MapleTable.TH>Email</MapleTable.TH>
									<MapleTable.TH>Name</MapleTable.TH>
									<MapleTable.TH>Confirmed</MapleTable.TH>
									<MapleTable.TH className='max-sm:hidden'></MapleTable.TH>
									<MapleTable.TH></MapleTable.TH>
									<MapleTable.TH></MapleTable.TH>
								</tr>
							</thead>
							<tbody className='divide-y divide-gray-200'>
								{_.map(senderAddresses, (item, i) => (
									<tr key={item.id}>
										<td>{item.email}</td>
										<td>{item.name}</td>
										<td>
											Verification:{' '}
											{item.confirmed ? (
												<div>Verified</div>
											) : (
												<div>
													Not Verified <br />
												</div>
											)}
											<br />
										</td>
										<td className='text-end'>
											<div className='flex flex-row gap-1 justify-content-end'>
												<DeleteButton
													size='sm'
													onDelete={() => onDeleteEmailSenderAddress(item)}
												></DeleteButton>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</MapleTable.Content>
					</MapleTable>
				)}
				{_.isEmpty(senderAddresses) && (
					<h3 className='mt-2 text-sm font-semibold text-gray-900'>No registered sender addresses found.</h3>
				)}
			</div>
			<AddOrEditEmailSenderAddressModal show={showAddOrEditEmailSenderAddressModal} onClose={onModalClose} />
		</div>
	);
}

export default CustomEmailSenderAddressComponent;
