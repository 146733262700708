import '../../App.scss';
import '../../css/modals.scss';
import React, { useState, useContext, useEffect, useMemo } from 'react';
import BaseSideModal from '../modals/BaseSideModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../common/buttons/SubmitButton';
import { serverPost } from '../../helpers/server';
import { BaseContext, getCurrencyOptions, timeOptions } from '../../helpers/common';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import CustomerInput from "../CustomerInput";
import Link from '../Link';
const _ = require('lodash');

function AdjustCreditModal(props) {
    const { t } = useTranslation('common');
    const { company, getApiUrl, getCompanySpecificUrl } = useContext(BaseContext);
    const [billableItems, setBillableItems] = useState([]);
    const [creditType, setCreditType] = useState("AMOUNT")
    const [doesExpire, setDoesExpire] = useState(false)

    const initialFields = useMemo(() => {
        return {
            customer: props.customer,
            expiration_date: moment().endOf('day')
        }
    }, [props.customer])

    useEffect(() => {
        serverPost(getApiUrl("/billable/items/find"), { companyId: company.id }).then((res) => {
            setBillableItems(_.filter(res, (r) => !r.standard));
        });
    }, [company, getApiUrl])

    const adjustCredit = (data, errorHandler) => {
        const creditData = {
            customer_id: props.customer.id,
            name: data.name,
            type: data.type
        }
        if (data.type === "AMOUNT") {
            creditData.amount = {
                value_in_cents: Math.round(parseFloat(data.amount_value) * 100),
                currency: data.amount_currency
            }
        } else {
            creditData.item_id = data.item_id;
            creditData.units = data.units;
        }
        creditData.state = "ACTIVE";
        if (data.does_expire) {
            creditData.expiration_date = data.expiration_date;
        } else {
            creditData.expiration_date = null;
        }
       // console.log("The data is " + JSON.stringify(creditData));
        serverPost(getApiUrl("/credits"), creditData, {}, errorHandler).then((res) => {
            if (res) {
                props.onClose(true);
            }
        })
    }

    const typeOptions = [
        { value: "AMOUNT", label: "Amount" },
        { value: "UNITS", label: "Units" },
    ]
    const expirationTimeOptions = timeOptions();
    expirationTimeOptions.unshift({ value: "end", label: "End of Day" })

    const billableItemOptions = _.map(billableItems, (bi) => {
        return { value: bi.id, label: bi.name }
    })

    const onFieldChange = (name, value) => {
        if (name === "type") {
            setCreditType(value);
        } else if (name === "does_expire") {
            setDoesExpire(value);
        }
    }

    return (
        <BaseSideModal {...props}>
            <BaseSideModal.Form initialFormFields={initialFields} onFieldChange={onFieldChange} onSubmit={adjustCredit}>
                <BaseSideModal.Header title={"Adjust Credit"} />
                <BaseSideModal.Body>
                    <Row>
                        <CustomerInput colSpan="12" defaultCustomer={props.customer} required />
                        <BaseForm.Input colSpan="12" name="name" label={t('common.name')} type="text" required />
                        <BaseForm.Input colSpan="12" name="type" label={t('credits.type')} type="select" options={typeOptions} showSearch={false} />
                        {
                            creditType === "AMOUNT" &&
                                <>
                                    <BaseForm.Input colSpan="6" name="amount_value" label={t('credits.amount')} type="number" step="0.01" min="0.01" required />
                                    <BaseForm.Input colSpan="6" name="amount_currency" label={t('common.currency')} type="select" options={getCurrencyOptions()} required />
                                </>
                        }
                        {
                            creditType === "UNITS" &&
                                <>
                                    <BaseForm.Input 
                                        colSpan="12" 
                                        name="units" 
                                        label={t('credits.units')} 
                                        type="number" 
                                        step="0.01" 
                                        required 
                                        min="0.01"
                                    />
                                    <BaseForm.Input 
                                        colSpan="12" 
                                        name="item_id" 
                                        label={t('credits.item')} 
                                        type="select"
                                        options={billableItemOptions} 
                                        required 
                                        showSearch={false} 
                                        disabled={!billableItemOptions.length}
                                    />
                                </>
                        }
                    </Row>
                    <Row>
                        <span className="body2">Expiration</span>
                        <BaseForm.Input colSpan="8" name="does_expire" label={t('credits.does_expire')} type="switch" />
                        {
                            doesExpire &&
                                <>
                                    <BaseForm.Input colSpan="8" name="expiration_date" label={t('credits.expires_on')}
                                        type="date" includeTime={true} minDate={moment()} required/>
                                    <span className="caption">Date/Time is based on your local timezone</span>
                                </>
                        }
                    </Row>
                    {(creditType === "UNITS" && !billableItemOptions.length) && (
                        <Row>
                            <span className="body1 error">
                                In order to issue credits of type units, we need to create a billable item first.{" "}
                                <Link href={getCompanySpecificUrl("/billable_items")}>
                                    Get started here.
                                </Link>
                            </span>
                        </Row>
                    )}
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <Row>
                        <Col md="12" className="text-end">
                            <SubmitButton variant="primary">Adjust</SubmitButton>
                        </Col>
                    </Row>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );
}

export default AdjustCreditModal;
