import '../App.scss';
import '../css/invoice.scss';
import React, { useContext, useEffect, useState } from 'react';
import { serverDelete, serverFetch, serverPost } from '../helpers/server';
import { BaseContext, IntegrationContext, renderComponents } from '../helpers/common';
import MapleTable from './MapleTable';
import Notification from './Notification';
import { useTranslation } from 'react-i18next';
import CopyableComponent from './CopyableComponent';
import AddOrEditEmailDomainModal from './modals/AddOrEditEmailDomainModal';
import Button from './common/buttons/Button';
import DeleteButton from './DeleteButton';
import EmptyState from './EmptyState';
import classnames from 'classnames';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import Link from './Link';

const _ = require('lodash');

function CustomEmailDomainComponent(props) {
	const { t } = useTranslation('common');
	const { integration } = useContext(IntegrationContext);
	const { company, getApiUrl, setPageTitle } = useContext(BaseContext);
	const [domains, setDomains] = useState([]);
	const [loading, setLoading] = useState(true);
	const [domainToEdit, setDomainToEdit] = useState(null);
	const [showAddOrEditEmailDomainModal, setShowAddOrEditEmailDomainModal] = useState(false);

	const fetchData = (skipCache = false) => {
		setLoading(true);
		serverFetch(getApiUrl(`/integrations/${integration.id}/custom_email_domain/domains`), { skipCache }).then(
			(res) => {
				setLoading(false);
				setDomains(res);
			},
		);
	};

	useEffect(() => {
		fetchData();
	}, []);

	const onModalClose = (didUpdate) => {
		setShowAddOrEditEmailDomainModal(false);
		if (didUpdate) {
			fetchData(true);
		}
	};

	const onAddEmailDomain = () => {
		setShowAddOrEditEmailDomainModal(true);
		setDomainToEdit(null);
	};

	const errorHandler = async (errorRes, errorMessage) => {
		const errorResponse = await errorRes.json();
		if (errorResponse && errorResponse.error_message) {
			Notification.Danger(errorResponse.error_message, 5000);
		}
	};

	const onDeleteEmailDomain = (item) => {
		serverDelete(
			getApiUrl(`/integrations/${integration.id}/custom_email_domain/domains/${item.id}`),
			errorHandler,
		).then((res) => {
			if (res) {
				fetchData(true);
			}
		});
	};

	const onVerifyDKIM = (item) => {
		serverPost(
			getApiUrl(`/integrations/${integration.id}/custom_email_domain/domains/${item.id}/verify_dkim`),
			errorHandler,
		).then((res) => {
			if (res) {
				fetchData(true);
			}
		});
	};

	const onVerifyReturnPath = (item) => {
		serverPost(
			getApiUrl(`/integrations/${integration.id}/custom_email_domain/domains/${item.id}/verify_return_path`),
			errorHandler,
		).then((res) => {
			if (res) {
				fetchData(true);
			}
		});
	};

	return (
		<div>
			<div className='flex flex-row items-center gap-4 pb-2 justify-end'>
				<div className='grow-0'>
					<div className='text-lg font-semibold leading-6 text-gray-900'>Domains</div>
				</div>
				<div className='ml-0 mr-0 sm:mt-0 grow shrink-0 flex flex-row justify-end'>
					<Button variant='primary' onClick={onAddEmailDomain}>
						<span>
							<i className={classnames('fa', 'fa-plus')} />
							&nbsp;
						</span>
						Add Domain
					</Button>
				</div>
			</div>
			<div>
				{!_.isEmpty(domains) && (
					<MapleTable>
						<MapleTable.Content>
							<thead>
								<tr>
									<MapleTable.TH>Domain</MapleTable.TH>
									<MapleTable.TH>DKIM Settings</MapleTable.TH>
									<MapleTable.TH>Return Path Settings</MapleTable.TH>
									<MapleTable.TH className='max-sm:hidden'></MapleTable.TH>
									<MapleTable.TH></MapleTable.TH>
									<MapleTable.TH></MapleTable.TH>
								</tr>
							</thead>
							<tbody className='divide-y divide-gray-200'>
								{_.map(domains, (item, i) => (
									<tr key={i}>
										<td>{item.domain}</td>
										<td>
											Verification:{' '}
											{item.dkim_verified ? (
												<div>Verified</div>
											) : (
												<div>
													Not Verified <br/>
													<Link
														className='text-sm flex flex-row gap-1 items-center'
														onClick={() => onVerifyDKIM(item)}
													>
														<ArrowPathIcon className='h-4 w-4' /> Verify
													</Link>
												</div>
											)}
											<br />
											Hostname :<CopyableComponent value={item.dkim_pending_host} />
											<br />
											Type: <CopyableComponent value={'TXT'} /> <br />
											Value :<CopyableComponent
												value={item.dkim_pending_text_value}
											/> <br />
										</td>
										<td>
											Verification:{' '}
											{item.return_path_domain_verified ? (
												<div>Verified</div>
											) : (
												<div>
													Not Verified <br />
													<Link
														className='text-sm flex flex-row gap-1 items-center'
														onClick={() => onVerifyReturnPath(item)}
													>
														<ArrowPathIcon className='h-4 w-4' /> Verify
													</Link>
												</div>
											)}
											<br />
											Hostname :<CopyableComponent value={item.return_path_domain} />
											<br />
											Type: <CopyableComponent value={'CNAME'} /> <br />
											Value :<CopyableComponent
												value={item.return_path_domain_cname_value}
											/>{' '}
											<br />
										</td>
										<td className='text-end'>
											<div className='flex flex-row gap-1 justify-content-end'>
												<DeleteButton
													size='sm'
													onDelete={() => onDeleteEmailDomain(item)}
												></DeleteButton>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</MapleTable.Content>
					</MapleTable>
				)}
				{_.isEmpty(domains) && (
					<h3 className='mt-2 text-sm font-semibold text-gray-900'>No registered domains found.</h3>
				)}
			</div>
			<AddOrEditEmailDomainModal show={showAddOrEditEmailDomainModal} onClose={onModalClose} />
		</div>
	);
}

export default CustomEmailDomainComponent;
