import '../../../App.scss';
import React, { useEffect, useState, useContext } from 'react';
import {serverPost, serverDelete} from '../../../helpers/server';
import {BaseContext, getApprovalRulePropertyMap, getDescriptionForRule} from '../../../helpers/common';
import MapleTable from '../../../components/MapleTable';
import Notification from '../../../components/Notification';
import Section from '../../../components/Section';
import ContentContainer from '../../../components/ContentContainer';
import Button from '../../../components/common/buttons/Button';
import { useTranslation } from 'react-i18next';
import Link from "../../../components/Link";
import DeleteButton from "../../../components/DeleteButton";
import Columns from "../../../components/Columns";
import AddOrEditContractVariableModal from "../../../components/modals/AddOrEditContractVariableModal";
import CopyableComponent from "../../../components/CopyableComponent";
const _ = require('lodash');

function ContractVariables() {
    const { t } = useTranslation('common');
    const { getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const [loading, setLoading] = useState(true);
    const [variables, setVariables] = useState([]);
    const [variableToEdit, setVariableToEdit] = useState(null);
    const [showAddOrEditCustomVariableModal, setShowAddOrEditCustomVariableModal] = useState(false);

    useEffect(() => {
        setPageTitle(`Contract Variables`);
    }, []);

    useEffect(() => {
        fetchData(true);
    }, []);

    const fetchData = (skipCache=false) => {
        serverPost(getApiUrl("/proposals/variables/find"), {}).then((res) => {
            setVariables(res);
            setLoading(false);
        });
    }

    const onModalClose = (didUpdate) => {
        setShowAddOrEditCustomVariableModal(false);
        setVariableToEdit(null);
        if (didUpdate) {
            fetchData(true)
        }
    }

    const onEditVariable = (variable) => {
        setShowAddOrEditCustomVariableModal(true);
        setVariableToEdit(variable);
    }

    const onDeleteVariable = (variable) => {
        serverDelete(getApiUrl(`/proposals/variables/${variable.id}`)).then((res) => {
            Notification.Success("Custom variable deleted");
            fetchData(true);
        })
    }

    const showChoicesColumn = _.some(variables, (e) => e.type === "enum");
    return (
        <>
            <ContentContainer>
                <Section
                    title="Custom Variables"
                    className={"mt-4"}
                    loading={loading}
                    actions={[{
                        variant: "primary",
                        icon: "fa-plus",
                        label: "Create Variable",
                        onClick: () => setShowAddOrEditCustomVariableModal(true)
                    }]}>
                    {
                        _.isEmpty(variables) &&
                            <span className="text-gray-500">No contract approval rules setup.</span>
                    }
                    {
                        !_.isEmpty(variables) &&
                            <MapleTable>
                                <MapleTable.Content>
                                    <thead>
                                    <tr>
                                        <MapleTable.TH>Name</MapleTable.TH>
                                        <MapleTable.TH></MapleTable.TH>
                                        <MapleTable.TH>Type</MapleTable.TH>
                                        {
                                            showChoicesColumn &&
                                            <MapleTable.TH>Choices</MapleTable.TH>
                                        }
                                        <MapleTable.TH></MapleTable.TH>
                                    </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                    {
                                        _.map(variables, (row, i) =>
                                            <tr key={i}>
                                                <td>{ row.name }</td>
                                                <td><CopyableComponent value={row.key}/></td>
                                                <td>{ row.type }</td>
                                                {
                                                    showChoicesColumn &&
                                                    <td><span className="text-sm font-normal text-gray-500">{ _.join(row.possible_values, ", ") }</span></td>
                                                }
                                                <td className="w-px whitespace-nowrap">
                                                    <div className="flex flex-row items-center justify-end">
                                                        <Link onClick={() => onEditVariable(row)}>
                                                            <Button variant="text-primary" size="sm">Edit</Button>
                                                        </Link>
                                                        <DeleteButton size="md" onDelete={() => onDeleteVariable(row)}></DeleteButton>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </MapleTable.Content>
                            </MapleTable>
                    }
                </Section>
            </ContentContainer>
            <AddOrEditContractVariableModal
                show={showAddOrEditCustomVariableModal} onClose={onModalClose}
                itemToEdit={variableToEdit}
            />
        </>
    );
}

export default ContractVariables;
