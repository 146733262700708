import '../../App.scss';
import React, {useEffect, useState, useContext, useRef} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {serverFetch, serverPost} from '../../helpers/server';
import {BaseContext, currencyFormatFromPrice, cyrb53, percentageFormat, updateFormField} from '../../helpers/common';
import Loader from '../../components/Loader';
import Section from "../../components/Section";
import BaseContainer from "../../components/BaseContainer";
import ContentContainer from "../../components/ContentContainer";
import BaseForm from "../../components/BaseForm";
import {Col, Row} from "react-bootstrap";
import MapleTable from "../../components/MapleTable";
import SubmitButton from "../../components/common/buttons/SubmitButton";
import ContentBox from "../../components/ContentBox";
import Link from "../../components/Link";
import moment from 'moment';
import {getCreditNoteReasonOptions, getCreditNoteRefundTypeOptions} from "../../helpers/invoices";
import DeleteButton from "../../components/DeleteButton";
import classnames from "classnames";
const _ = require('lodash');
var cache = require('js-cache');
var calcCostCache = new cache();

function InvoiceCreditNoteCreate() {
    const { uuid } = useParams();
    const navigate = useNavigate();
    const { getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);

    const [invoiceDetails, setInvoiceDetails] = useState({});
    const [settings, setSettings] = useState({});

    const [showInternalNotes, setShowInternalNotes] = useState(false);
    const [checkedItemIds, setCheckedItemIds] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]);
    const [initialFields, setInitialFields] = useState({});
    const [filteredInvoiceLineItems, setFilteredInvoiceLineItems] = useState([]);
    const [effectiveDateReference, setEffectiveDateReference] = useState("NOW");
    const [numberOfRefundRows, setNumberOfRefundRows] = useState(1);
    const [error, setError] = useState(null);
    const [creditRefundError, setCreditRefundError] = useState(null);
    const [previewResults, setPreviewResults] = useState({});
    const [previewResultsLoading, setPreviewResultsLoading] = useState(true);
    const [previewResultsError, setPreviewResultsError] = useState(null);
    const previewCalcKeyInProgress = useRef(null);

    useEffect(() => {
        setPageTitle(`Invoice - ${invoiceDetails.number}`);

        if (_.isEmpty(invoiceDetails)) {
            return;
        }
        const fields = {
            lines: {},
            effective_date: moment()
        };
        _.each(invoiceDetails.line_items, (item, i) => {
            fields.lines[item.id] = {}
            fields.lines[item.id]["line_item_id"] = item.id
            fields.lines[item.id]["credit_amount_value"] = item.amount.value_in_cents/100 - invoiceDetails.creditedMap[item.id]/100
        })
        setInitialFields(fields)
    }, [invoiceDetails]);

    useEffect(() => {
        setCheckedItems(_.filter(invoiceDetails.line_items, (i) => _.includes(checkedItemIds, i.id)))
    }, [checkedItemIds, invoiceDetails])

    const updateChecked = (event) => {
        // setSelectedCriteria(null);
        if (event.target.value === "all") {
            let newCheckedItems = [];
            if (event.target.checked) {
                newCheckedItems = _.map(invoiceDetails.line_items, (b, i) => b.id);
                newCheckedItems.push("all");
            }
            setCheckedItemIds(newCheckedItems);
        } else {
            const newCheckedItems = [...checkedItemIds];
            if (event.target.checked) {
                newCheckedItems.push(event.target.value);
            } else {
                let index = newCheckedItems.indexOf(event.target.value);
                if (index > -1) {
                    newCheckedItems.splice(index, 1);
                }
                index = newCheckedItems.indexOf("all");
                if (index > -1) {
                    newCheckedItems.splice(index, 1);
                }
            }
            setCheckedItemIds(newCheckedItems);
        }
    }

    const getFilteredLineItems = (lineItems) => {
        return _.filter(lineItems, li => {
            if (li.type === "CREDIT") {
                return false;
            }
            return true
        });
    }

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl(`/settings`)).then((res) => {
            setSettings(res)
        })

        serverFetch(getApiUrl(`/invoices/${uuid}`)).then((res) => {
            const creditedMap = {};
            _.each(res.line_items, (item) => {
                creditedMap[item.id] = 0;
            })
            _.each(res.pre_payment_credit_notes, (cn) => {
                _.each(cn.line_items, (item) => {
                    creditedMap[item.line_item_id] = creditedMap[item.line_item_id] + item.amount.value_in_cents;
                })
            })
            _.each(res.post_payment_credit_notes, (cn) => {
                _.each(cn.line_items, (item) => {
                    creditedMap[item.line_item_id] = creditedMap[item.line_item_id] + item.amount.value_in_cents;
                })
            })
            res.creditedMap = creditedMap;
            setFilteredInvoiceLineItems(getFilteredLineItems(res.line_items));
            let allItemIds = _.map(res.line_items, i => i.id);
            allItemIds.push("all")
            setCheckedItemIds(allItemIds);
            setInvoiceDetails(res)
        })
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (_.isEmpty(invoiceDetails)) {
            return;
        }
        calcTotals(initialFields);
    }, [initialFields, checkedItemIds]);

    useEffect(() => {
        if (previewResults.total_amount_to_be_refunded && previewResults.total_amount_to_be_refunded.value_in_cents > 0) {
            if (numberOfRefundRows === 0) {
                setNumberOfRefundRows(1);
            }
        } else {
            setNumberOfRefundRows(0);
        }
    }, [previewResults]);

    const onCalcError = async (errRes, errJson) => {
        let errorMessageJson = errJson;
        if (!errJson) {
            errorMessageJson = await errRes.json();
        }
        setPreviewResultsError(errorMessageJson.error_message);
    }

    const calcTotals = (formData) => {
        const previewData = {
            invoice_uuid: invoiceDetails.uuid,
        }
        let lineItems = [];
        let hasNonZeroValues = false;
        _.each(filteredInvoiceLineItems, (item, i) => {
            let camount = 0.0;
            if (_.includes(checkedItemIds, item.id) && initialFields.lines) {
                camount = parseFloat(initialFields.lines[item.id]?.credit_amount_value).toFixed(2)
            }
            if (camount !== 0) {
                hasNonZeroValues = true;
            }
            lineItems.push({
                line_item_id: item.id,
                amount: {
                    value_in_cents: camount*100,
                    currency: invoiceDetails.currency
                }
            })
        })
        previewData.line_items = lineItems

        if (!hasNonZeroValues) {
            console.log("There are no non zero values");
            setPreviewResultsLoading(false);
            previewCalcKeyInProgress.current = null;
            setPreviewResults({})
            return;
        }

        const previewCalcKey = cyrb53(JSON.stringify(previewData))
        if (previewCalcKeyInProgress.current === previewCalcKey) {
            // A request is already in progress with the same params so don't do anything.
            return;
        }
        previewCalcKeyInProgress.current = previewCalcKey;
        const existingValue = calcCostCache.get(previewCalcKey);
        if (existingValue) {
            setPreviewResults(existingValue);
            setPreviewResultsError(null);
        } else {
            // setPreviewResultsLoading(true);
            setPreviewResultsError(null);
            serverPost(getApiUrl(`/credit_notes/preview_invoice_totals`), previewData, {}, onCalcError).then(res => {
                if (res) {
                    if (previewCalcKeyInProgress.current === previewCalcKey) {
                        setPreviewResultsLoading(false);
                        calcCostCache.set(previewCalcKey, res, 30000);
                        setPreviewResults(res);
                        previewCalcKeyInProgress.current = null;
                        setPreviewResultsError(null);
                    }
                } else {
                    if (previewCalcKeyInProgress.current === previewCalcKey) {
                        setPreviewResultsLoading(false);
                        previewCalcKeyInProgress.current = null;
                        setPreviewResults({})
                    }
                }
            })
        }
    }

    const getTotalCreditAmount = (formData) => {
        let totalAmount = 0;
        _.each(checkedItemIds, (cid) => {
            const camount = initialFields.lines && initialFields.lines[cid]?.credit_amount_value
            if (camount) {
                totalAmount += parseFloat(camount);
            }
        })
        return totalAmount * 100.0
    }

    const onFieldChange = (name, value) => {
        setInitialFields(prevFields => {
            return updateFormField({...prevFields}, name, value);
        })
        if (name === "effective_date_reference") {
            setEffectiveDateReference(value);
        }
    }

    const addCreditNote = async (data, defaultErrorHandler) => {
        if (checkedItemIds.length === 0) {
            setError("Please select at least one line item to credit")
            return;
        }
        setError(null);
        const totalCreditAmount = {
            value_in_cents: getTotalCreditAmount(data),
            currency: invoiceDetails.currency
        }
        if (numberOfRefundRows === 1) {
            if (_.startsWith(data["0"].refund_type, "ISSUE_NEW_REFUND")) {
                const transactionId = _.split(data["0"].refund_type, ".")[1];
                const tDetails = _.find(previewResults.refundable_payment_transactions_details, d => d.transaction.id === transactionId)
                data["0"].credit_amount_value = (tDetails.refundable_amount.value_in_cents).toFixed(2);
            } else if (_.startsWith(data["0"].refund_type, "ATTACH_REFUND_TRANSACTION")) {
                const transactionId = _.split(data["0"].refund_type, ".")[1];
                const tDetails = _.find(previewResults.unattached_refund_transactions, d => d.id === transactionId)
                data["0"].credit_amount_value = (tDetails.total_amount.value_in_cents/100).toFixed(2);
            } else {
                data["0"].credit_amount_value = previewResults.total_amount_to_be_refunded.value_in_cents/100;
            }
        }
        let totalCreditRefundAmount = 0;
        let refundOptions = []
        _.each(_.range(numberOfRefundRows), (r) => {
            const rAmount = data[`${r}`].credit_amount_value ? data[`${r}`].credit_amount_value * 100 : 0;
            totalCreditRefundAmount += rAmount;
            const refundCredit = {
                refund_type: data[`${r}`].refund_type,
                existing_transaction_id: data[`${r}`].existing_transaction_id,
                amount: {
                    value_in_cents: rAmount,
                    currency: invoiceDetails.currency
                }
            }
            if (refundCredit.refund_type.startsWith("ATTACH_REFUND_TRANSACTION")) {
                const type = refundCredit.refund_type;
                refundCredit.refund_type = type.split(".")[0];
                refundCredit.existing_transaction_id = type.split(".")[1];
            } else if (refundCredit.refund_type.startsWith("ISSUE_NEW_REFUND")) {
                const type = refundCredit.refund_type;
                refundCredit.refund_type = type.split(".")[0];
                refundCredit.existing_transaction_id = type.split(".")[1];
            }
            refundOptions.push(refundCredit)
        })
        if (numberOfRefundRows > 0 && totalCreditRefundAmount !== previewResults.total_amount_to_be_refunded.value_in_cents) {
            setCreditRefundError("Credit amounts need to add up to the total credit amount.")
            return;
        }
        setCreditRefundError(null);
        let effectiveDate = moment();
        if (data.effective_date) {
            effectiveDate = moment(data.effective_date);
        }
        const noteData = {
            internal_reason: data.internal_reason,
            effective_date: effectiveDate.format(),
            invoice_uuid: invoiceDetails.uuid,
            note: data.note,
            internal_note: data.internal_note,
            refund_options: refundOptions,
            total_credit_amount: totalCreditAmount,
            line_items: [],
            total_amount: previewResults.total_amount,
            total_amount_to_be_credited: previewResults.total_amount_to_be_credited,
            total_amount_to_be_refunded: previewResults.total_amount_to_be_refunded,
            subtotal_credit_amount: previewResults.subtotal_credit_amount,
            total_amount_excluding_taxes: previewResults.total_amount_excluding_taxes,
            existing_invoice_amount: previewResults.existing_invoice_amount,
            adjusted_invoice_amount: previewResults.adjusted_invoice_amount
        }
        _.each(data.lines, (line) => {
            let amount = {
                value_in_cents: 0,
                currency: invoiceDetails.currency
            }
            if (_.includes(checkedItemIds, line.line_item_id)) {
                amount = {
                    value_in_cents: line.credit_amount_value*100,
                    currency: invoiceDetails.currency
                }
            }
            noteData.line_items.push({
                line_item_id: line.line_item_id,
                amount: amount
            })
        })
        const addResult = await serverPost(getApiUrl("/credit_notes"), noteData, null, defaultErrorHandler);
        if (addResult) {
            navigate(getCompanySpecificUrl(`/invoice/${invoiceDetails.uuid}`));
        }
    }

    const addRefundRow = () => {
        setNumberOfRefundRows(numberOfRefundRows + 1);
        setInitialFields(prevFields => {
            const newFields = {...prevFields};
            if (_.startsWith(newFields[String(numberOfRefundRows-1)].refund_type, "ISSUE_NEW_REFUND")) {
                const transactionId = _.split(newFields[String(numberOfRefundRows-1)].refund_type, ".")[1];
                const tDetails = _.find(previewResults.refundable_payment_transactions_details, d => d.transaction.id === transactionId)
                newFields[String(numberOfRefundRows-1)].credit_amount_value = (tDetails.refundable_amount.value_in_cents/100.00).toFixed(2);
            } else if (_.startsWith(newFields[String(numberOfRefundRows-1)].refund_type, "ATTACH_REFUND_TRANSACTION")) {
                const transactionId = _.split(newFields[String(numberOfRefundRows-1)].refund_type, ".")[1];
                const tDetails = _.find(previewResults.unattached_refund_transactions, d => d.id === transactionId)
                newFields[String(numberOfRefundRows-1)].credit_amount_value = (tDetails.total_amount.value_in_cents/100.00).toFixed(2);
            }
            return newFields;
        })
    }

    const removeRefundRow = (index) => {
        setNumberOfRefundRows(numberOfRefundRows - 1);
        setInitialFields(prevFields => {
            const newFields = {...prevFields};
            _.each(_.range(index, numberOfRefundRows), r => {
                if (r + 1 < numberOfRefundRows) {
                    newFields[String(r)].refund_type = newFields[String(r+1)].refund_type
                }
            })
            return newFields;
        })
    }

    const effectiveDateOptions = [
        { value: "NOW", label: "Effective Now" },
        { value: "SPECIFIC", label: "Specific Date" },
    ]

    let creditRefundTypeOptions = [
        { value: "CUSTOMER_CREDIT", label: "Credit customer's account" },
    ]
    if (!_.isEmpty(previewResults.refundable_payment_transactions_details)) {
        const refundableTransactions = _.filter(previewResults.refundable_payment_transactions_details, t => t.refundable_amount.value_in_cents > 0);
        if (refundableTransactions.length > 0) {
            creditRefundTypeOptions.push({ isDivider: true })
        }
        _.each(refundableTransactions, t => {
            creditRefundTypeOptions.push({ value: `ISSUE_NEW_REFUND.${t.transaction.id}`, label: `Issue Refund - ${moment(t.created_at).format("MMM D")} - ${ currencyFormatFromPrice(t.refundable_amount) }` })
        })
    }
    if (!_.isEmpty(previewResults.unattached_refund_transactions)) {
        creditRefundTypeOptions.push({ isDivider: true })
        _.each(previewResults.unattached_refund_transactions, t => {
            creditRefundTypeOptions.push({ value: `ATTACH_REFUND_TRANSACTION.${t.id}`, label: `Use Existing Refund - ${moment(t.created_at).format("MMM D")} - ${ currencyFormatFromPrice(t.total_amount) }` })
        })
    }

    const renderRow = (item, i) => {
        let min = 0;
        let max = (item.amount.value_in_cents - invoiceDetails.creditedMap[item.id])/100;
        if (max < 0) {
            min = max
            max = 0
        }
        return (
            <tr key={i}>
                <td className="!pr-0">
                    <div className="checkbox check-success inline">
                        <input type="checkbox" className="" value={item.id} id={item.id} name="line_item[]"  checked={checkedItemIds.includes(item.id)} onChange={ (event) => updateChecked(event) }/>
                        <label htmlFor={item.id}/>
                    </div>
                </td>
                <td>{ item.description }</td>
                <td>{ item.quantity }</td>
                <td className="text-end whitespace-nowrap">{ currencyFormatFromPrice(item.unit_price) }</td>
                <td className="text-end whitespace-nowrap">{ currencyFormatFromPrice(item.amount) }</td>
                { hasPreviousNotes && <td className="text-end">{ currencyFormatFromPrice({ value_in_cents: invoiceDetails.creditedMap[item.id], currency: invoiceDetails.currency }) }</td> }
                <td className="text-end">
                    <BaseForm.Hidden name={`lines.${item.id}.line_item_id`} />
                    <BaseForm.Input
                        formClassName="inline"
                        className="text-end" step={"0.01"} min={min} max={max}
                        type="number" name={`lines.${item.id}.credit_amount_value`} label="" hideLabel
                        disabled={(item.amount.value_in_cents - invoiceDetails.creditedMap[item.id]) === 0}
                    />
                </td>
            </tr>
        )
    }

    const hasPreviousNotes = !_.isEmpty(invoiceDetails.pre_payment_credit_notes) || !_.isEmpty(invoiceDetails.post_payment_credit_notes);
    return (
        <BaseContainer>
            <ContentContainer>
                <Loader loading={_.isEmpty(invoiceDetails) || _.isEmpty(settings)}>
                    {
                        () =>
                            <ContentBox>
                                <ContentBox.Body>
                                    <BaseForm initialFormFields={initialFields} onSubmit={addCreditNote} onFieldChange={onFieldChange}>
                                        <Section title={"Issue a credit note"} subtitle={"Adjust finalized invoices with credit notes."}>
                                            <Row>
                                                <BaseForm.Input
                                                    colSpan="4" type="select" name="effective_date_reference" options={effectiveDateOptions} showSearch={false}
                                                    label="Effective Date" required
                                                />
                                                {
                                                    effectiveDateReference === "SPECIFIC" &&
                                                        <BaseForm.Input
                                                            colSpan="4" type="date" name="effective_date" label="Effective Date"
                                                            required hideLabel maxDate={moment()} minDate={moment(invoiceDetails.invoice_date)}
                                                        />
                                                }
                                            </Row>
                                            <Row>
                                                <BaseForm.Input
                                                    colSpan="4" type="select" name="internal_reason" options={getCreditNoteReasonOptions()} showSearch={false}
                                                    label="Internal Reason" required
                                                />
                                            </Row>
                                            <Row>
                                                <BaseForm.Input
                                                    colSpan="6" type="textarea" name="note" height="100px"
                                                    label="Memo" placeholder="Customer requested..."
                                                    description={"Add a note to be displayed to the customer."}
                                                />
                                                {
                                                    showInternalNotes ?
                                                        <BaseForm.Input
                                                            colSpan="6" type="textarea" name="internal_note" height="100px"
                                                            label="Internal Note (optional)" placeholder="Customer requested..."
                                                            description={"This will not be visible to the customer."}
                                                        />
                                                        : <Col md="6">
                                                            <Link onClick={() => setShowInternalNotes(true)}>Add internal note</Link>
                                                        </Col>
                                                }
                                            </Row>
                                        </Section>
                                        <MapleTable className="w-full">
                                            <MapleTable.Content>
                                            <thead>
                                                <tr>
                                                    <MapleTable.TH className="!pr-0">
                                                        <div className="checkbox check-success inline">
                                                            <input type="checkbox" className="" value={"all"} id={"all"} name="line_item[]"  checked={checkedItemIds.includes("all")} onChange={ (event) => updateChecked(event) }/>
                                                            <label htmlFor={"all"}/>
                                                        </div>
                                                    </MapleTable.TH>
                                                    <MapleTable.TH>Description</MapleTable.TH>
                                                    <MapleTable.TH>Quantity</MapleTable.TH>
                                                    <MapleTable.TH className="text-end">Price</MapleTable.TH>
                                                    <MapleTable.TH className="text-end">Total</MapleTable.TH>
                                                    { hasPreviousNotes && <MapleTable.TH className="text-end">Credited</MapleTable.TH> }
                                                    <MapleTable.TH className="text-end">Creditable</MapleTable.TH>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200">
                                                { _.map(filteredInvoiceLineItems, (item, i) => renderRow(item, i)) }
                                            </tbody>
                                            </MapleTable.Content>
                                        </MapleTable>
                                        <div className="flex flex-col items-end">
                                            <Loader loading={previewResultsLoading}>
                                            {
                                                () =>
                                                    <table className="min-w-[350px] mr-2">
                                                        <tbody>
                                                        <tr>
                                                            <td>Invoice Total</td>
                                                            <td className="text-end min-w-[100px]">
                                                                { previewResults.existing_invoice_amount ? currencyFormatFromPrice(previewResults.existing_invoice_amount): "-" }
                                                            </td>
                                                        </tr>
                                                        {
                                                            !_.isEmpty(previewResults.tax_details_by_name) &&
                                                                <>
                                                                    <tr className="border-t border-gray-200">
                                                                        <td>Credit Amount Excluding Taxes</td>
                                                                        <td className="text-end">
                                                                            { previewResults.total_amount_excluding_taxes ? currencyFormatFromPrice(previewResults.total_amount_excluding_taxes): "-" }
                                                                        </td>
                                                                    </tr>
                                                                    {
                                                                        _.map(previewResults.tax_details_by_name, (v, k) =>
                                                                            <tr key={k}>
                                                                                <td>{ k } ({percentageFormat(v.tax_rate)})</td>
                                                                                <td className="text-end">
                                                                                    { currencyFormatFromPrice(v.tax_amount) }
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                </>
                                                        }
                                                        <tr className={classnames(!_.isEmpty(previewResults.tax_details_by_name) && "border-b border-gray-200")}>
                                                            <td>Total Credit Amount</td>
                                                            <td className="text-end">
                                                                { previewResults.total_amount ? currencyFormatFromPrice(previewResults.total_amount): "-" }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>New Invoice Total</td>
                                                            <td className="text-end">
                                                                { previewResults.adjusted_invoice_amount ? currencyFormatFromPrice(previewResults.adjusted_invoice_amount): "-" }
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>

                                            }
                                            </Loader>
                                            {
                                                previewResultsError &&
                                                    <div className="form-error-message mr-2">
                                                        <p className="mr-2">{ previewResultsError }</p>
                                                    </div>
                                            }
                                        </div>
                                        {
                                            previewResults.total_amount_to_be_refunded && previewResults.total_amount_to_be_refunded.value_in_cents > 0 &&
                                                <div className="flex flex-row justify-end mt-2">
                                                    <div className="basis-1/2">
                                                    <span className="text-lg font-semibold text-gray-900">Select how to credit...</span>
                                                        <table className="w-full mt-2">
                                                            <tbody>
                                                            {
                                                                _.map(_.range(numberOfRefundRows), (r, i) =>
                                                                    <tr key={i} className="thin">
                                                                        <td className="py-1">
                                                                            <BaseForm.Input
                                                                                type="select" min={0} label="Credit Amount" hideLabel
                                                                                className="w-full"
                                                                                formClassName="inline"
                                                                                options={creditRefundTypeOptions}
                                                                                name={`${i}.refund_type`}
                                                                                showSearch={false}
                                                                            />
                                                                        </td>
                                                                        <td className="text-end px-3 min-w-[120px]">
                                                                            {
                                                                                numberOfRefundRows === 1 ?
                                                                                    currencyFormatFromPrice(previewResults.total_amount_to_be_refunded)
                                                                                : <BaseForm.Input
                                                                                    type="number" min={0} label="Credit Amount" hideLabel
                                                                                    name={`${i}.credit_amount_value`}
                                                                                    step={"0.01"}
                                                                                    formClassName="inline"
                                                                                    className="text-end"
                                                                                    placeholder={"0.00"}
                                                                                    />
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                        {
                                                                            numberOfRefundRows > 1 &&
                                                                                <DeleteButton size="sm" onDelete={() => removeRefundRow(i)} />
                                                                        }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                            <tr className="thin">
                                                                <td>
                                                                    { creditRefundError && <span className="form-error-message">{ creditRefundError }</span> }
                                                                </td>
                                                                <td colSpan="2" className="text-end px-3">
                                                                    <Link onClick={() => addRefundRow()}>Add More</Link>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                        }
                                        <div className="flex flex-row w-full mt-3 items-center gap-2">
                                            <SubmitButton errorOnRight disabled={previewResultsLoading || _.isEmpty(previewResults)}>Issue Credit Note</SubmitButton>
                                            {
                                                error && <span className="form-error-message">{ error }</span>
                                            }
                                        </div>
                                    </BaseForm>
                                </ContentBox.Body>
                            </ContentBox>
                    }
                </Loader>
            </ContentContainer>
        </BaseContainer>
    );
}

export default InvoiceCreditNoteCreate;
