import '../../../App.scss';
import React, { useEffect, useContext, useState } from 'react';
import { serverPost } from '../../../helpers/server';
import {BaseContext, IntegrationContext, UserContext} from '../../../helpers/common';
import ContentContainer from '../../../components/ContentContainer';
import Label from '../../../components/Label';
import InfiniteScroll from "react-infinite-scroll-component";
import moment from 'moment';
import MapleTable from "../../../components/MapleTable";
import {useParams} from "react-router-dom";
import Loader from "../../../components/Loader";
import Link from "../../../components/Link";
import IntegrationSyncPreview from "../../../components/IntegrationSyncPreview";
const _ = require('lodash');

function Syncs() {
    const { getApiUrl, getCompanySpecificUrl, setPageTitle } = useContext(BaseContext);
    let { feature, entity } = useParams();
    feature = _.replace(_.upperCase(feature), " ", "_");
    const { integration, getIntegrationSpecificUrl } = useContext(IntegrationContext);

    const [loading, setLoading] = useState(true);
    const [sort, setSort] = useState("createdAtDesc");
    const [hasMore, setHasMore] = useState(true);
    const [fromKey, setFromKey] = useState(null);
    const [syncs, setSyncs] = useState([]);

    useEffect(() => {
        setPageTitle(`${integration.name} - Syncs`);
    }, [integration]);

    const onSearch = (restart = true) => {
        const limit = 50;
        const params = {
            sort_key: sort,
            pagination: {
                from_key: restart ? null: fromKey,
                limit: limit
            },
            query: {
                feature: feature
            }
        }
        serverPost(getApiUrl(`/integrations/${integration.id}/syncs/find`), params).then((res) => {
            setLoading(false);
            if (res) {
                const results = res.results || [];
                if (restart) {
                    setSyncs(results);
                } else {
                    setSyncs(_.concat(syncs, results));
                }
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
        });
    };

    useEffect(() => {
        onSearch(true);
    }, []);

    const renderStatus = (sync) => {
        if (sync.status === "IN_PROGRESS") {
            return (<Label.Info>In Progress</Label.Info>);
        } else if (sync.status === "SUCCESS") {
            return (<Label.Success>Succeeded</Label.Success>);
        } else if (sync.status === "FAIL") {
            return (<Label.Danger>Failed</Label.Danger>);
        } else if (sync.status === "ENQUEUED") {
            return (<Label.Info>Queued</Label.Info>);
        }
    }

    const renderType = (sync) => {
        if (sync.type === "INITIAL") {
            return "Initial Push";
        } else if (sync.type === "PUSH") {
            return "Scheduled Push";
        }
    }

    const onSyncTriggered = () => {
        onSearch(true);
        setTimeout(() => {
            onSearch(true);
        }, 2000)
    }

    return (
        <ContentContainer>
            <IntegrationSyncPreview
                feature={feature}
                onSyncTriggered={onSyncTriggered}
            />
            <h5>Syncs</h5>
            <InfiniteScroll
                dataLength={syncs.length}
                next={() => onSearch(false)}
                hasMore={hasMore}
                scrollableTarget="content-wrapper"
            >
                <Loader loading={loading}>
                    <MapleTable>
                        <MapleTable.Content>
                        <thead>
                            <tr>
                                <MapleTable.TH>Start Time</MapleTable.TH>
                                <MapleTable.TH>Type</MapleTable.TH>
                                <MapleTable.TH>Status</MapleTable.TH>
                                <MapleTable.TH></MapleTable.TH>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                        {
                            _.map(syncs, (sync, i) =>
                                <tr key={i}>
                                    <td>{ moment(sync.created_at).format("MMM D, YYYY h:mm:ssa") }</td>
                                    <td>{ renderType(sync) }</td>
                                    <td>{ renderStatus(sync) }</td>
                                    <td className="text-end">
                                    <Link href={getIntegrationSpecificUrl(`/${feature.toLowerCase()}/sync/${sync.id}`)}>View</Link>
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                        </MapleTable.Content>
                    </MapleTable>
                </Loader>
            </InfiniteScroll>
        </ContentContainer>
    );
}

export default Syncs;
