import '../App.scss';
import '../css/invoice.scss';
import React, {useContext, useEffect, useState} from 'react';
import {
    BaseContext,
    getCustomTaxRuleCustomerKeys, getCustomTaxRuleCustomerValues,
    getCustomTaxRulePropertyMap,
    getDescriptionForRule,
    IntegrationContext,
    percentageFormat
} from '../helpers/common';
import MapleTable from "./MapleTable";
import Label from "./Label";
import Button from './common/buttons/Button';
import AddOrEditCustomTaxRuleModal from "./modals/AddOrEditCustomTaxRuleModal";
import {notifyEvent, serverPatch, serverPost} from "../helpers/server";
import DeleteButton from "./DeleteButton";
import {ArrowDownIcon, ArrowUpIcon} from "@heroicons/react/24/outline";
const _ = require('lodash');

function TaxCalcCustomTaxRules(props) {
    const { getApiUrl } = useContext(BaseContext);
    const { integration } = useContext(IntegrationContext);
    const [showCustomTaxRuleModal, setShowCustomTaxRuleModal] = useState(false);
    const [customTaxRuleIndexToEdit, setCustomTaxRuleIndexToEdit] = useState(null);
    const [customTaxRules, setCustomTaxRules] = useState([]);
    const [products, setProducts] = useState([]);
    const [productOptionMap, setProductOptionMap] = useState({});

    useEffect(() => {
        setCustomTaxRules((integration.tax_data && integration.tax_data.custom_tax_rules) || [])
    }, [integration])

    useEffect(() => {
        const pLimit = 200
        const pParams = {
            pagination: {
                limit: pLimit
            },
            query: {}
        }
        serverPost(getApiUrl("/products/find"), pParams,{ skipCache: true }).then((res) => {
            if (res) {
                const results = res.results || [];
                setProducts(results);
                let m = {};
                _.each(results, r => {
                    m[r.id] = r.name
                })
                setProductOptionMap(m);
            }
        });
    }, []);

    const updateCustomRules = async (newRules, errorHandler) => {
        const updateData = {
            feature_data: {
                tax_data: {
                    custom_tax_rules: newRules,
                }
            }
        }
        const result = await serverPatch(getApiUrl(`/integrations/${integration.id}`), updateData, {}, errorHandler);
        if (result) {
            notifyEvent('integration_settings');
            setShowCustomTaxRuleModal(false);
        }
    }

    const onAdded = async (customRule, errorHandler) => {
        const newCustomTaxRules = [...customTaxRules, customRule]
        await updateCustomRules(newCustomTaxRules, errorHandler);
    }

    const onUpdated = async (customRule, errorHandler) => {
        const newCustomTaxRules = [...customTaxRules];
        newCustomTaxRules[customTaxRuleIndexToEdit] = customRule
        await updateCustomRules(newCustomTaxRules, errorHandler);
    }

    const addCustomTaxRule = () => {
        setCustomTaxRuleIndexToEdit(null);
        setShowCustomTaxRuleModal(true);
    }

    const editCustomTaxRule = (index) => {
        setCustomTaxRuleIndexToEdit(index);
        setShowCustomTaxRuleModal(true);
    }

    const onDeleteCustomTaxRule = (index) => {
        let newCustomTaxRules = [...customTaxRules];
        newCustomTaxRules.splice(index, 1)
        updateCustomRules(newCustomTaxRules);
    }

    const moveUp = (index) => {
        let newCustomTaxRules = [...customTaxRules];
        let rules = newCustomTaxRules.splice(index, 1);
        newCustomTaxRules.splice(index-1, 0, rules[0]);
        updateCustomRules(newCustomTaxRules);
    }

    const moveDown = (index) => {
        let newCustomTaxRules = [...customTaxRules];
        let rules = newCustomTaxRules.splice(index, 1);
        newCustomTaxRules.splice(index+1, 0, rules[0]);
        updateCustomRules(newCustomTaxRules);
    }

    return (
        <>
        <div>
            <p className="text-base font-semibold text-gray-700">Set custom tax rates based on certain conditions.</p>
            <p className="text-sm font-normal text-gray-500">In order to ensure that the right set of taxes are calculated on an invoice, you can set up rules on what taxes to be applied. If an invoice line item matches multiple conditions, the first matching tax rates will be applied.</p>
            <MapleTable>
                <MapleTable.Content>
                    <thead>
                    <tr>
                        <MapleTable.TH>Rule</MapleTable.TH>
                        <MapleTable.TH>Tax Rate(s)</MapleTable.TH>
                        <MapleTable.TH></MapleTable.TH>
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                    {
                        _.map(customTaxRules, (taxRule, i) =>
                            <tr key={i}>
                                <td>{ getDescriptionForRule(taxRule.rule, getCustomTaxRulePropertyMap(), productOptionMap) }</td>
                                <td>
                                    <div className="flex flex-row gap-1">
                                    {
                                        _.map(taxRule.taxes, (tax, i) =>
                                            <Label.Info key={i}>{ tax.tax_name }, { percentageFormat(tax.tax_rate) }</Label.Info>
                                        )
                                    }
                                    </div>
                                </td>
                                <td className="text-end">
                                    <div className="flex flex-row items-center justify-end gap-1">
                                        {
                                            i !== 0 ?
                                                <ArrowUpIcon className="w-4 h-4 cursor-pointer" onClick={() => moveUp(i)}/>
                                            : <div className="h4 w-4"/>
                                        }
                                        {
                                            i !== customTaxRules.length - 1 ?
                                                <ArrowDownIcon className="w-4 h-4 cursor-pointer" onClick={() => moveDown(i)}/>
                                            : <div className="h4 w-4"/>
                                        }
                                        <Button size="sm" variant="text-primary" onClick={() => editCustomTaxRule(i)}>
                                            <i className="fa fa-edit"/>
                                        </Button>
                                        <DeleteButton size="sm" onDelete={() => onDeleteCustomTaxRule(i)}/>
                                    </div>
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </MapleTable.Content>
            </MapleTable>
            <Button onClick={() => addCustomTaxRule()}>Add New Rule</Button>
        </div>
        <AddOrEditCustomTaxRuleModal
            show={showCustomTaxRuleModal}
            itemToEdit={!_.isNil(customTaxRuleIndexToEdit) ? customTaxRules[customTaxRuleIndexToEdit]: null}
            onClose={() => setShowCustomTaxRuleModal(false)}
            products={products}
            onAdded={onAdded}
            onUpdated={onUpdated}
        />
        </>
    );
}

export default TaxCalcCustomTaxRules;
