import '../../App.scss';
import React, { useEffect, useState, useContext } from 'react';
import { serverFetch, serverPost, serverPatch } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../components/BaseContainer';
import AddOrEditTaxRateModal from '../../components/modals/AddOrEditTaxRateModal';
import ArchiveButton from '../../components/common/buttons/ArchiveButton';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import BaseForm from '../../components/BaseForm';
import SubmitButton from '../../components/common/buttons/SubmitButton';
import SectionNav from '../../components/SectionNav';
import Checklist from '../../components/Checklist';
import Notification from '../../components/Notification';
import { Table, Row, Col } from 'react-bootstrap';
import Button from '../../components/common/buttons/Button';
const _ = require('lodash');

function Taxes() {
    const { t } = useTranslation('common');
    const { company, getApiUrl, setPageTitle } = useContext(BaseContext);
    const [taxRates, setTaxRates] = useState([]);
    const [showAddOrEditTaxRateModal, setShowAddOrEditTaxRateModal] = useState(false);
    const [taxRateToEdit, setTaxRateToEdit] = useState(false);
    const [filteredTaxRates, setFilteredTaxRates] = useState([]);
    const [hasArchivedTaxRates, setHasArchivedTaxRates] = useState(false);
    const [settings, setSettings] = useState({});
    const [checklistItems, setChecklistItems] = useState([]);
    const [activeNav, setActiveNav] = useState("ACTIVE");

    useEffect(() => {
        setPageTitle(`Taxes`);
    }, []);

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl(`/settings`)).then((res) => {
            setSettings(res)
        })

        serverPost(getApiUrl("/taxes/find"), { company_id: company.id }, { skipCache }).then((res) => {
            setTaxRates(res);
        });
    }

    useEffect(() => {
        setHasArchivedTaxRates(!_.isEmpty(_.filter(taxRates, p => p.state === "ARCHIVED")));
        setFilteredTaxRates(_.filter(taxRates, p => p.state === activeNav));
    }, [taxRates, activeNav])

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        serverFetch(getApiUrl("/tax_checklist")).then((res) => {
            setChecklistItems(res);
        });
    }, []);

    const onModalClose = () => {
        setShowAddOrEditTaxRateModal(false);
        fetchData(true);
    }

    const addTaxRate = () => {
        setTaxRateToEdit(null);
        setShowAddOrEditTaxRateModal(true);
    }

    const editTaxRate = (event, taxRate) => {
        event.stopPropagation();
        setTaxRateToEdit(taxRate);
        setShowAddOrEditTaxRateModal(true);
    }

    const deleteTaxRate = (taxRate) => {
        serverPatch(getApiUrl(`/taxes/${taxRate.id}`), { state: "ARCHIVED" }).then((res) => {
            fetchData(true);
        });
    }

    const updateSettings = (data, errorHandler) => {
        serverPatch(getApiUrl("/settings"), data, {}, errorHandler).then((res) => {
            if (res) {
                Notification.Success("Successfully updated");
            }
            fetchData(true);
        });
    }

    const onNavClick = (tabId) => {
        setActiveNav(tabId);
    }

    const tabItems = [{
        'label': 'Active',
        'id': 'ACTIVE',
        active: activeNav === "ACTIVE"
    }, {
        'label': 'Archived',
        'id': 'ARCHIVED',
        active: activeNav === "ARCHIVED"
    }]

    const taxOptions = [
        { value: "none", label: "Do Not Collect" },
        { value: "automatic", label: 'Automatically Collect Taxes' },
    ]
    // { value: "fixed", label: 'Manually Set Taxes' },

    return (
        <BaseContainer>
            <ContentContainer>
                <Section title="Taxes">
                    <span className="body1">How should we handle taxes for your invoices?</span>
                    <BaseForm initialFormFields={settings} onSubmit={updateSettings}>
                        <Row>
                            <BaseForm.Input colSpan="6" name="collect_taxes_type" type="select" label="Taxes"
                                hideLabel options={taxOptions} showSearch={false}/>
                            <Col md="3">
                                <div className="d-flex flex-row align-items-end">
                                    <SubmitButton>Update</SubmitButton>
                                </div>
                            </Col>
                        </Row>
                    </BaseForm>
                </Section>
                <div className="mt-2"/>
                {
                    settings.collect_taxes_type === "none" &&
                        <Section title={t('taxes.title')}>
                            <p>Not collecting taxes.</p>
                        </Section>
                }
                {
                    settings.collect_taxes_type === "fixed" &&
                        <Section title={t('taxes.title')}
                            actions={[{
                                variant: "primary",
                                icon: "fa-plus",
                                label: t('taxes.create_tax_rate'),
                                onClick: addTaxRate
                            }]}>
                            <span className="body1">Tax rates are calculated based on the customer's address and the rates listed below.</span>
                            {
                                hasArchivedTaxRates &&
                                    <>
                                        <SectionNav items={tabItems} onClick={onNavClick} />
                                        <br/>
                                    </>
                            }
                            <div>
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Rate</th>
                                        <th>Region</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    _.map(filteredTaxRates, (taxRate, i) =>
                                         <tr key={i}>
                                            <td>
                                                <span>{ taxRate.name }</span><br/>
                                                <span className="caption">{ taxRate.description }</span>
                                            </td>
                                            <td>{ `${taxRate.rate * 100}%` } <span className="caption">{ taxRate.inclusive ? "(inclusive)": "(exclusive)" }</span></td>
                                            <td>{ taxRate.region }</td>
                                            <td className="no-stretch text-end">
                                            {
                                                taxRate.state !== "ARCHIVED" &&
                                                    <>
                                                        <Button variant="text-primary" size="sm" onClick={(event) => editTaxRate(event, taxRate)}><i className="fa fa-edit"/></Button>
                                                        <ArchiveButton variant="text-danger" size="sm" onDelete={() => deleteTaxRate(taxRate)}/>
                                                    </>
                                            }
                                            </td>
                                         </tr>
                                    )
                                }
                                </tbody>
                            </Table>
                            </div>
                        </Section>
                }
                {
                    settings.collect_taxes_type === "automatic" &&
                        <Section title={t('taxes.title')}>
                            <p>Calculating tax dynamically for each invoice is a complex chore for any SaaS or eCommerce
                                business. Maple makes it simple to collect the right set of taxes via an integration with TaxJar.
                                In order to complete this setup, we will need to complete a few steps.</p>
                            <Checklist checklistItems={checklistItems} />
                        </Section>
                }
                <AddOrEditTaxRateModal show={showAddOrEditTaxRateModal} onClose={onModalClose} itemToEdit={taxRateToEdit} />
            </ContentContainer>
        </BaseContainer>
    );
}

export default Taxes;
