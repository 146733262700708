import '../../App.scss';
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {serverDelete, serverPost} from '../../helpers/server';
import {
    BaseContext,
    getDescriptionForCoupon,
    getDescriptionForCreditState,
    getLabelForCouponType
} from '../../helpers/common';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import AddOrEditCouponModal from '../../components/modals/AddOrEditCouponModal';
import Section from '../../components/Section';
import MapleTable from '../../components/MapleTable';
import Button from '../../components/common/buttons/Button';
import InfiniteScroll from "react-infinite-scroll-component";
import moment from 'moment';
import EmptyState from "../../components/EmptyState";
import Label from "../../components/Label";
import ArchiveButton from "../../components/common/buttons/ArchiveButton";
const _ = require('lodash');

function Coupons() {
    const navigate = useNavigate();
    const { company, getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const [showAddOrEditCouponModal, setShowAddOrEditCouponModal] = useState(false);
    const [coupons, setCoupons] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [fromKey, setFromKey] = useState(null);
    const [sort, setSort] = useState("createdAtDesc");
    const [query, setQuery] = useState(null)
    const [loading, setLoading] = useState(true)
    const [billableItems, setBillableItems] = useState([]);
    const [products, setProducts] = useState([]);
    const [productPricings, setProductPricings] = useState([]);
    const [couponToEdit, setCouponToEdit] = useState(null);

    useEffect(() => {
        setPageTitle(`Coupons`);
    }, []);

    const onSearch = (restart = true) => {
        const limit = 50;
        const params = {
            company_id: company.id,
            sort_key: sort,
            pagination: {
                from_key: restart ? null: fromKey,
                limit: limit
            },
            query: {
                search: query,
            }
        }
        serverPost(getApiUrl("/coupons/find"), params).then((res) => {
            if (res) {
                setCoupons(res);
                setHasMore(false);
            }
            setLoading(false);
        });
    };

    useEffect(() => {
        onSearch(true);
    }, [sort, query]);

    useEffect(() => {
        serverPost(getApiUrl("/billable/items/find"), { company_id: company.id }).then((res) => {
            setBillableItems(_.filter(res, (r) => !r.standard));
        });

        const pLimit = 100
        const pParams = {
            company_id: company.id,
            pagination: {
                limit: pLimit
            },
            query: {}
        }
        serverPost(getApiUrl("/products/find"), pParams,{ skipCache: true }).then((res) => {
            if (res) {
                const results = res.results || [];
                setProducts(results);
            }
        });

        const limit = 100
        const params = {
            company_id: company.id,
            sort_key: sort,
            pagination: {
                limit: limit
            },
        }
        serverPost(getApiUrl("/product_pricings/find"), params).then((res) => {
            if (res) {
                const results = res.results || [];
                setProductPricings(results);
            }
        });
    }, [])

    const onFieldChange = (name, value) => {
        if (name === "sort") {
            setSort(value);
        } else if (name === "query") {
            setQuery(value);
        }
    }

    const sortOptions = [
        { value: "createdAtAsc", label: 'Created At, Asc' },
        { value: "createdAtDesc", label: 'Created At, Desc' },
        { value: "updatedAtAsc", label: 'Updated At, Asc' },
        { value: "updatedAtDesc", label: 'Updated At, Desc' },
    ]

    const onModalClose = (didUpdate) => {
        setShowAddOrEditCouponModal(false);
        onSearch(true);
    }

    const addNewCoupon = () => {
        setShowAddOrEditCouponModal(true);
        setCouponToEdit(null);
    }

    const editCoupon = (event, coupon) => {
        event.stopPropagation();
        setShowAddOrEditCouponModal(true);
        setCouponToEdit(coupon);
    }

    const updateCoupon = (coupon) => {
        setShowAddOrEditCouponModal(true);
        setCouponToEdit(coupon);
    }

    const deleteCoupon = async (coupon) => {
        await serverDelete(getApiUrl(`/coupons/${coupon.id}`));
        onSearch(true);
    }

    const getDescriptionForCouponState = (state) => {
        if (state === "ACTIVE") {
            return (
                <Label.Success>Active</Label.Success>
            )
        } else if (state === "CONSUMED") {
            return (
                <Label.Warning>Consumed</Label.Warning>
            )
        } else if (state === "REVOKED") {
            return (
                <Label.Danger>Revoked</Label.Danger>
            )
        } else if (state === "EXPIRED") {
            return (
                <Label.Danger>Expired</Label.Danger>
            )
        }
    }

    return (
        <BaseContainer>
            <ContentContainer>
                <InfiniteScroll
                    dataLength={coupons.length}
                    next={() => onSearch(false)}
                    hasMore={hasMore}
                    scrollableTarget="content-wrapper"
                >
                    <Section title="Coupons"
                            variant="page"
                             loading={loading}
                             actions={!loading && !_.isEmpty(coupons) && [{
                                variant: "primary",
                                icon: "fa-plus",
                                label: "Create Coupon",
                                onClick: () => addNewCoupon()
                             }]}>
                        {
                            _.isEmpty(coupons) ?
                                <EmptyState
                                    title={"No coupons."}
                                    subtitle={"Get started by creating a new one."}
                                    buttonLabel={"New Coupon"}
                                    onClick={addNewCoupon}
                                />
                            : <MapleTable>
                                <MapleTable.Content>
                                    <thead>
                                        <tr>
                                            <MapleTable.TH>Name</MapleTable.TH>
                                            <MapleTable.TH>Type</MapleTable.TH>
                                            <MapleTable.TH>Description</MapleTable.TH>
                                            <MapleTable.TH>Limits</MapleTable.TH>
                                            <MapleTable.TH>Status</MapleTable.TH>
                                            <MapleTable.TH></MapleTable.TH>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                    {
                                        _.map(coupons, (row, i) =>
                                             <MapleTable.TR key={i} className="cursor-pointer" href={getCompanySpecificUrl(`/coupon/${row.id}`)}>
                                                <td>{ row.name }</td>
                                                <td>{ getLabelForCouponType(row.type) }</td>
                                                <td>{ getDescriptionForCoupon(row, products, productPricings, billableItems) }</td>
                                                <td>
                                                {
                                                    row.expiration_date ?
                                                        <><span>{ `Expiry: ${moment(row.expiration_date).format("MMM D, YYYY h:mma")}` }</span><br/></>
                                                    : null
                                                }
                                                {
                                                    row.num_redemptions ?
                                                       <><span>{ `Number of Redemptions: ${row.num_redemptions}` }</span><br/></>
                                                        : null
                                                }
                                                {
                                                    row.max_redemptions ?
                                                        <><span>{ `Max redemptions: ${row.max_redemptions}` }</span></>
                                                    : null
                                                }
                                                </td>
                                                <td>{ getDescriptionForCouponState(row.state)}</td>
                                                <td className="px-3 py-2.5 text-right text-sm font-medium">
                                                    {
                                                        row.state === "ACTIVE" &&
                                                            <>
                                                                <Button className="hide" variant="text-primary" onClick={(event) => editCoupon(event, row)}><i className="fa fa-edit"/></Button>
                                                                <ArchiveButton
                                                                    onDelete={() => deleteCoupon(row)} size="sm"
                                                                    title={"Confirm Revoke?"}
                                                                    body={"Are you sure you want revoke the coupon? This action can not be undone."}
                                                                />
                                                            </>
                                                    }
                                                </td>
                                             </MapleTable.TR>
                                        )
                                    }
                                    {
                                        hasMore &&
                                            <tr>
                                                <td colSpan="10" className="text-center">
                                                    <div className="spinner-border text-secondary"/>
                                                </td>
                                            </tr>
                                    }
                                    </tbody>
                                </MapleTable.Content>
                            </MapleTable>
                        }
                    </Section>
                </InfiniteScroll>
            </ContentContainer>
            <AddOrEditCouponModal show={showAddOrEditCouponModal} onClose={onModalClose} itemToEdit={couponToEdit} />
        </BaseContainer>
    );
}
/*
<BaseForm initialFormFields={initialFields} onFieldChange={onFieldChange}>
                                <MapleTable.Header>
                                    <MapleTable.LeftActions>
                                        <BaseForm.TextGroup name="query" icon="search-icon" placeholder="Search Coupons" />
                                    </MapleTable.LeftActions>
                                    <MapleTable.MiddleActions/>
                                    <MapleTable.RightActions>
                                        <div className="body2 gray4">Sort By: </div>
                                        <BaseForm.Input type="select" name="sort" options={sortOptions} />
                                    </MapleTable.RightActions>
                                </MapleTable.Header>
                            </BaseForm>
 */

export default Coupons;
