import '../../../App.scss';
import React, { useState, useContext } from 'react';
import { Button as RBButton, Spinner } from 'react-bootstrap';
import { UserContext } from '../../../helpers/common';
import classnames from 'classnames';
const _ = require('lodash');

function Button(props) {
	const userContext = useContext(UserContext);
	const { isMapleUser } = !_.isNil(userContext) ? userContext : { isMapleUser: false };

	const pprops = { ...props };
	delete pprops['onClick'];
	const [isLoading, setIsLoading] = useState(false);

	const onClick = async (e) => {
		setIsLoading(true);
		if (props.onClick) {
			await props.onClick(e);
		}
		setIsLoading(false);
	};

	const getUpdatedButtonColor = (variant) => {
		if (!isMapleUser) return '';

		switch (variant) {
			case '':
			case undefined:
			case 'primary':
				return 'btn-primary-updated';
			case 'outline-primary':
				return 'btn-outline-primary-updated';
			case 'danger':
				return 'btn-danger-updated';
			case 'text-danger':
				return 'btn-text-danger-updated';
			case 'text-primary':
				return 'btn-text-primary-updated';
			default:
				return '';
		}
	};

	return (
		<RBButton
			{...pprops}
			onClick={onClick}
			style={{ ...props.style, ...(props.color ? { background: props.color } : {}) }}
			className={classnames(
				{ 'btn-updated': isMapleUser },
				getUpdatedButtonColor(props.variant),
				'whitespace-nowrap',
				props.className,
			)}
		>
			<div className='d-flex flex-column align-items-center'>
				{isLoading ? (
					<Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true'>
						&nbsp;
					</Spinner>
				) : (
					<span className='submit-button-content'>{props.children}</span>
				)}
			</div>
		</RBButton>
	);
}

export default Button;
