import '../../App.scss';
import React, {useEffect, useContext, useMemo, useState} from 'react';
import {BaseContext, UserContext} from '../../helpers/common';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import Label from '../../components/Label';
import { Row, Col } from 'react-bootstrap';
import ContentBox from "../../components/ContentBox";
import {useNavigate} from "react-router-dom";
import {serverFetch} from "../../helpers/server";
const _ = require('lodash');

function Reports() {
    const navigate = useNavigate();
    const { isMapleUser } = useContext(UserContext);
    const { getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const [settings, setSettings] = useState({});
    const reports = useMemo(() => {
        let items = [
            { name: "Aged Receivables Report", description: "Get insights on your unpaid invoices", href:"/reports/invoice_aging_report" },
            { name: "Deferred Revenue", description: "Get insights on your recognized and deferred revenue.", href:"/reports/deferred_revenue" },
        ]
        if (isMapleUser || settings.revenue_recognition_feature_enabled) {
            items.push({ name: "Revenue Recognition", description: "Get insights on your recognized and deferred revenue.", href:"/reports/revenue_recognition", beta: true })
        }
        if (isMapleUser) {
            items.push({ name: "Journal Entries", description: "Get insights on your journal entries.", href:"/reports/journal_entries", beta: true })
        }
        return items;
    }, [settings]);

    useEffect(() => {
        setPageTitle(`Financial Reports`);
    }, []);

    useEffect(() => {
        serverFetch(getApiUrl("/settings")).then((res) => {
            setSettings(res);
        });
    }, []);

    return (
        <BaseContainer>
            <ContentContainer>
                <Section title="Financial Reports" variant="page">
                    <Row>
                        {
                            _.map(_.filter(reports, r => r), (report, i) =>
                                <Col key={i} md="4">
                                    <ContentBox className="cursor-pointer hover:shadow-none" onClick={() => navigate(getCompanySpecificUrl(report.href))}>
                                        <ContentBox.Body className="flex flex-col gap-2 !p-3 m-1">
                                            <div className="flex flex-row gap-2 items-center">
                                                <span className="grow text-base font-semibold">{ report.name }</span>
                                                {
                                                    report.beta &&
                                                        <Label.Warning>Beta</Label.Warning>
                                                }
                                            </div>
                                            <div className="h-24">
                                                { report.description }
                                            </div>
                                        </ContentBox.Body>
                                    </ContentBox>
                                </Col>
                            )
                        }
                    </Row>
                </Section>
            </ContentContainer>
        </BaseContainer>
    );
}

export default Reports;
