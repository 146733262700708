import '../App.scss';
import '../css/invoice.scss';
import React, { useEffect, useState, useContext, createRef } from 'react';
import { serverFetch, serverPatch, notifyEvent } from '../helpers/server';
import { BaseContext, IntegrationContext } from '../helpers/common';
import Loader from './Loader';
import ContentBox from './ContentBox';
import BaseForm from './BaseForm';
import {Table, Container, Row, Col} from 'react-bootstrap';
import Button from './common/buttons/Button';
import SubmitButton from "./common/buttons/SubmitButton";
const _ = require('lodash');

function IntegrationSelectAccountingAccount(props) {
    const { getApiUrl } = useContext(BaseContext);
    const { integration } = useContext(IntegrationContext);

    const [loading, setLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [accounts, setAccounts] = useState([]);

    useEffect(() => {
        if (isEditing) {
            serverFetch(getApiUrl(`/integrations/${integration.id}/accounting/accounts`), { skipCache: true }).then((res) => {
                if (res) {
                    setAccounts(res.accounts);
                }
                setLoading(false);
            })
        }
    }, [isEditing])

    const onAccountingAccountSelect = (data) => {
        const updateData = {
            feature_data: {
                accounting_data: {
                    selected_account: data.selected_account,
                }
            }
        }
        serverPatch(getApiUrl(`/integrations/${integration.id}`), updateData).then(res => {
            if (res) {
                setIsEditing(false);
                notifyEvent('integration_settings');
                if (props.onUpdate) {
                    props.onUpdate();
                }
            }
        })
    }

    const accountingAccountOptions = _.map(accounts, (acc) => {
        return {
            value: acc.id,
            label: acc.name
        }
    });

    return (
        <ContentBox>
            <ContentBox.Body>
                <div className="d-flex flex-row gap-3">
                    <div className="flex-grow-1">
                        <h5>Select { integration.name } Account</h5>
                    </div>
                    <div className="flex-shrink-0 d-flex align-items-center">
                        {
                            isEditing ?
                                <Button variant="text-danger" onClick={() => setIsEditing(false)}>Cancel</Button>
                                : <Button variant="text-primary" onClick={() => setIsEditing(true)}><i className="fa fa-edit" /> Edit</Button>
                        }
                    </div>
                </div>
                {
                    isEditing ?
                        <Loader loading={loading}>
                            <Container>
                                <Row>
                                    <Col md="8">
                                        <div className="mt-2">
                                            <BaseForm initialFormFields={integration.accounting_data} onSubmit={onAccountingAccountSelect}>
                                                <BaseForm.Input name="selected_account" label="Account" type="select" options={accountingAccountOptions} showSearch={false}/>
                                                <SubmitButton>Select</SubmitButton>
                                            </BaseForm>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Loader>
                        : <div className="flex flex-row gap-2">
                            <span className="text-sm font-semibold text-gray-700">Account:</span>
                            <span className="text-sm font-normal text-gray-500">{ integration.accounting_data && integration.accounting_data.selected_account }</span>
                        </div>
                }
            </ContentBox.Body>
        </ContentBox>
    );
}

export default IntegrationSelectAccountingAccount;
