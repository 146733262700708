const _ = require('lodash');

export function getCustomerExportFields() {
	return [
		{
			title: 'Customer Details',
			fields: [
				{ value: 'id', label: 'ID' },
				{ value: 'created_at', label: 'Created Date' },
				{ value: 'updated_at', label: 'Updated Date' },
				{ value: 'org_name', label: 'Organization Name' },
				{ value: 'name', label: 'Name' },
				{ value: 'email', label: 'Email' },
				{ value: 'phone', label: 'Phone' },
				{ value: 'address', label: 'Address' },
				{ value: 'mrr', label: 'MRR' },
				{ value: 'mrr_with_currency', label: 'MRR (with currency)' },
				{ value: 'arr', label: 'ARR' },
				{ value: 'arr_with_currency', label: 'ARR (with currency)' },
				{ value: 'identifier', label: 'External Identifier' },
				{ value: 'imported_from', label: 'Imported From' },
				{ value: 'import_ref', label: 'Import Reference' },
			],
		},
	];
}

export function getReferenceCustomerExportFields() {
	return {
		title: 'Customer Details',
		fields: [
			{ value: 'customer.id', label: 'ID' },
			{ value: 'customer.org_name', label: 'Organization Name' },
			{ value: 'customer.name', label: 'Name' },
			{ value: 'customer.email', label: 'Email' },
			{ value: 'customer.identifier', label: 'External Identifier' },
			{ value: 'customer.phone', label: 'Phone' },
			{ value: 'customer.address', label: 'Address' },
		],
	};
}

export function getPaymentExportFields() {
	return [
		{
			title: 'Payment Details',
			fields: [
				{ value: 'id', label: 'ID' },
				{ value: 'created_at', label: 'Created Date' },
				{ value: 'updated_at', label: 'Updated Date' },
				{ value: 'status', label: 'Status' },
				{ value: 'provider_type', label: 'Payment Source' },
				{ value: 'transaction_type', label: 'Transaction Type' },
				{ value: 'is_connected_account', label: 'Is Connected Account' },
				{ value: 'total_amount', label: 'Total Amount' },
				{ value: 'total_amount_with_currency', label: 'Total Amount (with currency)' },
				{ value: 'application_fee_amount', label: 'Application Fee' },
				{ value: 'application_fee_amount_with_currency', label: 'Application Fee (with currency)' },
				{ value: 'net_amount', label: 'Net Amount' },
				{ value: 'net_amount_with_currency', label: 'Net Amount (with currency)' },
				{ value: 'fee_amount', label: 'Fee Amount' },
				{ value: 'fee_amount_with_currency', label: 'Fee Amount (with currency)' },
				{ value: 'currency', label: 'Currency' },
				{ value: 'imported_from', label: 'Imported From' },
				{ value: 'import_ref', label: 'Import Reference' },
			],
		},
		getReferenceCustomerExportFields(),
	];
}

export function getSubscriptionExportFields() {
	return [
		{
			title: 'Subscription Details',
			fields: [
				{ value: 'id', label: 'ID' },
				{ value: 'created_at', label: 'Created Date' },
				{ value: 'updated_at', label: 'Updated Date' },
				{ value: 'start_date', label: 'Start Date' },
				{ value: 'end_date', label: 'End Date' },
				{ value: 'renewal_date', label: 'Renewal Date' },
				{ value: 'next_invoice_date', label: 'Next Invoice Date' },
				{ value: 'auto_renews', label: 'Auto Renews' },
				{ value: 'auto_charges', label: 'Auto Charges' },
				{ value: 'status', label: 'Status' },
				{ value: 'is_active', label: 'Is Active?' },
				{ value: 'currency', label: 'Currency' },
				{ value: 'mrr', label: 'MRR' },
				{ value: 'mrr_with_currency', label: 'MRR (with currency)' },
				{ value: 'arr', label: 'ARR' },
				{ value: 'arr_with_currency', label: 'ARR (with currency)' },
				{ value: 'imported_from', label: 'Imported From' },
				{ value: 'import_ref', label: 'Import Reference' },
				{ value: 'product_names', label: 'Product Names' },
				{ value: 'plan_description', label: 'Plan Description' },
				{ value: 'plan_ids', label: 'Plan IDs' },
			],
		},
		getReferenceCustomerExportFields(),
	];
}

export function getInvoiceExportFields() {
	return [
		{
			title: 'Invoice Details',
			fields: [
				{ value: 'id', label: 'ID' },
				{ value: 'created_at', label: 'Created Date' },
				{ value: 'updated_at', label: 'Updated Date' },
				{ value: 'invoice_date', label: 'Invoice Date' },
				{ value: 'due_date', label: 'Due Date' },
				{ value: 'uuid', label: 'UUID' },
				{ value: 'number', label: 'Number' },
				{ value: 'status', label: 'Status' },
				{ value: 'currency', label: 'Currency' },
				{ value: 'subtotal', label: 'Subtotal' },
				{ value: 'subtotal_with_currency', label: 'Subtotal (with currency)' },
				{ value: 'total', label: 'Total' },
				{ value: 'total_with_currency', label: 'Total (with currency)' },
				{ value: 'paid', label: 'Paid' },
				{ value: 'paid_with_currency', label: 'Paid (with currency)' },
				{ value: 'due', label: 'Due' },
				{ value: 'due_with_currency', label: 'Due (with currency)' },
				{ value: 'notes', label: 'Notes' },
				{ value: 'product_names', label: 'Product Names' },
				{ value: 'payment_instructions', label: 'Payment Instructions' },
				{ value: 'auto_charges', label: 'Auto Charges' },
				{ value: 'paid_date', label: 'Paid Date' },
				{ value: 'is_paid', label: 'Is Paid?' },
				{ value: 'imported_from', label: 'Imported From' },
				{ value: 'import_ref', label: 'Import Reference' },
			],
		},
		getReferenceCustomerExportFields(),
	];
}

export function getInvoiceLineItemExportFields() {
	return [
		{
			title: 'Invoice Line Item Details',
			fields: [
				{ value: 'id', label: 'ID' },
				{ value: 'created_at', label: 'Created Date' },
				{ value: 'updated_at', label: 'Updated Date' },
				{ value: 'product_name', label: 'Product' },
				{ value: 'product_tax_code', label: 'Product Tax Code' },
				{ value: 'period_start_date', label: 'Period Start Date' },
				{ value: 'period_end_date', label: 'Period End Date' },
				{ value: 'description', label: 'Description' },
				{ value: 'type', label: 'Type' },
				{ value: 'quantity', label: 'Quantity' },
				{ value: 'amount_before_discount', label: 'Amount Before Discount' },
				{ value: 'amount_before_discount_with_currency', label: 'Amount Before Discount (with currency)' },
				{ value: 'amount', label: 'Amount' },
				{ value: 'amount_with_currency', label: 'Amount (with currency)' },
				{ value: 'unit_price', label: 'Unit Price' },
				{ value: 'unit_price_with_currency', label: 'Unit Price (with currency)' },
				{ value: 'unit_price_before_discount', label: 'Unit Price Before Discount' },
				{
					value: 'unit_price_before_discount_with_currency',
					label: 'Unit Price Before Discount (with currency)',
				},
				{ value: 'is_prorated', label: 'Is Prorated' },
				{ value: 'taxable', label: 'Taxable' },
				{ value: 'tax', label: 'Tax' },
				{ value: 'tax_with_currency', label: 'Tax (with currency)' },
				{ value: 'tax_name', label: 'Tax Name' },
				{ value: 'tax_jurisdiction', label: 'Tax Jurisdiction' },
				{ value: 'tax_rate', label: 'Tax Rate' },
				{ value: 'imported_from', label: 'Imported From' },
				{ value: 'import_ref', label: 'Import Reference' },
			],
		},
		{
			title: 'Invoice Details',
			fields: [
				{ value: 'invoice.id', label: 'ID' },
				{ value: 'invoice.created_at', label: 'Created Date' },
				{ value: 'invoice.updated_at', label: 'Updated Date' },
				{ value: 'invoice.invoice_date', label: 'Invoice Date' },
				{ value: 'invoice.due_date', label: 'Due Date' },
				{ value: 'invoice.number', label: 'Number' },
				{ value: 'invoice.uuid', label: 'UUID' },
				{ value: 'invoice.status', label: 'Status' },
				{ value: 'invoice.currency', label: 'Currency' },
				{ value: 'invoice.subtotal', label: 'Subtotal' },
				{ value: 'invoice.subtotal_with_currency', label: 'Subtotal (with currency)' },
				{ value: 'invoice.total', label: 'Total' },
				{ value: 'invoice.total_with_currency', label: 'Total (with currency)' },
				{ value: 'invoice.paid', label: 'Paid' },
				{ value: 'invoice.paid_with_currency', label: 'Paid (with currency)' },
				{ value: 'invoice.due', label: 'Due' },
				{ value: 'invoice.due_with_currency', label: 'Due (with currency)' },
				{ value: 'invoice.notes', label: 'Notes' },
				{ value: 'invoice.payment_instructions', label: 'Payment Instructions' },
				{ value: 'invoice.auto_charges', label: 'Auto Charges' },
				{ value: 'invoice.paid_date', label: 'Paid Date' },
				{ value: 'invoice.is_paid', label: 'Is Paid?' },
				{ value: 'imported_from', label: 'Imported From' },
				{ value: 'import_ref', label: 'Import Reference' },
			],
		},
		getReferenceCustomerExportFields(),
	];
}

export function getContractExportFields() {
	return [
		{
			title: 'Contract Details',
			fields: [
				{ value: 'id', label: 'ID' },
				{ value: 'created_at', label: 'Created Date' },
				{ value: 'updated_at', label: 'Updated Date' },
				{ value: 'title', label: 'Title' },
				{ value: 'start_date', label: 'Start Date' },
				{ value: 'completed_date', label: 'Completed Date' },
				{ value: 'signed_date', label: 'Signed Date' },
				{ value: 'status', label: 'Status' },
				{ value: 'currency', label: 'Currency' },
				{ value: 'tcv', label: 'TCV' },
				{ value: 'tcv_with_currency', label: 'TCV (with currency)' },
				{ value: 'acv', label: 'Paid' },
				{ value: 'acv_with_currency', label: 'ACV (with currency)' },
				{ value: 'auto_charges', label: 'Auto Charges' },
				{ value: 'owner_name', label: 'Owner Name' },
				{ value: 'plan_description', label: 'Plan Description' },
				{ value: 'plan_ids', label: 'Plan IDs' },
			],
		},
		getReferenceCustomerExportFields(),
	];
}

export function getBillableEventsExportFields() {
	return [
		{
			title: 'Billable Event Details',
			fields: [
				{ value: 'id', label: 'ID' },
				{ value: 'created_at', label: 'Created Date' },
				{ value: 'transaction_id', label: 'Transaction ID' },
				{ value: 'timestamp', label: 'Event Timestamp' },
				{ value: 'customer_id', label: 'Customer ID' },
				{ value: 'customer_name', label: 'Customer Name' },
				{ value: 'item_id', label: 'Item ID' },
				{ value: 'item_name', label: 'Item Name' },
				{ value: 'subscription_id', label: 'Subscription IDs' },
				{ value: 'properties', label: 'Properties' },
			],
		},
	];
}

export function getCustomerImportRowExportFields() {
	return [
		{
			title: 'Customer Import Row Details',
			fields: [
				{ value: 'row_number', label: 'Row Number' },
				{ value: 'status', label: 'Status' },
				{ value: 'customer.name', label: 'Contact Name' },
				{ value: 'customer.email', label: 'Contact Email' },
				{ value: 'customer.org_name', label: 'Organization Name' },
				{ value: 'customer.identifier', label: 'Identifier' },
				{ value: 'customer.billing_emails', label: 'Billing Emails' },
				{ value: 'customer.phone_number', label: 'Phone Number' },
				{ value: 'error', label: 'Error' },
			],
		},
	];
}

export function getBillableEventImportRowExportFields() {
	return [
		{
			title: 'Billable Event Import Row Details',
			fields: [
				{ value: 'row_number', label: 'Row Number' },
				{ value: 'status', label: 'Status' },
				{ value: 'billable.transaction_id', label: 'Transaction ID' },
				{ value: 'billable.customer_id', label: 'Maple Customer ID' },
				{ value: 'billable.item_id', label: 'Item ID' },
				{ value: 'billable.event_timestamp', label: 'Event Timestamp' },
				{ value: 'billable.properties', label: 'Properties' },
				{ value: 'error', label: 'Error' },
			],
		},
	];
}
