import '../App.scss';
import '../css/invoice.scss';
import React, {useEffect, useState, useContext, createRef, forwardRef, useImperativeHandle} from 'react';
import { IntegrationContext, UserContext } from '../helpers/common';
import Loader from './Loader';
import ContentBox from './ContentBox';
import BaseForm from './BaseForm';
import { Table } from 'react-bootstrap';
import Button from './common/buttons/Button';
import SubmitButton from "./common/buttons/SubmitButton";
import classnames from "classnames";
import Select from "react-select/async";
import DeleteButton from "./DeleteButton";
const _ = require('lodash');

const IntegrationEntityFieldMapComponent = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        getUpdatedMapping() {
            return getUpdatedMapping();
        }
    }))

    const {integration} = useContext(IntegrationContext);
    const formRef = createRef();
    const userContext = useContext(UserContext);
    const { isMapleUser } = !_.isNil(userContext) ? userContext : { isMapleUser: false };

    const [isEditing, setIsEditing] = useState(false);
    const [initialMapping, setInitialMapping] = useState({});
    const [initialFields, setInitialFields] = useState({});
    const [currentFields, setCurrentFields] = useState({});
    const [isRemoteToMaple, setIsRemoteToMaple] = useState(false);
    const [availableFieldMappings, setAvailableFieldMappings] = useState([]);
    const [entity, setEntity] = useState(null);
    const [currentGroupingAndFieldMappingsByGroupKey, setCurrentGroupingAndFieldMappingsByGroupKey] = useState({});
    const [groupingMapByIDByGroupKey, setGroupingMapByIDByGroupKey] = useState({})

    useEffect(() => {
        setEntity(props.entity);
    }, [props.entity])

    useEffect(() => {
        if (props.forceEditing) {
            setIsEditing(true)
        }
    }, [props.forceEditing])

    useEffect(() => {
        setAvailableFieldMappings(props.availableFieldMappings);
        const newGroupingMapByIDByGroupKey = {};
        const newCurrentGroupingAndFieldMappingsByGroupKey = {};
        _.map(props.availableFieldMappings, (field, j) => {
            if (field.source_field.table_choice_grouping_key && field.source_field.table_choices) {
                const groupKey = field.source_field.table_choice_grouping_key
                newGroupingMapByIDByGroupKey[groupKey] = {}
                _.forEach(field.source_field.table_choices, table_choice => {
                    newGroupingMapByIDByGroupKey[groupKey][table_choice.table_id.toString()] = {
                        table_row_id: table_choice.table_id,
                        table_row_name: table_choice.table_name,
                        choices: table_choice.choices,
                    }
                })
                // create empty array to store current group mappings (2D table mappings)
                newCurrentGroupingAndFieldMappingsByGroupKey[groupKey] = []
                // initialize current group mapping if there's only 1 table choice
                if (field.source_field.table_choices.length === 1) {
                    let newGFMs = []
                    const table_row_id = field.source_field.table_choices[0].table_id;
                    const newGFM = {
                        table_row_id: table_row_id,
                        table_row_name: newGroupingMapByIDByGroupKey[groupKey][table_row_id].table_row_name,
                        choices: _.map(newGroupingMapByIDByGroupKey[groupKey][table_row_id].choices, choice => {
                            return {
                                value: choice.key,
                                label: choice.name,
                            };
                        }),
                    }
                    newGFMs.push(newGFM)
                    newCurrentGroupingAndFieldMappingsByGroupKey[groupKey] = newGFMs
                }
            }
        })
        setGroupingMapByIDByGroupKey(newGroupingMapByIDByGroupKey)
        setCurrentGroupingAndFieldMappingsByGroupKey(newCurrentGroupingAndFieldMappingsByGroupKey)
    }, [props.availableFieldMappings])

    useEffect(() => {
        if (!_.isNil(props.isRemoteToMaple)) {
            setIsRemoteToMaple(props.isRemoteToMaple);
        } else {
            setIsRemoteToMaple(false);
        }
        setInitialMapping(props.initialMapping);
        let ifields = {
            "sub": {}
        };
        let newGFMMapByKey = {}
        if (props.initialMapping) {
            if (props.isRemoteToMaple) {
                _.each(props.initialMapping, (imap) => {
                    ifields[imap.remote_field] = imap.maple_field || null
                    if (imap.sub_field_mapping) {
                        const groupKey = imap.sub_field_mapping_grouping_key
                        if (groupKey) {
                            // 2D table choices
                            let newGFMs = []
                            for (let [table_row_id, preSelectedMappings] of Object.entries(imap.sub_field_mapping)) {
                                if (groupingMapByIDByGroupKey && groupingMapByIDByGroupKey[groupKey] && groupingMapByIDByGroupKey[groupKey][table_row_id.toString()]) {
                                    const newGFM = {
                                        table_row_id: table_row_id,
                                        table_row_name: groupingMapByIDByGroupKey[groupKey][table_row_id].table_row_name,
                                        choices: _.map(groupingMapByIDByGroupKey[groupKey][table_row_id].choices, choice => {
                                            return {
                                                value: choice.key,
                                                label: choice.name,
                                            };
                                        }),
                                    }
                                    newGFMs.push(newGFM)
                                }
                            }
                            newGFMMapByKey[groupKey] = newGFMs
                            ifields["sub"][imap.maple_field] = {}
                            ifields["sub"][imap.maple_field][groupKey] = imap.sub_field_mapping
                        } else {
                            // 1D choices
                            ifields["sub"][imap.maple_field] = imap.sub_field_mapping
                        }
                    }
                })
            } else {
                _.each(props.initialMapping, (imap) => {
                    ifields[imap.maple_field] = imap.remote_field || null
                    if (imap.sub_field_mapping) {
                        ifields["sub"][imap.remote_field] = imap.sub_field_mapping
                    }
                })
            }
        }
        setInitialFields(ifields)
        setCurrentFields(ifields)
        setCurrentGroupingAndFieldMappingsByGroupKey(prevState => {
            const finalState = { ...prevState, ...newGFMMapByKey }
            return finalState
        });
    }, [props.isRemoteToMaple, props.initialMapping, groupingMapByIDByGroupKey])

    const getUpdatedMapping = () => {
        const newMapping = {...currentFields}
        if (formRef.current) {
            const formData = formRef.current.getFormData()
            _.each(formData, (v, k) => {
                newMapping[k] = v;
            })
        }
        return newMapping;
    }

    const onFieldChange = (name, value) => {
        setCurrentFields(prevFields => {
            const newFields = {...prevFields};
            if (_.startsWith(name, "sub.")) {
            } else {
                newFields[name] = value;
            }
            return newFields;
        })
    }

    const onEdit = () => {
        setIsEditing(true);
    }

    const onSave = (data) => {
        const updatedMapping = [];
        _.each(_.keys(data), (key) => {
            if (key === "sub" || !data[key]) {
                return;
            }
            let mapping = {}
            let subFieldMapping = null;
            let subFieldMappingGroupKey = null;
            if (!_.isEmpty(data.sub) && data.sub[data[key]]) {
                const subFieldMappingFormData = data.sub[data[key]]
                if (subFieldMappingFormData) {
                    if (Object.keys(subFieldMappingFormData).length === 1) {
                        // 2D table choices
                        const groupKey = Object.keys(subFieldMappingFormData)[0]
                        subFieldMappingGroupKey = groupKey
                        subFieldMapping = subFieldMappingFormData[groupKey]
                    } else {
                        // 1D array choices
                        subFieldMapping = subFieldMappingFormData
                    }
                }
            }
            if (isRemoteToMaple) {
                mapping.remote_field = key
                mapping.maple_field = data[key]
                if (subFieldMapping) {
                    mapping.sub_field_mapping = subFieldMapping
                }
                if (subFieldMappingGroupKey) {
                    mapping.sub_field_mapping_grouping_key = subFieldMappingGroupKey
                }
            } else {
                mapping.maple_field = key
                mapping.remote_field = data[key]
                if (subFieldMapping) {
                    mapping.sub_field_mapping = subFieldMapping
                }
                if (subFieldMappingGroupKey) {
                    mapping.sub_field_mapping_grouping_key = subFieldMappingGroupKey
                }
            }
            updatedMapping.push(mapping)
        })
        setIsEditing(false);
        if (props.onUpdate) {
            props.onUpdate(updatedMapping)
        }
    }

    const addNewGroupingRow = (value, groupKey) => {
        setCurrentGroupingAndFieldMappingsByGroupKey(prevGroupingAndFieldMappingsByGroupKey => {
            const newGroupingAndFieldMappingsByGroupKey = { ...prevGroupingAndFieldMappingsByGroupKey }
            const newGFM = {
                table_row_id:  value.value,
                table_row_name: value.label,
                choices: _.map(value.choices, c => {
                    return {
                        value: c.key,
                        label: c.name
                    }
                }),
            }
            newGroupingAndFieldMappingsByGroupKey[groupKey].push(newGFM)
            return newGroupingAndFieldMappingsByGroupKey;
        });
    }

    const onGroupingRowDelete = (id, groupKey) => {
        setCurrentGroupingAndFieldMappingsByGroupKey(prevGroupingAndFieldMappingsByGroupKey => {
            const newGroupingAndFieldMappingsByGroupKey = { ...prevGroupingAndFieldMappingsByGroupKey }
            newGroupingAndFieldMappingsByGroupKey[groupKey] = prevGroupingAndFieldMappingsByGroupKey[groupKey].filter((gfm) => gfm.table_row_id !== id)
            return newGroupingAndFieldMappingsByGroupKey;
        });
    };

    const renderAvailableFieldRow = (field, i) => {
        const renderChoiceMappingValue = (fieldKey, subFieldKey, choices) => {
            if (currentFields["sub"][currentFields[fieldKey]]) {
                const matchingKey = currentFields["sub"][currentFields[fieldKey]][subFieldKey]
                const matchingObject = _.find(choices, (c) => c.value === matchingKey);
                if (matchingObject) {
                    return matchingObject.label
                } else {
                    return "Old Mapping"
                }
            } else {
                return "Not Mapped"
            }
        }
        const renderTableRowInnerChoiceMappingValue = (fieldKey, subFieldKey, groupKey, tableRowID, choices) => {
            if (currentFields["sub"][currentFields[fieldKey]]) {
                const matchingKey = currentFields["sub"][currentFields[fieldKey]][groupKey][tableRowID][subFieldKey]
                const matchingObject = _.find(choices, (c) => c.value === matchingKey);
                if (matchingObject) {
                    return matchingObject.label
                } else {
                    return "Old Mapping"
                }
            } else {
                return "Not Mapped"
            }
        }
        const relevantFields = field.available_fields;
        const remoteOptions = _.map(relevantFields, (rf) => {
            return {
                value: rf.key,
                label: `${rf.name} (${rf.key})`
            }
        })
        if (field.ignorable) {
            remoteOptions.unshift({value: null, label: "Ignore"});
        }

        const key = field.source_field.key;
        const name = field.source_field.name;
        const existingMapping = currentFields[`${key}`];
        const existingField = existingMapping && _.find(relevantFields, (f) => f.key === existingMapping)
        const existingValue = existingField && `${existingField.name} (${existingField.key})`
        const selectedRemoteField = _.find(relevantFields, f => f.key === currentFields[key]);
        const remoteChoiceFields = _.map(field.source_field.choices, c => {
            return {
                value: c.key,
                label: c.name
            }
        })
        let remainingTableRowChoices = _.filter(field.source_field.table_choices, tableChoice => {
            const groupKey = field.source_field.table_choice_grouping_key
            if (groupKey && currentGroupingAndFieldMappingsByGroupKey[groupKey]) {
                for (let gfm of currentGroupingAndFieldMappingsByGroupKey[groupKey]) {
                    if (gfm.table_row_id === tableChoice.table_id) {
                        return false;
                    }
                }
                return true;
            }
            return true;
        });
        remainingTableRowChoices = _.map(remainingTableRowChoices, table_choice => {
            return {
                label: table_choice.table_name,
                value: table_choice.table_id,
                choices: table_choice.choices,
            }
        })
        // A little hacky for better readable in rendering (for the scenario when only 1 table choice is available to choose from, we render in the 1D choices instead of 2D choices)
        let singleGFM = null;
        if (field.source_field.type === "TABLE" && selectedRemoteField && field.source_field.table_choices.length === 1) {
            singleGFM = currentGroupingAndFieldMappingsByGroupKey[field.source_field.table_choice_grouping_key][0];
        }

        return (
            <React.Fragment key={i}>
                <tr className="thin">
                    <td>{name}</td>
                    <td><i className="fa fa-arrow-right"/></td>
                    <td>
                        {
                            isEditing && (field.editable && !field.is_autocomputed) ?
                                <BaseForm.Input type="select" name={`${key}`}
                                                options={remoteOptions} showSearch={true}/>
                                : <div className={classnames("text-gray-500 flex flex-row items-center", (isEditing && field.is_autocomputed) ? "h-9" : "")}>
                                    {
                                        field.is_autocomputed ?
                                            <span className="italic">*Auto Computed</span>
                                            : <span>{existingValue || "Ignore"}</span>
                                    }
                                </div>
                        }
                    </td>
                </tr>
                {
                    field.source_field.type === "CHOICE" ?
                        selectedRemoteField && !_.isEmpty(selectedRemoteField.choices) &&
                        <tr className="thin" key={`choice.${i}`}>
                            <td colSpan={3}>
                                <Table borderless className="max-w-xl">
                                    <tbody>
                                    {
                                        _.map(selectedRemoteField.choices, (c, j) =>
                                            <tr className="thin" key={`${c.key}_${j}`}>
                                                <td>{c.name}</td>
                                                <td><i className="fa fa-arrow-right"/></td>
                                                <td>
                                                    {
                                                        isEditing ?
                                                            <BaseForm.Input
                                                                outerInputClassName={"p-0"} type="select" name={`sub.${currentFields[key]}.${c.key}`}
                                                                options={remoteChoiceFields} showSearch={true}
                                                            />
                                                            : <div className={classnames("text-gray-500 flex flex-row items-center")}>
                                                                { renderChoiceMappingValue(key, c.key, remoteChoiceFields) }
                                                            </div>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </Table>
                            </td>
                        </tr>
                        : field.source_field.type === "TABLE" && selectedRemoteField && !_.isEmpty(selectedRemoteField.choices) &&
                        (field.source_field.table_choices.length === 0 ?
                                <div
                                    className="text-red-400">{`There is no ${field.source_field.table_choice_grouping_name}.`}</div>
                                : (field.source_field.table_choices.length === 1 && singleGFM ?
                                    <tr className="thin" key={`choice.${i}`}>
                                        <td colSpan={3}>
                                            <div className="text-sm font-semibold">{singleGFM.table_row_name}</div>
                                            <Table borderless className="max-w-xl">
                                                <tbody>
                                                {
                                                    _.map(selectedRemoteField.choices, (mapleChoice, j) =>
                                                        <tr className="thin" key={`${mapleChoice.key}_${j}`}>
                                                            <td>{mapleChoice.name}</td>
                                                            <td><i className="fa fa-arrow-right"/></td>
                                                            <td>
                                                                {
                                                                    isEditing ?
                                                                        <BaseForm.Input
                                                                            outerInputClassName={"p-0"} type="select"
                                                                            name={`sub.${currentFields[key]}.${field.source_field.table_choice_grouping_key}.${singleGFM.table_row_id}.${mapleChoice.key}`}
                                                                            options={singleGFM.choices} showSearch={true}
                                                                        />
                                                                        : <div
                                                                            className={classnames("text-gray-500 flex flex-row items-center")}>
                                                                            {renderTableRowInnerChoiceMappingValue(key, mapleChoice.key, field.source_field.table_choice_grouping_key, singleGFM.table_row_id, singleGFM.choices)}
                                                                        </div>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                </tbody>
                                            </Table>
                                        </td>
                                    </tr>
                                : <tr className="thin" key={`choice.${i}`}>
                                    <td colSpan={3}>
                                        <div className="max-w-2xl overflow-visible">
                                            {
                                                currentGroupingAndFieldMappingsByGroupKey[field.source_field.table_choice_grouping_key] && currentGroupingAndFieldMappingsByGroupKey[field.source_field.table_choice_grouping_key].length === 0 && !isEditing ?
                                                    <div
                                                        className={classnames("text-gray-500 flex flex-row items-center")}>{`No ${field.source_field.table_choice_grouping_name}(s) mapped.`}</div>
                                                    : <Table className="whitespace-nowrap">
                                                        <tbody className="divide-y divide-gray-200">
                                                        <tr>
                                                            <th className="py-2">{`${_.capitalize(field.source_field.table_choice_grouping_name)}`}</th>
                                                            {
                                                                _.map(selectedRemoteField.choices, (mapleChoice, i) =>
                                                                    <th key={i}>{mapleChoice.name}</th>
                                                                )
                                                            }
                                                        </tr>
                                                        {
                                                            _.map(_.sortBy(currentGroupingAndFieldMappingsByGroupKey[field.source_field.table_choice_grouping_key], (gfm) => gfm.table_row_name), (gfm, i) =>
                                                                <tr key={i}>
                                                                    <td className="text-nowrap py-2">
                                                                        <div className="flex flex-row gap-1 items-center">
                                                                            <div className="text-sm font-semibold">{gfm.table_row_name}</div>
                                                                            {
                                                                                isEditing && <DeleteButton
                                                                                    onDelete={() => onGroupingRowDelete(gfm.table_row_id, field.source_field.table_choice_grouping_key)}/>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    {
                                                                        _.map(selectedRemoteField.choices, (mapleChoice, i) =>
                                                                            <td key={i}>
                                                                                {
                                                                                    isEditing ?
                                                                                        <BaseForm.Input
                                                                                            outerInputClassName={"p-0"}
                                                                                            type="select"
                                                                                            name={`sub.${currentFields[key]}.${field.source_field.table_choice_grouping_key}.${gfm.table_row_id}.${mapleChoice.key}`}
                                                                                            options={gfm.choices}
                                                                                            showSearch={true}
                                                                                        />
                                                                                        : <div
                                                                                            className={classnames("text-gray-500 flex flex-row items-center")}>
                                                                                            {renderTableRowInnerChoiceMappingValue(key, mapleChoice.key, field.source_field.table_choice_grouping_key, gfm.table_row_id, gfm.choices)}
                                                                                        </div>
                                                                                }
                                                                            </td>
                                                                        )
                                                                    }
                                                                </tr>
                                                            )
                                                        }
                                                        {
                                                            isEditing && remainingTableRowChoices.length > 0 &&
                                                            <tr>
                                                                <td colSpan={selectedRemoteField.choices.length + 1}
                                                                    className="py-2">
                                                                    <div className="form-group">
                                                                        <div className={`${isMapleUser ? 'form-input-updated' : 'form-input'} max-w-sm`}>
                                                                            <Select
                                                                                menuPlacement={props.menuPlacement || "auto"}
                                                                                components={{DropdownIndicator: null}}
                                                                                isMulti={false}
                                                                                className={classnames("select-container", props.menuPlacement)}
                                                                                classNames={{
                                                                                    menu: (state) => {
                                                                                        if (!isMapleUser) return "";
                                                                                        return state.menuPlacement === 'top' ? "!bottom-dropdown-spacing-rs" : "!top-dropdown-spacing-rs"
                                                                                    },
                                                                                }}
                                                                                defaultOptions={remainingTableRowChoices}
                                                                                classNamePrefix="select2"
                                                                                getOptionLabel={(op) => op.label}
                                                                                getOptionValue={(op) => op.value}
                                                                                value={null}
                                                                                placeholder={`Add a ${field.source_field.table_choice_grouping_name}...`}
                                                                                onChange={(value) => addNewGroupingRow(value, field.source_field.table_choice_grouping_key)}
                                                                                isClearable={false}
                                                                                noOptionsMessage={(inputValue) => {
                                                                                    return `Start typing...`;
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        }
                                                        </tbody>
                                                    </Table>
                                            }
                                        </div>
                                    </td>
                                </tr>
                                )
                        )
                }
            </React.Fragment>
        )
    }

    const hasAnyEditableFields = entity && true;
    return (
        <ContentBox className="max-w-3xl">
            <ContentBox.Body>
                <Loader loading={_.isEmpty(entity)}>
                    {
                        () =>
                            <div>
                                <BaseForm ref={formRef} initialFormFields={initialFields} onFieldChange={onFieldChange} onSubmit={onSave}>
                                    <div className="flex flex-row gap-3 items-center">
                                        <h5>Field Mapping - {entity && _.upperFirst(_.lowerCase(entity))}</h5>
                                        <div className="grow"/>
                                        {
                                            hasAnyEditableFields && !props.forceEditing &&
                                            <div>
                                                {
                                                    isEditing ?
                                                        <div className="flex flex-row gap-1">
                                                            <Button variant="text-danger"
                                                                    onClick={() => setIsEditing(false)}>Cancel</Button>
                                                            <SubmitButton>Save</SubmitButton>
                                                        </div>
                                                        : <Button variant="text-primary" onClick={onEdit}><i
                                                            className="fa fa-edit"/> Edit</Button>
                                                }
                                            </div>
                                        }
                                    </div>
                                    <div className="overflow-x-scroll overflow-y-visible">
                                        <Table borderless>
                                            <thead>
                                            {
                                                isRemoteToMaple ?
                                                    <tr>
                                                        <th>{integration.name} Field</th>
                                                        <th><i className="fa fa-arrow-right"/></th>
                                                        <th>Maple Field</th>
                                                    </tr>
                                                    : <tr>
                                                        <th>Maple Field</th>
                                                        <th><i className="fa fa-arrow-right"/></th>
                                                        <th>{integration.name} Field</th>
                                                    </tr>
                                            }
                                            </thead>
                                            <tbody>
                                            {
                                                _.map(availableFieldMappings, (field, j) => renderAvailableFieldRow(field, j))
                                            }
                                            </tbody>
                                        </Table>
                                    </div>
                                </BaseForm>
                            </div>
                    }
                </Loader>
            </ContentBox.Body>
        </ContentBox>
    );
})

/*
{
                                            _.map(entity.fields, (field, i) => renderAvailableFieldRow(field, i))
                                        }
 */
export default IntegrationEntityFieldMapComponent;
