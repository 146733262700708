import '../../App.scss';
import '../../css/modals.scss';
import React from 'react';
import BaseForm from '../BaseForm';
import SubmitButton from '../common/buttons/SubmitButton';
import BaseSideModal from './BaseSideModal';
import { useState, useEffect, useContext, useMemo } from 'react';
import {
    BaseContext,
    getInvoicePaymentTermFromDueDateFromCreation,
    getInvoicePaymentTermOptions
} from '../../helpers/common';
import {serverFetch, serverPatch} from '../../helpers/server';
import { useTranslation } from 'react-i18next';
const _ = require('lodash');

function UpdateSubscriptionAutoChargeModal(props) {
    const { t } = useTranslation('common');
    const { getApiUrl } = useContext(BaseContext);
    const [subscription, setSubscription] = useState(null);
    const [settings, setSettings] = useState({});
    const [autoCharge, setAutoCharge] = useState(null);
    const [invoicePaymentTerms, setInvoicePaymentTerms] = useState(null);
    const [initialFields, setInitialFields] = useState({});

    useEffect(() => {
        serverFetch(getApiUrl("/settings")).then((res) => {
            setSettings(res);
        });
    }, []);

    useEffect(() => {
        if (props.subscription) {
            setSubscription(props.subscription)
            const ifields = {
                auto_charges: props.subscription.auto_charges
            }
            // if (props.subscription.auto_charges) {
            //     ifields["invoice_payment_terms"] = getInvoicePaymentTermFromDueDateFromCreation(settings.invoice_due_date_from_creation);
            //     ifields["invoice_due_date_from_creation"] = settings.invoice_due_date_from_creation;
            //     setInvoicePaymentTerms(getInvoicePaymentTermFromDueDateFromCreation(settings.invoice_due_date_from_creation));
            // } else {
                ifields["invoice_payment_terms"] = getInvoicePaymentTermFromDueDateFromCreation(props.subscription.invoice_due_date_from_creation);
                ifields["invoice_due_date_from_creation"] = props.subscription.invoice_due_date_from_creation;
                setInvoicePaymentTerms(getInvoicePaymentTermFromDueDateFromCreation(props.subscription.invoice_due_date_from_creation));
            // }
            setInitialFields(ifields)
            setAutoCharge(props.subscription.auto_charges);
        } else {
            setSubscription(null);
            setInitialFields({});
        }
    }, [props.subscription, settings])

    const onSubmit = async (data) => {
        const updateData = {
            auto_charges: data.auto_charges
        }
        updateData.invoice_due_date_from_creation = data.invoice_due_date_from_creation
        // if (updateData.auto_charges) {
        //     updateData.invoice_due_date_from_creation = 0
        // } else {
            if (!_.isNil(data.invoice_payment_terms)) {
                if (data.invoice_payment_terms !== "custom") {
                    updateData.invoice_due_date_from_creation = parseInt(data.invoice_payment_terms);
                }
            }
        // }
        const res = await serverPatch(getApiUrl(`/subscriptions/${subscription.id}`), updateData);
        if (res) {
            props.onClose(true);
        }
    }

    const onFieldChange = (name, value) => {
        if (name === "auto_charges") {
            setAutoCharge(value);
        } else if (name === "invoice_payment_terms") {
            setInvoicePaymentTerms(value);
        }
    }

    const paymentMechanismOptions = [
        { value: true, label: "Automatically charge a payment method on file",
            disabled: _.isNil(settings.payment_config), disabledDescription: "* Payment Provider needs to be setup" },
        { value: false, label: "Email invoice to customer manually" },
    ]

    if (!props.show) {
        return;
    }
    return (
        <BaseSideModal {...props}>
            <BaseSideModal.Form onFieldChange={onFieldChange} initialFormFields={initialFields} onSubmit={onSubmit} >
                <BaseSideModal.Header title={t('subscriptions.update_billing_mechanism')}/>
                <BaseSideModal.Body>
                    <BaseForm.Input type="select" name="auto_charges" label={t('subscriptions.payment_mechanism')} options={paymentMechanismOptions} showSearch={false}/>
                    <BaseForm.Input type="select" colSpan="12" name="invoice_payment_terms" label="Invoice Payment Terms" options={getInvoicePaymentTermOptions()} showSearch={false} />
                    {
                        invoicePaymentTerms === "custom" &&
                        <BaseForm.Input type="number" colSpan="12" name="invoice_due_date_from_creation" label="Due Date (in days)" step="1" min="0" />
                    }
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <SubmitButton>{t('common.update')}</SubmitButton>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );
}

export default UpdateSubscriptionAutoChargeModal;
