import '../../../App.scss';
import React, { useContext, useEffect } from 'react';
import {notifyEvent, serverFetch, serverPatch} from '../../../helpers/server';
import { BaseContext, IntegrationContext } from '../../../helpers/common';
import Button from '../../../components/common/buttons/Button';
import { useOutletContext } from 'react-router-dom';
import {getFeatureDescriptions, getIntegrationInterfaceForKey} from "../../../helpers/integrations";
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import Label from "../../../components/Label";
const _ = require('lodash');

function Welcome(props) {
    const { setShowNext, setShowProgress, next, navigateTo, setVisibility } = useOutletContext();
    const { getApiUrl, setPageTitle } = useContext(BaseContext);
    const { integration } = useContext(IntegrationContext);
    const integrationInterface = getIntegrationInterfaceForKey(integration.key);
    const featureDescriptions = getFeatureDescriptions(integration);

    useEffect(() => {
        setPageTitle(`Integrations - Welcome`);
    }, []);

    useEffect(() => {
        setShowProgress(false);
        setShowNext(false);
    }, []);

    const onInstall = async () => {
        if (integration.auth_type === undefined || integration.auth_type === "oauth2") {
            setVisibility("api", false);
            serverFetch(getApiUrl(`/integrations/${integration.id}/oauth_url`)).then(res => {
                if (res) {
                    window.location.href = res;
                }
            })
        } else if (integration.auth_type === "api") {
            setVisibility("oauth_callback", false);
            setVisibility("api", true);
            next();
        } else if (integration.auth_type === "none") {
            const updateData = {
                connection_data: {}
            }
            const res = await serverPatch(getApiUrl(`/integrations/${integration.id}`), updateData)
            if (res) {
                notifyEvent('integration_settings');
                setVisibility("oauth_callback", false);
                setVisibility("api", false);
                next();
            }
        }
    }

    const websiteUrl = integrationInterface.getWebsiteUrl();
    return (
        <div className="flex flex-col gap-5 mt-4 max-w-3xl mx-auto">
            <div className="flex flex-row gap-3 items-center">
                <div className="grow-0 shrink-0">
                    <img src={ integration.icon } alt="Integration Logo" className="h-20 w-20 rounded-md"/>
                </div>
                <div className="flex flex-col gap-1 grow">
                    <h2>{ integration.name }</h2>
                    <span className="text-base text-gray-700">{ integrationInterface.getDescription() }</span>
                </div>
                <div className="self-center">
                    {
                        integration.live_mode ?
                            <Label.Success>Live</Label.Success>
                            : <Label.Warning>Test</Label.Warning>
                    }
                </div>
            </div>
            <div className="flex flex-col gap-2 justify-center mt-2">
                <div className="flex flex-row items-center">
                    <p className="text-base font-semibold grow">About { integration.name }</p>
                    {
                        websiteUrl &&
                        <a target="_blank" href={websiteUrl} className="hide text-sm flex flex-row gap-1 justify-center"><span>Visit Website </span><ArrowTopRightOnSquareIcon className="h-4 w-4 cursor-pointer"/></a>
                    }
                </div>
                <div className="text-sm text-gray-700">{ integrationInterface.getWelcomePageContent() }</div>
                {
                    websiteUrl &&
                        <div>
                            <p className="text-base font-semibold">Website</p>
                            <a target="_blank" href={websiteUrl} className="text-sm">{ websiteUrl }</a>
                        </div>
                }
            </div>
            <div className="mx-auto">
            {
                integration.auth_type === "none" ?
                    <Button variant="primary" onClick={onInstall}>Install Application</Button>
                : <Button variant="primary" onClick={onInstall}>Connect Account</Button>
            }
            </div>
            <div className="">
                <p className="text-base font-semibold mb-2">Features</p>
                <div className="flex flex-col gap-3">
                {
                    _.map(featureDescriptions, (f, i) =>
                        <div key={i} className={"w-full rounded-md shadow-sm ring-1 ring-black ring-opacity-10 p-3 bg-white"}>
                            <span className="text-sm font-semibold text-gray-900">{ f.title }</span><br/>
                            <span className="text-sm text-gray-700">{ f.description }</span>
                        </div>
                    )
                }
                </div>
            </div>
        </div>
    );
}

export default Welcome;
