import '../App.scss';
import {BaseContext, IntegrationContext, UserContext} from '../helpers/common'
import React, {useContext, useEffect, useState} from 'react';
import BaseContainer from './BaseContainer';
import {Outlet, useNavigate, useOutletContext, useParams} from "react-router-dom";
import Label from "./Label";
import {
    getFeatureDescription, isActionMappingAvailable,
    isFieldMappingAvailable,
    isSyncEnabled
} from "../helpers/integrations";
import SecondaryLeftNav from "./SecondaryLeftNav";
import Breadcrumb from "./Breadcrumb";
import {notifyEvent, serverFetch, serverPatch} from "../helpers/server";
import Button from "./common/buttons/Button";
import Notification from "./Notification";
const _ = require('lodash');

function IntegrationContainer(props) {
    const { getCompanySpecificUrl, getApiUrl } = useContext(BaseContext);
    const { isMapleUser } = useContext(UserContext);
    const navigate = useNavigate();
    const { integration, getIntegrationSpecificUrl } = useContext(IntegrationContext);
    const outletContext = useOutletContext();
    const [ allRequiredData, setAllRequiredData ] = useState([]);
    let { key, id } = useParams();

    useEffect(() => {
        fetchRequiredData(true)
    }, [integration]);

    const fetchRequiredData = (skipCache = false) => {
        serverFetch(getApiUrl(`/integrations/${integration.id}/get_required_data`), { skipCache }).then((res) => {
            if (res) {
                setAllRequiredData(res);
            }
        })
    }

    let items = [];

    _.each(integration.available_features, (feature) => {
        const details = getFeatureDescription(feature);
        items.push({ 'label': details.title, 'value': feature, isHeader: true })
        if (isFieldMappingAvailable(integration, feature)) {
            items.push({
                'label': "Field Mapping",
                'link': getIntegrationSpecificUrl(`/feature/${feature.toLowerCase()}`),
                'value': feature,
            })
        }
        if (isActionMappingAvailable(integration, feature)) {
            items.push({
                'label': "Action Mapping",
                'link': getIntegrationSpecificUrl(`/feature/${feature.toLowerCase()}/actions`),
                'value': feature,
            })
        }
        if (isSyncEnabled(integration, feature)) {
            items.push({
                'label': "Matches",
                'link': getIntegrationSpecificUrl(`/${feature.toLowerCase()}/references/customer`),
                'value': feature,
            })
            items.push({
                'label': "Syncs",
                'link': getIntegrationSpecificUrl(`/${feature.toLowerCase()}/syncs`),
                'value': feature,
            })
        }
    })

    const completeOnboarding = () => {
        const updateData = {
            feature_data: {
                accounting_data: {
                    ready_for_sync: true
                }
            }
        }
        serverPatch(getApiUrl(`/integrations/${integration.id}`), updateData).then(res => {
            Notification.Success(`${integration.name} onboarding marked as complete.`)
            notifyEvent('integration_settings');
            fetchRequiredData(true);
            if (props.onUpdate) {
                props.onUpdate();
            }
        })
    }

    items.push({ label: "Settings", isHeader: true })
    items.push({ label: 'Logs', link: getIntegrationSpecificUrl("/logs"), value: 'logs' })
    items.push({ label: 'Settings', link: getIntegrationSpecificUrl("/settings"), value: 'settings' })

    const breadcrumbItems = [
        { name: 'Integrations', href: getCompanySpecificUrl(`/integrations`), current: false },
        { name: integration.name, current: true }
    ]

    const getRequiredDataRow = (row, k) => {
        if (row === "SELECT_ORG") {
            return <div key={k} className="p-3 rounded-md shadow-sm bg-white ring-1 ring-black ring-opacity-5">
                <div className="flex flex-row gap-2 items-center text-sm">
                    <div className="grow">
                        <h5 className="body2">Select an organization</h5>
                        <span className="body1">Your oauth connection allows us access to multiple organizations. Please select the organization you want to associate this account with.</span>
                    </div>
                    <div>
                        <Button variant="text-primary" onClick={() => navigate(getIntegrationSpecificUrl("/settings"))}>Select</Button>
                    </div>
                </div>
            </div>
        } else if (row === "INITIAL_MAPPING_REQUIRED" && isMapleUser) {
            const feature = integration.enabled_features[0].toLowerCase();
            return <div key={k} className="p-3 rounded-md shadow-sm bg-white ring-1 ring-black ring-opacity-5">
                <div className="flex flex-row gap-2 items-center text-sm">
                    <div className="grow">
                        <h5 className="body2">Map initial data</h5>
                        <span className="body1">Match the different objects between Maple and {integration.name} before completing the setup.</span>
                    </div>
                    <div className="flex flex-row gap-1">
                        <Button variant="text-primary" onClick={() => navigate(getIntegrationSpecificUrl(`/${feature}/references/customer`))}>Map</Button>
                        <Button variant="primary" onClick={() => completeOnboarding()}>Complete</Button>
                    </div>
                </div>
            </div>
        } else if (row === "SELECT_ACCOUNTING_BANK_ACCOUNT") {
            const feature = integration.enabled_features[0].toLowerCase();
            return <div key={k} className="p-3 rounded-md shadow-sm bg-white ring-1 ring-black ring-opacity-5">
                <div className="flex flex-row gap-2 items-center text-sm">
                    <div className="grow">
                        <h5 className="body2">Select Clearing Bank Account</h5>
                        <span className="body1">Select a clearing bank account to record all the payments against.</span>
                    </div>
                    <div className="flex flex-row gap-1">
                        <Button variant="primary" onClick={() => navigate(getIntegrationSpecificUrl("/settings#select-bank-account"))}>Select</Button>
                    </div>
                </div>
            </div>
        }
        return <div key={k}/>
    }

    return (
        <BaseContainer>
            <div className="flex flex-row">
                <Breadcrumb items={breadcrumbItems} className="grow" />
                <div className="shrink-0">
                    {
                        integration.live_mode ?
                            <Label.Success>Live</Label.Success>
                            : <Label.Warning>Test</Label.Warning>
                    }
                </div>
            </div>
            <div className="flex flex-row gap-3">
                <div className="shrink-0">
                    <div className="grow text-lg font-semibold leading-6 text-gray-900">{ integration.name }</div>
                    <SecondaryLeftNav className="mt-2" activeKey={key} items={items} />
                </div>
                <div className="grow">
                    {
                        !_.isEmpty(allRequiredData) &&
                            <div className="flex flex-col gap-2 mb-6">
                                <div className="text-lg font-semibold leading-6 text-gray-900">Pending Onboarding Steps</div>
                                { _.map(allRequiredData, (d, k) => getRequiredDataRow(d, k)) }
                            </div>
                    }
                    <Outlet context={outletContext} />
                </div>
            </div>
        </BaseContainer>
    );
}

export default IntegrationContainer;
