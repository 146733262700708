import '../../../App.scss';
import React, { useEffect, useState, useContext } from 'react';
import {serverPost, serverDelete, serverFetch} from '../../../helpers/server';
import {BaseContext, getApprovalRulePropertyMap, getDescriptionForRule, UserContext} from '../../../helpers/common';
import MoreButton from '../../../components/common/buttons/MoreButton';
import BaseContainer from '../../../components/BaseContainer';
import MapleTable from '../../../components/MapleTable';
import ArchiveButton from '../../../components/common/buttons/ArchiveButton';
import Notification from '../../../components/Notification';
import Section from '../../../components/Section';
import ContentContainer from '../../../components/ContentContainer';
import { useTranslation } from 'react-i18next';
import ExistingContractTemplates from "../../../components/ExistingContractTemplates";
import AddOrEditApprovalRuleModal from "../../../components/modals/AddOrEditApprovalRuleModal";
import Link from "../../../components/Link";
import DeleteButton from "../../../components/DeleteButton";
import Columns from "../../../components/Columns";
import Label from "../../../components/Label";
import SectionNav from "../../../components/SectionNav";
import {Outlet, useOutletContext} from "react-router-dom";
import {isAccountingEnabled} from "../../../helpers/integrations";
const _ = require('lodash');

function ContractSettingsContainer() {
    const { t } = useTranslation('common');
    const { getCompanySpecificUrl } = useContext(BaseContext);
    const outletContext = useOutletContext();

    const tabItems = [{
        'label': 'Templates',
        'href': getCompanySpecificUrl("/settings/contracts"),
        'id': 'templates',
    }, {
        'label': 'Approval Rules',
        'href': getCompanySpecificUrl("/settings/contracts/approvals"),
        'id': 'approvals',
    }, {
        'label': 'Custom Variables',
        'href': getCompanySpecificUrl("/settings/contracts/variables"),
        'id': 'variables',
    }, {
        'label': 'General',
        'href': getCompanySpecificUrl("/settings/contracts/general"),
        'id': 'general',
    }]

    return (
        <BaseContainer>
            <Section title={"Contract Settings"} variant="page">
                <SectionNav size="sm" items={tabItems} shouldAutoMatch={true} className="mt-2"/>
                <Outlet context={outletContext} />
            </Section>
        </BaseContainer>
    );
}

export default ContractSettingsContainer;
