import React, { useContext, useEffect, useState } from 'react';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import { Col, Row } from 'react-bootstrap';
import Button from '../common/buttons/Button';
import BaseForm from '../BaseForm';
import {
	BaseContext,
	formatEventString,
	getApprovalRulePropertyKeys,
	getApprovalRulePropertyValues,
} from '../../helpers/common';
import CopyableContainer from '../CopyableContainer';
import { HashtagIcon } from '@heroicons/react/24/solid';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import { serverPost } from '../../helpers/server';
import Notification from '../Notification';
const _ = require('lodash');

function CreateOrUpdateWorkflowRuleModal(props) {
	const { company, getApiUrl } = useContext(BaseContext);
	const [itemToEdit, setItemToEdit] = useState(null);
	const [areas, setAreas] = useState([]);
	const [triggers, setTriggers] = useState([]);
	const [rulePropertyValues, setRulePropertyValues] = useState([]);
	const [rulePropertyKeys, setRulePropertyKeys] = useState([]);
	const [slackChannels, setSlackChannels] = useState([]);
	const [currentMessage, setCurrentMessage] = useState(null);
	const [currentTrigger, setCurrentTrigger] = useState(null);
	const [currentChannel, setCurrentChannel] = useState(null);
	const [isTestButtonClicked, setIsTestButtonClicked] = useState(false);

	const messageVariableMap = {
		ContractTitle: 'Sample Contract',
		InvoiceNumber: 'INV-1',
		InvoiceAmount: '$1000',
		CustomerName: 'Sample Customer',
		PaymentAmount: '$1000',
		ApproverNames: 'Josh',
	};

	useEffect(() => {
		if (!_.isNil(props.currentRule)) {
			const channel = props.channels.find((channel) => channel.name === props.selectedChannel);
			const fields = {
				id: props.currentRule.id,
				name: props.currentRule.name,
				area: _.split(_.split(props.currentRule.trigger_event, '.')[0], '_')[0],
				trigger: props.currentRule.trigger_event,
				rule: props.currentRule.rule,
				channel: channel,
			};
			setCurrentChannel(channel);
			setCurrentMessage(props.messageMap[props.currentRule.trigger_event]);
			setItemToEdit(fields);
		} else {
			setItemToEdit(null);
			setCurrentMessage(null);
			setCurrentChannel(null);
			setCurrentTrigger(null);
		}
	}, [props.currentRule, props.selectedChannel]);

	useEffect(() => {
		const options = _.map(props.ruleAreas, (a) => {
			return {
				value: a,
				label: _.startCase(a),
			};
		});

		setAreas(options);
	}, [props.ruleAreas]);

	useEffect(() => {
		console.log(props.channels);
		setSlackChannels(props.channels);
	}, [props.channels]);

	const onSlackChannelSearch = (query) => {
		return _.filter(slackChannels, (item) => {
			if (!_.isNil(query) && !_.isEmpty(query)) {
				if (item['name'].toLowerCase().includes(query.toLowerCase())) {
					return true;
				}
			} else {
				return true;
			}
		});
	};

	const getSlackChannelValue = (channel) => {
		return channel.name;
	};

	const getSlackChannelLabel = (channel) => {
		return (
			<div className='flex flex-row gap-2 items-center'>
				<div className='shrink-0'>
					{channel.is_private && <LockClosedIcon className={'w-3 h-3'}></LockClosedIcon>}
					{!channel.is_private && <HashtagIcon className={'w-3 h-3'}></HashtagIcon>}
				</div>
				<div className='flex flex-col grow'>{channel.name}</div>
			</div>
		);
	};

	const onFieldChange = (name, value) => {
		if (name === 'area') {
			setCurrentTrigger(null);
			const triggers = props.ruleMap[value]['events'];
			const options = _.map(triggers, (t) => {
				return {
					value: t,
					label: formatEventString(t),
				};
			});
			setTriggers(options);

			const rules = props.ruleMap[value]['rules_options'];
			const ruleValueOptions = _.map(rules, (r) => {
				return {
					value: r.key,
					label: r.label,
				};
			});
			const ruleKeyOptions = _.map(rules, (r) => {
				return {
					Key: r.key,
					Type: r.type,
				};
			});
			setRulePropertyKeys(ruleKeyOptions);
			setRulePropertyValues(ruleValueOptions);
		} else if (name === 'trigger') {
			setCurrentTrigger(value);
		} else if (name === 'channel') {
			setCurrentChannel(value);
		}
	};

	useEffect(() => {
		setCurrentMessage(props.messageMap[currentTrigger]);
	}, [currentTrigger]);

	const processFields = (fields) => {
		const processedFields = {
			name: fields.name,
			trigger_event: fields.trigger,
			rule: fields.rule,
			workflow_actions: [
				{
					action_type: 'SEND_MESSAGE',
					integration_id: props.integrationID,
					action_params: {
						slack: {
							channel: fields.channel.name,
							text: currentMessage,
						},
					},
				},
			],
		};
		return processedFields;
	};

	const getExampleMessage = (template) => {
		const message = template.replace(/{{\.(\w+)}}/g, (match, key) => messageVariableMap[key] || match);
		switch (currentTrigger) {
			case 'contract.signed':
			case 'contract.executed':
				return (
					<span>
						"{message} <span className={'cursor-pointer text-blue-600'}>View Contract</span>"
					</span>
				);
			case 'invoice.paid':
				return (
					<span>
						"{message} <span className={'cursor-pointer text-blue-600'}>View Invoice</span>"
					</span>
				);
			case 'subscription.created':
			case 'subscription.unpaid':
			case 'subscription.default_usage_threshold_exceeded':
			case 'subscription.cancelled':
				return (
					<span>
						"{message} <span className={'cursor-pointer text-blue-600'}>View Subscription</span>"
					</span>
				);
			case 'payment.failed':
				return (
					<span>
						"{message} <span className={'cursor-pointer text-blue-600'}>View Payment</span>"
					</span>
				);
			case 'invoice_payment_retry_schedule.completed':
				return (
					<span>
						"{message} <span className={'cursor-pointer text-blue-600'}>View Campaign</span>"
					</span>
				);
			case 'contract.needs_approval':
				return (
					<span>
						"{message} <span className={'cursor-pointer text-blue-600'}>Approve Contract</span>"
					</span>
				);
			default:
				return message;
		}
	};

	const testAction = () => {
		setIsTestButtonClicked(true);
		const message = currentMessage.replace(/{{\.(\w+)}}/g, (match, key) => messageVariableMap[key] || match);
		const data = {
			workflow_integration_id: props.integrationID,
			workflow_action_type: 'SEND_MESSAGE',
			workflow_event_type: currentTrigger,
			company_slug: company.slug,
			params: {
				slack: {
					channel: currentChannel.name,
					text: message,
				},
			},
		};
		serverPost(getApiUrl('/workflows/test_action'), data).then((res) => {
			if (res) {
				Notification.Success('Successfully performed test action');
			} else {
				Notification.Danger('Error testing workflow rule. Please try again later.');
			}
		});
		setTimeout(() => setIsTestButtonClicked(false), 1000);
	};

	return (
		<BaseAddOrEditItemModal
			{...props}
			size={'lg'}
			itemLabel={'Rule'}
			itemBaseUrl={'/workflows'}
			onFieldChange={onFieldChange}
			itemToEdit={itemToEdit}
			processAddFields={processFields}
			processUpdateFields={processFields}
		>
			<Row>
				<BaseForm.Input colSpan='12' name='name' label='Rule Name' type='text' required />
				<BaseForm.Input
					colSpan='12'
					name='area'
					label='Domain'
					type='select'
					required
					options={areas}
					showSearch={false}
				/>
				<BaseForm.Input
					colSpan='12'
					name='trigger'
					label='Trigger'
					type='select'
					required
					options={triggers}
					showSearch={false}
				/>
				<BaseForm.Input
					colSpan='12'
					name='rule'
					label='Rule'
					type='rule'
					isColored={true}
					propertyValues={rulePropertyValues}
					propertyFields={rulePropertyKeys}
				/>
				<BaseForm.Input
					colSpan='12'
					name='channel'
					label='Slack Channel'
					type='selector'
					required
					defaultOptions={slackChannels}
					loadOptions={onSlackChannelSearch}
					getOptionLabel={getSlackChannelLabel}
					getOptionValue={getSlackChannelValue}
				/>
				<span className={'text-xs'}>
					*To receive notifications in private channels, please invite the Maple app to the desired private
					channel first
				</span>
				<br />
				{currentMessage && (
					<>
						<div className={'text-sm font-semibold text-gray-700'}>Message Template</div>
						<br />
						<CopyableContainer copyableValue={currentMessage} />
						<div className={'text-xs'}>Sample Message: {getExampleMessage(currentMessage)}</div>
					</>
				)}
				<br />
				<Col colSpan='12'>
					<Button
						variant={'primary'}
						onClick={() => testAction()}
						disabled={!currentChannel || !currentMessage || !currentTrigger || isTestButtonClicked}
					>
						Test Rule
					</Button>
				</Col>
			</Row>
		</BaseAddOrEditItemModal>
	);
}

export default CreateOrUpdateWorkflowRuleModal;
