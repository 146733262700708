import BaseModal from "./BaseModal";
import BaseSideModal from "./BaseSideModal";
import React, {useContext, useState} from "react";
import BaseForm from "../BaseForm";
import {serverPost} from "../../helpers/server";
import Notification from "../Notification";
import {BaseContext} from "../../helpers/common";
import {Col, Row} from "react-bootstrap";
import SubmitButton from "../common/buttons/SubmitButton";
import {useTranslation} from "react-i18next";
import moment from "moment";

function ManualExecutionModal(props) {
    const {getApiUrl} = useContext(BaseContext);
    const { t } = useTranslation('common');
    const [startDateType, setStartDateType] = useState("IMMEDIATELY")

    const onFieldChange = (field, value) => {
        if (field === "start_type") {
            setStartDateType(value);
        }
    }

    const typeOptions = [
        { value: "IMMEDIATELY", label: "Immediately" },
        { value: "SPECIFIC_DATE", label: "Specific Date" },
    ]

    const manuallyExecute = (data) => {
        const postData = {}
        if (data.start_type === "SPECIFIC_DATE") {
            postData.start_date = moment(data.start_date).format();
        }
        serverPost(getApiUrl(`/proposals/${props.proposal_id}/manual_execute`), postData).then(res => {
            if (res) {
                Notification.Success("Successfully executed contract")
                props.onExecute(true)
                props.onClose();
            } else {
                Notification.Danger("Could not execute contract")
            }
        })
    }

    return (
        <BaseSideModal {...props}>
            <BaseSideModal.Form onFieldChange={onFieldChange} onSubmit={manuallyExecute}>
                <BaseSideModal.Header title={"Manually execute this contract?"}/>
                <BaseSideModal.Body>
                    <div>
                        <span className="text-sm">Are you sure you want to manually execute this contract?</span><br/><br/>
                        <span className="text-sm">When do you want the associated subscription to be started?</span><br/><br/>
                    </div>
                    <Row>
                    <BaseForm.Input colSpan="12" name="start_type" label={t('subscriptions.start_date')} type="select" options={typeOptions} showSearch={false} />
                    </Row>
                    {
                        startDateType === "SPECIFIC_DATE" &&
                        <>
                            <BaseForm.Input
                                colSpan="12" name="start_date" label={'Specific Date'} type="date" includeTime={true} required />
                            <span className="caption">Date/Time is based on your local timezone</span>
                        </>
                    }
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <Row>
                        <Col md="12" className="text-end">
                            <SubmitButton variant="primary">Confirm</SubmitButton>
                        </Col>
                    </Row>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    )
}

export default ManualExecutionModal;