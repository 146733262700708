import '../../App.scss';
import '../../css/modals.scss';
import React, {createRef, forwardRef, useImperativeHandle, useState} from 'react';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../common/buttons/SubmitButton';
import {currencyFormatFromPrice} from '../../helpers/common';
import {useTranslation} from 'react-i18next';
import AuthorizeNetPaymentInput from "../AuthorizeNetPaymentInput";

const _ = require('lodash');

const AuthorizeNetInvoicePaymentModal = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        setErrorFields(errors) {
            setErrorFields(errors);
        },
    }));

    const { t } = useTranslation('common');
    const [errorFields, setErrorFields] = useState(null);
    const setupRef = createRef();

    const onSubmit = async (data) => {
        if (setupRef.current) {
            const { paymentMethodData, error } = await setupRef.current.getPaymentMethodData(data);
            if (error) {
                setErrorFields(error);
                return;
            }
            if (props.onPay) {
                await props.onPay(paymentMethodData);
            }
        }
    }

    return (
        <BaseModal {...props}>
            <BaseForm onSubmit={onSubmit} errorFields={errorFields}>
            <BaseModal.Header>
                <BaseModal.Title>{t('invoice.payment.title')}</BaseModal.Title>
            </BaseModal.Header>
            <BaseModal.Body>
                <div className="body1">
                    Processing payment for invoice ({ props.invoice.number }) for the amount { currencyFormatFromPrice(props.invoice.due) }
                </div>
                <AuthorizeNetPaymentInput ref={setupRef} {...props} />
            </BaseModal.Body>
            <BaseModal.Footer>
                <SubmitButton variant="primary">Process Payment</SubmitButton>
            </BaseModal.Footer>
            </BaseForm>
        </BaseModal>
    );

})

export default AuthorizeNetInvoicePaymentModal;
