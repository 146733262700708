import '../../App.scss';
import React, {useEffect, useState, useContext, useMemo} from 'react';
import {BaseContext, currencyFormatFromPrice, downloadBlob, getCurrencyOptions} from '../../helpers/common';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import Link from '../../components/Link';
import ContentBox from "../../components/ContentBox";
import MapleTable from "../../components/MapleTable";
import Columns from "../../components/Columns";
import moment from "moment";
import {serverPost} from "../../helpers/server";
import BaseForm from "../../components/BaseForm";
import { Row } from 'react-bootstrap';
import SubmitButton from "../../components/common/buttons/SubmitButton";
import {ChevronDownIcon, ChevronRightIcon} from "@heroicons/react/20/solid";
import Breadcrumb from "../../components/Breadcrumb";
const _ = require('lodash');

function DeferredRevenue() {
    const { company, getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const [loading, setLoading] = useState(true);
    const [lineItems, setLineItems] = useState([]);
    const [endTimes, setEndTimes] = useState([]);
    const [reportInput, setReportInput] = useState({});
    const initialFields = useMemo(() => {
        return {
            reference_date: moment(),
            currency: company.preferred_currency
        }
    }, []);
    const [summary, setSummary] = useState({
        month_totals: [],
        recognized_total: {},
        balance_total: {},
    });

    useEffect(() => {
        setPageTitle(`Financial Reports`);
    }, []);

    useEffect(() => {
        runReport(initialFields);
    }, [])

    const exportResults = () => {
        serverPost(getApiUrl("/reports/invoice_deferred_revenue_report_export"), reportInput, { noJson: true }).then(res => {
            downloadBlob(res, `Deferred-Revenue-Report-${moment(reportInput.reference_date).format("YYYY-MM-DD")}-${reportInput.currency}.csv`);
        })
    }

    const runReport = (data) => {
        setLoading(true);
        const reportData = {
            reference_date: moment(data.reference_date).endOf("day").utcOffset(0, true),
            currency: data.currency
        }
        setReportInput(reportData);
        serverPost(getApiUrl("/reports/invoice_deferred_revenue_report"), reportData).then(res => {
            setLoading(false);
            if (res) {
                setLineItems(res.invoice_line_items);
                setEndTimes(Object.keys(res.summary.month_totals))
                setSummary(res.summary);
            }
        })
    };

    const breadcrumbItems = [
        { name: 'Financial Reports', href: getCompanySpecificUrl(`/reports`), current: false },
        { name: "Deferred Revenue Report", current: true }
    ]

    return (
        <BaseContainer>
            <ContentContainer>
                <Breadcrumb items={breadcrumbItems}/>
                <Section variant="page" title="Deferred Revenue Report">
                    <ContentBox>
                        <ContentBox.Body>
                            <BaseForm initialFormFields={initialFields} onSubmit={runReport}>
                                <Row>
                                    <BaseForm.Input colSpan="4" type="date" name="reference_date" label="Report as of" />
                                    <BaseForm.Input colSpan="4" type="select" name="currency" label="Currency" options={getCurrencyOptions()} showSearch={false} />
                                    <SubmitButton>Run</SubmitButton>
                                </Row>
                            </BaseForm>
                        </ContentBox.Body>
                    </ContentBox>
                </Section>
                <Section loading={loading} className="mt-4">
                    <MapleTable>
                        <div className="flex flex-row items-center">
                            <div className="grow">
                                <span className="text-base font-semibold text-gray-700">Deferred Revenue Report</span><br/>
                                <span className="text-gray-500">{ reportInput.currency } deferred revenue report as of { moment(reportInput.reference_date).format("D MMM, YY") }</span>
                            </div>
                            <div className="inline-block cursor-pointer px-2.5 py-1.5 rounded-md bg-white border-1 border-slate-300" onClick={exportResults}>
                                <span className="text-slate-900">Export</span>
                            </div>
                        </div>
                        <MapleTable.Content>
                            <thead>
                                <tr>
                                    <MapleTable.TH>Invoice</MapleTable.TH>
                                    <MapleTable.TH>Period</MapleTable.TH>
                                    <MapleTable.TH>Paid Date</MapleTable.TH>
                                    <MapleTable.TH className="text-end">Amount</MapleTable.TH>
                                    <MapleTable.TH className="text-end bg-gray-50">Recognized</MapleTable.TH>
                                    <MapleTable.TH className="text-end bg-gray-50">Balance</MapleTable.TH>
                                    {
                                        _.map(endTimes, (et, b) =>
                                            <MapleTable.TH key={b} className="text-end w-px whitespace-nowrap">{ moment(et).format("MMM, YY") }</MapleTable.TH>
                                        )
                                    }
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                <tr>
                                    <td className="font-semibold"></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className="text-end bg-gray-50">{ currencyFormatFromPrice(summary.recognized_total) }</td>
                                    <td className="text-end bg-gray-50">{ currencyFormatFromPrice(summary.balance_total) }</td>
                                    {
                                        _.map(endTimes, (et, b) =>
                                            <td key={b} className="text-end">
                                                <span className="font-semibold">{ currencyFormatFromPrice(summary.month_totals[et].amount) }</span><br/>
                                                <span className="hide">{ summary.month_totals[et].total_days }</span>
                                            </td>
                                        )
                                    }
                                </tr>
                            {
                                _.map(lineItems, (row, i) =>
                                    <React.Fragment key={i}>
                                        <tr>
                                            <td >
                                                <Link target="_blank" href={getCompanySpecificUrl(`/invoice/${row.invoice.uuid}`)}>
                                                    <span className="w-px whitespace-nowrap">{ row.invoice.number }</span>
                                                </Link>
                                            </td>
                                            <td>
                                                <span className="w-px whitespace-nowrap">{ moment(row.invoice_line_item.period.start_date).format("D MMM, YY") } - </span>
                                                <span className="w-px whitespace-nowrap">{ moment(row.invoice_line_item.period.end_date).format("D MMM, YY") }</span>
                                            </td>
                                            <td><span className="w-px whitespace-nowrap">{ moment(row.invoice.paid_date).format("D MMM, YY") }</span></td>
                                            <td className="text-end">
                                                <span>{ currencyFormatFromPrice(row.amount_in_target_currency) }</span><br/>
                                                {
                                                    row.amount_in_target_currency.currency !== row.invoice_line_item.amount.currency &&
                                                        <span className="text-xs italic">{ currencyFormatFromPrice(row.invoice_line_item.amount) }</span>
                                                }
                                            </td>
                                            <td className="text-end bg-gray-50"><span className="w-px whitespace-nowrap">{ currencyFormatFromPrice(row.recognized_amount) }</span></td>
                                            <td className="text-end bg-gray-50"><span className="w-px whitespace-nowrap">{ currencyFormatFromPrice(row.balance_amount) }</span></td>
                                            {
                                                _.map(endTimes, (et, m) =>
                                                    <td key={m} className="text-end">
                                                        {
                                                            row.month_totals[et] ?
                                                                <span>{ currencyFormatFromPrice(row.month_totals[et].amount) }<br/></span>
                                                            : <span className="text-gray-300">-<br/></span>
                                                        }
                                                        {
                                                            row.month_totals[et] ?
                                                                <span className="hide">{ row.month_totals[et] ? row.month_totals[et].days: "-" }</span>
                                                            : <span className="hide text-gray-300">-</span>
                                                        }
                                                    </td>
                                                )
                                            }
                                        </tr>
                                    </React.Fragment>
                                )
                            }
                            </tbody>
                        </MapleTable.Content>
                    </MapleTable>
                </Section>
            </ContentContainer>
        </BaseContainer>
    );
}

export default DeferredRevenue;
