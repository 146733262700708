import '../../App.scss';
import React, {useEffect, useState, useContext, createRef, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {serverFetch, serverPost, serverPatch} from '../../helpers/server';
import {ClockIcon, CheckCircleIcon} from "@heroicons/react/24/solid";
import {
    BaseContext,
    renderContractStatusLabel,
    downloadBlob,
    convertVariablesIntoHtml,
    getCustomerNameOrEmail,
    getInvoicePaymentTermOptions,
    getInvoicePaymentTermFromDueDateFromCreation,
    currencyFormatFromPrice,
    findCustomVariables,
    renderCustomVariableInput,
    getInvoicePaymentTermDescription,
    UserContext,
    renderCampaignStatusLabel,
    getConfigActionTypeKey,
    getStatusForStep,
    findNumberOfCounterSignatories, sleep, findNumberOfSignatories, validateSignatories, getPlanLengthOptions
} from '../../helpers/common';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import SubmitButton from '../../components/common/buttons/SubmitButton';
import Section from '../../components/Section';
import SectionNav from '../../components/SectionNav';
import BaseForm from '../../components/BaseForm';
import Loader from '../../components/Loader';
import ProductPricingSelection from '../../components/ProductPricingSelection';
import BundlePricingTable from '../../components/BundlePricingTable';
import ConfirmationButton from '../../components/common/buttons/ConfirmationButton';
import KeyValueDisplay from '../../components/KeyValueDisplay2';
import SingleContractEmailModal from '../../components/modals/SingleContractEmailModal';
import ShareContractLinkModal from '../../components/modals/ShareContractLinkModal';
import ContentBox from '../../components/ContentBox';
import Link from '../../components/Link';
import {Row, Col, Table, Alert} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import Notification from "../../components/Notification";
import {ShareIcon, WrenchScrewdriverIcon} from "@heroicons/react/20/solid";
import DropdownMenu from "../../components/DropdownMenu";
import SimpleModal from "../../components/modals/SimpleModal";
import Columns from "../../components/Columns";
import ApprovalRequestListSection from "../../components/ApprovalRequestListSection";
import ErrorComponent from "../../components/ErrorComponent";
import IntegrationReferenceEntitySummaryModal from "../../components/modals/IntegrationReferenceEntitySummary";
import MapleTable from "../../components/MapleTable";
import DeleteButton from "../../components/DeleteButton";
import ManualExecutionModal from "../../components/modals/ManualExecutionModal";
import SignOfflineModal from "../../components/modals/SignOfflineModal";
import Button from "../../components/common/buttons/Button";
import BaseOverlayTrigger from "../../components/BaseOverlayTrigger";
import useGetMembers from "../../helpers/hooks/api/useGetMembers";
import {getAllProductPricingIdsInContract, getProductPricingIdsInPhase} from "../../helpers/subscriptions";

const _ = require('lodash');

function Contract() {
    const navigate = useNavigate();
    const {t} = useTranslation('common');
    const {uuid} = useParams();

    const {isSuperUser, isMapleUser, userInfo} = useContext(UserContext);
    const {getApiUrl, setPageTitle, getCompanySpecificUrl, hasAccess} = useContext(BaseContext);
    const [loadingContract, setLoadingContract] = useState(true);
    const [contractDetails, setContractDetails] = useState({});
    const [activeNav, setActiveNav] = useState("details");
    const [teamMembers, setTeamMembers] = useState([]);
    const [teamMemberOptions, setTeamMemberOptions] = useState([]);
    const [counterSignatoryOptions, setCounterSignatoryOptions] = useState([]);
    const [settings, setSettings] = useState({});
    const [showPONumber, setShowPONumber] = useState(false);
    const [autoCharges, setAutoCharges] = useState(true);
    const [productPricings, setProductPricings] = useState([]);
    const [preselectedProductPricingIds, setPreselectedProductPricingIds] = useState([]);
    const [initialContractData, setInitialContractData] = useState({});
    const [initialFields, setInitialFields] = useState({});
    const [templateFields, setTemplateFields] = useState({});
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [showLinkModal, setShowLinkModal] = useState(false);
    const [showRevertConfirmationModal, setShowRevertConfirmationModal] = useState(false);
    const [showReferenceSummaryModal, setShowReferenceSummaryModal] = useState(false);
    const [startDateType, setStartDateType] = useState("ON_COMPLETION");
    const [details, setDetails] = useState([]);
    const [customVariableDetails, setCustomVariableDetails] = useState({});
    const [error, setError] = useState(null);
    const [sort, setSort] = useState("createdAtDesc");
    const [isEditingTemplate, setIsEditingTemplate] = useState(false);
    const [approvalRequests, setApprovalRequests] = useState([]);
    const [invoicePaymentTerms, setInvoicePaymentTerms] = useState("IMMEDIATE");
    const [customVariables, setCustomVariables] = useState([]);
    const [availableCustomVariables, setAvailableCustomVariables] = useState([]);
    const [isTrial, setIsTrial] = useState(false);
    const pricingsRef = createRef();
    const [canTriggerCampaign, setCanTriggerCampaign] = useState(false);
    const [mostRecentCampaign, setMostRecentCampaign] = useState(null);
    const [showManualExecuteModal, setShowManualExecuteModal] = useState(false);
    const [showSignOfflineModal, setShowSignOfflineModal] = useState(false);
    const [showUploadSignedContractModal, setShowUploadSignedContractModal] = useState(false);
    const [numberOfCounterSignatories, setNumberOfCounterSignatories] = useState(0);
    const [numberOfSignatories, setNumberOfSignatories] = useState(0);
    const [hasNonOneTimeItems, setHasNonOneTimeItems] = useState(false);
    const [hasAnyoneSigned, setHasAnyoneSigned] = useState(false);
    const [hasEveryoneSigned, setHasEveryoneSigned] = useState(false);
    const [signatoryError, setSignatoryError] = useState(null);
    const updateSuccessRef = useRef(false);
    const formRef = useRef(null);
    const isFinalizingRef = useRef(false);
    const { members, fetchMembers } = useGetMembers(false);

    const hasContractsWritePermission = hasAccess("proposal", userInfo, "write");

    useEffect(() => {
        setPageTitle(`Contract - ${contractDetails.title}`);
    }, [contractDetails]);

    useEffect(() => {
        setMostRecentCampaign(getMostRecentCampaign())
    }, [contractDetails]);

    useEffect(() => {
        const isPending = contractDetails.status === 'PENDING_SIGNATURES' || contractDetails.status === 'PENDING_PAYMENT';
        setCanTriggerCampaign(isPending && getCanTriggerCampaign())
    }, [contractDetails, settings]);

    useEffect(() => {
        if (!_.isNil(uuid)) {
            fetchData();
        }
    }, [uuid])

    useEffect(() => {
        const teamOptions = _.map(members, (member) => {
            return {
                value: member.id,
                label: member.user.name || member.user.email
            }
        })
        teamOptions.sort((a, b) => {
            if (a.label < b.label) {
                return -1
            } else {
                return 1
            }
        })
        const counterSignOptions = teamOptions.map((option) => option)

        teamOptions.unshift({value: null, label: "No one"})
        counterSignOptions.unshift({value: null, label: "Skip counter sign"})

        setTeamMembers(members)
        setTeamMemberOptions(teamOptions)
        setCounterSignatoryOptions(counterSignOptions)
    }, [members]);

    useEffect(() => {
        serverFetch(getApiUrl("/settings")).then((res) => {
            setSettings(res);
        });

        serverFetch(getApiUrl(`/proposals/templates/fields`)).then((res) => {
            if (res) {
                setTemplateFields(_.keyBy(res, 'key'))
            }
        })

        fetchMembers()

        serverPost(getApiUrl("/proposals/variables/find"), {}).then((res) => {
            setAvailableCustomVariables(res);
        });
    }, []);

    useEffect(() => {
        if (!_.isEmpty(contractDetails)) {
            const requestData = {
                query: {
                    proposal_id: contractDetails.id
                }
            }
            serverPost(getApiUrl(`/approval_requests/find`), requestData).then((res) => {
                if (res) {
                    setApprovalRequests(res);
                }
            });
        } else {
            setApprovalRequests([]);
        }
    }, [contractDetails]);

    const updateConfigWithProductPricingInfo = (phase, pricings) => {
        if (phase.onetime_items) {
            _.each(phase.onetime_items, item => {
                const pp = _.find(pricings, r => r.id === item.product_pricing_id);
                if (pp && pp.product_metric_pricings.length > 0) {
                    phase.config[pp.product_metric_pricings[0].id] = {
                        num_licenses: item.aggregate
                    }
                }
            })
        }
    }

    useEffect(() => {
        if (preselectedProductPricingIds.length === 0) {
            setProductPricings([]);
        } else {
            serverPost(getApiUrl(`/product_pricings/batch`), {ids: preselectedProductPricingIds}).then((res) => {
                if (res) {
                    setProductPricings(res);
                    setInitialFields(prevFields => {
                        const newFields = {...prevFields};
                        _.each(newFields.phases, phase => {
                            updateConfigWithProductPricingInfo(phase, res);
                        })
                        return newFields;
                    });
                    setInitialContractData(prevData => {
                        const newData = {...prevData};
                        _.each(newData.phases, phase => {
                            updateConfigWithProductPricingInfo(phase, res);
                        })
                        return newData;
                    });
                }
            });
        }
    }, [preselectedProductPricingIds]);

    useEffect(() => {
        let newDetails = {};
        _.each(customVariables, cv => {
            const variable = _.find(availableCustomVariables, acv => acv.key === cv);
            if (variable) {
                newDetails[`${variable.name} (${variable.key})`] = contractDetails.custom_variables[variable.key];
            }
        })
        setCustomVariableDetails(newDetails)
    }, [customVariables]);

    const getCanTriggerCampaign = () => {
        const areEnabled = settings.invoice_email_config && settings.invoice_email_config.send_customer_emails &&
            settings.proposal_sign_reminder_enabled
        const noActiveCampaign = contractDetails.campaigns &&
            (contractDetails.campaigns.length === 0 ||
                !contractDetails.campaigns.some(c => c.status === "ACTIVE"));
        const hasFinalizedDate = contractDetails.finalized_date

        return areEnabled && noActiveCampaign && hasFinalizedDate;
    }

    const fetchData = (skipCache = false) => {
        serverFetch(getApiUrl(`/proposals/${uuid}`), {skipCache: skipCache}).then((res) => {
            if (res) {
                res.isExpired = res.status === "EXPIRED"
                setContractDetails(res);
                const dateType = res.start_type;
                const initialConfig = _.keyBy(res.config_items, 'product_metric_pricing_id');
                _.each(res.one_time_billables, (otb) => {
                    _.each(otb.product_pricing.product_metric_pricings, (pmp) => {
                        initialConfig[pmp.id] = {
                            num_licenses: otb.aggregate
                        };
                    })
                });
                let phases = [{
                    product_pricing_ids: getProductPricingIdsInPhase(res),
                    config: initialConfig,
                    discounts: _.mapValues(_.keyBy(res.discounts, "item_pricing_id"), (v) => {
                        return {
                            percentage: v.percent * 100,
                            amount: v.amount.value_in_cents / 100,
                            type: v.type,
                            expiration_date: v.expiration_date,
                        };
                    }),
                    minimum_spend: res.minimum_spend && res.minimum_spend.value_in_cents/100,
                }]
                if (res.scheduled_changes) {
                    _.each(res.scheduled_changes, sc => {
                        let phase = {
                            onetime_items: sc.onetime_items,
                            product_pricing_ids: getProductPricingIdsInPhase(sc),
                            config: _.keyBy(sc.config_items, 'product_metric_pricing_id'),
                            discounts: _.mapValues(_.keyBy(sc.discounts, "item_pricing_id"), (v) => {
                                return {
                                    percentage: v.percent * 100,
                                    amount: v.amount.value_in_cents / 100,
                                    type: v.type,
                                    expiration_date: v.expiration_date,
                                };
                            }),
                            minimum_spend: sc.minimum_spend && sc.minimum_spend.value_in_cents/100,
                            timing: sc.timing,
                            relative_term: sc.relative_term,
                            proration_type: sc.proration_type,
                        }
                        updateConfigWithProductPricingInfo(phase, productPricings);
                        phases.push(phase)
                    })
                }
                const ifields = {
                    owner: res.owner && res.owner.id,
                    expiration_date: res.expiration_date,
                    start_date: res.start_date,
                    customer: res.customer,
                    term: res.term,
                    title: res.title,
                    signatories: res.signatories.length > 1 ? _.keyBy(res.signatories, 'position') : {0 : res.signatories[0]},
                    counter_signatories: res.counter_signatories && res.counter_signatories.map((c) => c.company_user.id),
                    start_date_type: dateType,
                    require_payment_method: res.require_payment_method,
                    auto_charges: res.auto_charges,
                    phases: phases,
                    invoice_payment_terms: getInvoicePaymentTermFromDueDateFromCreation(res.invoice_due_date_from_creation),
                    invoice_due_date_from_creation: res.invoice_due_date_from_creation,
                    po_number: res.po_number,
                    custom_variables: res.custom_variables,
                    trial: res.trial,
                    num_trial_days: res.trial ? res.trial_term.count : null,
                    change_timing: res.change_timing,
                }
                setInitialFields(ifields);
                setInitialContractData(ifields);
                setHasNonOneTimeItems(res.term.frequency !== "ONETIME");
                setIsTrial(res.trial);
                setInvoicePaymentTerms(getInvoicePaymentTermFromDueDateFromCreation(res.invoice_due_date_from_creation))
                setPreselectedProductPricingIds(getAllProductPricingIdsInContract(res));
                setStartDateType(dateType);
                if (res.po_number) {
                    setShowPONumber(true);
                }
                setAutoCharges(res.auto_charges);
                setCustomVariables(findCustomVariables(res.content_template));
                setNumberOfCounterSignatories(findNumberOfCounterSignatories(res.content_template))
                setNumberOfSignatories(findNumberOfSignatories(res.content_template))
                setHasAnyoneSigned(checkIfAnySignatoryHasSigned(res.signatories, res.counter_signatories))

                const everyoneSigned = checkIfEveryoneHasSigned(res.signatories, res.counter_signatories)
                setHasEveryoneSigned(everyoneSigned)
                const data = {
                    'Customer': <Link
                        href={getCompanySpecificUrl(`/customer/${res.customer.id}`)}>{getCustomerNameOrEmail(res.customer)}</Link>,
                    'ID': res.id,
                }
                if (res.po_number) {
                    data['PO Number'] = res.po_number
                }
                data['Created At'] = moment(res.created_at).format("DD MMM, YYYY h:mma")
                data['Expiration Date'] = res.expiration_date ? moment(res.expiration_date).format("DD MMM, YYYY h:mma") : null
                data['Start Date'] = getStartDate(res)
                const paymentTermDescription = getInvoicePaymentTermDescription(res.invoice_due_date_from_creation);
                data['Payment Terms'] = <div className="d-flex flex-row gap-2 align-items-center">
                    <span key={0}>
                        {res.auto_charges ? "Automatic" : "Manual"}
                    </span>
                    {
                        !_.isNil(paymentTermDescription) &&
                        <span key={1} className="text-gray-400">({paymentTermDescription})</span>
                    }
                    <span key={2} className="grow"/>
                </div>;
                data['Require Payment Method'] =
                    <span className="text-gray-400">{res.require_payment_method ? "True" : "False"}</span>
                data['Owner'] = res.owner ?
                    <div>{res.owner.user.name} <span className="text-gray-500">({res.owner.user.email})</span>
                    </div> : null
                data['Signatory'] = getCustomerSignatoriesSummary(res.signatories, res.signed_offline)
                if (res.counter_signatories && res.counter_signatories.length > 0) {
                    data['Counter Signatory'] = getCounterSignatoriesSummary(res.counter_signatories, res.signed_offline);
                }
                if (res.minimum_spend && res.minimum_spend.value_in_cents > 0) {
                    data['Minimum Spend'] = currencyFormatFromPrice(res.minimum_spend);
                }
                if (res.signed_offline) {
                    data['Signed Offline'] = <div>Yes</div>
                    if (res.signed_file_id && res.signed_file_id.length > 0) {
                        data['Signed Contract'] =
                            <Link onClick={() => downloadUploadedContract(res.signed_file_id)}>Download signed
                                contract</Link>
                    } else {
                        data['Signed Contract'] = <div className="flex flex-row gap-1">
                            <div className="grow"><span className="text-gray-500">Not Uploaded </span></div>
                            <Link onClick={() => setShowUploadSignedContractModal(true)}>Upload</Link>
                        </div>
                    }
                } else if (_.includes(["EXECUTED", "COMPLETE", "PENDING_PAYMENT", "AWAITING_MANUAL_ACTION"], res.status) && everyoneSigned) {
                    data['Signed Contract'] =
                        <Link onClick={() => downloadContract()}>Download signed contract</Link>
                }
                setDetails(data);
            }
            setLoadingContract(false);
        })
    }

    const getCounterSignatoriesSummary = (counter_signatories, signed_offline) => {
        return _.map(_.range(counter_signatories.length), (_, i) => (
            <div key={i} className={"flex flex-row items-center gap-1"}>
                {
                    counter_signatories[i].company_user.user.name
                }
                <span className="text-gray-500"> ({counter_signatories[i].company_user.user.email})</span>
                {!signed_offline && counter_signatories[i].signed &&
                    <BaseOverlayTrigger content={counter_signatories[i].signed_date ? `Signed on ${moment(counter_signatories[i].signed_date).format("DD MMM, YYYY h:mma")}` : "Signed"}>
                        <CheckCircleIcon className={"w-5 h-5 text-green-300"}/>
                    </BaseOverlayTrigger>}
                {!signed_offline && !counter_signatories[i].signed &&
                    <BaseOverlayTrigger content={"Pending signature"}>
                        <ClockIcon className={"w-5 h-5 text-orange-300"}/>
                    </BaseOverlayTrigger>}
            </div>
        ))
    }

    const getCustomerSignatoriesSummary = (signatories, signed_offline) => {
        return _.map(_.range(signatories.length), (_, i) => (
            <div key={i} className={"flex flex-row items-center gap-1"}>
                {
                    signatories[i].name
                }
                <span className="text-gray-500"> ({signatories[i].email})</span>
                {!signed_offline && signatories[i].signed &&
                    <BaseOverlayTrigger content={signatories[i].signed_date ? `Signed on ${moment(signatories[i].signed_date).format("DD MMM, YYYY h:mma")}` : "Signed"}>
                        <CheckCircleIcon className={"w-5 h-5 text-green-300"}/>
                    </BaseOverlayTrigger>}
                {!signed_offline && !signatories[i].signed &&
                    <BaseOverlayTrigger content={"Pending signature"}>
                        <ClockIcon className={"w-5 h-5 text-orange-300"}/>
                    </BaseOverlayTrigger>}
            </div>
        ))
    }

    const checkIfAnySignatoryHasSigned = (signatories, counter_signatories) => {
        for (let i = 0; i < signatories.length; i++) {
            if (signatories[i].signed) {
                return true
            }
        }
        if (counter_signatories) {
            for (let i = 0; i < counter_signatories.length; i++) {
                if (counter_signatories[i].signed) {
                    return true
                }
            }
        }
        return false
    }

    const checkIfEveryoneHasSigned = (signatories, counter_signatories) => {
        for (let i = 0; i < signatories.length; i++) {
            if (!signatories[i].signed) {
                return false
            }
        }
        if (counter_signatories) {
            for (let i = 0; i < counter_signatories.length; i++) {
                if (!counter_signatories[i].signed) {
                    return false
                }
            }
        }
        return true
    }

    const finalizeContract = async () => {
        isFinalizingRef.current = true
        if (formRef.current) {
            await formRef.current.submitForm()
        }
        if (activeNav === "content" || updateSuccessRef.current) {
            const result = await serverPatch(getApiUrl(`/proposals/${uuid}/finalize`), {})
            if (result) {
                setIsEditingTemplate(false);
                fetchData(true);
            }
        }
    }

    const onNavClick = (itemId) => {
        setActiveNav(itemId);
    }

    const getStartDate = (contract) => {
        if (contract.start_type === "SPECIFIC_DATE") {
            return moment(contract.start_date).format("DD MMM, YYYY")
        } else if (contract.start_type === "ON_COMPLETION") {
            return contract.completed_date ? `${moment(contract.completed_date).format("DD MMM, YYYY h:mma")} (Completed Date)` : "When completed"
        } else if (contract.start_type === "MANUAL_ACTION") {
            return contract.start_date ? `${moment(contract.start_date).format("DD MMM, YYYY")} (Manually Executed Date)` : "When manually executed"
        }
    }

    const updateContract = async (data, defaultErrorHandler) => {
        let pricingData = {};
        setSignatoryError(null);
        data.customer = contractDetails.customer;
        if (pricingsRef.current) {
            const validationResult = pricingsRef.current.validate(data);
            if (!_.isNil(validationResult)) {
                setError(validationResult)
                return;
            } else {
                setError(null);
            }

            pricingData = pricingsRef.current.getPricingSelectionFields(data);
        }

        let isToday = false;
        let startDate = null;
        if (data.start_date_type === "SPECIFIC_DATE") {
            isToday = moment(0, "HH").diff(data.start_date, "days") === 0;
            if (isToday) {
                startDate = moment();
            } else {
                startDate = moment(data.start_date).hour(12);
            }
        }

        let require_payment_method = data.auto_charges;
        if (!_.isNil(data.require_payment_method)) {
            require_payment_method = data.require_payment_method
        }
        const contractData = {
            ...pricingData,
            owner_id: data.owner || null,
            start_date: startDate,
            title: data.title,
            expiration_date: data.expiration_date || null,
            term: data.term ? {
                frequency: data.term.frequency,
                count: parseInt(data.term.count)
            } : {
                frequency: "YEAR",
                count: 1
            },
            auto_charges: data.auto_charges,
            require_payment_method: require_payment_method,
            invoice_due_date_from_creation: data.invoice_due_date_from_creation,
            po_number: data.po_number,
            custom_variables: data.custom_variables,
            trial: data.trial,
            start_type: startDateType,
        }
        if (data.counter_signatories) {
            data.counter_signatories = Object.keys(data.counter_signatories).reduce((acc, key) => {
                if (data.counter_signatories[key] && data.counter_signatories[key].length > 0) {
                    acc[key] = data.counter_signatories[key]
                }
                return acc
            }, {})
            contractData.counter_signatories = Object.keys(data.counter_signatories).map(key => {
                return {
                    company_user_id: (data.counter_signatories[key]),
                    position: parseInt(key)
                }
            })
        }

        if (data.signatories) {
            data.signatories = Object.keys(data.signatories).reduce((acc, key) => {
                if (data.signatories[key] && (data.signatories[key].name || data.signatories[key].email)) {
                    acc[key] = data.signatories[key]
                }
                return acc
            }, {})

            const err = validateSignatories(data.signatories)
            if (!_.isNil(err)) {
                setSignatoryError(err)
                return
            }
            contractData.signatories = Object.keys(data.signatories).map(key => {
                return {
                    name: (data.signatories[key].name),
                    title: data.signatories[key].title,
                    email: data.signatories[key].email,
                    position: parseInt(key)
                }
            })
        }
        if (data.trial) {
            contractData['trial_term'] = {
                frequency: 'DAY',
                count: data.num_trial_days,
            }
        }
        if (!_.isNil(data.invoice_payment_terms)) {
            if (data.invoice_payment_terms !== "custom") {
                contractData.invoice_due_date_from_creation = parseInt(data.invoice_payment_terms);
            }
        }
        const result = await serverPatch(getApiUrl(`/proposals/${uuid}`), contractData, {}, defaultErrorHandler)
        if (result) {
            updateSuccessRef.current = true
            if (!isFinalizingRef.current) {
                Notification.Success(`${contractDetails.title} updated`);
                fetchData(true);
            }
        } else {
            updateSuccessRef.current = false
            Notification.Danger("Could not update contract");
        }
    }

    const updateContractContentTemplate = (data) => {
        serverPatch(getApiUrl(`/proposals/${uuid}`), data).then((res) => {
            if (res) {
                Notification.Success("Successfully updated contract template");
                fetchData(true);
                setIsEditingTemplate(false);
            } else {
                Notification.Danger("Could not update contract template");
            }

        })
    }

    const downloadContract = () => {
        const filename = `${contractDetails.title || "Contract"}.pdf`;
        serverPost(getApiUrl(`/proposals/${uuid}/preview`), {}, {noJson: true}).then((res) => {
            downloadBlob(res, filename);
        })
    }

    const downloadContract2 = () => {
        serverFetch(getApiUrl(`/proposals/${uuid}/contract_pdf_url`)).then((res) => {
            if (res) {
                const filename = `${contractDetails.title || "Contract"}.pdf`;
                serverFetch(res.url, {noJson: true}).then((res) => {
                    downloadBlob(res, filename);
                })
            } else {
                Notification.Danger("Unable to download contract. Please try again later.")
            }
        });
    }

    const downloadUploadedContract = (file_id) => {
        serverFetch(getApiUrl(`/files/download/${file_id}`), {}).then((res) => {
            if (!res) {
                Notification.Danger("Unable to download signed contract");
            } else {
                const filename = `${res.filename}`;
                fetch(res.url, {headers: res.headers}).then((res2) => {
                    if (!res2.ok) {
                        Notification.Danger("Unable to download signed contract");
                    } else {
                        res2.blob().then(value => {
                            downloadBlob(value, filename)
                        })
                    }
                })
            }
        })
    }

    const onFieldChange = (name, value) => {
        if (name === "start_date_type") {
            setStartDateType(value);
        } else if (name === "invoice_payment_terms") {
            setInvoicePaymentTerms(value);
        } else if (name === "auto_charges") {
            setAutoCharges(value)
        } else if (name === "trial") {
            setIsTrial(value);
        }
        pricingsRef.current.onFieldChange(name, value);
    }

    const tabItems = [{
        'label': 'Details',
        'id': 'details',
        active: activeNav === "details"
    }];
    if (_.includes(["PENDING_SIGNATURES", "DRAFT", "NEEDS_APPROVAL", "REJECTED"], contractDetails.status)) {
        tabItems.push({
            'label': 'Preview',
            'id': 'content',
            active: activeNav === "content"
        })
    }

    if (contractDetails.campaigns && contractDetails.campaigns.length > 0) {
        tabItems.push({
            'label': 'Reminders',
            'id': 'reminders',
            active: activeNav === "reminders"
        })
    }

    const getMostRecentCampaign = () => {
        if (!contractDetails.campaigns) {
            return null
        }
        const sortedCampaigns = contractDetails.campaigns.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
        return sortedCampaigns[0]
    }

    const onProductPricingSelectionChange = (phase, selectedProductPricings) => {
        const flag = _.some(selectedProductPricings, (spp) => {
            const flag = _.some(spp.product_metric_pricings, (pmp) => {
                return pmp.item.type !== "ONETIME_ITEM";
            })
            return flag
        })
        setHasNonOneTimeItems(flag);
        if (flag && (_.isNil(initialFields.term) || initialFields.term.frequency === "ONETIME")) {
            setInitialFields(prevFields => {
                const newFields = {...prevFields};
                if (_.isNil(newFields.term)) {
                    newFields.term = {
                        frequency: "YEAR",
                        count: 1
                    }
                } else {
                    newFields.term.frequency = "YEAR";
                }
                return newFields;
            })
        }
    }

    const canEdit = _.includes(["DRAFT"], contractDetails.status);
    const canRevertToDraft = contractDetails.status === "PENDING_SIGNATURES" || contractDetails.status === "EXPIRED";
    const paymentMechanismOptions = [
        {
            value: true, label: "Collect payment information and charge automatically.",
            disabled: _.isNil(settings.payment_config), disabledDescription: "* Payment Provider needs to be setup"
        },
        {value: false, label: "Email invoice to customer manually"},
    ]

    const startDateTypeOptions = [
        {value: "ON_COMPLETION", label: 'When contract is completed'},
        {value: "SPECIFIC_DATE", label: 'On a specific date'},
        {value: "MANUAL_ACTION", label: 'On manual confirmation'}
    ]

    const changeDateOptions = [
        { value: "RENEWAL", label: `At renewal - ${ contractDetails.previous_subscription ? moment(contractDetails.previous_subscription.renewal_date).format("MMM D, YYYY h:mm:ssa"): ""}` },
        isMapleUser && { value: "CUSTOM", label: 'Custom Date' },
    ]

    const getCounterSignatoryInputRows = (num) => {
        return (
            <Row className="mt-2">
                <div className="body2 mb-2">Counter Signatory</div>
                {_.map(_.range(num), (_, i) => (
                    <BaseForm.Input key={i} colSpan="4" name={`counter_signatories.${i}`} type="select"
                                    options={counterSignatoryOptions} showSearch={counterSignatoryOptions.length > 5}/>
                ))}
            </Row>
        )
    }

    const getSignatoryInputRows = (num) => {
        return (
            <Row className="mt-2">
                <div className="body2">Signatories</div>
                <div className="body1 mb-2">Details about who is going to be signing the contract.</div>
                {_.map(_.range(num), (_, i) => (
                    <React.Fragment key={i}>
                        <BaseForm.Input colSpan="4" name={`signatories.${i}.name`} label={num > 1 ? `Signatory ${i+1} Name` : "Name"} type="text" required={num===1} />
                        <BaseForm.Input colSpan="4" name={`signatories.${i}.title`} label={"Title"} type="text" />
                        <BaseForm.Input colSpan="4" name={`signatories.${i}.email`} label={t('common.email')} type="text" transformations={["lowercase", "trim"]} required={num===1} validations={{ validEmail: true }}/>
                    </React.Fragment>
                ))}
            </Row>
        )
    }

    const renderEditableDetails = () => {
        return (
            <BaseForm ref={formRef} initialFormFields={initialFields} onFieldChange={onFieldChange} onSubmit={updateContract}>
                <Row className="mt-2">
                    <Col md="4">
                        <div className="flex flex-col h-full justify-center">
                            <Link href={getCompanySpecificUrl(`/customer/${contractDetails.customer.id}`)}>
                                <Columns.CustomerName customer={contractDetails.customer}/>
                            </Link>
                        </div>
                    </Col>
                    <Col md="4"/>
                    <BaseForm.Input colSpan="4" name="owner" label={"Owner"} type="select" options={teamMemberOptions}/>
                </Row>
                <hr/>
                <Row className="mt-2">
                    <div className="body2">Contract Details</div>
                    <div className="body1 mb-2">Details about the contract.</div>
                    <BaseForm.Input colSpan="6" name="title" label="Title" type="text" required/>
                    {
                        !showPONumber &&
                        <Col md="6" className="flex items-center">
                            <Link onClick={() => setShowPONumber(true)}>Add PO Number</Link>
                        </Col>
                    }
                    {
                        showPONumber &&
                        <BaseForm.Input colSpan="6" name="po_number" label="PO Number" type="text"/>
                    }
                </Row>
                <Row>
                    <BaseForm.Input colSpan="6" name="expiration_date" label={"Expiration Date"} type="date"
                                    includeTime={true} minDate={moment()}/>
                    <BaseForm.Input colSpan="6" name="auto_charges" label={t('subscriptions.payment_mechanism')}
                                    type="select"
                                    options={paymentMechanismOptions} showSearch={false}/>
                    {
                        !autoCharges ?
                            <>
                                <Col md={"6"}></Col>
                                <BaseForm.Input type="select" colSpan="3" name="invoice_payment_terms"
                                                label="Invoice Payment Terms" options={getInvoicePaymentTermOptions()}
                                                showSearch={false}/>
                                {
                                    invoicePaymentTerms === "custom" &&
                                    <BaseForm.Input type="number" colSpan="3" name="invoice_due_date_from_creation"
                                                    label="Due Date (in days)" step="1" min="0"/>
                                }
                            </>
                            : <>
                                <Col md={"6"}></Col>
                                <BaseForm.Input type="switch" colSpan="6" name="require_payment_method"
                                                label="Require payment method before execution"/>
                            </>
                    }
                </Row>
                <hr/>
                {numberOfSignatories > 0 && getSignatoryInputRows(numberOfSignatories)}
                {signatoryError && <div className="form-error-message">{signatoryError}</div>}
                {numberOfCounterSignatories > 0 && getCounterSignatoryInputRows(numberOfCounterSignatories)}
                {
                    !_.isEmpty(customVariables) &&
                    <div className="my-2">
                        <hr className="mb-2"/>
                        <div className="body2">Custom Variables</div>
                        <div className="body1">Fill out the values for the custom variables to be used in the
                            contract.
                        </div>
                        <Row className="mt-2">
                            {
                                _.map(customVariables, (cv, i) => renderCustomVariableInput(cv, i, availableCustomVariables))
                            }
                        </Row>
                    </div>
                }
                <hr/>
                <Row className="mt-2">
                    <div className="body2">Plan Details</div>
                    <div className="body1 mb-2">Details about the plan and pricing.</div>
                    {
                        contractDetails.previous_subscription ?
                            <>
                                <BaseForm.Input
                                    colSpan="6" name="change_timing" label={"Change Date"} type="select"
                                    options={changeDateOptions} showSearch={false}/>
                            </>
                        : <>
                        <BaseForm.Input colSpan="6" name="start_date_type" label={t('subscriptions.start_date')}
                                        type="select"
                                        options={startDateTypeOptions} showSearch={false}/>
                        {
                            startDateType === "SPECIFIC_DATE" &&
                            <BaseForm.Input colSpan="6" name="start_date" label={'Specific Date'} type="date"
                                            required/>
                        }
                        </>
                    }
                </Row>
                {
                    hasNonOneTimeItems &&
                    <Row>
                        <Col md="6">
                            <BaseForm.InputGroup label="Term">
                                <BaseForm.Number name="term.count" min="1"/>
                                <BaseForm.Divider/>
                                <BaseForm.SingleSelect name="term.frequency" options={getPlanLengthOptions()}
                                                       showSearch={false}/>
                            </BaseForm.InputGroup>
                        </Col>
                        <Col lg="3" className="flex items-end">
                            <BaseForm.Input
                                type="switch" name="trial" label={"Free trial days"} outerInputClassName="grow"
                            />
                        </Col>
                        {
                            isTrial &&
                            <BaseForm.Input colSpan={3} type="number" name="num_trial_days" label="Trial Days" step="1"
                                            min="0"/>
                        }
                    </Row>
                }
                <br/>
                <ProductPricingSelection
                    ref={pricingsRef} productPricings={productPricings}
                    initialSelectionData={initialContractData}
                    editable={true} allowPhases
                    allowDiscounts={true} initialFields={initialFields}
                    setInitialFields={setInitialFields}
                    allowMinimumSpend={true}
                    onSelectionChange={onProductPricingSelectionChange}/>
                <br/><br/>
                {
                    error &&
                    <div className="form-error-message">{error}</div>
                }
                <Row>
                    <Col md="12">
                        <SubmitButton errorOnRight>Update</SubmitButton>
                        {!_.isNil(signatoryError) && <div className="form-error-message">{signatoryError}</div>}
                    </Col>
                </Row>
            </BaseForm>
        );
    }

    const renderFinalizedDetails = () => {
        return (
            <>
                <Section className="mt-3 max-w-2xl" actions={[]}>
                    <KeyValueDisplay items={details}/>
                </Section>
                {
                    !_.isEmpty(customVariableDetails) &&
                    <Section title="Custom Variables" className="mt-3 max-w-2xl" actions={[]}>
                        <KeyValueDisplay items={customVariableDetails}/>
                    </Section>
                }
                <Section title="Plan Details">
                    <div className="body1">Details about the plan and pricing.</div>
                    <div className="body1"><strong>Start
                        Date:</strong> {contractDetails.start_date ? moment(contractDetails.start_date).format("DD MMM, YYYY") : "When executed"}
                    </div>
                    <BundlePricingTable
                        bundlePricing={contractDetails.bundle_pricing}
                        oneTimeBillables={contractDetails.one_time_billables}
                        configItems={contractDetails.config_items}
                        discounts={contractDetails.discounts}
                        showPricingLinks={true}
                        term={contractDetails.term}
                        minimumSpend={contractDetails.minimum_spend}
                        displayDiscountExpiration={true}
                        scheduledChanges={contractDetails.scheduled_changes}
                    />
                </Section>
            </>
        )
    }

    const renderDeclinedDetails = () => {
        return (
            <>
                <Section title="Details" actions={[]}>
                    <div className="d-flex flex-row flex-wrap gap-3">
                        <KeyValueDisplay items={details} className="half-flex"/>
                        <div className="half-flex align-self-center text-center d-flex flex-column gap-3"
                             style={{paddingTop: "50px", paddingBottom: "50px"}}>

                        </div>
                    </div>
                </Section>
                <Section title="Plan Details">
                    <div className="body1">Details about the plan and pricing.</div>
                    <div className="body1"><strong>Start
                        Date:</strong> {moment(contractDetails.start_date).format("DD MMM, YYYY")}</div>
                    <br/>
                    <BundlePricingTable
                        bundlePricing={contractDetails.bundle_pricing}
                        oneTimeBillables={contractDetails.one_time_billables}
                        configItems={contractDetails.config_items}
                        discounts={contractDetails.discounts}
                        showPricingLinks={true}
                        term={contractDetails.term}
                        minimumSpend={contractDetails.minimum_spend}
                        displayDiscountExpiration={true}
                    />
                </Section>
            </>
        )
    }

    const stopCampaign = (campaignId) => {
        serverPost(getApiUrl(`/campaigns/${campaignId}/stop`), {}).then(res => {
            if (res) {
                fetchData(true);
            }
        })
    }

    const renderCampaignInformation = () => {
        return (
            <Section title={`Campaign Summary`} className="mt-3"
                     right={
                         <div className="grow flex flex-row gap-3 items-center">
                             <div className="grow">
                                 {renderCampaignStatusLabel(mostRecentCampaign)}
                             </div>
                             <div className="grow-0 shrink-0 flex flex-row gap-3">
                                 {mostRecentCampaign.status === "ACTIVE" &&
                                     <DeleteButton onDelete={() => stopCampaign(mostRecentCampaign.id)}
                                                   title={"Confirm stop reminders?"}
                                                   body={"Are you sure you want to stop reminders? Any future steps will not be executed."}>
                                         Stop Reminders
                                     </DeleteButton>
                                 }
                             </div>
                         </div>}>
                {
                    <MapleTable>
                        <Table hover>
                            <thead>
                            <tr>
                                <th>Step No.</th>
                                <th>Scheduled Date</th>
                                <th>Completed Date</th>
                                <th>Action</th>
                                <th>Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                _.map(mostRecentCampaign.steps, (step, i) =>
                                    <tr key={i}>
                                        <td>{step.step_number + 1}</td>
                                        <td>{moment(step.enqueued_at).format("D MMMM, YYYY, h:mm:ssa")}</td>
                                        <td>{step.completed_at !== "0001-01-01T00:00:00Z" ? moment(step.completed_at).format("D MMMM, YYYY, h:mm:ssa") : ""}</td>
                                        <td>{t(getConfigActionTypeKey(step.action_type))}</td>
                                        <td>{getStatusForStep(step)}</td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </Table>
                    </MapleTable>
                }
            </Section>
        )
    }

    const renderContractStatus = () => {
        if (contractDetails.status === "PENDING_SIGNATURES") {
            return <span>The contract is pending signatures.</span>
        } else if (contractDetails.status === "NEEDS_APPROVAL") {
            return <span>The contract is waiting to be approved.</span>
        } else if (contractDetails.status === "PENDING_PAYMENT") {
            return <span>The contract is signed, waiting for payment input.</span>
        } else if (contractDetails.status === "EXECUTED") {
            return <span>The contract is fully executed.</span>
        } else if (contractDetails.status === "DRAFT") {
            return <span>The contract is in draft. Please review and finalize before sending it out.</span>
        } else if (contractDetails.status === "REJECTED") {
            return <span>The contract is rejected by the company approver(s). Please revise and request approval again.</span>
        } else if (contractDetails.status === "COMPLETE") {
            return <span>The contract is signed and payment details have been entered. It is awaiting execution.</span>
        } else if (contractDetails.status === "AWAITING_MANUAL_ACTION") {
            return <span>The contract is pending manual confirmation to mark it as executed.</span>
        }
    }

    const revertContract = async (startEditingTemplate) => {
        const result = await serverPost(getApiUrl(`/proposals/${uuid}/revert`), {})
        if (result) {
            fetchData(true);
            if (startEditingTemplate) {
                setIsEditingTemplate(true);
            }
        }
    }

    const manuallyExecuteContract = () => {
        serverPost(getApiUrl(`/proposals/${uuid}/manual_execute`), {}).then(res => {
            if (res) {
                Notification.Success("Successfully executed contract")
                fetchData(true)
            } else {
                Notification.Danger("Could not execute contract")
            }
        })
    }

    const downloadPDFAction = () => {
        if (!contractDetails.signed_offline) {
            downloadContract()
        } else {
            downloadUploadedContract(contractDetails.signed_file_id)
        }
    }

    const onActionSelected = (type) => {
        if (type === "pdf") {
            downloadPDFAction();
        } else if (type === "email") {
            setShowEmailModal(true);
        } else if (type === "link") {
            setShowLinkModal(true);
        } else if (type === "edit") {
            setShowRevertConfirmationModal(true);
        } else if (type === "references") {
            setShowReferenceSummaryModal(true);
        } else if (type === "trigger_campaign") {
            const data = {
                type: "proposal_sign_reminder",
                proposal_id: contractDetails.id,
                customer_id: contractDetails.customer_id
            }
            serverPost(getApiUrl("/campaigns/trigger"), data).then((res) => {
                if (res) {
                    Notification.Success("Successfully started reminder schedule")
                    fetchData(true)
                } else {
                    Notification.Danger("Could not start reminder schedule")
                }
            })
        } else if (type === "manual_execute") {
            setShowManualExecuteModal(true);
        } else if (type === "sign_offline") {
            setShowSignOfflineModal(true)
        }
    }

    const signOffline = (data, errorHandler) => {
        const postData = {}
        if (data && data.file_id) {
            postData.file_id = data.file_id;
        }
        serverPost(getApiUrl(`/proposals/${uuid}/sign_offline`), postData).then((res) => {
            if (res) {
                Notification.Success("Successfully marked contract as signed offline")
                fetchData(true)
            } else {
                Notification.Danger("Could not mark contract as signed offline")
            }
        })
        setShowSignOfflineModal(false)
    }

    const uploadContract = (data, errorHandler) => {
        const postData = {
            file_id: data.file_id
        }
        serverPost(getApiUrl(`/proposals/${uuid}/sign_offline`), postData).then((res) => {
            if (res) {
                Notification.Success("Successfully uploaded signed contract")
                fetchData(true)
            } else {
                Notification.Danger("Could not upload signed contract")
            }
        })
        setShowUploadSignedContractModal(false)
    }

    const actionOptions = _.compact([
        _.includes(["PENDING_SIGNATURES", "NEEDS_APPROVAL", "REJECTED", "DECLINED", "EXPIRED"], contractDetails.status) && {
            id: "edit",
            label: "Edit"
        },
        isSuperUser && {id: "references", label: "References"},
        canTriggerCampaign && {id: "trigger_campaign", label: "Start Reminder Schedule"},
        contractDetails.status === "AWAITING_MANUAL_ACTION" && {id: "manual_execute", label: "Mark as Executed"},
        _.includes(["PENDING_SIGNATURES", "PENDING_PAYMENT", "PENDING_COUNTER_SIGNATURES"], contractDetails.status) && !hasEveryoneSigned && {id: "sign_offline", label: "Mark as Signed Offline"}
    ]);

    const isFinalizedOrLater = contractDetails.status !== "DRAFT" && contractDetails.status !== "EXPIRED";
    const isAwaitingManualActionOrLater = _.includes(["COMPLETE", "EXECUTED", "AWAITING_MANUAL_ACTION"], contractDetails.status);
    const showDownloadPDF = () => {
        let isFileAvailable = true
        if (contractDetails.signed_offline) {
            isFileAvailable = contractDetails.signed_file_id && contractDetails.signed_file_id.length > 0
        }
        return isFinalizedOrLater && isFileAvailable
    }
    const shareOptions = _.compact([
        isFinalizedOrLater && !isAwaitingManualActionOrLater && {id: "link", label: "Share via Link"},
        isFinalizedOrLater && !isAwaitingManualActionOrLater && {id: "email", label: "Share via Email"},
        showDownloadPDF() && {id: "pdf", label: "Download PDF"},
    ]);

    return (
        <BaseContainer>
            <Loader loading={loadingContract}>
                {
                    _.isEmpty(contractDetails) ?
                        <ErrorComponent
                            title={"Contract Not Found"}
                            description={"Oops, we can't find the contract you are looking for."}
                            primaryAction={{href: getCompanySpecificUrl("/contracts"), label: "Show all contracts"}}
                            hideSupport
                        />
                        : <>
                            <ContentContainer>
                                <Section
                                    variant="page" title={contractDetails.title} subtitle={renderContractStatus()}
                                    right={
                                        <div className="grow flex flex-row gap-3 items-center">
                                            <div className="grow">
                                                {renderContractStatusLabel(contractDetails)}
                                            </div>
                                            <div className="grow-0 shrink-0 flex flex-row gap-3">
                                                {
                                                    !_.isEmpty(actionOptions) &&
                                                    <DropdownMenu className="py-2 px-1" items={actionOptions}
                                                                  onClick={onActionSelected}>
                                                        <div className="flex flex-row gap-1 items-center">
                                                            <WrenchScrewdriverIcon className="h-4 w-4"/>
                                                            <span>Actions</span>
                                                        </div>
                                                    </DropdownMenu>
                                                }
                                                {
                                                    contractDetails.status === "DRAFT" &&
                                                    <Button variant="primary" onClick={finalizeContract}>Finalize</Button>
                                                }
                                                {
                                                    !_.isEmpty(shareOptions) &&
                                                    <DropdownMenu className="py-2 px-1" items={shareOptions}
                                                                  onClick={onActionSelected}>
                                                        <div className="flex flex-row gap-1 items-center">
                                                            <ShareIcon className="h-4 w-4"/>
                                                            <span>Share</span>
                                                        </div>
                                                    </DropdownMenu>
                                                }
                                            </div>
                                        </div>
                                    }
                                />
                                <ApprovalRequestListSection approvalRequests={approvalRequests}
                                                            onUpdate={() => fetchData(true)}/>
                                {
                                    contractDetails.previous_subscription &&
                                        <Alert variant="warning my-2">
                                            <div className="body2">Changing existing subscription</div>
                                            <div className="body1">This is a renewal contract for an existing <Link href={getCompanySpecificUrl(`/subscription/${contractDetails.previous_subscription.id}`)}>subscription</Link>.</div>
                                        </Alert>
                                }
                                {
                                    tabItems.length > 1 &&
                                    <SectionNav items={tabItems} onClick={onNavClick}/>
                                }
                                {
                                    activeNav === "details" &&
                                    <Loader loading={loadingContract}>
                                        {() => {
                                            if (contractDetails.status === "DECLINED") {
                                                return renderDeclinedDetails();
                                            } else if (canEdit) {
                                                return renderEditableDetails();
                                            } else {
                                                return renderFinalizedDetails();
                                            }
                                        }}
                                    </Loader>
                                }
                                {
                                    activeNav === "content" && isEditingTemplate &&
                                    <ContentBox>
                                        <ContentBox.Body className="scrollable">
                                            <BaseForm initialFormFields={contractDetails}
                                                      onSubmit={updateContractContentTemplate}>
                                                <div className="contract-content">
                                                    <div className="d-flex flex-row gap-3 align-items-center">
                                                    <span className="body1 flex-grow-1">
                                                        Please feel free to update the content as you choose. Note that, any changes to the content will not be reflected in the template it is adopted from.
                                                    </span>
                                                        <span className="flex-shrink-0 flex flex-row">
                                                        <Button variant="text"
                                                                onClick={(event) => setIsEditingTemplate(false)}>
                                                            Cancel
                                                        </Button>
                                                        <SubmitButton>Update</SubmitButton>
                                                    </span>
                                                    </div>
                                                </div>
                                                <div className="contract-content">
                                                    <Row>
                                                        <BaseForm.Input colSpan="12" type="text" name="title"
                                                                        label="Title"/>
                                                        <BaseForm.Input colSpan="12" type="editor2" name="content_template"
                                                                        height="800" tokens={templateFields}/>
                                                    </Row>
                                                </div>
                                            </BaseForm>
                                        </ContentBox.Body>
                                    </ContentBox>
                                }
                                {
                                    activeNav === "content" && !isEditingTemplate &&
                                    <ContentBox>
                                        <ContentBox.Body className="scrollable">
                                            <div className="contract-content">
                                                <div className="d-flex flex-row gap-3 align-items-center">
                                                <span className="body1 flex-grow-1">
                                                    Here is the preview of the contents of the contract.
                                                </span>
                                                    <span className="flex-shrink-0">
                                                    {
                                                        canRevertToDraft ?
                                                            <ConfirmationButton
                                                                variant="text-primary"
                                                                title={"Move to Draft?"}
                                                                body={
                                                                    <div>
                                                                        {hasAnyoneSigned && <div>
                                                                            <span>Are you sure you want to move the contract back to draft?</span><br/><br/>
                                                                            <span>Some signatories have already signed. Their signatures will be invalidated, and they will be requested to sign the document again.</span>
                                                                        </div>}
                                                                        {!hasAnyoneSigned && <span>Are you sure you want to move the contract back to draft? Doing so will make the contract links unavailable until it is finalized again.</span>}
                                                                    </div>
                                                                }
                                                                confirmationText={(hasAnyoneSigned && !contractDetails.isExpired) ? "CONFIRM REVERT" : null}
                                                                onConfirm={(event) => {
                                                                    revertContract(true);
                                                                }}>
                                                                <i className="fa fa-edit"/>&nbsp;Move to Draft & Edit
                                                            </ConfirmationButton>
                                                            : <Button variant="text-primary"
                                                                      onClick={(event) => setIsEditingTemplate(true)}>
                                                                <i className="fa fa-edit"/>&nbsp;Edit
                                                            </Button>
                                                    }
                                                </span>
                                                </div>
                                            </div>
                                            <div className="content-body1">
                                                <div className="contract-content"
                                                     dangerouslySetInnerHTML={{__html: convertVariablesIntoHtml(contractDetails.content, contractDetails.counter_signatories, contractDetails.signatories)}}/>
                                            </div>
                                        </ContentBox.Body>
                                    </ContentBox>
                                }
                                {
                                    activeNav === "reminders" &&
                                    renderCampaignInformation()
                                }
                            </ContentContainer>
                            <SingleContractEmailModal show={showEmailModal} onClose={setShowEmailModal}
                                                      contract={contractDetails}/>
                            <ShareContractLinkModal show={showLinkModal} onClose={setShowLinkModal}
                                                    contract={contractDetails} hasEveryoneSigned={hasEveryoneSigned}/>
                            <SimpleModal
                                title={"Confirm revert and edit?"}
                                body={
                                    <div>
                                        <span>In order to make edit to a finalized contract, we would have to move it back to <strong>Draft</strong>.</span><br/><br/>
                                        {!contractDetails.isExpired && hasAnyoneSigned && <div>
                                            <span>Are you sure you want to proceed?</span><br/><br/>
                                            <span>Some signatories have already signed. Their signatures will be invalidated, and they will be requested to sign the document again.</span>
                                        </div>}
                                        {!contractDetails.isExpired && !hasAnyoneSigned && <span>Are you sure you want to proceed? Any existing links to the contract will not work until the contract is finalized again.</span>}
                                    </div>
                                }
                                confirmationText={(hasAnyoneSigned && !contractDetails.isExpired) ? "CONFIRM REVERT" : null}
                                buttonVariant={"danger"}
                                buttonTitle={"Revert and Edit"}
                                show={showRevertConfirmationModal}
                                onClose={setShowRevertConfirmationModal} onConfirm={async () => await revertContract(false)}
                            />
                            <ManualExecutionModal
                                proposal_id={uuid}
                                show={showManualExecuteModal}
                                onClose={setShowManualExecuteModal}
                                onExecute={fetchData}
                            />
                            <IntegrationReferenceEntitySummaryModal
                                show={showReferenceSummaryModal} onClose={setShowReferenceSummaryModal}
                                entityKey={"CONTRACT"}
                                referenceID={contractDetails.id}
                            />
                            <SignOfflineModal
                                show={showSignOfflineModal}
                                proposal_id={uuid}
                                onClose={setShowSignOfflineModal}
                                onSubmit={signOffline}
                                required={false}
                                hasAnyoneSigned={hasAnyoneSigned}
                            />
                            <SignOfflineModal
                                show={showUploadSignedContractModal}
                                proposal_id={uuid}
                                onClose={setShowUploadSignedContractModal}
                                onSubmit={uploadContract}
                                required={true}
                                title={"Upload Signed Contract"}
                                submitLabel={"Upload"}
                            />
                        </>
                }
            </Loader>
        </BaseContainer>
    );
}

export default Contract;
