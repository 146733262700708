import '../App.scss';
import React from 'react';
import { renderChildren } from '../helpers/common';
import ConfirmationButton from './common/buttons/ConfirmationButton';
const _ = require('lodash');

function DeleteButton(props) {

    return (
        <ConfirmationButton size={props.size} onConfirm={props.onDelete} variant={props.variant || "text-danger"}
            title={props.title || "Confirm Delete?"} body={props.body || "Are you sure you want to delete?"}
            confirmationText={props.confirmationText} buttonClassName={props.buttonClassName} className={props.className}
            buttonTitle={props.buttonTitle}>
            {
                !_.isEmpty(props.children) ?
                    renderChildren(props)
                : <i className="fa fa-trash"/>
            }
        </ConfirmationButton>
    );
}

export default DeleteButton;
