import '../App.scss';
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { getUrl, serverPost } from '../helpers/server';
import BaseForm from '../components/BaseForm';
import SubmitButton from '../components/common/buttons/SubmitButton';
import { useTranslation } from 'react-i18next';

function LoginComponent(props) {
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const [loginError, setLoginError] = useState(null);

    const onLogin = async (data, defaultErrorHandler) => {
        setLoginError(null);
        data.email = data.email.trim().toLowerCase();
        const loginResult = await serverPost(getUrl('/users/login'), data, { suppressUnauthenticated: true },
            defaultErrorHandler);
        if (loginResult) {
            localStorage.setItem("auth_token", loginResult.token);
            navigate(props.redirectUrl || '/user/home');
        }
    }

    return (
        <div>
            <h3>Login to Maple</h3>
            <br/>
            <BaseForm onSubmit={onLogin}>
                <BaseForm.Input type="text" name="email" label={t('common.email')} transformations={["lowercase", "trim"]} validations={{ required: true, validEmail: true }}/>
                <BaseForm.Input type="password" name="password" label={t('auth.password')} required/>
                {
                    loginError &&
                        <p className="form-error-message">{loginError}</p>
                }
                <SubmitButton type="submit" errorOnRight>{t('auth.login')}</SubmitButton><br/>
                <span className="body1">Forgot your password? <Link to="/forgot_password">Reset Password.</Link></span>
            </BaseForm>
        </div>
    );
}

export default LoginComponent;
