import { useParams } from 'react-router-dom';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { BaseContext, renderFileImportStatusLabel } from '../../helpers/common';
import { serverFetch, serverPost } from '../../helpers/server';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import InfiniteScroll from 'react-infinite-scroll-component';
import Section from '../../components/Section';
import MapleTable from '../../components/MapleTable';
import MapleTableHeaderWithActions from '../../components/MapleTableHeaderWithActions';
import SortableTableHeader from '../../components/SortableTableHeader';
import BaseCSVExportModal from '../../components/modals/BaseCSVExportModal';
import { getBillableEventImportRowExportFields, getCustomerImportRowExportFields } from '../../helpers/exportFields';
import Notification from '../../components/Notification';
import moment from 'moment/moment';
import SectionNav from "../../components/SectionNav";

const _ = require('lodash');

function Import() {
	const { importId } = useParams();
	const { company, getApiUrl, setPageTitle, getCompanySpecificUrl, hasAccess } = useContext(BaseContext);
	const [fromKey, setFromKey] = useState(null);
	const [importRows, setImportRows] = useState([]);
	const [meta, setMeta] = useState(null);
	const [loading, setLoading] = useState(true);
	const [hasMore, setHasMore] = useState(true);
	const [filters, setFilters] = useState([]);
	const [importFile, setImportFile] = useState(null);
	const [importType, setImportType] = useState(null);
	const [sort, setSort] = useState('rowNumberAsc');
	const [showExportModal, setShowExportModal] = useState(false);
	const [selectedFilters, setSelectedFilters] = useState( { import_row_status: ['FAILED']});

	const [tabItems, setTabItems] = useState([{
		'label': 'Failed',
		'id': 'FAILED',
		'active': true
	}, {
		'label': 'Pending',
		'id': 'PENDING',
		'active': false
	}, {
		'label': 'Completed',
		'id': 'COMPLETED',
		'active': false
	}]);

	useEffect(() => {
		setPageTitle(`Import - ${importId}`);
	}, [importId]);

	useEffect(() => {
		if (!_.isNil(importId)) {
			onSearch();
		}
	}, [importId, selectedFilters, sort]);

	useEffect(() => {
		if (!_.isNil(importId)) {
			serverFetch(getApiUrl(`/import/file_import/${importId}`), {}).then((res) => {
				if (res) {
					setImportFile(res);
					setImportType(res.type);
				}
			});
		}
	}, [importId]);

	useEffect(() => {
		const importStatusOptions = [
			{ value: 'COMPLETED', label: 'Completed' },
			{ value: 'FAILED', label: 'Failed' },
			{ value: 'PENDING', label: 'Pending' },
		];
		setFilters([
			{
				title: 'Import Row Status',
				type: 'multi-select',
				name: 'import_row_status',
				options: importStatusOptions,
			},
		]);
	}, []);

	const onParamsChange = (data) => {
		setSelectedFilters(data);
		localStorage.setItem(company.id + '_filter_cache_file_import_rows_' + importId, JSON.stringify(data));
	};

	const onSearch = (restart = true) => {
		const limit = 50;
		const params = {
			sort_key: sort || 'rowNumberAsc',
			pagination: {
				from_key: restart ? null : fromKey,
				limit: limit,
			},
			query: {
				import_row_status: selectedFilters.import_row_status ? {
					condition: "OR",
					values: selectedFilters.import_row_status,
				} : null
			},
			include_meta: restart,
		};
		serverPost(getApiUrl(`/import/file_import/${importId}/rows`), params).then((res) => {
			if (res) {
				const results = res.results || [];
				if (restart) {
					setImportRows(results);
				} else {
					setImportRows(_.concat(importRows, results));
				}
				if (res.meta) {
					setMeta(res.meta);
				}

				setLoading(false);
				setFromKey(res.pagination.from_key);
				setHasMore(results.length === limit);
			}
		});
	};

	const getQueryParams = () => {
		return {
			import_row_status: selectedFilters.import_row_status ? {
				condition: "OR",
				values: selectedFilters.import_row_status,
			} : null
		};
	};

	const getTableHeaders = () => {
		if (importType === 'CUSTOMER') {
			return (
				<tr>
					<SortableTableHeader
						onSortChange={setSort}
						sortKeyUp={'rowNumberAsc'}
						sortKeyDown={'rowNumberDesc'}
						currentSort={sort}
					>
						Row Number
					</SortableTableHeader>
					<MapleTable.TH>Status</MapleTable.TH>
					<MapleTable.TH>Contact Name</MapleTable.TH>
					<MapleTable.TH>Contact Email</MapleTable.TH>
					<MapleTable.TH>Organization Name</MapleTable.TH>
					<MapleTable.TH>Identifier</MapleTable.TH>
					<MapleTable.TH>Billing Emails</MapleTable.TH>
					<MapleTable.TH>Phone Number</MapleTable.TH>
					<MapleTable.TH>Error</MapleTable.TH>
				</tr>
			);
		} else if (importType === 'BILLABLE_EVENT') {
			return (
				<tr>
					<SortableTableHeader
						onSortChange={setSort}
						sortKeyUp={'rowNumberAsc'}
						sortKeyDown={'rowNumberDesc'}
						currentSort={sort}
					>
						Row Number
					</SortableTableHeader>
					<MapleTable.TH>Status</MapleTable.TH>
					<MapleTable.TH>Transaction ID</MapleTable.TH>
					<MapleTable.TH>Maple Customer ID</MapleTable.TH>
					{/*<MapleTable.TH >External Customer Identifier</MapleTable.TH>*/}
					<MapleTable.TH>Item ID</MapleTable.TH>
					<MapleTable.TH>Event Timestamp</MapleTable.TH>
					<MapleTable.TH>Properties</MapleTable.TH>
					<MapleTable.TH>Error</MapleTable.TH>
				</tr>
			);
		}
	};

	const getTableRows = () => {
		if (importType === 'CUSTOMER') {
			const rows = _.map(importRows, (row, i) => (
				<MapleTable.TR key={i}>
					<td>{row.row_number}</td>
					<td>{renderFileImportStatusLabel(row)}</td>
					<td>{row.properties.customer_properties.name || <span className='gray2'>None</span>}</td>
					<td>{row.properties.customer_properties.email || <span className='gray2'>None</span>}</td>
					<td>
						{row.properties.customer_properties.organization_name || <span className='gray2'>None</span>}
					</td>
					<td>{row.properties.customer_properties.external_identifier}</td>
					<td>{row.properties.customer_properties.billing_emails || <span className='gray2'>None</span>}</td>
					<td>{row.properties.customer_properties.phone_number || <span className='gray2'>None</span>}</td>
					<td>{row.error_message ? row.error_message.join(', ') : <span className='gray2'>None</span>}</td>
				</MapleTable.TR>
			));
			return rows;
		} else if (importType === 'BILLABLE_EVENT') {
			const rows = _.map(importRows, (row, i) => (
				<MapleTable.TR key={i}>
					<td>{row.row_number}</td>
					<td>{renderFileImportStatusLabel(row)}</td>
					<td>
						{row.properties.billable_event_properties.transaction_id || <span className='gray2'>None</span>}
					</td>
					<td>
						{row.properties.billable_event_properties.customer_id || <span className='gray2'>None</span>}
					</td>
					{/*<td>{row.properties.billable_event_properties.customer_identifier  ||*/}
					{/*    <span className="gray2">None</span>}</td>*/}
					<td>{row.properties.billable_event_properties.item_id}</td>
					<td>
						{row.properties.billable_event_properties.event_timestamp || (
							<span className='gray2'>None</span>
						)}
					</td>
					<td className={'max-w-sm'}>
						{row.properties.billable_event_properties.properties ? (
							<div className='text-xs text-gray-500 max-w-sm'>
								<code>{JSON.stringify(row.properties.billable_event_properties.properties)}</code>
							</div>
						) : (
							<span className='gray2'>None</span>
						)}
					</td>
					<td>{row.error_message ? row.error_message.join(', ') : <span className='gray2'>None</span>}</td>
				</MapleTable.TR>
			));
			return rows;
		}
	};

	const getExportFields = () => {
		if (importType === 'CUSTOMER') {
			return getCustomerImportRowExportFields();
		} else if (importType === 'BILLABLE_EVENT') {
			return getBillableEventImportRowExportFields();
		}
	};

	const csvExportSuccessNotification = (res) => {
		if (res) {
			Notification.Success('The CSV export will be emailed to you shortly', 8000);
		}
	};

	const getSectionTitle = () => {
		if (!_.isNil(importFile)) {
			const createdAt = moment(importFile.created_at).format('MMM D, YYYY h:mm:ss a');
			if (importType === 'CUSTOMER') {
				return 'Customers Import - ' + createdAt;
			} else if (importType === 'BILLABLE_EVENT') {
				return 'Events Import - ' + createdAt;
			}
		}
		return '';
	};

	const onTabClick = (id) => {
		if (id) {
			setSelectedFilters({
				import_row_status: [id]
			});
			const newTabItems = tabItems.map(item => {
				item.active = item.id === id;
				return item
			})
			setTabItems(newTabItems);
		}
	}

	return (
		<BaseContainer>
			<ContentContainer>
				<InfiniteScroll
					next={() => onSearch(false)}
					hasMore={hasMore}
					scrollableTarget='content-wrapper'
					dataLength={importRows.length}
				>
					<Section
						title={getSectionTitle()}
						variant="page"
						loading={loading}
						actions={[{
							variant: "primary-outline",
							icon: "fa-arrow-up-right-from-square",
							label: "Export",
							onClick: () => setShowExportModal(true),
							disabled: importRows.length === 0
						}]}
					>
						<SectionNav size={"sm"} items={tabItems} shouldAutoMatch={true} className={"mt-2"} onClick={onTabClick}/>
						<MapleTable>
							<MapleTableHeaderWithActions
								showSearch={false}
								showFilters={false}
								meta={meta}
								onParamsChange={onParamsChange}
							/>
							<MapleTable.Content>
								<thead>{getTableHeaders()}</thead>
								<tbody className='divide-y divide-gray-200'>{getTableRows()}</tbody>
							</MapleTable.Content>
						</MapleTable>
					</Section>
				</InfiniteScroll>
			</ContentContainer>
			<BaseCSVExportModal
				show={showExportModal}
				onClose={setShowExportModal}
				sort={sort}
				useQuery={true}
				query={getQueryParams()}
				fields={getExportFields()}
				title={'Customer Import Rows CSV Export'}
				filenamePrefix='Customer-ImportRows-CSV'
				exportUrl={`/import/file_import/${importId}/export`}
				noDownload={true}
				onNoDownloadSuccess={csvExportSuccessNotification}
				bodyText={
					<div>
						The exported CSV will have a <strong>maximum of 10,000 rows</strong>. The CSV will be sent to
						you in an email once exported.
					</div>
				}
			/>
		</BaseContainer>
	);
}

export default Import;
