import '../../App.scss';
import '../../css/modals.scss';
import BaseModal from './BaseModal';
import BaseSideModal from './BaseSideModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../common/buttons/SubmitButton';
import { useContext, useState, useEffect } from 'react';
import { BaseContext, getCurrencyOptions } from '../../helpers/common';
import { serverPost } from '../../helpers/server';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
const _ = require("lodash");

function MarkInvoiceAsPaidModal(props) {
    const { t } = useTranslation('common');
    const { getApiUrl } = useContext(BaseContext);
    const [initialFields, setInitialFields] = useState({});
    const [invoice, setInvoice] = useState({});

    useEffect(() => {
        setInitialFields({
            amount: props.invoice && props.invoice.due && props.invoice.due.value_in_cents/100,
            max_amount: props.invoice && props.invoice.due && props.invoice.due.value_in_cents/100,
            currency: props.invoice && props.invoice.due && props.invoice.due.currency,
            paid_date: moment()
        })
        setInvoice(props.invoice)
    }, [props.invoice])

    const onSubmit = (data) => {
        const paymentData = {
            ...data,
            amount: {
                value_in_cents: Math.round(parseFloat(data.amount) * 100),
                currency: data.currency
            }
        }
        serverPost(getApiUrl(`/invoices/${props.invoice.uuid}/mark_paid`), paymentData).then((res) => {
            if (res) {
                props.onClose(true);
            }
        });
    }

    const typeOptions = [
        { value: "BANK_TRANSFER", label: "Bank Transfer" },
        { value: "CASH", label: "Cash" },
        { value: "CHEQUE", label: "Check" },
        { value: "WIRE", label: "Wire" },
        { value: "CREDIT_CARD_OFFLINE", label: "Credit Card Offline" },
        { value: "BILL_COM", label: "Bill.com" },
    ]

    return (
        <BaseSideModal {...props}>
            <BaseSideModal.Form initialFormFields={initialFields} onSubmit={onSubmit}>
                <BaseSideModal.Header title={"Invoice - Add Payment"}/>
                <BaseSideModal.Body>
                    {
                        !_.isEmpty(invoice) &&
                            <Row>
                                <BaseForm.Input colSpan={12} name="provider_type" label="Type" type="select" options={typeOptions} required showSearch={false}/>
                                <BaseForm.Input colSpan={6} name="amount" label="Amount" type="number" step="0.01" min="0.01"
                                                max={invoice.due.value_in_cents/100} validations={{ required: true, min: 0.01, max: initialFields.max_amount}} />
                                <BaseForm.Input colSpan={6} name="currency" label={t('common.currency')} type="select" options={getCurrencyOptions()} required disabled />
                                <BaseForm.Input colSpan={12} name="paid_date" label={"Paid Date"} type="date" includeTime={true} required />
                                <BaseForm.Input colSpan={12} name="notes" label="Notes" type="textarea" height="120px"/>
                            </Row>
                    }
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <div className="d-flex flex-row align-items-end">
                        <SubmitButton>Add Payment</SubmitButton>
                    </div>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );

}

export default MarkInvoiceAsPaidModal;
