import '../../../App.scss';
import React, { useEffect, useState, useContext } from 'react';
import {serverPost, serverDelete, getUrl} from '../../../helpers/server';
import {BaseContext, UserContext} from '../../../helpers/common';
import AddOrEditWebhookModal from '../../../components/modals/AddOrEditWebhookModal';
import BaseContainer from '../../../components/BaseContainer';
import DeleteButton from '../../../components/DeleteButton';
import ContentContainer from '../../../components/ContentContainer';
import Section from '../../../components/Section';
import Label from '../../../components/Label';
import Button from '../../../components/common/buttons/Button';
import { useTranslation } from 'react-i18next';
import MapleTable from "../../../components/MapleTable";
import CopyableComponent from "../../../components/CopyableComponent";
import ContentBox from "../../../components/ContentBox";
import {CommandLineIcon} from "@heroicons/react/20/solid";
import EmptyState from "../../../components/EmptyState";
import BaseForm from "../../../components/BaseForm";
import Notification from "../../../components/Notification";
import {useNavigate} from "react-router-dom";
const _ = require('lodash');

function Webhooks() {
    const { t } = useTranslation('common');
    const { userInfo, isSuperUser } = useContext(UserContext);
    const navigate = useNavigate();
    const { company, getApiUrl, getCompanySpecificUrl, setPageTitle, hasAccess } = useContext(BaseContext);
    const [showAddOrEditWebhookModal, setShowAddOrEditWebhookModal] = useState(false);
    const [webhookToEdit, setWebhookToEdit] = useState(null);
    const [webhooks, setWebhooks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [initialFields, setInitialFields] = useState(null);
    const [addedWebhook, setAddedWebhook] = useState(null);

    const hasWritePermissions = hasAccess("developers", userInfo, "write");

    useEffect(() => {
        setPageTitle(`Webhooks`);
    }, [setPageTitle]);

    const fetchData = (skipCache=false) => {
        serverPost(getApiUrl("/webhooks/find"), {}, { skipCache: skipCache }).then((res) => {
            setWebhooks(res.results || []);
            setLoading(false);
        });
    }

    useEffect(() => {
        const enabledData = {
            enabled: {}
        }
        _.each(webhooks, (w) => {
            enabledData['enabled'][w.id] = w.enabled;
        })
        setInitialFields(enabledData)
    }, [webhooks]);

    useEffect(() => {
        fetchData();
    }, []);

    const onModalClose = () => {
        setShowAddOrEditWebhookModal(false);
        fetchData(true);
    }

    const onEditWebhook = (event, webhook) => {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        setShowAddOrEditWebhookModal(true);
        setWebhookToEdit(webhook);
    }

    const onCreateWebhook = () => {
        setShowAddOrEditWebhookModal(true);
        setWebhookToEdit(null);
    }

    const onDeleteWebhook = (webhook) => {
        serverDelete(getApiUrl(`/webhooks/${webhook.id}`)).then((res) => {
            fetchData(true);
        });
    }

    const onWebhookAdded = (webhook) => {
        onModalClose(true);
        setAddedWebhook(webhook);
    }

    const onFieldChange = (name, value) => {
        if (_.startsWith(name, "enabled.")) {
            const parts = name.split('.');
            const action = value ? "enable": "disable";
            serverPost(getApiUrl(`/webhooks/${parts[1]}/${action}`), {}).then((res) => {
                if (res) {
                    Notification.Success("Successfully updated");
                } else {
                    Notification.Danger("Unable to update webhook");
                }
            });
        }
    }

    const isEmpty = webhooks.length === 0;
    return (
        <BaseContainer>
            <ContentContainer>
                <Section title={t('webhooks.title')} loading={loading} variant="page"
                         subtitle={<CopyableComponent
                             className="thin"
                             value={`Company ID: ${company.id}`}
                             copyableValue={company.id}>
                         </CopyableComponent>}
                         actions={hasWritePermissions && !isEmpty && [{
                        icon: "fa-plus",
                        label: t('webhooks.create'),
                        onClick: () => onCreateWebhook()
                    }]}>
                    {
                        addedWebhook &&
                            <ContentBox>
                                <ContentBox.Body>
                                    <div className="body2">Newly added webhook - Keep your secret safe</div>
                                    <div className="body1">Save and store this secret securely, such as a password
                                        manager or secret store. You won’t be able to see it again.</div>
                                    <CopyableComponent value={addedWebhook.secret} />
                                </ContentBox.Body>
                            </ContentBox>
                    }
                    {
                        isEmpty ?
                            <EmptyState
                                icon={CommandLineIcon}
                                title={"No webhooks."}
                                subtitle={"Get started by creating a new one."}
                                hasWritePermissions={hasWritePermissions}
                                buttonLabel={"New Webhook"}
                                onClick={onCreateWebhook}
                            />
                        : <BaseForm initialFormFields={initialFields} onFieldChange={onFieldChange}>
                            <MapleTable>
                            <MapleTable.Content>
                                <thead>
                                <tr>
                                    <MapleTable.TH>Endpoint</MapleTable.TH>
                                    <MapleTable.TH>Description</MapleTable.TH>
                                    <MapleTable.TH>Events</MapleTable.TH>
                                    <MapleTable.TH className="whitespace-nowrap">Status</MapleTable.TH>
                                    <MapleTable.TH className="w-px whitespace-nowrap">Enabled</MapleTable.TH>
                                    <MapleTable.TH className="w-px whitespace-nowrap"></MapleTable.TH>
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                {
                                    _.map(webhooks, (row, i) =>
                                        <MapleTable.TR key={i} className={"cursor-pointer"} href={getCompanySpecificUrl(`/developers/webhooks/${row.id}`)}>
                                            <td>{ row.endpoint }</td>
                                            <td><span className="text-gray-500">{ row.description }</span></td>
                                            <td><span className="text-gray-500">{ row.events.length } events</span></td>
                                            <td><Label.Success>Active</Label.Success></td>
                                            <td className="w-px whitespace-nowrap">
                                                <BaseForm.SwitchGroup name={`enabled.${row.id}`} hideLabel formClassName="inline" />
                                            </td>
                                            <td className="text-end w-px whitespace-nowrap">
                                                <Button variant="text-primary" size="sm" onClick={(event) => onEditWebhook(event, row)} >
                                                    <i className="fa fa-edit"/>
                                                </Button>
                                                <DeleteButton size="sm" onDelete={() => onDeleteWebhook(row)} />
                                            </td>
                                        </MapleTable.TR>
                                    )
                                }
                                </tbody>
                            </MapleTable.Content>
                            </MapleTable>
                        </BaseForm>
                    }
                </Section>
            </ContentContainer>
            <AddOrEditWebhookModal show={showAddOrEditWebhookModal} onClose={onModalClose} onWebhookAdded={onWebhookAdded} itemToEdit={webhookToEdit} />
        </BaseContainer>
    );
}

export default Webhooks;
