import '../../App.scss';
import '../../css/modals.scss';
import BaseSideModal from './BaseSideModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../common/buttons/SubmitButton';
import React, { useContext, useState, useEffect } from 'react';
import { BaseContext } from '../../helpers/common';
import {serverFetch, serverPatch} from '../../helpers/server';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useGetMembers from "../../helpers/hooks/api/useGetMembers";
const _ = require("lodash");

function ChangeOwnerModal(props) {
    const { t } = useTranslation('common');
    const { getApiUrl } = useContext(BaseContext);
    const { members: teamMembers, fetchMembers } = useGetMembers(false);

    useEffect(() => {
        fetchMembers();
    }, [])

    const onSubmit = (data, errorHandler) => {
        serverPatch(getApiUrl(`/customers/${props.customer.id}`), data).then((res) => {
            if (res) {
                if (props.onClose) {
                    props.onClose(true)
                }
            }
        });
    }

    const teamMemberOptions = _.map(teamMembers, (member) => {
        return {
            value: member.id,
            label: member.user.name || member.user.email
        }
    })
    teamMemberOptions.unshift({ value: null, label: "No one" })

    return (
        <BaseSideModal {...props} >
            <BaseSideModal.Form onSubmit={onSubmit} initialFormFields={props.customer}>
                <BaseSideModal.Header title={"Change Owner"}/>
                <BaseSideModal.Body>
                    <Row>
                        <BaseForm.Input colSpan="12" name="owner_id" label="Owner" type="select"
                                        options={teamMemberOptions} showSearch={teamMembers.length > 5}/>
                    </Row>
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <SubmitButton>Update</SubmitButton>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );

}

export default ChangeOwnerModal;
