import '../App.scss';
import 'daterangepicker/daterangepicker';
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { BaseContext, currencyFormatFromPrice, downloadBlob, getPropsForMetric, UserContext } from '../helpers/common';
import { serverPost } from '../helpers/server';
import { Table } from 'react-bootstrap';
import Chart from './Chart';
import moment from 'moment';
import BaseForm from "./BaseForm";
import ContentBox from "./ContentBox";
import {useNavigate, useSearchParams} from "react-router-dom";
const _ = require("lodash");

function Metric(props) {
    const navigate = useNavigate();
    const { getApiUrl } = useContext(BaseContext);
    const [ searchParams ] = useSearchParams();
    let defaultFrequency = searchParams.get('f');
    let defaultGroupBy = searchParams.get('g');
    let defaultDateRangeLabel = searchParams.get('r');
    let defaultStartDate = searchParams.get('s');
    let defaultEndDate = searchParams.get('e');

    const [metricKey, setMetricKey] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [lastUpdated, setLastUpdated] = useState(null);
    const [fullData, setFullData] = useState(null);
    const [lines, setLines] = useState([]);
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    const [frequency, setFrequency] = useState("DAY");
    const [groupBy, setGroupBy] = useState(null);
    const [initialFields, setInitialFields] = useState(null);
    const [showBreakout, setShowBreakout] = useState(false);
    const [showGroupingOptions, setShowGroupingOptions] = useState(true);
    const [dateRangeLabel, setDateRangeLabel] = useState(null);

    useEffect(() => {
        if (!props.saveUrl) {
            return;
        }
        if (defaultFrequency) {
            setFrequency(defaultFrequency.toUpperCase());
        }
        if (defaultGroupBy) {
            setGroupBy(defaultGroupBy.toUpperCase());
            setInitialFields(prevFields => {
                const newFields = {...prevFields};
                newFields.groupBy = defaultGroupBy.toUpperCase();
                return newFields;
            })
        }
        if (defaultDateRangeLabel) {
            setDateRangeLabel(defaultDateRangeLabel)
        }
        if (defaultStartDate) {
            setStartDate(moment(defaultStartDate))
        }
        if (defaultEndDate) {
            setEndDate(moment(defaultEndDate))
        }
    }, [defaultFrequency, defaultGroupBy, defaultDateRangeLabel, defaultStartDate, defaultEndDate, props.saveUrl]);

    useEffect(() => {
        if (props.defaultFrequency) {
            setFrequency(props.defaultFrequency);
        }
    }, [props.defaultFrequency]);

    useEffect(() => {
        setMetricKey(props.metricKey);
        setChartData(null)

        const newMetricProps = getPropsForMetric(props.metricKey);
        const newShowGrouping = _.isNil(newMetricProps.showGrouping) ? false: newMetricProps.showGrouping;
        if (!newShowGrouping && !_.isNil(groupBy)) {
            setGroupBy(null);
            setInitialFields({'groupBy': null})
        }
    }, [props.metricKey])

    useEffect(() => {
        setShowBreakout(props.showBreakout);
    }, [props.showBreakout]);

    useEffect(() => {
        if (!_.isNil(props.showGroupingOptions) && !_.isUndefined(props.showGroupingOptions)) {
            setShowGroupingOptions(props.showGroupingOptions);
        }
    }, [props.showGroupingOptions]);

    useEffect(() => {
        if (_.isNil(metricKey)) {
            return;
        }
        const reportData = {
            metric: metricKey,
            period: {
                start_date: startDate.utcOffset(0, true),
                end_date: endDate.utcOffset(0, true)
            },
            frequency: frequency,
            grouping: groupBy
        }
        let url = getApiUrl(`/reports/cached_metrics`);
        if (props.dontCache) {
            url = getApiUrl(`/reports/metrics`);
        }
        serverPost(url, reportData).then((res) => {
            if (props.dontCache) {
                setChartData(res);
            } else {
                setChartData(res.metrics);
            }
            setLastUpdated(moment(res.last_updated).format("MMM DD, h:mm:ssa"));
        });
    }, [metricKey, startDate, endDate, frequency, groupBy])

    useEffect(() => {
        const fData = [chartData];
        const baseline = { key: "value", label: metricProps.yLabel, type: metricProps.type,
            getValue: (d) => d["value"], visible: groupBy === null
        }
        let groupingLines = [];
        const hasGrouping = chartData && _.some(chartData, (c) => !_.isNil(c.grouping));
        if (chartData && hasGrouping) {
            const allGroupingKeys = _.uniq(_.flatMap(chartData, (c) => _.isNil(c.grouping) ? []: _.keys(c.grouping)))

            // const keys = _.keys(chartData[0].grouping);
            _.each(allGroupingKeys, (key, i) => {
                const gg = _.find(chartData, (c) => !_.isNil(c.grouping[key])).grouping[key];
                groupingLines.push({ key: `grouping.${key}`, label: gg.name, type: metricProps.type,
                    visible: true, getValue: (d) => {
                        if (d["grouping"][key]) {
                            return d["grouping"][key].value
                        } else {
                            if (metricProps.type === "currency") {
                                return { value_in_cents: 0 }
                            } else {
                                return 0
                            }
                        }
                    }
                })
                fData.push(chartData);
            })
        }
        setLines([baseline, ...groupingLines]);
        setFullData(fData);
    }, [chartData])

    const metricProps = getPropsForMetric(metricKey);

    const exportCSVData = () => {
        const reportData = {
            metric: metricKey,
            period: {
                start_date: startDate.utcOffset(0, true),
                end_date: endDate.utcOffset(0, true)
            },
            frequency: frequency,
            grouping: groupBy
        }
        serverPost(getApiUrl(`/reports/metrics/export`), reportData, { noJson: true }).then((res) => {
            downloadBlob(res, `Metrics-${metricProps.yLabel}-${startDate.utcOffset(0, true).format("YYYY-MM-DD")}-${endDate.utcOffset(0, true).format("YYYY-MM-DD")}.csv`);
        });
    }

    let breakoutRowNames = [];
    let hasBreakoutData = false;
    if (chartData && chartData.length > 0 && chartData[0].breakout && !_.isEmpty(chartData[0].breakout)) {
        breakoutRowNames = _.keys(chartData[0].breakout)
        hasBreakoutData = true;
    }
    const nameMap = {
        "NEW_ACTIVATION": "New",
        "VOLUNTARY_CHURN": "Churn",
        "INVOLUNTARY_CHURN": "IChurn",
        "GROWTH": "Expansion/Contraction",
        "UPGRADE": "Upgrade",
        "DOWNGRADE": "Downgrade"
    }

    const getValueForMetric = (value) => {
        if (metricProps.type === "currency") {
            return currencyFormatFromPrice(value);
        } else if (metricProps.type === "percent") {
            return `${value}%`;
        }
        return value;
    }
    const getDefaultValueForMetric = () => {
        if (metricProps.type === "currency") {
            return currencyFormatFromPrice({ value_in_cents: 0, currency: "USD" });
        } else if (metricProps.type === "percent") {
            return `0%`;
        }
        return 0;
    }
    const getLabelForBreakoutPeriod = (period) => {
        if (frequency === "DAY") {
            const start = moment(period.start_date).utc();
            return start.format("MMM D");
        } else if (frequency === "WEEK") {
            const start = moment(period.start_date).utc();
            const end = moment(period.end_date).utc();
            return `${start.format("M/D")}-${end.format("M/D")}`;
        } else if (frequency === "MONTH") {
            const start = moment(period.start_date).utc();
            return start.format("MMM'YY");
        } else {
            return "";
        }
    }

    const onFieldChange = (name, value) => {
        if (name === "groupBy") {
            updateUrl('g', value);
            setGroupBy(value);
        }
    }

    const onFrequencyChange = (freq) => {
        updateUrl('f', freq);
        setFrequency(freq);
    }

    const updateUrl = (key, value) => {
        if (!props.saveUrl) {
            return;
        }
        let urlParser = new URL(window.location.href);
        if (value) {
            urlParser.searchParams.set(key, value.toLowerCase());
        } else {
            urlParser.searchParams.delete(key);
        }
        navigate(urlParser.pathname + urlParser.search, { replace: true });
    }

    const defaultGroupByOptions = [
        { label: "None", value: null },
        { label: "Products", value: "PRODUCT" },
        { label: "Plans", value: "PRICING" },
        { label: "Product tags", value: "PRODUCT_TAGS" },
        { label: "Plan tags", value: "PRICING_TAGS" },
    ]

    let defaultDateRange = props.defaultDateRange;
    if (dateRangeLabel) {
        defaultDateRange = _.startCase(_.toLower(dateRangeLabel));
    }
    return (
        <ContentBox>
            <ContentBox.Body>
                {
                    showGroupingOptions && metricProps.showGrouping &&
                        <div className="metrics-comparison-header">
                            <span className="body2">Group By</span>
                            <BaseForm initialFormFields={initialFields} onFieldChange={onFieldChange}>
                                <BaseForm.SelectGroup name="groupBy" options={metricProps.groupings || defaultGroupByOptions} disabled={!metricProps.showGrouping}
                                                      showSearch={false} formClassName="thin" borderless/>
                            </BaseForm>
                        </div>
                }
                <Chart
                    xKey={(d) => d.period}
                    data={fullData}
                    onDatesChange={(start, end, label) => {
                        updateUrl('r', label);
                        if (label === "Custom Range") {
                            updateUrl('s', start.format());
                            updateUrl('e', end.format());
                        } else {
                            updateUrl('s', null);
                            updateUrl('e', null);
                        }
                        setStartDate(start);
                        setEndDate(end);
                    }}
                    onFrequencyChange={onFrequencyChange}
                    frequency={frequency}
                    defaultDateRange={defaultDateRange || 'This Month'}
                    lines={lines}
                    exportCSV={!props.hideExport && exportCSVData}
                    maxDate={!props.noMaxDate && moment().format("MM/DD/YYYY")}
                    hideFrequencySelection={props.hideFrequencySelection}
                    {...metricProps}
                    showCustomRangeLabel={props.showCustomRangeLabel}
                    chartType={props.chartType}
                    lastUpdated={lastUpdated}
                    customDateRanges={props.customDateRanges}
                />
            </ContentBox.Body>
            {
                showBreakout && hasBreakoutData &&
                    <div className="breakout-table-wrapper">
                        <Table>
                            <thead>
                                <tr>
                                    <th className="sticky-col no-stretch">Breakout</th>
                                    {
                                        _.map(chartData, (d, i) =>
                                            <th key={i} className="no-stretch">{ getLabelForBreakoutPeriod(d.period) }</th>
                                        )
                                    }
                                </tr>
                            </thead>
                            <tbody>
                            {
                                _.map(breakoutRowNames, (name, j) =>
                                    <tr key={j}>
                                        <td className="sticky-col no-stretch"><span className="body2">{nameMap[name]}</span></td>
                                        {
                                            _.map(chartData, (d, k) =>
                                                <td key={k} className="no-stretch">
                                                    { _.has(d.breakout, name) ? getValueForMetric(d.breakout[name]): getDefaultValueForMetric() }
                                                </td>
                                            )
                                        }
                                    </tr>
                                )
                            }
                            </tbody>
                        </Table>
                    </div>
            }
        </ContentBox>
    )
}

export default Metric;
