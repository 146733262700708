import '../../../App.scss';
import React, { useEffect, useState, useContext } from 'react';
import {serverPost, serverDelete} from '../../../helpers/server';
import {BaseContext, UserContext} from '../../../helpers/common';
import MoreButton from '../../../components/common/buttons/MoreButton';
import MapleTable from '../../../components/MapleTable';
import ArchiveButton from '../../../components/common/buttons/ArchiveButton';
import Notification from '../../../components/Notification';
import Section from '../../../components/Section';
import ContentContainer from '../../../components/ContentContainer';
import Button from '../../../components/common/buttons/Button';
import { useTranslation } from 'react-i18next';
import ExistingContractTemplates from "../../../components/ExistingContractTemplates";
import Link from "../../../components/Link";
import CopyableComponent from "../../../components/CopyableComponent";
const _ = require('lodash');

function ContractTemplates() {
    const { t } = useTranslation('common');
    const { getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const [loading, setLoading] = useState(true);
    const [templates, setTemplates] = useState([]);

    useEffect(() => {
        setPageTitle(`Contract Settings`);
    }, []);

    useEffect(() => {
        fetchData(true);
    }, []);

    const fetchData = (skipCache=false) => {
        serverPost(getApiUrl("/proposals/templates/find"), {}).then((res) => {
            setTemplates(res);
            setLoading(false);
        });
    }

    const deleteTemplate = (template) => {
        serverDelete(getApiUrl(`/proposals/templates/${template.id}`)).then((res) => {
            Notification.Success("Template successfully deleted");
            fetchData(true);
        })
    }

    const onTemplateActionSelected = (template) => {
        const templateData = {
            name: `${template.name} - Copy`,
            content: template.content
        }
        serverPost(getApiUrl('/proposals/templates'), templateData).then((res) => {
            if (res) {
                fetchData();
            }
        })
    }

    const templateActionOptions = [
        { id: "duplicate", label: "Duplicate" }
    ]

    return (
        <>
            <ContentContainer>
                <Section
                    title="Contract Templates"
                    className={"mt-4"}
                    loading={loading}
                    actions={[{
                        variant: "primary",
                        icon: "fa-plus",
                        label: "Create Template",
                        link: getCompanySpecificUrl("/contract/template/create")
                    }]}>
                    <MapleTable>
                        <MapleTable.Content overflow>
                            <thead>
                                <tr>
                                    <MapleTable.TH>Name</MapleTable.TH>
                                    <MapleTable.TH></MapleTable.TH>
                                    <MapleTable.TH></MapleTable.TH>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                            {
                                _.map(templates, (row, i) =>
                                     <tr key={i}>
                                        <td>{ row.name }</td>
                                         <td><CopyableComponent value={row.id}/></td>
                                        <td className="flex flex-row items-center justify-end">
                                            <Link href={getCompanySpecificUrl(`/contract/template/edit/${row.id}`)}>
                                                <Button variant="text-primary" size="sm">Edit</Button>
                                            </Link>
                                            <ArchiveButton size="md" onDelete={() => deleteTemplate(row)}></ArchiveButton>
                                            <MoreButton items={templateActionOptions} onSelect={() => onTemplateActionSelected(row)} />
                                        </td>
                                     </tr>
                                )
                            }
                            </tbody>
                        </MapleTable.Content>
                    </MapleTable>
                </Section>
                <hr className="mt-4"/>
                <ExistingContractTemplates
                    className="mt-3"
                    title={"Or select an existing template"}
                    showAddNew={false}
                    templates={templates}
                />
            </ContentContainer>
        </>
    );
}

export default ContractTemplates;
