import '../../App.scss';
import '../../css/modals.scss';
import React, { useContext } from 'react';
import BaseForm from '../BaseForm';
import { IntegrationContext } from '../../helpers/common';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const _ = require('lodash');

function AddOrEditEmailSenderAddressModal(props) {
	const { t } = useTranslation('common');
	const { integration } = useContext(IntegrationContext);

	const cleanupFields = (fields) => {
		if (props.itemToEdit && !props.itemToEdit.editable) {
			delete fields.type;
		}
		return fields;
	};

	return (
		<BaseAddOrEditItemModal
			{...props}
			processUpdateFields={cleanupFields}
			itemLabel={'Sender Address'}
			itemBaseUrl={`/integrations/${integration.id}/custom_email_domain/sender_signatures`}
		>
			<Row>
				<BaseForm.Input colSpan='12' name='email' label={'Email'} type='text' transformations={["lowercase", "trim"]} validations={{ required: true, validEmail: true }} />
				<BaseForm.Input colSpan='12' name='name' label={'Name'} type='text' required/>
			</Row>
		</BaseAddOrEditItemModal>
	);
}

export default AddOrEditEmailSenderAddressModal;
