import {ExclamationTriangleIcon} from "@heroicons/react/24/outline";
import {XMarkIcon} from "@heroicons/react/20/solid";
import React, {useEffect, useState} from "react";

function WarningBanner(props) {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    return (
        show &&
        <div className="flex items-center gap-x-6 bg-yellow-300 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
            <div className="flex flex-1 justify-start">
                <ExclamationTriangleIcon className="size-7 text-black"/>
            </div>
            <p className="text-sm/6 text-black font-semibold">
                {props.body ? props.body : ""}
            </p>
            <div className="flex flex-1 justify-end">
                <button type="button" className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
                        onClick={() => props.onClose()}>
                    <span className="sr-only">Dismiss</span>
                    <XMarkIcon aria-hidden="true" className="size-5 text-black"/>
                </button>
            </div>
        </div>
    )
}

export default WarningBanner;