import '../App.scss';
import React from 'react';
import BaseLeftNav from '../components/BaseLeftNav';
import BarChartSquare02 from "@untitled-ui/icons-react/build/esm/BarChartSquare02";
import Play from "@untitled-ui/icons-react/build/esm/Play";
import Cube01 from "@untitled-ui/icons-react/build/esm/Cube01";

const iconSize = {
    width: 20,
    height: 20,
};

function SuperLeftNav(props) {
    const items = [
        { label: "Dashboard", link: "/internal/super/dashboard", icon: <BarChartSquare02 {...iconSize} /> },
        { label: "Tasks", link: "/internal/super/tasks", icon: <Cube01 {...iconSize} /> },
        { label: "Playground", link: "/internal/playground", icon: <Play {...iconSize} /> },
    ];

    return (
        <BaseLeftNav items={items} {...props} />
    );
}

export default SuperLeftNav;
