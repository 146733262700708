import {useTranslation} from "react-i18next";
import {useContext} from "react";
import {BaseContext} from "../../helpers/common";
import BaseSideModal from "./BaseSideModal";
import {Row} from "react-bootstrap";
import BaseForm from "../BaseForm";
import SubmitButton from "../common/buttons/SubmitButton";

function SignOfflineModal(props) {
    const { t } = useTranslation('common');
    const { getApiUrl } = useContext(BaseContext);

    const renderFileUpload = () => {
        const label = props.required ? "Signed Contract" : "Signed Contract (Optional)";

        return (
            <BaseForm.Input
                colSpan={12}
                name="file"
                label={label}
                type="file"
                fileType="contractUploads"
                required={props.required}
                accept={"image/*,application/pdf"}
                hidePreview={true}
            />
        )
    }

    const renderUploadText = () => {
         return props.required
            ? (<span className="text-sm">Upload the signed document below.</span>)
            : (<span className="text-sm">You can optionally upload the signed document below.</span>)
    }

    return (
        <BaseSideModal {...props} >
            <BaseSideModal.Form onSubmit={props.onSubmit}>
                <BaseSideModal.Header title={props.title || "Mark Contract as Signed Offline"}/>
                <BaseSideModal.Body>
                    <div>
                        <span className="text-sm">Please confirm that the document has been signed by all the relevant signatories.</span><br/>
                        {renderUploadText()}<br/><br/>
                        {props.hasAnyoneSigned &&
                            <div>
                            <span className="text-sm"><strong>Note:</strong> Some signatories have already signed. Their signatures will be invalidated.</span><br/><br/>
                            </div>}
                    </div>
                    <Row>
                        {
                            renderFileUpload()
                        }
                    </Row>
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <SubmitButton>{props.submitLabel || "Confirm"}</SubmitButton>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );
}

export default SignOfflineModal;
