import '../App.scss';
import '../js/forms.js';
import Label from './Label';
import { Link } from 'react-router-dom';
import React, { useEffect, useState, useMemo, useCallback, useContext } from 'react';
import classnames from 'classnames';
import ChevronDown from '@untitled-ui/icons-react/build/esm/ChevronDown';
import ChevronUp from '@untitled-ui/icons-react/build/esm/ChevronUp';
import { UserContext, BaseContext } from '../helpers/common';
import Profile from './layout/profile/Profile.js';
const _ = require('lodash');

function BaseLeftNav(props) {
	const userContext = useContext(UserContext);
	const baseContext = useContext(BaseContext);
	const { userInfo } = !_.isNil(userContext) ? userContext : { userInfo: {} };
	const { company } = !_.isNil(baseContext) ? baseContext : { company: null };

	const [shouldAutoMatch, setShouldAutoMatch] = useState(true);

	const isItemActive = useCallback(
		(item) => {
			if (item.active) {
				return item.active;
			} else if (shouldAutoMatch) {
				if (item.matchPathnameOnly) {
					return item.link === window.location.pathname;
				} else {
					return item.link === window.location.pathname + window.location.search;
				}
			} else {
				return false;
			}
		},
		[shouldAutoMatch],
	);

	const hasActiveSubItem = useCallback(
		(item) => {
			return item.items.findIndex((si) => isItemActive(si));
		},
		[isItemActive],
	);

	const openedSubMenu = useMemo(() => {
		const openedSubMenuIndex = props.items.findIndex((item, i) => {
			if (!item) return false;
			if (!('items' in item)) return false;

			const activeSubMenu = hasActiveSubItem(item);
			if (activeSubMenu !== -1) {
				return true;
			}
			return false;
		});
		return openedSubMenuIndex;
	}, [hasActiveSubItem, props.items]);

	const [subMenuOpen, setSubMenuOpen] = useState(openedSubMenu !== -1 ? openedSubMenu : null);

	useEffect(() => {
		if (!_.isNil(props.shouldAutoMatch)) {
			setShouldAutoMatch(props.shouldAutoMatch);
		}
	}, [props.shouldAutoMatch]);

	return (
		<div id='left-nav' className={classnames('left-nav', 'noprint', props.className)}>
			<div>
				<div className='nav-header'>
					<div className='nav-header-logo' />
				</div>
				<div className='nav-items'>
					{props.items.map((item, i) => {
						return (
							item && (
								<MenuItem
									key={i}
									label={item.label}
									icon={item.icon}
									link={item.hasSubMenu ? null : item.link}
									active={!item.hasSubMenu && isItemActive(item)}
									isSubmenuActive={openedSubMenu === i}
									showSubMenu={subMenuOpen === i}
									onParentMenuClick={() => setSubMenuOpen(subMenuOpen === i ? null : i)}
									hasSubMenu={item.hasSubMenu}
								>
									{item.hasSubMenu && (
										<div
											className={classnames(
												'sub-items',
												hasActiveSubItem(item) !== -1 ? 'show' : '',
											)}
										>
											{item.items.map((subItem, j) => {
												return (
													subItem && (
														<MenuItem
															key={j}
															label={subItem.label}
															link={subItem.link}
															active={isItemActive(subItem)}
															tag={subItem.tag}
														/>
													)
												);
											})}
										</div>
									)}
								</MenuItem>
							)
						);
					})}
				</div>
			</div>
			<div className='nav-footer'>
				<Profile userInfo={userInfo} company={company} />
			</div>
		</div>
	);
}

function MenuItem(props) {
	const MenuLink = ({ children }) => {
		return (
			<Link className='nav-items-link' to={props.link} onClick={(e) => e.stopPropagation()}>
				{children}
			</Link>
		);
	};

	const ParentMenu = ({ children }) => {
		return (
			<div
				className='parent-menu'
				onClick={() => {
					props.onParentMenuClick();
				}}
			>
				{children}
			</div>
		);
	};

	const activeClass = props.active ? 'active' : '';
	const openClass = props.isSubmenuActive ? 'open' : '';

	const ParentComponent = props.hasSubMenu ? ParentMenu : MenuLink;

	return (
		<ParentComponent>
			<div className={classnames('nav-item-container', openClass, activeClass)}>
				<div className={classnames('nav-item')}>
					<div className='nav-item-logo'>{props.icon}</div>
					<div className='nav-title'>
						<span className='text-base'>{props.label}</span>
						{props.tag && <Label.Info className='text-sm !py-0 ml-2 opacity-80'>{props.tag}</Label.Info>}
					</div>
					{!_.isEmpty(props.children) && (
						<div className='nav-item-chevron'>
							{props.showSubMenu ? (
								<ChevronUp width={16} height={16} />
							) : (
								<ChevronDown width={16} height={16} />
							)}
						</div>
					)}
				</div>
			</div>
			{props.showSubMenu && <>{props.children}</>}
		</ParentComponent>
	);
}

export default BaseLeftNav;
