import '../App.scss';
import React, { useContext } from 'react';
import { Image } from 'react-bootstrap';
import Button from './common/buttons/Button';
import { renderComponents, UserContext } from '../helpers/common';
import classnames from 'classnames';
const _ = require('lodash');

function ContentContainer(props) {
	const userContext = useContext(UserContext);
	const { isMapleUser } = !_.isNil(userContext) ? userContext : { isMapleUser: false };

	return (
		<div className={classnames('content-container', props.className)}>
			{(props.title || props.actions) && (
				<div
					className={classnames('content-header d-flex flex-row gap-2', {
						'content-header-update': isMapleUser,
					})}
				>
					{props.logo && (
						<div className='logo align-self-center'>
							<Image src={props.logo} />
						</div>
					)}
					<div className='title align-self-center'>
						<h3
							className={classnames({
								'title-update': isMapleUser && props.variant !== 'page',
								'title-update-page': isMapleUser && props.variant === 'page',
							})}
						>
							{props.title}
						</h3>
						{props.subtitle && <div className='mt-1 text-sm text-gray-700'>{props.subtitle}</div>}
					</div>
					<div className='flex-grow-1' />
					<div className='flex-grow-0 flex-shrink-0 align-self-center'>
						{props.actions && (
							<>
								{_.map(props.actions, (action, i) => (
									<Button key={i} variant={action.variant} onClick={action.onClick}>
										{action.icon && (
											<span>
												<i className={classnames('fa', action.icon)} />
												&nbsp;
											</span>
										)}
										{action.label}
									</Button>
								))}
							</>
						)}
						{props.customActions && <>{renderComponents(props.customActions)}</>}
					</div>
				</div>
			)}
			<div className={classnames('content-body', props.bodyClassName)}>{props.children}</div>
		</div>
	);
}

export default ContentContainer;
