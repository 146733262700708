import '../App.scss';
import '../css/invoice.scss';
import React, { useEffect, useState, useContext, createRef } from 'react';
import { serverFetch, serverPatch, notifyEvent } from '../helpers/server';
import { BaseContext, IntegrationContext } from '../helpers/common';
import Loader from './Loader';
import ContentBox from './ContentBox';
import BaseForm from './BaseForm';
import {Table, Container, Row, Col} from 'react-bootstrap';
import Button from './common/buttons/Button';
import SubmitButton from "./common/buttons/SubmitButton";
const _ = require('lodash');

function IntegrationSelectAccountingBankAccount(props) {
    const { getApiUrl } = useContext(BaseContext);
    const { integration } = useContext(IntegrationContext);

    const [loading, setLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [bankAccounts, setBankAccounts] = useState([]);

    useEffect(() => {
        serverFetch(getApiUrl(`/integrations/${integration.id}/accounting/bank_accounts`), { skipCache: true }).then((res) => {
            if (res) {
                setBankAccounts(res.accounts);
            }
            setLoading(false);
        })
    }, [])

    const onAccountingAccountSelect = (data) => {
        const updateData = {
            feature_data: {
                accounting_data: {
                    selected_bank_account: data.selected_bank_account,
                }
            }
        }
        serverPatch(getApiUrl(`/integrations/${integration.id}`), updateData).then(res => {
            if (res) {
                setIsEditing(false);
                notifyEvent('integration_settings');
                if (props.onUpdate) {
                    props.onUpdate();
                }
            }
        })
    }

    const bankAccountOptions = _.map(bankAccounts, (acc) => {
        return {
            value: acc.id,
            label: acc.name
        }
    });

    const selectedBankAccountId = integration.accounting_data && integration.accounting_data.selected_bank_account;
    const selectedBankAccount = selectedBankAccountId && _.find(bankAccounts, b => b.id === selectedBankAccountId);
    return (
        <ContentBox id="select-bank-account">
            <ContentBox.Body>
                <div className="d-flex flex-row gap-3">
                    <div className="flex-grow-1">
                        <h5>Select { integration.name } Clearing Bank Account</h5>
                    </div>
                    <div className="flex-shrink-0 d-flex align-items-center">
                        {
                            isEditing ?
                                <Button variant="text-danger" onClick={() => setIsEditing(false)}>Cancel</Button>
                                : <Button variant="text-primary" onClick={() => setIsEditing(true)}><i className="fa fa-edit" /> Edit</Button>
                        }
                    </div>
                </div>
                {
                    isEditing ?
                        <Loader loading={loading}>
                            <Container>
                                <Row>
                                    <Col md="8">
                                        <div className="mt-2">
                                            <BaseForm initialFormFields={integration.accounting_data} onSubmit={onAccountingAccountSelect}>
                                                <BaseForm.Input name="selected_bank_account" label="Bank Account" type="select" options={bankAccountOptions} showSearch={false}/>
                                                <SubmitButton>Select</SubmitButton>
                                            </BaseForm>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Loader>
                        : <div className="flex flex-row gap-2">
                            <span className="text-sm font-semibold text-gray-700">Clearing Bank Account:</span>
                            <span className="text-sm font-normal text-gray-500">{ selectedBankAccount && selectedBankAccount.name }</span>
                        </div>
                }
            </ContentBox.Body>
        </ContentBox>
    );
}

export default IntegrationSelectAccountingBankAccount;
