import '../App.scss';
import React, {useState, useEffect, useContext} from 'react';
import { ProgressBar } from 'react-bootstrap';
import Button from './common/buttons/Button';
import classnames from 'classnames';
import {BaseContext, currencyFormatFromPrice} from "../helpers/common";
import {serverPost} from "../helpers/server";
import moment from 'moment';
import EditGoalModal from "./modals/EditGoalModal";
const _ = require("lodash");

function ErrorComponent(props) {
    const { getApiUrl } = useContext(BaseContext);
    const [loading, setLoading] = useState(true);
    const [mrrData, setMrrData] = useState(null);
    const [showData, setShowData] = useState(false);
    const [showEditGoalModal, setShowEditGoalModal] = useState(false);
    const [targetValue, setTargetValue] = useState(null);
    const [newValue, setNewValue] = useState(null);
    const [progress, setProgress] = useState(0);
    const [timelineProgress, setTimelineProgress] = useState(50);
    const [variant, setVariant] = useState("success");

    useEffect(() => {
        const metricData = {
            metric: "MRR",
            period: {
                start_date: moment().startOf('month').add(-1, 'month').utcOffset(0, true),
                end_date: moment().endOf('month').utcOffset(0, true)
            },
            frequency: "MONTH"
        }
        serverPost(getApiUrl(`/reports/metrics`), metricData).then((res) => {
            setLoading(false);
            setMrrData(res);
            processGoal(res);
        });
    }, [])

    const processGoal = (data) => {
        const goal = JSON.parse(localStorage.getItem("goal")) || { 'percent': 10 };

        const oldValue = data[0].value;
        const tValue = { value_in_cents: oldValue.value_in_cents * goal.percent/100, currency: oldValue.currency };
        setTargetValue(tValue);
        const nValue = { value_in_cents: data[1].value.value_in_cents - oldValue.value_in_cents, currency: data[1].value.currency };
        setNewValue(nValue);
        const pp = (nValue.value_in_cents / tValue.value_in_cents * 100) || 0;
        setProgress(pp);
        const tp = (moment().get() - moment().startOf('month').get())/(moment().endOf('month').get() - moment().startOf('month').get())*100;
        setTimelineProgress(tp);
        setVariant(pp >= tp ? "success": "danger")
        setShowData(goal.show === true);
    }

    const onModalClose = () => {
        setShowEditGoalModal(false);
        processGoal(mrrData);
    }

    if (!showData || loading) {
        return null;
    }

    return (
        <>
            <div className="goal-progress content-box">
                <div className={classnames("goal-left", variant)}>
                    <span><i className="fa fa-flag" /></span>
                    <span className="body2">MRR Goal</span>
                </div>
                <div className="goal-middle">
                    <span className={classnames("", variant + "-color")}>
                        <span className="body2">{ newValue && currencyFormatFromPrice(newValue) }</span>
                        <span className="caption"> ({ progress.toFixed(2) }%)</span>
                    </span>
                    <ProgressBar>
                        <ProgressBar variant={variant} now={progress} />
                        {
                            timelineProgress > progress &&
                                <ProgressBar variant="info" now={timelineProgress-progress} />
                        }
                    </ProgressBar>
                    <br/>
                </div>
                <div className="align-self-center d-flex flex-column align-items-center">
                    <h4 className={classnames("", variant + "-color")}>
                        { targetValue && currencyFormatFromPrice(targetValue) }
                    </h4>
                </div>
                <div className="goal-right">
                    <Button variant="text" onClick={() => setShowEditGoalModal(true)}><i className="fa fa-edit" /> Edit</Button>
                </div>
            </div>
            <EditGoalModal show={showEditGoalModal} onClose={onModalClose} />
        </>
    );
}

export default ErrorComponent;
