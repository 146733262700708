import '../../App.scss';
import '../../css/modals.scss';
import BaseSideModal from './BaseSideModal';
import React, { useContext, useState, useEffect } from 'react';
import {
    BaseContext,
    currencyFormatFromPrice,
    getErrorMessageForTaxCalculationErrors,
    percentageFormat
} from '../../helpers/common';
import Button from '../common/buttons/Button';
import { useTranslation } from 'react-i18next';
import MapleTable from "../MapleTable";
import Label from "../Label";
import Link from "../Link";
import moment from 'moment';
import {serverPost} from "../../helpers/server";
import BaseForm from "../BaseForm";
import SubmitButton from "../common/buttons/SubmitButton";
import InfiniteScroll from "react-infinite-scroll-component";
import Columns from "../Columns";
import CopyableComponent from "../CopyableComponent";
import Section from "../Section";
import Loader from "../Loader";
const _ = require("lodash");

function CustomerObjectsModal(props) {
    const { t } = useTranslation('common');
    const { getCompanySpecificUrl, getApiUrl, company } = useContext(BaseContext);
    const [initialFields, setInitialFields] = useState({});
    const [customer, setCustomer] = useState(null);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [originalObjects, setOriginalObjects] = useState([]);
    const [billableItems, setBillableItems] = useState([]);
    const [objects, setObjects] = useState([]);
    const [loadingItems, setLoadingItems] = useState(true);

    const [fromKey, setFromKey] = useState(null);
    const [hasMore, setHasMore] = useState(false);
    const [sort, setSort] = useState("createdAtDesc");

    useEffect(() => {
        setCustomer(props.customer)
    }, [props.customer])

    useEffect(() => {
        if (props.show) {
            onSearch(true);

            serverPost(getApiUrl("/billable/items/find"), {}).then((res) => {
                setBillableItems(_.filter(res, (r) => !r.standard));
            });
        }
    }, [props.show])

    useEffect(() => {
        if (_.isEmpty(originalObjects) || _.isEmpty(billableItems)) {
            setObjects([]);
            return;
        }
        const processedObjects = [...originalObjects];
        _.each(processedObjects, (o) => {
            o.item = _.find(billableItems, (i) => i.id === o.item_id);
        })
        setObjects(processedObjects);
    }, [originalObjects, billableItems])

    useEffect(() => {
        setLoadingItems(true);
        onSearch(true);
    }, [selectedItemId]);

    const onFieldChange = (name, value) => {
        if (name === "sort") {
            setSort(value);
        } else if (name === "item_id") {
            setSelectedItemId(value);
        }
    }

    const onSearch = (restart = true) => {
        if (!selectedItemId || _.isNil(customer) || _.isEmpty(customer)) {
            return;
        }
        const limit = 50;
        const params = {
            company_id: company.id,
            sort_key: sort,
            pagination: {
                from_key: restart ? null: fromKey,
                limit: limit
            },
            query: {
                customer_id: customer && customer.id,
                item_id: selectedItemId
            }
        }
        serverPost(getApiUrl("/objects/find"), params).then((res) => {
            if (res) {
                const results = res.results || [];
                if (restart) {
                    setOriginalObjects(results);
                } else {
                    setOriginalObjects(_.concat(objects, results));
                }
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
            setLoadingItems(false);
        });
    }

    const billableItemOptions = _.map(billableItems, (bi) => {
        return { value: bi.id, label: bi.name }
    })

    return (
        <BaseSideModal {...props} size="lg">
            <BaseSideModal.Form initialFormFields={initialFields}>
                <BaseSideModal.Header title={"Customer - Objects"}/>
                <BaseSideModal.Body>
                    <Section>
                        {
                            !loadingItems && _.isEmpty(billableItems) &&
                            <p>In order to start managing objects, you will have create a object-based item first. <Link to={getCompanySpecificUrl("/billable_items")}>Get started here.</Link></p>
                        }
                        <p className="text-sm">
                            View all the objects attached to the customer. To learn more about managing objects, please
                            refer to the <a href="https://maplebilling.readme.io/reference/create_object">documentation</a>.
                        </p>
                        <BaseForm onFieldChange={onFieldChange} className="mt-3">
                            <BaseForm.Input colSpan="3" type="select" name="item_id" label={"Item"} options={billableItemOptions} showSearch={false}/>
                        </BaseForm>
                        <Loader loading={loadingItems}>
                        <InfiniteScroll
                            dataLength={objects.length}
                            next={() => onSearch(false)}
                            hasMore={hasMore}
                            scrollableTarget="content-wrapper"
                        >
                            {
                                _.isEmpty(objects) ?
                                    <span className="text-sm text-gray-500">No objects found for this item.</span>
                                    : <MapleTable>
                                        <MapleTable.Content>
                                            <thead>
                                            <tr>
                                                <th>Identifier</th>
                                                <th className="!pl-0">Properties</th>
                                            </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200">
                                            {
                                                _.map(objects, (row, i) =>
                                                    <tr key={i} className="cursor-pointer">
                                                        <td className="w-px whitespace-nowrap">
                                                            <div className="flex flex-col gap-2">
                                                                <p className="text-sm text-gray-500">{ row.object_identifier }</p>
                                                            </div>
                                                        </td>
                                                        <td className="!pl-0">
                                                            <code className="text-xs break-all">{ JSON.stringify(row.properties) }</code>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            {
                                                hasMore &&
                                                <tr>
                                                    <td colSpan={2} className="text-center">
                                                        <div className="spinner-border text-secondary"/>
                                                    </td>
                                                </tr>
                                            }
                                            </tbody>
                                        </MapleTable.Content>
                                    </MapleTable>
                            }
                        </InfiniteScroll>
                        </Loader>
                    </Section>
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <Button onClick={() => props.onClose()}>Close</Button>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );

}

export default CustomerObjectsModal;
