import React, {useEffect, useState} from "react";
import classnames from "classnames";
import BaseOverlayTrigger from "./BaseOverlayTrigger";
import {DocumentDuplicateIcon} from "@heroicons/react/24/outline";

function CopyableContainer(props) {
    const [copyableValue, setCopyableValue] = useState(null);
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        setCopyableValue(props.copyableValue || "");
    }, [props.copyableValue]);

    const onCopy = async (event) => {
        event.stopPropagation();
        event.preventDefault();
        await navigator.clipboard.writeText(copyableValue);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, [2000])
    }

    const getContent = () => {
        if (copied) {
            return <span><i className="fa fa-circle-check fa-c-success"/>&nbsp;Copied</span>
        } else {
            return <span><i className="fa fa-file"/>&nbsp;Copy to Clipboard</span>
        }
    }

    return (
    <div className={classnames("cursor-pointer block", props.className)} onClick={(event) => onCopy(event)}>
            <BaseOverlayTrigger content={getContent()}>
                <pre className="text-sm overflow-auto bg-gray-100 rounded-md">
                  {copyableValue}
                </pre>
            </BaseOverlayTrigger>
        </div>

    );
}

export default CopyableContainer;