import '../App.scss';
import React, {useState, useEffect, useContext} from 'react';
import {BaseContext, IntegrationContext} from "../helpers/common";
import {serverPost} from "../helpers/server";
import moment from 'moment';
import ContentBox from "./ContentBox";
import Button from "./common/buttons/Button";
import Notification from "./Notification";
import Link from "./Link";
import {useNavigate} from "react-router-dom";
const _ = require("lodash");

function IntegrationSyncPreview(props) {
    const { getApiUrl } = useContext(BaseContext);
    const { integration, getIntegrationSpecificUrl } = useContext(IntegrationContext);
    const [syncPreview, setSyncPreview] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        fetchSyncPreview();
    }, [])

    const fetchSyncPreview = (skipCache=false) => {
        const previewData = {
            feature: props.feature
        }
        serverPost(getApiUrl(`/integrations/${integration.id}/syncs/preview`), previewData,{ skipCache }).then((res) => {
            if (res) {
                setSyncPreview(res[props.feature.toLowerCase()])
            }
        })
    }

    const errorHandler = async (res) => {
        const errorResponse = await res.json();
        Notification.Danger(errorResponse.error_message);
    }

    const triggerSync = async () => {
        const params = {
            feature: props.feature,
            sync_type: "PUSH"
        }
        const res = await serverPost(getApiUrl(`/integrations/${integration.id}/trigger_sync`), params, {}, errorHandler)
        if (res) {
            fetchSyncPreview();
            if (props.onSyncTriggered) {
                props.onSyncTriggered();
            }
        }
    }

    if (_.isNil(syncPreview) || _.isEmpty(syncPreview)) {
        return null;
    }
    const invoiceCount = syncPreview.invoices ? syncPreview.invoices.length: 0;
    if (invoiceCount < 1) {
        return null;
    }
    return (
        <ContentBox>
            <ContentBox.Body>
                <div className="flex flex-row gap-2 items-center">
                    <div className="flex flex-col gap-2 grow">
                        <span className="text-sm font-semibold">Next Sync Preview</span>
                        <span className="text-sm">There are {invoiceCount} invoices with updates that are yet to be pushed.</span>
                        <span className="text-xs text-gray-400 italic hide">Next scheduled push: {moment().format("MMM D, YYYY h:mm:ssa")}.</span>
                    </div>
                    <div className="flex flex-row gap-2 items-center">
                        <Button variant="text-primary" onClick={() => navigate(getIntegrationSpecificUrl(`/${props.feature}/sync_preview`))}>View details</Button>
                        <Button variant="primary" onClick={triggerSync}>Trigger Sync</Button>
                    </div>
                </div>
            </ContentBox.Body>
        </ContentBox>
    )
}

export default IntegrationSyncPreview;
