import '../../App.scss';
import '../../css/modals.scss';
import BaseSideModal from './BaseSideModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../common/buttons/SubmitButton';
import React, { useContext, useState, useEffect } from 'react';
import { BaseContext } from '../../helpers/common';
import {serverFetch, serverPatch} from '../../helpers/server';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function UpdateAvalaraCustomerEntityCodeModal(props) {
    const { t } = useTranslation('common');
    const { getApiUrl } = useContext(BaseContext);
    const [initialFields, setInitialFields] = useState(null);

    useEffect(() => {
        if (!_.isNil(props.customer) && !_.isNil(props.avalaraIntegration)) {
            let entityCode = null;
            const intID = props.avalaraIntegration.id;
            if (props.customer.customer_integration_metadata && props.customer.customer_integration_metadata[intID] && props.customer.customer_integration_metadata[intID].avalara) {
                entityCode = props.customer.customer_integration_metadata[intID].avalara.entity_code;
            }
            setInitialFields({
                entity_code: entityCode
            })
        } else {
            setInitialFields(null);
        }
    }, [props.customer, props.avalaraIntegration]);

    const onSubmit = (data, errorHandler) => {
        const updateData = {
            customer_integration_metadata: props.customer.customer_integration_metadata
        }
        const intID = props.avalaraIntegration.id;
        updateData.customer_integration_metadata = updateData.customer_integration_metadata || {}
        updateData.customer_integration_metadata[intID] = updateData.customer_integration_metadata[intID] || {};
        updateData.customer_integration_metadata[intID].avalara = updateData.customer_integration_metadata[intID].avalara || {};
        updateData.customer_integration_metadata[intID].avalara.entity_code = data.entity_code;
        serverPatch(getApiUrl(`/customers/${props.customer.id}`), updateData).then((res) => {
            if (res) {
                if (props.onClose) {
                    props.onClose(true)
                }
            }
        });
    }

    return (
        <BaseSideModal {...props} >
            <BaseSideModal.Form onSubmit={onSubmit} initialFormFields={initialFields}>
                <BaseSideModal.Header title={"Manage Avalara Customer Entity Code"}/>
                <BaseSideModal.Body>
                    <Row>
                        <BaseForm.Input colSpan="12" name="entity_code" label="Entity Use Code" type="text"/>
                    </Row>
                    <p className="mt-2 text-sm">Entity use codes are used to identify reasons for exemption available in AvaTax. To learn more or
                        to find the right entity use code, please visit <a href="https://knowledge.avalara.com/bundle/hrj1669376021210/page/List_of_entity_use_codes.html" target="_blank">this knowledge base article</a>.</p>
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <SubmitButton>Update</SubmitButton>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );

}

export default UpdateAvalaraCustomerEntityCodeModal;
