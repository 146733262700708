import '../../App.scss';
import React, {useEffect, useState, useContext, useMemo} from 'react';
import {BaseContext, currencyFormatFromPrice, downloadBlob, getCurrencyOptions} from '../../helpers/common';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import Link from '../../components/Link';
import ContentBox from "../../components/ContentBox";
import MapleTable from "../../components/MapleTable";
import Columns from "../../components/Columns";
import moment from "moment";
import {serverPost} from "../../helpers/server";
import BaseForm from "../../components/BaseForm";
import { Row, Container } from 'react-bootstrap';
import SubmitButton from "../../components/common/buttons/SubmitButton";
import {ChevronDownIcon, ChevronRightIcon} from "@heroicons/react/20/solid";
import Breadcrumb from "../../components/Breadcrumb";
const _ = require('lodash');

function InvoiceAgingReport() {
    const { company, getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const [loading, setLoading] = useState(true);
    const [customerGroupings, setCustomerGroupings] = useState([]);
    const [reportInput, setReportInput] = useState({});
    const [reportCurrency, setReportCurrency] = useState("USD");
    const [showCustomerInvoices, setShowCustomerInvoices] = useState([]);
    const initialFields = useMemo(() => {
        return {
            reference_date: moment(),
            currency: company.preferred_currency
        }
    }, []);
    const [summary, setSummary] = useState({
        less_than_30_days: { value_in_cents: 0, currency: "USD" },
        less_than_60_days: { value_in_cents: 0, currency: "USD" },
        less_than_90_days: { value_in_cents: 0, currency: "USD" },
        more_than_90_days: { value_in_cents: 0, currency: "USD" },
        overdue_total: { value_in_cents: 0, currency: "USD" }
    });

    useEffect(() => {
        setPageTitle(`Financial Reports`);
    }, []);

    useEffect(() => {
        runReport(initialFields);
    }, [])

    const exportResults = () => {
        serverPost(getApiUrl("/reports/invoice_aged_report_export"), reportInput, { noJson: true }).then(res => {
            downloadBlob(res, `Invoice-Aged-Report-${moment(reportInput.reference_date).format("YYYY-MM-DD")}-${reportInput.currency}.csv`);
        })
    }

    const runReport = (data) => {
        setLoading(true);
        const reportData = {
            reference_date: data.reference_date,
            currency: data.currency
        }
        setReportCurrency(data.currency);
        setReportInput(reportData);
        serverPost(getApiUrl("/reports/invoice_aged_report"), reportData).then(res => {
            setLoading(false);
            if (res) {
                const groupedCustomers = [];
                const grouping = _.groupBy(res.invoices, 'customer_id');
                _.each(grouping, (invoices, cid) => {
                    groupedCustomers.push({
                        ...invoices[0].customer,
                        invoices: invoices,
                        less_than_30_days: {
                            value_in_cents: _.sumBy(invoices, i => i.less_than_30_days.value_in_cents),
                            currency: invoices[0].less_than_30_days.currency
                        },
                        less_than_60_days: {
                            value_in_cents: _.sumBy(invoices, i => i.less_than_60_days.value_in_cents),
                            currency: invoices[0].less_than_60_days.currency
                        },
                        less_than_90_days: {
                            value_in_cents: _.sumBy(invoices, i => i.less_than_90_days.value_in_cents),
                            currency: invoices[0].less_than_90_days.currency
                        },
                        more_than_90_days: {
                            value_in_cents: _.sumBy(invoices, i => i.more_than_90_days.value_in_cents),
                            currency: invoices[0].more_than_90_days.currency
                        },
                        overdue_total: {
                            value_in_cents: _.sumBy(invoices, i => i.overdue_total.value_in_cents),
                            currency: invoices[0].overdue_total.currency
                        },
                    })
                })
                setCustomerGroupings(groupedCustomers);
                setShowCustomerInvoices(_.map(groupedCustomers, gc => false));
                setSummary(res.summary);
            }
        })
    };

    const renderSummaryValue = (invoices, value) => {
        if (value.value_in_cents === 0) {
            return <span className="text-sm text-gray-500">-</span>
        } else if (invoices.length > 0) {
            return <span className="text-sm">{ currencyFormatFromPrice(value) }</span>
        } else {
            return <span className="text-sm text-gray-500">-</span>
        }
    }

    const updateShowCustomerInvoices = (index) => {
        setShowCustomerInvoices(prevShowInvoices => {
            const newShowInvoices = [...prevShowInvoices];
            newShowInvoices[index] = !newShowInvoices[index];
            return newShowInvoices;
        })
    }

    const breadcrumbItems = [
        { name: 'Financial Reports', href: getCompanySpecificUrl(`/reports`), current: false },
        { name: "Aged Receivables Report", current: true }
    ]

    return (
        <BaseContainer>
            <ContentContainer>
                <Breadcrumb items={breadcrumbItems}/>
                <Section variant="page" title="Aged Receivables Report">
                    <ContentBox>
                        <ContentBox.Body>
                            <BaseForm initialFormFields={initialFields} onSubmit={runReport}>
                                <Row>
                                    <BaseForm.Input colSpan="4" type="date" name="reference_date" label="Report as of" />
                                    <BaseForm.Input colSpan="4" type="select" name="currency" label="Currency" options={getCurrencyOptions()} showSearch={false} />
                                    <SubmitButton>Run</SubmitButton>
                                </Row>
                            </BaseForm>
                        </ContentBox.Body>
                    </ContentBox>
                </Section>
                <Section loading={loading}>
                    <MapleTable>
                        <div className="flex flex-row items-center">
                            <div className="grow">
                                <span className="text-base font-semibold text-gray-700">Invoice Aging Report</span><br/>
                                <span className="text-gray-500">{ reportInput.currency } invoice aging report as of { moment(reportInput.reference_date).format("D MMM, YY") }</span>
                            </div>
                            <div className="inline-block cursor-pointer px-2.5 py-1.5 rounded-md bg-white border-1 border-slate-300" onClick={exportResults}>
                                <span className="text-slate-900">Export</span>
                            </div>
                        </div>
                        <MapleTable.Content>
                            <thead>
                                <tr>
                                    <MapleTable.TH></MapleTable.TH>
                                    <MapleTable.TH>Accounts Receivable</MapleTable.TH>
                                    <MapleTable.TH></MapleTable.TH>
                                    <MapleTable.TH className="text-end">0 - 30 Days</MapleTable.TH>
                                    <MapleTable.TH className="text-end">30 - 60 Days</MapleTable.TH>
                                    <MapleTable.TH className="text-end">60 - 90 Days</MapleTable.TH>
                                    <MapleTable.TH className="text-end">90+ Days</MapleTable.TH>
                                    <MapleTable.TH className="text-end">Total</MapleTable.TH>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                <tr>
                                    <td className="!pr-0"></td>
                                    <td className="font-semibold">Total</td>
                                    <td></td>
                                    <td className="font-semibold text-end">{ summary.less_than_30_days.value_in_cents > 0 ? currencyFormatFromPrice(summary.less_than_30_days): "-" }</td>
                                    <td className="font-semibold text-end">{ summary.less_than_60_days.value_in_cents > 0 ? currencyFormatFromPrice(summary.less_than_60_days): "-" }</td>
                                    <td className="font-semibold text-end">{ summary.less_than_90_days.value_in_cents > 0 ? currencyFormatFromPrice(summary.less_than_90_days): "-" }</td>
                                    <td className="font-semibold text-end">{ summary.more_than_90_days.value_in_cents > 0 ? currencyFormatFromPrice(summary.more_than_90_days): "-" }</td>
                                    <td className="font-semibold text-end">{ summary.overdue_total.value_in_cents > 0 ? currencyFormatFromPrice(summary.overdue_total): "-" }</td>
                                </tr>
                            {
                                _.map(customerGroupings, (row, i) =>
                                    <React.Fragment key={i}>
                                        <tr className="cursor-pointer" onClick={() => updateShowCustomerInvoices(i)}>
                                            <td className="!pr-0 w-px whitespace-nowrap">
                                                {
                                                    showCustomerInvoices[i] ?
                                                        <ChevronDownIcon className="w-5 h-5 text-gray-500"/>
                                                    : <ChevronRightIcon className="w-5 h-5 text-gray-500"/>
                                                }
                                            </td>
                                            <td >
                                                <Columns.CustomerName customer={row} hideAvatar/>
                                            </td>
                                            <td className="text-end">
                                                <span className="w-px whitespace-nowrap">{ row.invoices.length } Invoice(s)</span><br/>
                                            </td>
                                            <td className="text-end">{ renderSummaryValue(row.invoices, row.less_than_30_days, "<30") }</td>
                                            <td className="text-end">{ renderSummaryValue(row.invoices, row.less_than_60_days, "30<60") }</td>
                                            <td className="text-end">{ renderSummaryValue(row.invoices, row.less_than_90_days, "60<90") }</td>
                                            <td className="text-end">{ renderSummaryValue(row.invoices, row.more_than_90_days, "90+") }</td>
                                            <td className="text-end">{ renderSummaryValue(row.invoices, row.overdue_total, "total") }</td>
                                        </tr>
                                        {
                                            showCustomerInvoices[i] && _.map(row.invoices, (inv, j) =>
                                                <tr key={j}>
                                                    <td className="!pr-0 !py-1"></td>
                                                    <td className="!py-1 text-end">
                                                        <Link target="_blank" href={getCompanySpecificUrl(`/invoice/${inv.uuid}`)}>
                                                            <span>{ inv.number }</span>
                                                        </Link>
                                                    </td>
                                                    <td className="!py-1 text-end">
                                                        <Link target="_blank" href={getCompanySpecificUrl(`/invoice/${inv.uuid}`)}>
                                                            <span>{ currencyFormatFromPrice(inv.due) }</span>
                                                        </Link>
                                                    </td>
                                                    <td className="!py-1 text-end">{ renderSummaryValue([inv], inv.less_than_30_days) }</td>
                                                    <td className="!py-1 text-end">{ renderSummaryValue([inv], inv.less_than_60_days) }</td>
                                                    <td className="!py-1 text-end">{ renderSummaryValue([inv], inv.less_than_90_days) }</td>
                                                    <td className="!py-1 text-end">{ renderSummaryValue([inv], inv.more_than_90_days) }</td>
                                                    <td className="!py-1 text-end">{ renderSummaryValue([inv], inv.overdue_total) }</td>
                                                </tr>
                                            )
                                        }
                                    </React.Fragment>
                                )
                            }
                            </tbody>
                        </MapleTable.Content>
                    </MapleTable>
                </Section>
            </ContentContainer>
        </BaseContainer>
    );
}

export default InvoiceAgingReport;
