import '../../App.scss';
import '../../css/modals.scss';
import React, { useContext } from 'react';
import BaseForm from '../BaseForm';
import { IntegrationContext } from '../../helpers/common';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const _ = require('lodash');

function AddOrEditEmailDomainModal(props) {
	const { t } = useTranslation('common');
	const { integration } = useContext(IntegrationContext);

	const cleanupFields = (fields) => {
		if (props.itemToEdit && !props.itemToEdit.editable) {
			delete fields.type;
		}
		return fields;
	};

	return (
		<BaseAddOrEditItemModal
			{...props}
			processUpdateFields={cleanupFields}
			itemLabel={'Domain'}
			itemBaseUrl={`/integrations/${integration.id}/custom_email_domain/domains`}
		>
			<Row>
				<BaseForm.Input colSpan='12' name='domain' label={'Domain'} type='text' required />
				<BaseForm.Input colSpan='12' name='return_path_domain' label={'Return Path (optional)'} type='text' />
			</Row>
		</BaseAddOrEditItemModal>
	);
}

export default AddOrEditEmailDomainModal;
