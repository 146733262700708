import React, { useEffect, useState } from 'react';
import '../../App.scss';
import '../../css/table.scss';
import '../../css/forms.scss';
import TabHeader from '../../components/TabHeader';
import SuperContainer from '../../components/SuperContainer';
import ContentContainer from '../../components/ContentContainer';
import SectionNav from '../../components/SectionNav';
import BaseForm from '../../components/BaseForm';
import Section from '../../components/Section';
import Columns from '../../components/Columns';
import KeyValueDisplay from '../../components/KeyValueDisplay';
import ContentBox from '../../components/ContentBox';
import Label from '../../components/Label';
import { Alert, Row, Col, Table, InputGroup } from 'react-bootstrap';
import Button from '../../components/common/buttons/Button';
import moment from 'moment';
import classnames from 'classnames';
const _ = require("lodash");

function Playground() {
    const [activeNav, setActiveNav] = useState("home")

    const defaultFormFields = {
        textInput1: "Default Text",
        passwordInput1: "Default Password",
        dateInput1: moment("2022-06-01"),
        datetimeInput1: moment().endOf('day'),
        selectInput1: "2",
        colorInput1: "#dc2127",
        textareaInput1: "Default textarea",
        editorInput1: "<p>Testing editor</p>",

        textInput2: "Default Text",
        passwordInput2: "Default Password",
        dateInput2: moment("2022-06-01"),
        datetimeInput2: moment("2022-06-01"),
        selectInput2: "2",
        colorInput2: "#dc2127",
        textareaInput2: "Default textarea",

        textInput3: "Default Text",
        passwordInput3: "Default Password",
        dateInput3: moment("2022-06-01"),
        datetimeInput3: moment("2022-06-01"),
        selectInput3: "2",
        colorInput3: "#dc2127",
        textareaInput3: "Default textarea",

        textInput4: "Default Text",
        passwordInput4: "Default Password",
        dateInput4: moment("2022-06-01"),
        datetimeInput4: moment("2022-06-01"),
        selectInput4: "2",
        colorInput4: "#dc2127",
        textareaInput4: "Default textarea",

        textInput5: "Default Text",
        passwordInput5: "Default Password",
        dateInput5: moment("2022-06-01"),
        datetimeInput5: moment("2022-06-01"),
        selectInput5: "2",
        colorInput5: "#dc2127",
        textareaInput5: "Default textarea",

        textInput6: "Default Text",
        passwordInput6: "Default Password",
        dateInput6: moment("2022-06-01"),
        datetimeInput6: moment("2022-06-01"),
        selectInput6: "2",
        colorInput6: "#dc2127",
        textareaInput6: "Default textarea"
    };

    useEffect(() => {
        document.title = `Playground`;
    }, []);

    const onSubmit = (data) => {
        console.log("Submit data is " + JSON.stringify(data));
    }

    const selectOptions = [
        { value: '1', label: 'One' },
        { value: '2', label: 'Two' },
        { value: '3', label: 'Three' },
    ]

    const onNavClick = (valueId) => {
        setActiveNav(valueId);
    }

    const tabItems = [{
        'label': 'Home',
        'link': '/',
        'id': 'home',
        counter: 2,
        active: activeNav === "home"
    }, {
        'label': 'Customers',
        'link': '/customers',
        'id': 'customers',
        active: activeNav === "customers"
    }, {
        'label': 'Payments',
        'link': '/payments',
        'id': 'payments',
        counter: 22,
        active: activeNav === "payments"
    }]

    const rightTabItems = [{
        'label': 'Developers',
        'link': '/developers',
        'id': 'developers',
        active: activeNav === "developers"
    }, {
        'label': 'Test Mode',
        'link': '/test_mode',
        'id': 'test_mode',
        active: activeNav === "test_mode"
    }]

    const sectionActions = [{
        variant: "alink",
        icon: "fa-plus",
        label: "Create",
        link: "/home"
    }, {
        variant: "outline-primary",
        icon: "fa-file",
        label: "Export",
        onClick: () => { alert("Export clicked"); }
    }, {
        variant: "primary",
        icon: "fa-save",
        label: "New Payment",
        onClick: () => { alert("Export clicked"); }
    }]

    const tableRows = [{
        amount: "$9.95",
        subtitle: "Developer",
        currency: "USD",
        icon: "fa-repeat",
        status: "paid",
        description: "Subscription Creation",
        date: moment().format("ddd, D, hh:mm a"),
        isDisabled: false
    }, {
        amount: "$9.95",
        subtitle: "Business",
        currency: "USD",
        icon: "fa-repeat",
        status: "failed",
        description: "Subscription Update",
        date: moment().format("ddd, D, hh:mm a"),
        isDisabled: false
    }, {
        amount: "$9.95",
        subtitle: "Startup",
        currency: "USD",
        icon: "fa-repeat",
        status: "paid",
        description: "Subscription Update (disabled row)",
        date: moment().format("ddd, D, hh:mm a"),
        isDisabled: true
    }];

    const metadataItems = [{
        something: "Name",
        value: "Browser Mockup API"
    }, {
        something: "Created",
        value: "Feb 24"
    }, {
        something: "Statement descriptor",
    }, {
        something: "Feature list",
        value: null,
    }]

    return (
        <SuperContainer>
            <ContentBox>
                <ContentBox.Body>
                    <p><strong>Tab Header</strong></p>
                    <TabHeader items={tabItems} rightItems={rightTabItems} />
                    <br/>
                    <p><strong>Section Header</strong></p>
                    <SectionNav size="sm" items={tabItems} rightItems={rightTabItems} onClick={onNavClick} />
                    <br/>
                    <p><strong>Empty Section</strong></p>
                    <Section title="Subscriptions" emptyPlaceholder="No subscriptions" actions={sectionActions}/>
                    <br/>
                    <Section title="Labels">
                        <div className="d-flex flex-column" style={{ gap: "10px" }}>
                            <Label.Success>Success</Label.Success>
                            <Label.Warning>Warning</Label.Warning>
                            <Label.Danger>Danger</Label.Danger>
                        </div>
                    </Section>
                    <br/>
                    <Section title="Icons">
                        <div style={{ display: "flex", flexDirection: "row"}}>
                            <div className="close-icon"/>
                            <div className="dashboard-icon"/>
                            <div className="invoice-icon"/>
                            <div className="download-icon"/>
                            <div className="chevron-down-icon"/>
                            <div className="subscription-icon"/>
                            <div className="billables-icon"/>
                            <div className="search-icon"/>
                            <div className="add-circle-icon"/>
                            <div className="calendar-icon"/>
                            <div className="settings-icon"/>
                            <div className="delete-icon"/>
                            <div className="overflow-icon"/>
                            <div className="chevron-right-icon"/>
                            <div className="reports-icon"/>
                            <div className="edit-icon"/>
                            <div className="add-icon"/>
                            <div className="chevron-left-icon"/>
                            <div className="products-icon"/>
                            <div className="filter-icon"/>
                            <div className="subgroup-icon"/>
                        </div>
                    </Section>
                    <br/>
                    <Section title="Buttons">
                        <br/>
                        <div className="d-flex flex-row gap-3">
                            <div className="d-flex flex-column gap-3">
                                <Button variant="primary">Add New</Button>
                                <Button variant="primary"><i className="fa fa-plus"/> Add New</Button>
                                <Button variant="outline-primary"><i className="fa fa-plus"/> Add New</Button>
                                <Button variant="text-primary"><i className="fa fa-plus"/> Add New</Button>
                            </div>
                            <div className="d-flex flex-column gap-3">
                                <Button variant="danger">Add New</Button>
                                <Button variant="danger"><i className="fa fa-plus"/> Add New</Button>
                                <Button variant="outline-danger"><i className="fa fa-plus"/> Add New</Button>
                                <Button variant="text-danger"><i className="fa fa-plus"/> Add New</Button>
                            </div>
                            <div className="d-flex flex-column gap-3">
                                <Button variant="primary" disabled>Add New</Button>
                                <Button variant="primary" disabled><i className="fa fa-plus"/> Add New</Button>
                                <Button variant="outline" disabled><i className="fa fa-plus"/> Add New</Button>
                                <Button variant="text" disabled><i className="fa fa-plus"/> Add New</Button>
                            </div>
                        </div>
                    </Section>
                    <br/>
                    <p><strong>Left Nav with some content on right</strong></p>
                    <div className="content-wrapper">
                        <ContentContainer>
                            <Section title="Subscriptions" emptyPlaceholder="No subscriptions" actions={sectionActions}>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Amount</th>
                                            <th></th>
                                            <th></th>
                                            <th>Description</th>
                                            <th>Date</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        _.map(tableRows, (row, i) =>
                                             <tr key={i} className={classnames(row.isDisabled && "de-highlighted")}>
                                                <td>
                                                    <div className="checkbox check-success inline">
                                                        <input type="checkbox" className="" value="checkall" id="checkall" name="delete[]" checked={false} />
                                                        <label htmlFor="checkall"/>
                                                    </div>
                                                </td>
                                                <td>{ row.amount }</td>
                                                <td>{ row.currency }</td>
                                                <td>
                                                {
                                                    row.status === "paid" &&
                                                         <Label.Success>Paid <i className="fa fa-check"/></Label.Success>
                                                }
                                                {
                                                    row.status === "failed" &&
                                                         <Label.Danger>Failed <i className="fa fa-remove"/></Label.Danger>
                                                }
                                                </td>
                                                <td>{ row.description }</td>
                                                <td>{ row.date }</td>
                                                <td></td>
                                             </tr>
                                        )
                                    }
                                    </tbody>
                                </Table>
                            </Section>
                        </ContentContainer>
                    </div>
                    <br/>
                    <p><strong>Table Section (with hover) and subtitles</strong></p>
                    <Section title="Subscriptions" emptyPlaceholder="No subscriptions" actions={sectionActions}>
                        <Table hover>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th width="100px">Amount</th>
                                    <th width="70px"></th>
                                    <th width="100px"></th>
                                    <th>Description</th>
                                    <th>Date</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                _.map(tableRows, (row, i) =>
                                     <tr key={i} className={classnames(row.isDisabled && "de-highlighted")}>
                                        <td><Columns.CustomerName customer={{ name: "Aswin", email: "a@b.com" }}/></td>
                                        <td>
                                            <p><strong>{ row.amount }</strong></p>
                                            <p>{ row.subtitle }</p>
                                        </td>
                                        <td>{ row.currency }</td>
                                        <td>
                                        {
                                            row.status === "paid" &&
                                                 <Label.Success>Paid <i className="fa fa-check"/></Label.Success>
                                        }
                                        {
                                            row.status === "failed" &&
                                                 <Label.Danger>Failed <i className="fa fa-remove"/></Label.Danger>
                                        }
                                        </td>
                                        <td><span className="bordered">{ row.description }</span></td>
                                        <td><Button disabled={row.isDisabled} variant="outline-primary"><i className="fa fa-circle-plus"/> Create payment link</Button></td>
                                        <td></td>
                                     </tr>
                                )
                            }
                            </tbody>
                        </Table>
                    </Section>
                    <br/>
                    <p><strong>Key/Value Section (with one section)</strong></p>
                    <Section title="Subscriptions" emptyPlaceholder="No subscriptions" actions={sectionActions}>
                        <KeyValueDisplay items={metadataItems} keyField={"something"} />
                    </Section>
                    <br/>
                    <p><strong>Key/Value Section (with two sections)</strong></p>
                    <Section title="Subscriptions" emptyPlaceholder="No subscriptions" actions={sectionActions} contentClassName="d-flex flex-row">
                        <KeyValueDisplay items={metadataItems} keyField={"something"} />
                        <KeyValueDisplay items={metadataItems} keyField={"something"} />
                    </Section>
                    <br/>
                    <Alert variant="info"><strong>Base form input items:</strong></Alert>
                    <BaseForm initialFormFields={defaultFormFields} onSubmit={onSubmit}>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Text input" name="textInput1" required />
                            <BaseForm.Input colSpan="4" type="text" label="Text input" name="textInput1" icon="search-icon" required />
                            <BaseForm.Input colSpan="4" type="password" label="Password input" name="passwordInput1" required />
                            <BaseForm.Input colSpan="4" type="switch" label="Switch input" name="switchInput1" value="One" required />
                            <BaseForm.Input colSpan="4" type="check" label="Check input" name="checkInput1" value="One" required />
                            <BaseForm.Input colSpan="4" type="check" label="Check input" name="checkInput1" value="Two" required />
                            <BaseForm.Input colSpan="4" type="radio" label="Radio input" name="radioInput1" value="One" required />
                            <BaseForm.Input colSpan="4" type="radio" label="Radio input" name="radioInput1" value="Two" required />
                            <BaseForm.Input colSpan="4" type="text" label="Text input" name="textInput1" required />
                            <BaseForm.Input colSpan="4" type="color" label="Color input" name="colorInput1" required />
                            <BaseForm.Input colSpan="4" type="date" label="Date input" name="dateInput1" required />
                            <BaseForm.Input colSpan="4" type="date" label="Datetime input" name="datetimeInput1" includeTime={true} minDate={moment()} required />
                            <BaseForm.Input colSpan="4" type="select" label="Select input" name="selectInput1" options={selectOptions} required />
                            <BaseForm.Input colSpan="4" type="selector" label="Selector input" name="selectorInput1" />
                            <BaseForm.Input colSpan="4" type="file" label="File input" name="fileInput1" fileType="test" required />
                            <BaseForm.Input colSpan="4" type="textarea" label="Textarea input" name="textareaInput1" height="125px" required />
                            <BaseForm.Input colSpan="4" type="editor" label="Editor input" name="editorInput1" required />
                            <BaseForm.Input colSpan="4" type="address" label="Address input" name="addressInput1" />
                        </Row>
                        <Row>
                            <Col md="3">
                                <Button type="submit" variant="primary">Submit</Button>
                            </Col>
                        </Row>
                    </BaseForm>
                    <Alert variant="info"><strong>Base form input items: disabled</strong></Alert>
                    <BaseForm initialFormFields={defaultFormFields}>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Text input" name="textInput2" required disabled/>
                            <BaseForm.Input colSpan="4" type="password" label="Password input" name="passwordInput2" required disabled/>
                            <BaseForm.Input colSpan="4" type="switch" label="Switch input" name="switchInput2" required disabled/>
                            <BaseForm.Input colSpan="4" type="check" label="Check input" name="checkInput2" required disabled/>
                            <BaseForm.Input colSpan="4" type="date" label="Date input" name="dateInput2" required disabled/>
                            <BaseForm.Input colSpan="4" type="select" label="Select input" name="selectInput2" options={selectOptions} required disabled/>
                            <BaseForm.Input colSpan="4" type="file" label="File input" name="fileInput2" fileType="test" required disabled/>
                            <BaseForm.Input colSpan="4" type="textarea" label="Textarea input" name="textareaInput2" required disabled/>
                        </Row>
                    </BaseForm>
                    <Alert variant="info"><strong>Base form input items: without labels</strong></Alert>
                    <BaseForm initialFormFields={defaultFormFields}>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" name="textInput3" required hideLabel />
                            <BaseForm.Input colSpan="4" type="password" name="passwordInput3" required hideLabel />
                            <BaseForm.Input colSpan="4" type="switch" name="switchInput3" label="Switch input" required hideLabel />
                            <BaseForm.Input colSpan="4" type="check" name="checkInput3" required hideLabel />
                            <BaseForm.Input colSpan="4" type="text" name="textInput3" required hideLabel />
                            <BaseForm.Input colSpan="4" type="date" id="dateInput3" name="dateInput" required hideLabel />
                            <BaseForm.Input colSpan="4" type="select" name="selectInput3" options={selectOptions} required hideLabel />
                            <BaseForm.Input colSpan="4" type="text" name="textInput3" required hideLabel />
                            <BaseForm.Input colSpan="4" type="file" name="fileInput3" fileType="test" required hideLabel />
                            <BaseForm.Input colSpan="4" type="text" name="textInput3" required hideLabel />
                            <BaseForm.Input colSpan="4" type="textarea" name="textareaInput3" required hideLabel />
                        </Row>
                    </BaseForm>
                    <Alert variant="info"><strong>Base form input items: with side contents</strong></Alert>
                    <BaseForm initialFormFields={defaultFormFields}>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Text input" name="textInput4" required leftContent="L" rightContent="R" />
                            <BaseForm.Input colSpan="4" type="password" label="Password input" name="passwordInput4" required leftContent="L" rightContent="R" />
                            <BaseForm.Input colSpan="4" type="check" label="Check input" name="checkInput4" required leftContent="L" rightContent="R" />
                            <BaseForm.Input colSpan="4" type="text" label="Text input" name="textInput4" required leftContent="L" rightContent="R" />
                            <BaseForm.Input colSpan="4" type="date" id="dateInput3" label="Date input" name="dateInput4" required leftContent="L" rightContent="R" />
                            <BaseForm.Input colSpan="4" type="select" label="Select input" name="selectInput4" options={selectOptions} required leftContent="L" rightContent="R" />
                            <BaseForm.Input colSpan="4" type="text" label="Text input" name="textInput4" required leftContent="L" rightContent="R" />
                            <BaseForm.Input colSpan="4" type="file" label="File input" name="fileInput4" fileType="test" required leftContent="L" rightContent="R" />
                            <BaseForm.Input colSpan="4" type="text" label="Text input" name="textInput4" required leftContent="L" rightContent="R" />
                            <BaseForm.Input colSpan="4" type="textarea" label="Textarea input" name="textareaInput4" required leftContent="L" rightContent="R" />
                        </Row>
                    </BaseForm>
                    <Alert variant="info"><strong>Base form items: without .input</strong></Alert>
                    <BaseForm initialFormFields={defaultFormFields}>
                        <Row>
                            <Col md="4">
                                <BaseForm.TextGroup type="text" name="textInput5" label="Text Input" />
                            </Col>
                            <Col md="4">
                                <BaseForm.PasswordGroup type="password" name="passwordInput5" label="Password Input" />
                            </Col>
                            <Col md="4">
                                <BaseForm.CheckGroup name="checkInput5" label="Check Input" />
                            </Col>
                            <Col md="4">
                                <BaseForm.TextGroup type="text" name="textInput5" label="Text Input" />
                            </Col>
                            <Col md="4">
                                <BaseForm.DateGroup id="dateInput4" name="dateInput5" label="Date Input" />
                            </Col>
                            <Col md="4">
                                <BaseForm.SelectGroup name="selectInput5" label="Select Input" options={selectOptions} />
                            </Col>
                            <Col md="4">
                                <BaseForm.TextGroup type="text" name="textInput5" label="Text Input" />
                            </Col>
                            <Col md="4">
                                <BaseForm.FileGroup name="fileInput5" label="File Input" fileType="test" />
                            </Col>
                            <Col md="4">
                                <BaseForm.TextGroup type="text" name="textInput5" label="Text Input" />
                            </Col>
                            <Col md="4">
                                <BaseForm.TextAreaGroup type="textarea2" name="textareaInput5" label="Textarea Input" />
                            </Col>
                        </Row>
                    </BaseForm>
                    <Alert variant="info"><strong>Base form inside large input group</strong></Alert>
                    <BaseForm initialFormFields={defaultFormFields}>
                        <Row>
                            <Col md="12">
                                <BaseForm.InputGroup>
                                    <InputGroup.Text>A</InputGroup.Text>
                                    <BaseForm.Text name="textInput6" label="Text Input" />
                                    <InputGroup.Text>B</InputGroup.Text>
                                    <BaseForm.Text name="passwordInput6" label="Password Input" />
                                    <InputGroup.Text>C</InputGroup.Text>
                                    <BaseForm.CheckboxRadio name="checkInput6" label="Check Input" />
                                    <InputGroup.Text>D</InputGroup.Text>
                                </BaseForm.InputGroup>
                                <BaseForm.InputGroup>
                                    <InputGroup.Text>A</InputGroup.Text>
                                    <BaseForm.Text name="textInput6" label="Text Input" />
                                    <InputGroup.Text>B</InputGroup.Text>
                                    <BaseForm.Date id="dateInput6" name="dateInput" label="Date Input" />
                                    <InputGroup.Text>C</InputGroup.Text>
                                    <BaseForm.Select name="selectInput6" label="Select Input" options={selectOptions} />
                                    <InputGroup.Text>D</InputGroup.Text>
                                </BaseForm.InputGroup>
                                <BaseForm.InputGroup>
                                    <InputGroup.Text>A</InputGroup.Text>
                                    <BaseForm.Text name="textInput6" label="Text Input" />
                                    <InputGroup.Text>C</InputGroup.Text>
                                    <BaseForm.File name="fileInput6" label="File Input" fileType="test" />
                                    <InputGroup.Text>D</InputGroup.Text>
                                </BaseForm.InputGroup>
                                <BaseForm.InputGroup>
                                    <InputGroup.Text>A</InputGroup.Text>
                                    <BaseForm.Text name="textInput6" label="Text Input" />
                                    <InputGroup.Text>B</InputGroup.Text>
                                    <BaseForm.TextArea type="textarea2" name="textareaInput6" label="Textarea Input" />
                                    <InputGroup.Text>C</InputGroup.Text>
                                </BaseForm.InputGroup>
                            </Col>
                        </Row>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
        </SuperContainer>
    );
}

export default Playground;
