import React, { useContext } from 'react';
import { UserContext, BaseContext } from '../../../helpers/common';
import { Link } from 'react-router-dom';
import Button from '../../common/buttons/Button';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import classnames from 'classnames';
import Profile from '../profile/Profile';
const _ = require('lodash');

const languageMap = {
	en: 'English',
	fr: 'French',
	es: 'Spanish',
};

function Header(props) {
	const userContext = useContext(UserContext);
	const baseContext = useContext(BaseContext);
	const { userInfo, isSuperUser, isLoggedIn } = !_.isNil(userContext)
		? userContext
		: { userInfo: {}, isLoggedIn: false };
	const { company } = !_.isNil(baseContext) ? baseContext : { company: null };
	const { i18n } = useTranslation('common');
	const showLanguageSelection = false;

	const changeLanguage = (event, language) => {
		event.preventDefault();
		i18n.changeLanguage(language);
		localStorage.setItem('language', language);
	};

	const toggleLeftNav = (event) => {
		event.preventDefault();
		if ($('#left-nav').hasClass('show')) {
			// It is showing. Hide it.
			$('#left-nav').removeClass('show');
		} else {
			// It is not showing. Show it.
			$('#left-nav').addClass('show');
		}
	};

	return (
		<div
			className={classnames(
				'header',
				'border-b border-gray-200',
				props.className,
			)}
		>
			<div className='header-toolbar'>
				<div className='header-item d-lg-none'>
					<div className='header-item-inner'>
						<Button variant='text' onClick={(event) => toggleLeftNav(event)}>
							<i className='fa fa-bars' />
						</Button>
					</div>
				</div>
			</div>
			<div className='header-toolbar expandable'></div>
			<div className='header-toolbar'>
				{showLanguageSelection && (
					<div className='header-item'>
						<div className='header-item-inner'>
							<div className='dropdown'>
								<button
									type='button'
									className='btn dropdown-toggle'
									data-bs-toggle='dropdown'
									aria-haspopup='true'
									aria-expanded='false'
									data-bs-offset='5,8'
								>
									{languageMap[i18n.language]}
								</button>
								<ul className='dropdown-menu dropdown-menu-end'>
									{_.map(languageMap, (label, code) => (
										<li key={code}>
											<a href={`#${code}`} onClick={(event) => changeLanguage(event, code)}>
												{label}
											</a>
										</li>
									))}
								</ul>
							</div>
						</div>
					</div>
				)}
				{props.isSuper && isSuperUser && (
					<div className='header-item'>
						<div className='header-item-inner'>
							<Button variant='danger'>Super User Mode</Button>
						</div>
					</div>
				)}
				{isLoggedIn && <Profile isHeader userInfo={userInfo} company={company} />}
				{!isLoggedIn && (
					<div className='header-item'>
						<div className='header-item-inner'>
							<Link to='/login'>
								<Button variant='primary'>Login</Button>
							</Link>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default Header;
