import '../../App.scss';
import '../../css/modals.scss';
import React from 'react';
import BaseModal from '../modals/BaseModal';
import { useContext } from 'react';
import { BaseContext } from '../../helpers/common';
import Button from '../common/buttons/Button';
import { useTranslation } from 'react-i18next';
const _ = require('lodash');

function ArchiveModal(props) {
    const { t } = useTranslation('common');
    const { company, getApiUrl } = useContext(BaseContext);

    const onConfirm = () => {
        if (props.onConfirm) {
            props.onConfirm();
        }
        props.onClose();
    }

    return (
        <BaseModal {...props}>
            <BaseModal.Header>
                <BaseModal.Title>Confirm Archive?</BaseModal.Title>
            </BaseModal.Header>
            <BaseModal.Body>
                <div className="body1">
                    Are you sure you want to archive?
                </div>
            </BaseModal.Body>
            <BaseModal.Footer>
                <Button variant={"danger"} className={props.buttonClassName} onClick={onConfirm}>
                    { props.buttonTitle || "Confirm" }
                </Button>
            </BaseModal.Footer>
        </BaseModal>
    );
}

export default ArchiveModal;
