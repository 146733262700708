import '../../../App.scss';
import React from 'react';
import BaseContainer from '../../../components/BaseContainer';
import ContentContainer from '../../../components/ContentContainer';
import Button from '../../../components/common/buttons/Button';
import { useTranslation } from 'react-i18next';

function InvoiceTemplates() {
    const { t } = useTranslation('common');

    return (
        <BaseContainer>
            <ContentContainer>
                { t('invoice.templates.title') }
                <Button variant="success">Create</Button>
            </ContentContainer>
        </BaseContainer>
    );
}

export default InvoiceTemplates;
