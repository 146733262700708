import '../../App.scss';
import React, {useEffect, useContext, useMemo} from 'react';
import {BaseContext} from '../../helpers/common';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import Breadcrumb from "../../components/Breadcrumb";
import {Col, Row} from "react-bootstrap";
import Metric from "../../components/Metric";
import MapleTable from "../../components/MapleTable";
import Link from "../../components/Link";
import Label from '../../components/Label';
import moment from "moment/moment";
const _ = require('lodash');

function RevenueRecognitionOverview() {
    const { setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);

    useEffect(() => {
        setPageTitle(`Revenue Recognition`);
    }, []);

    const customDateRanges = useMemo(() => {
        return {
            'This Year': [moment().startOf('year'), moment().endOf('day')],
            'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
            'Next Year': [moment().add(1, 'year').startOf('year'), moment().add(1, 'year').endOf('year')]
        }
    }, []);

    const breadcrumbItems = [
        { name: 'Financial Reports', href: getCompanySpecificUrl(`/reports`), current: false },
        { name: "Revenue Recognition", current: true }
    ]

    return (
        <BaseContainer>
            <ContentContainer>
                <Breadcrumb items={breadcrumbItems}/>
                <Section variant="page" title="Overview">
                    <Row className="gy-3 gx-3">
                        <Col lg="6" md="6">
                            <Metric
                                metricKey="RECOGNIZED_REVENUE"
                                chartType="bar"
                                label="New Customers"
                                yLabel="Customers"
                                defaultDateRange='This Year'
                                showGroupingOptions={false}
                                dontCache
                                defaultFrequency="MONTH"
                                hideFrequencySelection
                                hideExport
                                customDateRanges={customDateRanges}
                                noMaxDate
                                showCustomRangeLabel={false}
                            />
                        </Col>
                        <Col lg="6" md="6">
                            <Metric
                                metricKey="TOTAL_INVOICED_REVENUE"
                                chartType="bar"
                                label="New Subscriptions"
                                yLabel="Subscriptions"
                                defaultDateRange='This Year'
                                showGroupingOptions={false}
                                defaultFrequency="MONTH"
                                hideFrequencySelection
                                hideExport
                                customDateRanges={customDateRanges}
                                noMaxDate
                                showCustomRangeLabel={false}
                            />
                        </Col>
                    </Row>
                </Section>
                <Section title="Revenue Recognition">
                    <MapleTable>
                        <MapleTable.Content>
                            <tbody className="divide-y divide-gray-200">
                                <MapleTable.TR>
                                    <td>
                                        <Link href={getCompanySpecificUrl(`/reports/revenue_recognition/recognized`)}>
                                            <span className="text-sm font-semibold">Recognized Revenue</span>
                                        </Link>
                                    </td>
                                    <td>Report to show a breakdown of recognized revenue over time.</td>
                                </MapleTable.TR>
                                <MapleTable.TR>
                                    <td>
                                        <div className="flex flex-row gap-2 items-center">
                                            <span className="text-sm font-semibold">Deferred Revenue</span>
                                            <Label.Info>Coming Soon</Label.Info>
                                        </div>
                                    </td>
                                    <td>Report to show a breakdown of deferred revenue over time.</td>
                                </MapleTable.TR>
                                <MapleTable.TR>
                                    <td>
                                        <div className="flex flex-row gap-2 items-center">
                                            <span className="text-sm font-semibold">Unbilled Revenue</span>
                                            <Label.Info>Coming Soon</Label.Info>
                                        </div>
                                    </td>
                                    <td>Report to show a breakdown of unbilled revenue over time.</td>
                                </MapleTable.TR>
                            </tbody>
                        </MapleTable.Content>
                    </MapleTable>
                </Section>
            </ContentContainer>
        </BaseContainer>
    );
}

export default RevenueRecognitionOverview;
