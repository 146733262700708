import '../../App.scss';
import React, {useContext, useEffect, useState} from 'react';
import {BaseContext} from '../../helpers/common';
import BaseContainer from '../../components/BaseContainer';
import InvoiceListSection from '../../components/InvoiceListSection';
import ContentContainer from '../../components/ContentContainer';
import {serverFetch} from "../../helpers/server";
import {useSearchParams} from "react-router-dom";

function Invoices() {
    const { setPageTitle, getApiUrl } = useContext(BaseContext);
    const [metricsSummary, setMetricsSummary] = useState(null)
    const [ searchParams ] = useSearchParams();
    let customer_id = searchParams.get('customer_id');
    const [disableInitialFilters, setDisableInitialFilters] = useState(customer_id != null);
    const [extraQueryFilters, setExtraQueryFilters] = useState({
        customer_id: customer_id || null,
    });

    useEffect(() => {
        setPageTitle(`Invoices`);
    }, []);

    useEffect(() => {
        serverFetch(getApiUrl(`/reports/invoice_metrics_summary`)).then(res => {
            setMetricsSummary(res)
        })
    }, []);

    useEffect(() => {
        setDisableInitialFilters(customer_id != null);
        setExtraQueryFilters({
            customer_id: customer_id || null,
        })
    }, [customer_id]);

    return (
        <BaseContainer>
            <ContentContainer>
                <InvoiceListSection
                    showTableActions={true} showBulkSelection={true} metricsSummary={metricsSummary}
                    disableInitialFilters={disableInitialFilters}
                    extraQueryFilters={extraQueryFilters}
                    variant="page"
                />
            </ContentContainer>
        </BaseContainer>
    );
}

export default Invoices;
