import '../../App.scss';
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { serverPost, serverPatch } from '../../helpers/server';
import { BaseContext, renderDescriptionForItemPricing } from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../components/BaseContainer';
import ArchiveButton from '../../components/common/buttons/ArchiveButton';
import AddOrEditBillableItemPricingModal from '../../components/modals/AddOrEditBillableItemPricingModal';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import MapleTable from '../../components/MapleTable';
import Button from '../../components/common/buttons/Button';
import SectionNav from "../../components/SectionNav";
import CopyableComponent from "../../components/CopyableComponent";
import EmptyState from "../../components/EmptyState";
const _ = require('lodash');

function BillableItemPricings() {
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const { company, getApiUrl, getCompanySpecificUrl, setPageTitle } = useContext(BaseContext);
    const [loading, setLoading] = useState(true);
    const [itemPricings, setItemPricings] = useState([]);
    const [billableItems, setBillableItems] = useState([]);
    const [billableItemPricingToEdit, setBillableItemPricingToEdit] = useState(null);
    const [showAddOrEditBillableItemPricingModal, setShowAddOrEditBillableItemPricingModal] = useState(false);
    const [filteredPricings, setFilteredPricings] = useState([]);
    const [hasArchivedPricings, setHasArchivedPricings] = useState(false);
    const [activeNav, setActiveNav] = useState("ACTIVE");

    useEffect(() => {
        setPageTitle(`Billable Item Prices`);
    }, []);

    const fetchData = (skipCache=false) => {
        serverPost(getApiUrl("/item_pricings/find"), { company_id: company.id }, { skipCache }).then((res) => {
            setItemPricings(res);
            setLoading(false);
        });

        serverPost(getApiUrl("/billable/items/find"), { company_id: company.id }, { skipCache }).then((res) => {
            setBillableItems(res);
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setHasArchivedPricings(!_.isEmpty(_.filter(itemPricings, p => p.state === "ARCHIVED")));
        setFilteredPricings(_.filter(itemPricings, p => p.state === activeNav));
    }, [itemPricings, activeNav])

    const onModalClose = (didUpdate) => {
        setShowAddOrEditBillableItemPricingModal(false);
        if (didUpdate) {
            fetchData(true);
        }
    }

    const onAddBillablePricing = () => {
        setShowAddOrEditBillableItemPricingModal(true);
        setBillableItemPricingToEdit(null);
    }

    const onEditBillablePricing = (pricing) => {
        setShowAddOrEditBillableItemPricingModal(true);
        setBillableItemPricingToEdit({...pricing});
    }

    const onCreateBillableItem = () => {
        console.log("In item pricings on create billable item");
        navigate(getCompanySpecificUrl("/billable_items"));
    }

    const onArchiveItemPricing = (pricing) => {
        serverPatch(getApiUrl(`/item_pricings/${pricing.id}`), { state: "ARCHIVED" }).then((res) => {
            if (res) {
                fetchData(true);
            }
        })
    }

    const onNavClick = (tabId) => {
        setActiveNav(tabId);
    }

    const tabItems = [{
        'label': 'Active',
        'id': 'ACTIVE',
        active: activeNav === "ACTIVE"
    }, {
        'label': 'Archived',
        'id': 'ARCHIVED',
        active: activeNav === "ARCHIVED"
    }]

    return (
        <BaseContainer>
            <ContentContainer>
                <Section
                    loading={loading}
                    title="Billable Item Prices"
                    subtitle={"A list of all billable item prices. These are reuseable prices that can be used to setup product prices."}
                    actions={!_.isEmpty(filteredPricings) && !loading && [
                    {icon: "fa-plus", label: "Add Item Pricing", onClick: onAddBillablePricing}
                ]}>
                    {
                        hasArchivedPricings &&
                        <>
                            <SectionNav items={tabItems} onClick={onNavClick} />
                        </>
                    }
                    { !_.isEmpty(filteredPricings) &&
                    <MapleTable>
                        <MapleTable.Content>
                            <thead>
                                <tr>
                                    <MapleTable.TH>{ t('common.name') }</MapleTable.TH>
                                    <MapleTable.TH>{ t('common.price') }</MapleTable.TH>
                                    <MapleTable.TH></MapleTable.TH>
                                    <MapleTable.TH className="text-end"></MapleTable.TH>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                            {
                                _.map(filteredPricings, (pricing, i) =>
                                    <tr key={i}>
                                        <td>
                                            <div className="body1">
                                                { pricing.name }
                                                {
                                                    pricing.external_name &&
                                                        <span className="text-sm font-normal text-gray-500"> ({ pricing.external_name })</span>
                                                }
                                            </div>
                                            <div className="mt-0 text-gray-500">{ pricing.description }</div>
                                        </td>
                                        <td>
                                            { renderDescriptionForItemPricing(pricing, false, _.find(billableItems, (bi) => bi.id === pricing.item_id), billableItems) }
                                        </td>
                                        <td className="max-sm:hidden">
                                            <CopyableComponent value={ pricing.id }/>
                                        </td>
                                        <td className="text-end">
                                        {
                                            pricing.state !== "ARCHIVED" &&
                                                <div className="flex flex-row gap-1 justify-content-end">
                                                    <Button variant="text-primary" size="sm" onClick={() => onEditBillablePricing(pricing)}>Edit</Button>
                                                    {
                                                        pricing.editable &&
                                                        <ArchiveButton size="sm" onDelete={() => onArchiveItemPricing(pricing)}></ArchiveButton>
                                                    }
                                                </div>
                                        }
                                        </td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </MapleTable.Content>
                    </MapleTable>
                    }
                    {
                        _.isEmpty(filteredPricings) &&
                            <EmptyState
                                title={"No item prices."}
                                subtitle={"Get started by creating a new one."}
                                buttonLabel={"New Price"}
                                onClick={onAddBillablePricing}
                            />
                    }
                </Section>
            </ContentContainer>
            <AddOrEditBillableItemPricingModal
                show={showAddOrEditBillableItemPricingModal}
                onClose={onModalClose}
                pricingToEdit={billableItemPricingToEdit}
                billableItems={billableItems}
                onCreateBillableItem={onCreateBillableItem}
            />
        </BaseContainer>
    );
}

export default BillableItemPricings;
