import '../App.scss';
import React from 'react';
import Header from './layout/header/Header';
import classnames from 'classnames';
import { Container } from 'react-bootstrap';
import Notification from './Notification';

function BaseContainerWithoutNav(props) {
	return (
		<div className='page-wrapper'>
			<div className='right-wrapper'>
				<Notification />
				<Header />
				<Container fluid className={classnames('content-wrapper', props.containerClassName)}>
					{props.children}
				</Container>
			</div>
		</div>
	);
}

export default BaseContainerWithoutNav;
