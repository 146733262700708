import '../../App.scss';
import '../../css/modals.scss';
import React, {createRef, useContext, useMemo} from 'react';
import { serverPost } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import BaseAddDiscountModal from "./BaseAddDiscountModal";
import BaseSideModal from "./BaseSideModal";
import {Col, Row} from "react-bootstrap";
import SubmitButton from "../common/buttons/SubmitButton";
import moment from "moment/moment";
const _ = require('lodash');

function AddDiscountModal(props) {
    const { t } = useTranslation('common');
    const { getApiUrl } = useContext(BaseContext);
    const discountRef = createRef();

    const initialFields = useMemo(() => {
        return {
            customer: props.customer,
            expiration_date: moment().endOf('day')
        }
    }, [props.customer])

    const addDiscount = (data, errorHandler) => {
        let discountData = null;
        if (discountRef.current) {
            discountData = discountRef.current.getDiscountData(data, errorHandler);
        }
        serverPost(getApiUrl("/discounts"), discountData, {}, errorHandler).then((res) => {
            if (res) {
                props.onClose(true);
            }
        })
    }

    const onFieldChange = (name, value) => {
        if (discountRef.current) {
            discountRef.current.onFieldChange(name, value);
        }
    }

    return (
        <BaseSideModal {...props}>
            <BaseSideModal.Form initialFormFields={initialFields} onFieldChange={onFieldChange} onSubmit={addDiscount}>
                <BaseSideModal.Header title={"Add Discount"} />
                <BaseSideModal.Body>
                    <BaseAddDiscountModal ref={discountRef} requireCustomer customer={props.customer} />
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <Row>
                        <Col md="12" className="text-end">
                            <SubmitButton variant="primary">Add Discount</SubmitButton>
                        </Col>
                    </Row>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );
}

export default AddDiscountModal;
