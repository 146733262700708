import '../App.scss';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { BaseContext } from '../helpers/common';
import BaseContainerWithoutNav from './BaseContainerWithoutNav';
import { Container, Row, Col } from 'react-bootstrap';
import Button from './common/buttons/Button';
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Notification from "./Notification";
const _ = require('lodash');

function Flow(props) {
    const { t } = useTranslation('common');
    const { getCompanySpecificUrl } = useContext(BaseContext);
    const navigate = useNavigate();
    const [numberOfPages, setNumberOfPages] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const childRoutes = useRef([]);
    const [donePath, setDonePath] = useState(null);
    const [baseUrl, setBaseUrl] = useState("");
    const visibilityMap = useRef({});

    const [showPrev, setShowPrev] = useState(true);
    const [showNext, setShowNext] = useState(true);
    const [showProgress, setShowProgress] = useState(true);

    let location = useLocation();

    useEffect(() => {
        console.log("Location visibility map is " + JSON.stringify(location.state && location.state.visibilityMap));
        if (location.state && !_.isNil(location.state.visibilityMap)) {
            visibilityMap.current = location.state.visibilityMap;
        } else {
            visibilityMap.current = _.mapValues(_.keyBy(_.values(props.childRoutes), (a) => a.props.path), (b) => !b.props.hide);
        }
        const filteredRoutes = _.filter(props.childRoutes, (p) => visibilityMap.current[p.props.path]);
        childRoutes.current = filteredRoutes;
        setNumberOfPages(filteredRoutes.length);
        let cpage = _.findIndex(props.childRoutes, (r) => window.location.pathname.endsWith(r.props.path));
        if (cpage === -1) {
            cpage = 0;
            let pname = location.pathname.endsWith('/') ? location.pathname : location.pathname + "/";
            navigate(`${pname}${filteredRoutes[0].props.path}`, { replace: true, state:{ visibilityMap: visibilityMap.current } })
        }
        setCurrentPage(cpage);
        setBaseUrl(window.location.pathname.slice(0, -1*props.childRoutes[cpage].props.path.length));
    }, [props.childRoutes, location, navigate])

    const onPrev = () => {
        if (currentPage !== 0) {
            const prevPage = currentPage - 1;
            const nextUrl = window.location.pathname.slice(0, -1*childRoutes.current[currentPage].props.path.length) + childRoutes.current[prevPage].props.path;
            navigate(nextUrl, { state:{ visibilityMap: visibilityMap.current } });
            setCurrentPage(prevPage);
        }
        setShowNext(true);
        setShowPrev(true);
    }

    const onNext = (options) => {
        let adjustedBaseUrl = baseUrl;
        if (!_.isNil(options) && !_.isEmpty(options)) {
            if (options.baseUrl) {
                adjustedBaseUrl = options.baseUrl;
                setBaseUrl(options.baseUrl);
            }
        }
        const nextPage = currentPage + 1;
        if (nextPage === childRoutes.current.length) {
            if (donePath) {
                navigate(donePath);
            } else {
                navigate(getCompanySpecificUrl("/"));
            }
        } else {
            const nextUrl = adjustedBaseUrl + childRoutes.current[nextPage].props.path;
            navigate(nextUrl, { state:{ visibilityMap: visibilityMap.current } });
            setCurrentPage(nextPage);
        }
        setShowNext(true);
        setShowPrev(true);
    }

    const navigateTo = (path) => {
        const routeIndex = _.findIndex(childRoutes.current, (cr) => cr.props.path === path)
        if (routeIndex !== -1) {
            const nextUrl = baseUrl + path;
            navigate(nextUrl, { state:{ visibilityMap: visibilityMap.current } });
            setCurrentPage(routeIndex);
        }
    }

    const setVisibility = (path, shouldShow) => {
        visibilityMap.current[path] = shouldShow;
        childRoutes.current = _.filter(props.childRoutes, (p) => visibilityMap.current[p.props.path]);
    }

    const shouldShowPrev = showPrev && currentPage !== 0;
    const shouldShowNext = showNext;
    const isLastPage = currentPage === (numberOfPages - 1);
    return (
        <BaseContainerWithoutNav>
            <Notification/>
            <Container className="flow-container">
                <Row className="flow-content d-grid">
                    <Outlet context={{
                        baseUrl,
                        setDonePath,
                        setShowNext,
                        setShowPrev,
                        setShowProgress,
                        navigateTo,
                        setVisibility,
                        next: onNext,
                        prev: onPrev
                    }} />
                </Row>
                <Row>
                    <Col md="3">
                    {
                        shouldShowPrev &&
                            <Button variant="outline-primary" onClick={onPrev}>{ t('common.back') }</Button>
                    }
                    </Col>
                    <Col md="6" className="text-center">
                    {
                        showProgress &&
                            <p>{ currentPage + 1 } out of { numberOfPages }</p>
                    }
                    </Col>
                    <Col md="3" className="text-end">
                    {
                        shouldShowNext &&
                            <Button variant="primary" onClick={onNext}>{ isLastPage ? t('common.done') : t('common.next') }</Button>
                    }
                    </Col>
                </Row>
            </Container>

        </BaseContainerWithoutNav>
    );
}

export default Flow;
