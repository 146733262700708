import '../App.scss';
import React, {useState, useEffect, useContext, forwardRef, useImperativeHandle} from 'react';
import {
    BaseContext,
    getDescriptionForDiscount,
    getDescriptionForCreditState,
    UserContext
} from '../helpers/common';
import {serverDelete, serverPost} from '../helpers/server';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Section from './Section';
import moment from 'moment';
import DeleteButton from "./DeleteButton";
import AddDiscountModal from "./modals/AddDiscountModal";
import MapleTable from "./MapleTable";
const _ = require('lodash');

const DiscountListSection = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        refresh() {
            onSearch(true);
        },
    }));

    const { t } = useTranslation('common')
    const navigate = useNavigate();
    const { userInfo } = useContext(UserContext);
    const { getApiUrl, getCompanySpecificUrl, hasAccess } = useContext(BaseContext);
    const [customer, setCustomer] = useState(null);
    const [discounts, setDiscounts] = useState([]);
    const [showAddDiscountModal, setShowAddDiscountModal] = useState(false);
    const [allowCreation, setAllowCreation] = useState(true);
    const [loading, setLoading] = useState(true);

    const hasPricingPermission = hasAccess("pricing", userInfo, "read");
    const hasDiscountsWritePermission = hasAccess("promotions", userInfo, "write");
    const hasPricingWritePermission = hasPricingPermission && customer && !customer.managed_externally;

    useEffect(() => {
        if (!_.isNil(props.customer)) {
            setCustomer(props.customer);
        }
    }, [props.customer])

    useEffect(() => {
        if (!_.isNil(props.allowCreation)) {
            setAllowCreation(props.allowCreation);
        }
    }, [props.allowCreation])

    useEffect(() => {
        if (!_.isNil(props.discounts)) {
            setDiscounts(props.discounts);
        }
    }, [props.discounts])

    const onSearch = (restart = true) => {
        if (_.isNil(customer) || _.isEmpty(customer)) {
            return;
        }
        const params = {
            customer_id: customer.id,
            state: "ACTIVE",
            ...props.extraQueryFilters,
        }
        serverPost(getApiUrl("/discounts/find"), params).then((res) => {
            setLoading(false);
            if (res) {
                setDiscounts(res);
            }
        });
    };

    useEffect(() => {
        onSearch(true);
    }, [customer]);

    const applyDiscount = () => {
        setShowAddDiscountModal(true);
    }

    const revokeDiscount = (discount) => {
        serverDelete(getApiUrl(`/discounts/${discount.id}`)).then((res) => {
            onSearch();
        })
    }

    const onModalClose = (didUpdate) => {
        setShowAddDiscountModal(false);
        if (didUpdate) {
            onSearch();
        }
    }

    return (
        <Section title="Discounts" variant={props.variant} hideSubtitle={props.hideSubtitle} className="mt-4" actions={allowCreation && hasDiscountsWritePermission && [{
            variant: "primary",
            icon: "fa-plus",
            label: "Create Discount",
            onClick: applyDiscount
        }]}>
            {
                <MapleTable>
                    <MapleTable.Content>
                        <thead>
                        <tr>
                            <MapleTable.TH>Issued Date</MapleTable.TH>
                            <MapleTable.TH>Name</MapleTable.TH>
                            <MapleTable.TH>Amount</MapleTable.TH>
                            <MapleTable.TH>Expiry</MapleTable.TH>
                            <MapleTable.TH>Status</MapleTable.TH>
                            <MapleTable.TH></MapleTable.TH>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                        {
                            _.map(discounts, (row, i) =>
                                <tr key={i} className={classnames(row.state !== "ACTIVE" && "de-highlighted")}>
                                    <td>{moment(row.created_at).format("MMM D, YYYY")}</td>
                                    <td>{row.name}</td>
                                    <td>{getDescriptionForDiscount(row, getCompanySpecificUrl)}</td>
                                    <td>
                                        {
                                            row.expiration_date ?
                                                moment(row.expiration_date).format("MMM D, YYYY")
                                                : <div className="gray2">None</div>
                                        }
                                    </td>
                                    <td className="w-px whitespace-nowrap">{getDescriptionForCreditState(row.state)}</td>
                                    <td className="w-px whitespace-nowrap">
                                        {
                                            row.state === "ACTIVE" && hasDiscountsWritePermission &&
                                            <DeleteButton title="Revoke Discount?"
                                                          body="Are you sure you want to revoke the discount?"
                                                          onDelete={() => revokeDiscount(row)}/>
                                        }
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                    </MapleTable.Content>
                </MapleTable>
            }
            <AddDiscountModal show={showAddDiscountModal} onClose={onModalClose} customer={customer} />
        </Section>
    );
})

export default DiscountListSection;
