import '../../App.scss';
import '../../css/modals.scss';
import React, {createRef, useState, useEffect, useContext} from 'react';
import BaseForm from '../BaseForm';
import SubmitButton from '../common/buttons/SubmitButton';
import BaseSideModal from '../modals/BaseSideModal';
import {
    BaseContext,
    getInvoicePaymentTermOptions,
    getPaymentDataFromPaymentMethod,
    getPaymentMethodDescription, updateFormField
} from '../../helpers/common';
import {serverFetch, serverPost} from '../../helpers/server';
import { Row, Col, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomerInput from "../CustomerInput";
import ProductPricingSelection from "../ProductPricingSelection";
import AddOrEditProductPricingModal2 from "./AddOrEditProductPricingModal2";
const _ = require('lodash');

function AddOneTimeChargesToCustomerModal(props) {
    const { t } = useTranslation('common');
    const { company, getApiUrl } = useContext(BaseContext);
    const [initialFields, setInitialFields] = useState({aggregate: {}});
    const [type, setType] = useState("IMMEDIATE");
    const [error, setError] = useState(null);
    const [autoCharges, setAutoCharges] = useState(true);
    const [invoicePaymentTerms, setInvoicePaymentTerms] = useState(null);
    const [settings, setSettings] = useState({});
    const [customer, setCustomer] = useState(null);
    const [paymentMethods, setPaymentMethods] = useState([])
    const [showAddProductPricingModal, setShowAddProductPricingModal] = useState(false)
    const [createdProductPricing, setCreatedProductPricing] = useState(null)
    const [paymentMethodOptions, setPaymentMethodOptions] = useState([{
        value: null,
        label: "Use default customer payment method"
    }]);
    const pricingsRef = createRef();

    useEffect(() => {
        setInitialFields(prevFields => {
            const newFields = {...prevFields};
            newFields.customer = props.customer;
            return newFields;
        });
        setCustomer(props.customer);
    }, [props.customer]);

    useEffect(() => {
        serverFetch(getApiUrl("/settings")).then((res) => {
            setSettings(res);
        });
    }, []);

    useEffect(() => {
        if (props.show && !_.isNil(customer)) {
            fetchPaymentMethods(true);
        }
    }, [props.show, customer]);

    useEffect(() => {
        const options = _.map(paymentMethods, (pm) => {
            return {
                value: pm.id,
                label: getPaymentMethodDescription(t, pm)
            }
        })
        options.unshift({
            value: null,
            label: "Use default customer payment method"
        })
        setInitialFields(prevFields => {
            const newFields = {...prevFields};
            newFields.payment_method_id = null;
            return newFields;
        });
        setPaymentMethodOptions(options)
    }, [paymentMethods]);

    const fetchPaymentMethods = (skipCache=false) => {
        serverFetch(getApiUrl(`/customers/${customer.id}/payment_methods`), { skipCache: skipCache }).then((res) => {
            if (res) {
                setPaymentMethods(res.methods);
            }
        });
    }

    const addItems = (onetimeFields, errorHandler) => {
        let pricingData = {};
        if (pricingsRef.current) {
            const validationResult = pricingsRef.current.validate(onetimeFields);
            if (!_.isNil(validationResult)) {
                setError(validationResult)
                return;
            }

            pricingData = pricingsRef.current.getPricingSelectionFields(onetimeFields);
        }
        setError(null);
        const onetimeData = {
            ...pricingData,
            company_id: company.id,
            customer_id: props.customer.id,
            type: onetimeFields.type,
            auto_charges: _.isNil(onetimeFields.auto_charges) ? false: onetimeFields.auto_charges
        }
        if (onetimeData.type === "IMMEDIATE") {
            onetimeData.invoice_due_date_from_creation = onetimeFields.invoice_due_date_from_creation;
            if (!_.isNil(onetimeFields.invoice_payment_terms)) {
                if (onetimeFields.invoice_payment_terms !== "custom") {
                    onetimeData.invoice_due_date_from_creation = parseInt(onetimeFields.invoice_payment_terms);
                }
            }
        }
        if (onetimeFields.payment_method_id) {
            const method = _.find(paymentMethods, p => p.id === onetimeFields.payment_method_id);
            if (method) {
                const paymentData = getPaymentDataFromPaymentMethod(method);
                if (paymentData) {
                    onetimeData.payment_data = paymentData
                }
            }
        }
        serverPost(getApiUrl("/one_times"), onetimeData, {}, errorHandler).then((res) => {
            if (res) {
                props.onClose(true);
            }
        });
    }

    const onProductPricingCreated = (productPricing) => {
        setTimeout(() => {
            setCreatedProductPricing(productPricing);
        }, 100);
    }

    useEffect(() => {
        let show = props.show && !showAddProductPricingModal;
        if (!_.isNil(createdProductPricing) && show) {
            if (pricingsRef.current) {
                pricingsRef.current.onProductPricingCreated(createdProductPricing)
            }
            setCreatedProductPricing(null)
        }
    }, [createdProductPricing, props.show, showAddProductPricingModal])

    const onModalClose = () => {
        setShowAddProductPricingModal(false);
    }

    const onFieldChange = (name, value) => {
        if (name === "type") {
            setType(value);
        } else if (name === "auto_charges") {
            setAutoCharges(value);
        } else if (name === "invoice_payment_terms") {
            setInvoicePaymentTerms(value);
        } else if (_.startsWith(name, "phases.")) {
            if (pricingsRef.current) {
                pricingsRef.current.onFieldChange(name, value);
            }
        } else {
            setInitialFields(prevFields => {
                const newFields = {...prevFields};
                updateFormField(newFields, name, value);
                return newFields
            })
        }
    }

    const typeOptions = [
        { value: "IMMEDIATE", label: "Charge these immediately" },
        { value: "NEXT", label: "Add these charges to the next invoice" },
    ]

    const paymentMechanismOptions = [
        { value: true, label: "Collect payment information and charge automatically.",
            disabled: _.isNil(settings.payment_config), disabledDescription: "* Payment Provider needs to be setup" },
        { value: false, label: "Email invoice to customer manually" },
    ]

    return (
        <>
            <BaseSideModal
                {...props}
                show={props.show && !showAddProductPricingModal}
                size="lg"
            >
                <BaseSideModal.Form initialFormFields={initialFields} onFieldChange={onFieldChange} onSubmit={addItems} >
                    <BaseSideModal.Header title={"Add One Time Charges"} />
                    <BaseSideModal.Body>
                        <Row>
                            <CustomerInput colSpan="6" defaultCustomer={props.customer} required />
                        </Row>
                        <Row>
                            <BaseForm.Input colSpan="6" name="type" label={"When should these be charged?"} type="select"
                                            options={typeOptions} showSearch={false} />
                            {
                                type === "IMMEDIATE" &&
                                    <BaseForm.Input colSpan="6" name="auto_charges" label={t('subscriptions.payment_mechanism')} type="select"
                                                    options={paymentMechanismOptions} showSearch={false} />
                            }
                        </Row>
                        <Row>
                            <Col md="6"></Col>
                            {
                                !autoCharges &&
                                <>
                                    <BaseForm.Input type="select" colSpan="3" name="invoice_payment_terms" label="Payment Terms" options={getInvoicePaymentTermOptions()} showSearch={false} />
                                    {
                                        invoicePaymentTerms === "custom" &&
                                        <BaseForm.Input type="number" colSpan="3" name="invoice_due_date_from_creation" label="Due Date (in days)" step="1" min="0" />
                                    }
                                </>
                            }
                            {
                                autoCharges && type === "IMMEDIATE" &&
                                <>
                                    <BaseForm.Input colSpan="6" type="select" name="payment_method_id" label={"Payment Method"} options={paymentMethodOptions} showSearch={false}/>
                                </>
                            }
                        </Row>
                        <Alert>
                            <span className="caption">Note: Only one time charges are allowed to be added here. If you would like to add a recurring charge, please create a subscription instead.</span>
                        </Alert>
                        <ProductPricingSelection
                            ref={pricingsRef} allowDiscounts={true} excludeBundlePricing
                            extraQueryFilters={{ type: "ONETIME_PRICING" }} allowCreation
                            onCreateProductPricing={() => setShowAddProductPricingModal(true)}
                        />
                        {
                            error &&
                                <div className="form-error-message">{ error }</div>
                        }
                    </BaseSideModal.Body>
                    <BaseSideModal.Footer>
                        <Row>
                            <Col md="12" className="text-end">
                                <SubmitButton variant="primary">Add Charges</SubmitButton>
                            </Col>
                        </Row>
                    </BaseSideModal.Footer>
                </BaseSideModal.Form>
            </BaseSideModal>
            <AddOrEditProductPricingModal2
                formClassName={"ml-4"}
                show={showAddProductPricingModal}
                onProductPricingCreated={onProductPricingCreated}
                allowOnetimeOnly
                onClose={onModalClose}
            />
        </>
    );
}

export default AddOneTimeChargesToCustomerModal;
