import '../../../App.scss';
import React, { useContext } from 'react';
import { BaseFormContext } from '../../BaseForm';
import { Button, Spinner } from 'react-bootstrap';
import classnames from 'classnames';
import { UserContext } from '../../../helpers/common';
const _ = require('lodash');

function SubmitButton(props) {
	const userContext = useContext(UserContext);
	const { isMapleUser } = !_.isNil(userContext) ? userContext : { isMapleUser: false };

	const { customErrorFields } = useContext(BaseFormContext);
	const hasError =
		!_.isNil(customErrorFields) &&
		!_.isUndefined(customErrorFields) &&
		!_.isNil(customErrorFields.error_message) &&
		!_.isEmpty(customErrorFields.error_message);
	const pprops = { ...props };
	delete pprops['errorOnRight'];

	const getUpdatedButtonColor = (variant) => {
		if (!isMapleUser) return '';

		switch (variant) {
			case '':
			case undefined:
			case 'primary':
				return 'btn-primary-updated';
			case 'outline-primary':
				return 'btn-outline-primary-updated';
			case 'danger':
				return 'btn-danger-updated';
			case 'text-danger':
				return 'btn-text-danger-updated';
			case 'text-primary':
				return 'btn-text-primary-updated';
			default:
				return '';
		}
	};

	return (
		<div className={classnames('flex items-center gap-2', props.className)}>
			{hasError && !props.errorOnRight && (
				<span className='form-error-message'>{customErrorFields.error_message}</span>
			)}
			<Button
				type='submit'
				{...pprops}
				style={props.color && { background: props.color }}
				className={classnames(
					{ 'btn-updated': isMapleUser },
					getUpdatedButtonColor(props.variant),
					'whitespace-nowrap',
				)}
			>
				<div className='d-flex flex-column align-items-center'>
					<Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true'>
						&nbsp;
					</Spinner>
					<span className='submit-button-content'>{props.children}</span>
				</div>
			</Button>
			{hasError && props.errorOnRight && (
				<span className='form-error-message'>{customErrorFields.error_message}</span>
			)}
		</div>
	);
}

export default SubmitButton;
