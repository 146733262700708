import '../App.scss';
import '../css/invoice.scss';
import React, {useEffect, useState, useContext, createRef, useRef} from 'react';
import { serverFetch, serverPatch, notifyEvent } from '../helpers/server';
import { BaseContext, IntegrationContext } from '../helpers/common';
import {useParams} from "react-router-dom";
import MapleTable from "./MapleTable";
import BaseForm from "./BaseForm";
import SubmitButton from "./common/buttons/SubmitButton";
import Notification from "./Notification";
import Section from "./Section";
const _ = require('lodash');

function IntegrationWorkflowActionsComponent(props) {
    const { getApiUrl } = useContext(BaseContext);
    const { integration } = useContext(IntegrationContext);
    let { feature } = useParams();
    const [remoteToMapleActions, setRemoteToMapleActions] = useState([]);
    const [mapleToRemoteActions, setMapleToRemoteActions] = useState([]);
    const [initialFields, setInitialFields] = useState(null);
    const [featureFieldName, setFeatureFieldName] = useState(null);
    const selectedFeature = feature || props.feature

    useEffect(() => {
        fetchIntegrationMapping();
    }, [selectedFeature]);

    useEffect(() => {
        if (selectedFeature.toUpperCase() === "ACCOUNTING") {
            setFeatureFieldName("accounting_data");
        } else if (selectedFeature.toUpperCase() === "CRM") {
            const imap = {}
            if (integration.crm_data && integration.crm_data.remote_to_maple_action_mapping) {
                imap["remote_to_maple_action_mapping"] = {}
                _.each(integration.crm_data.remote_to_maple_action_mapping, (v, k) => {
                    imap["remote_to_maple_action_mapping"][k] = v.maple_action_details.action;
                })
            }
            if (integration.crm_data && integration.crm_data.maple_to_remote_action_mapping) {
                imap["maple_to_remote_action_mapping"] = {}
                _.each(integration.crm_data.maple_to_remote_action_mapping, (v, k) => {
                    imap["maple_to_remote_action_mapping"][k] = v.remote_action_details.action;
                })
            }
            setInitialFields(imap);
            setFeatureFieldName("crm_data");
        }
    }, [selectedFeature, integration])

    const fetchIntegrationMapping = () => {
        serverFetch(getApiUrl(`/integrations/${integration.id}/available_actions/${selectedFeature.toUpperCase()}`), { skipCache: true }).then(res => {
            if (res) {
                setRemoteToMapleActions(res[selectedFeature.toUpperCase()].remote_to_maple_available_actions);
                setMapleToRemoteActions(res[selectedFeature.toUpperCase()].maple_to_remote_available_actions);
            }
        });
    }

    const onUpdateMapping = (data) => {
        let updatedFeatureData = {};
        updatedFeatureData[featureFieldName] = {}
        if (data.remote_to_maple_action_mapping) {
            const mmap = {}
            _.map(data.remote_to_maple_action_mapping, (v, k) => {
                if (v) {
                    mmap[k] = {
                        remote_action: k,
                        maple_action_details: {
                            action: v
                        }
                    }
                }
            })
            updatedFeatureData[featureFieldName]["remote_to_maple_action_mapping"] = mmap
        }
        if (data.maple_to_remote_action_mapping) {
            const mmap = {}
            _.map(data.maple_to_remote_action_mapping, (v, k) => {
                if (v) {
                    mmap[k] = {
                        maple_action: k,
                        remote_action_details: {
                            action: v
                        }
                    }
                }
            })
            updatedFeatureData[featureFieldName]["maple_to_remote_action_mapping"] = mmap
        }

        const updateData = {
            feature_data: updatedFeatureData
        }

        serverPatch(getApiUrl(`/integrations/${integration.id}`), updateData).then(res => {
            if (res) {
                notifyEvent('integration_settings');
                fetchIntegrationMapping();
                Notification.Success("Successfully updated");
                if (props.onUpdate) {
                    props.onUpdate();
                }
            }
        })
    }

    const renderRemoteToMapleAction = (row, i) => {
        const possibleActions = _.map(row.destination_actions, (action) => {
            return {
                value: action.key,
                label: action.name
            }
        })
        possibleActions.unshift({ value: null, label: "Do nothing" })
        return (
            <tr key={i} className="!py-0">
                <td><span className="!py-0 text-sm font-semibold">{ row.action.name }</span></td>
                <td className="!py-0 w-px whitespace-nowrap">
                    <BaseForm.SelectGroup
                        name={`remote_to_maple_action_mapping.${row.action.key}`} options={possibleActions} showSearch={false}
                        formClassName="thin" align="end" borderless/>
                </td>
            </tr>
        )
    }

    const renderMapleToRemoteAction = (row, i) => {
        const possibleActions = _.map(row.destination_actions, (action) => {
            return {
                value: action.key,
                label: action.name
            }
        })
        possibleActions.unshift({ value: null, label: "Do nothing" })
        return (
            <tr key={i} className="!py-0">
                <td><span className="!py-0 text-sm font-semibold">{ row.action.name }</span></td>
                <td className="!py-0 w-px whitespace-nowrap">
                    <BaseForm.SelectGroup
                        name={`maple_to_remote_action_mapping.${row.action.key}`} options={possibleActions} showSearch={false}
                        formClassName="thin" align="end" borderless/>
                </td>
            </tr>
        )
    }

    return (
        <Section title={`Action Mapping`} subtitle={`Actions can be mapped between ${integration.name} and Maple.`}>
            {
                (!_.isEmpty(remoteToMapleActions) || !_.isEmpty(mapleToRemoteActions)) &&
                    <BaseForm initialFormFields={initialFields} onSubmit={onUpdateMapping}>
                        <div className="max-w-3xl">
                            {
                                !_.isEmpty(remoteToMapleActions) &&
                                    <>
                                        <div className="mt-2">Choose how you would like to handle actions that happen in { integration.name }</div>
                                        <MapleTable>
                                            <MapleTable.Content overflow>
                                                <tbody>
                                                {
                                                    _.map(remoteToMapleActions, (row, i) => renderRemoteToMapleAction(row, i))
                                                }
                                                </tbody>
                                            </MapleTable.Content>
                                        </MapleTable>
                                    </>
                            }
                            {
                                !_.isEmpty(mapleToRemoteActions) &&
                                    <>
                                        <div className="mt-2">Choose what you would like to happen in { integration.name } when an action happens in Maple</div>
                                        <MapleTable>
                                            <MapleTable.Content overflow>
                                                <tbody>
                                                { 
                                                    _.map(mapleToRemoteActions, (row, i) => renderMapleToRemoteAction(row, i))
                                                }
                                                </tbody>
                                            </MapleTable.Content>
                                        </MapleTable>
                                    </>
                            }
                            <SubmitButton variant="primary">Update</SubmitButton>
                        </div>
                    </BaseForm>
            }
        </Section>
    );
}

export default IntegrationWorkflowActionsComponent;
