import '../../App.scss';
import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { serverFetch, serverDelete } from '../../helpers/server';
import {
    BaseContext,
    getDescriptionForCoupon,
    getLabelForCouponType
} from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../components/BaseContainer';
import KeyValueDisplay from '../../components/KeyValueDisplay2';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import Loader from '../../components/Loader';
import AddOrEditPromoCodeModal from '../../components/modals/AddOrEditPromoCodeModal';
import moment from 'moment';
import classnames from "classnames";
import DeleteButton from "../../components/DeleteButton";
import CopyableComponent from "../../components/CopyableComponent";
import MapleTable from "../../components/MapleTable";
import ErrorComponent from "../../components/ErrorComponent";
import Label from "../../components/Label";
const _ = require('lodash');

function Coupon(props) {
    const { t } = useTranslation('common');
    const { couponId } = useParams();
    const { getApiUrl, getCompanySpecificUrl, setPageTitle } = useContext(BaseContext);
    const [showAddOrEditPromoCodeModal, setShowAddOrEditPromoCodeModal] = useState(false);
    const [loadingCoupon, setLoadingCoupon] = useState(true);
    const [coupon, setCoupon] = useState({});
    const [details, setDetails] = useState({});

    useEffect(() => {
        setPageTitle(`Coupon - ${coupon.name}`);
    }, [coupon]);

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl(`/coupons/${couponId}`), { skipCache }).then((res) => {
            if (res) {
                setCoupon(res);
            }
            setLoadingCoupon(false);
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (_.isEmpty(coupon)) {
            return;
        }
        const data = {
            'ID': coupon.id,
            'Created At': moment(coupon.created_at).format("DD MMM, YYYY h:mm:ssa"),
            'Type': getLabelForCouponType(coupon.type),
            'Details': getDescriptionForCoupon(coupon, coupon.products, [coupon.product_pricing], []),
            'Number of Redemptions': <span>{ coupon.num_redemptions }</span>,
            'Max Redemptions': <span>{ coupon.max_redemptions }</span>,
            'Expiration Date': coupon.expiration_date && moment(coupon.expiration_date).format("DD MMM, YYYY h:mm:ssa"),
        };
        setDetails(data);
    }, [coupon]);

    const onModalClose = (didUpdate=false) => {
        setShowAddOrEditPromoCodeModal(false);
        fetchData(didUpdate);
    }

    const renderActions = () => {
        return null;
    }

    const archivePromoCode = (promoCode) => {
        serverDelete(getApiUrl(`/coupons/${couponId}/promo/${promoCode.id}`)).then(res => {
            fetchData(true);
        })
    }

    const renderCouponStatus = () => {
        if (coupon.state === "ACTIVE") {
            return (
                <Label.Success>Active</Label.Success>
            )
        } else if (coupon.state === "CONSUMED") {
            return (
                <Label.Warning>Consumed</Label.Warning>
            )
        } else if (coupon.state === "REVOKED") {
            return (
                <Label.Danger>Revoked</Label.Danger>
            )
        } else if (coupon.state === "EXPIRED") {
            return (
                <Label.Danger>Expired</Label.Danger>
            )
        }
    }

    const getDescriptionForPromoState = (state) => {
        if (state === "ACTIVE") {
            return (
                <Label.Success>Active</Label.Success>
            )
        } else if (state === "REVOKED") {
            return (
                <Label.Danger>Revoked</Label.Danger>
            )
        } else if (state === "ARCHIVED") {
            return (
                <Label.Info>Archived</Label.Info>
            )
        }
    }

    return (
        <BaseContainer>
            <Loader loading={loadingCoupon}>
            {
                _.isEmpty(coupon) ?
                    <ErrorComponent
                        title={"Coupon Not Found"}
                        description={"Oops, we can't find the coupon you are looking for."}
                        primaryAction={{ href:getCompanySpecificUrl("/coupons"), label:"Show all coupons" }}
                        hideSupport
                    />
                : <>
                    <ContentContainer variant="page" title={
                            <div className="d-flex flex-row gap-3">
                                <span>{ coupon.name }</span>
                                <div className="d-flex align-items-center gap-3">
                                    { renderCouponStatus() }
                                </div>
                            </div>
                        } customActions={ renderActions() }>
                        <Section title="Details" actions={[]}>
                            <KeyValueDisplay items={details} />
                        </Section>
                        <Section title="Promo Codes" className="mt-4" actions={[{
                            variant: "primary",
                            icon: "fa-plus",
                            label: "Create Promo Code",
                            onClick: () => setShowAddOrEditPromoCodeModal(true)
                        }]}>
                            {
                                <MapleTable>
                                    <MapleTable.Content>
                                        <thead>
                                            <tr>
                                                <MapleTable.TH>Promotion Code</MapleTable.TH>
                                                <MapleTable.TH>ID</MapleTable.TH>
                                                <MapleTable.TH>Expiration Date</MapleTable.TH>
                                                <MapleTable.TH>Creation Date</MapleTable.TH>
                                                <MapleTable.TH>Status</MapleTable.TH>
                                                <MapleTable.TH></MapleTable.TH>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200">
                                        {
                                            _.map(coupon.promo_codes, (row, i) =>
                                                <tr key={i} className={classnames(row.state !== "ACTIVE" && "de-highlighted")}>
                                                    <td>{row.code}</td>
                                                    <td>
                                                        <CopyableComponent value={row.code} />
                                                    </td>
                                                    <td>
                                                        {
                                                            row.expiration_date ?
                                                                moment(row.expiration_date).format("DD MMM, YYYY h:mm:ssa")
                                                            : <span className="gray3">None</span>
                                                        }
                                                    </td>
                                                    <td>
                                                        { moment(row.created_at).format("D MMM, YYYY") }
                                                    </td>
                                                    <td>
                                                        { getDescriptionForPromoState(row.state) }
                                                    </td>
                                                    <td className="text-end">
                                                        {
                                                            row.state === "ACTIVE" &&
                                                                <DeleteButton
                                                                    title="Archive Promo Code?"
                                                                    body="Are you sure you want to archive the promo code?"
                                                                    onDelete={() => archivePromoCode(row)}/>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </MapleTable.Content>
                                </MapleTable>
                            }
                        </Section>
                    </ContentContainer>
                </>
            }
            </Loader>
            <AddOrEditPromoCodeModal show={showAddOrEditPromoCodeModal} onClose={onModalClose} coupon={coupon} />
        </BaseContainer>
    );
}

export default Coupon;
