import '../../App.scss';
import React, { useEffect, useRef, useState, useContext, useMemo, createRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {serverFetch, serverPatch, serverPost} from '../../helpers/server';
import {
    BaseContext,
    getCustomerNameOrEmail,
    getInvoicePaymentTermFromDueDateFromCreation,
    getInvoicePaymentTermOptions,
    findCustomVariables,
    UserContext,
    renderCustomVariableInput,
    findNumberOfCounterSignatories,
    findNumberOfSignatories,
    validateSignatories,
    formatTerm, currencyFormatFromPrice, getPlanLengthOptions
} from '../../helpers/common';
import SubmitButton from '../../components/common/buttons/SubmitButton';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import ContentBox from '../../components/ContentBox';
import BaseForm from '../../components/BaseForm';
import ProductPricingSelection from '../../components/ProductPricingSelection';
import Loader from '../../components/Loader';
import {Row, Col, Breadcrumb} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import CustomerInput from "../../components/CustomerInput";
import Link from "../../components/Link";
import useGetMembers from "../../helpers/hooks/api/useGetMembers";
import BundlePricingTable from "../../components/BundlePricingTable";
import AddOrEditProductPricingModal2 from "../../components/modals/AddOrEditProductPricingModal2";
import AddOrEditCustomerModal from "../../components/modals/AddOrEditCustomerModal";
const _ = require('lodash');

function ContractCreate() {
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const { uuid } = useParams();
    const [ searchParams ] = useSearchParams();
    let defaultCustomerId = searchParams.get('customer');
    let previousSubscriptionId = searchParams.get('ps');
    let dealID = searchParams.get('did');
    let integrationID = searchParams.get('iid');

    const {isMapleUser} = useContext(UserContext);
    const { company, getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const { userInfo } = useContext(UserContext);
    const [contract, setContract] = useState({});
    const [teamMembers, setTeamMembers] = useState([]);
    const [teamMemberOptions, setTeamMemberOptions] = useState([]);
    const [counterSignatoryOptions, setCounterSignatoryOptions] = useState([]);
    const [settings, setSettings] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [productPricings, setProductPricings] = useState([]);
    const [showPOField, setShowPOField] = useState(false);
    const [error, setError] = useState(null);
    const pricingsRef = createRef();
    const [previousSubscription, setPreviousSubscription] = useState(null);
    const [defaultCustomer, setDefaultCustomer] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [sort, setSort] = useState("createdAtDesc");
    const [isEditing, setIsEditing] = useState(false);
    const [isTrial, setIsTrial] = useState(false);
    const [initialFields, setInitialFields] = useState({});
    const [startDateType, setStartDateType] = useState("ON_COMPLETION");
    const [invoicePaymentTerms, setInvoicePaymentTerms] = useState("IMMEDIATE");
    const [autoCharges, setAutoCharges] = useState(true);
    const [customVariables, setCustomVariables] = useState([]);
    const [availableCustomVariables, setAvailableCustomVariables] = useState([]);
    const [prefetchDealDetails, setPrefetchDealDetails] = useState(null);
    const [askForPhoneNumber, setAskForPhoneNumber] = useState(false);
    const [numberOfCounterSignatories, setNumberOfCounterSignatories] = useState(0);
    const [numberOfSignatories, setNumberOfSignatories] = useState(0);
    const [hasNonOneTimeItems, setHasNonOneTimeItems] = useState(false);
    const [signatoryError, setSignatoryError] = useState(null);
    const [showAddProductPricingModal, setShowAddProductPricingModal] = useState(false);
    const [showAddOrEditCustomerModal, setShowAddOrEditCustomerModal] = useState(false);
    const formRef = createRef();
    const signatoryNameUpdatedRef = useRef(false);
    const signatoryEmailUpdatedRef = useRef(false);
    const signatoryTitleUpdatedRef = useRef(false);
    const titleUpdatedRef = useRef(false);
    const { members: nonPendingMembers, fetchMembers } = useGetMembers(false);
    const defaultFields = useMemo(() => {
        return {
            term: {
                frequency: "YEAR",
                count: 1
            },
            phases: [{
                relative_term: {
                    count: 1,
                    frequency: "YEAR"
                }
            }]
        }
    }, []);

    useEffect(() => {
        setPageTitle(`Contracts`);
    }, []);

    useEffect(() => {
        serverFetch(getApiUrl("/settings")).then((res) => {
            setSettings(res);
            setInvoicePaymentTerms(getInvoicePaymentTermFromDueDateFromCreation(res.invoice_due_date_from_creation))
            if (_.isNil(res.payment_config)) {
                setInitialFields(prevFields => {
                    const newFields = {...prevFields};
                    newFields.auto_charges = false;
                    newFields.require_payment_method = false;
                    return newFields;
                });
            } else {
                setInitialFields(prevFields => {
                    const newFields = {...prevFields};
                    newFields.auto_charges = true;
                    newFields.require_payment_method = res.contract_payment_method_required;
                    return newFields;
                });
            }

            setInitialFields(prevFields => {
                const newFields = {...prevFields};
                newFields.invoice_payment_terms = getInvoicePaymentTermFromDueDateFromCreation(res.invoice_due_date_from_creation);
                newFields.invoice_due_date_from_creation = res.invoice_due_date_from_creation;

                if (res.default_contract_template_id) {
                    newFields.template_id = res.default_contract_template_id;
                }
                if (res.default_contract_counter_signatory_id) {
                    newFields.counter_signatories = [res.default_contract_counter_signatory_id]
                }
                return newFields;
            });
        });

        serverPost(getApiUrl("/proposals/variables/find"), {}).then((res) => {
            setAvailableCustomVariables(res);
        });
    }, []);

    useEffect(() => {
        if (prefetchDealDetails) {
            setInitialFields(prevFields => {
                const newFields = {...prevFields};
                if (prefetchDealDetails.customer) {
                    newFields.customer = prefetchDealDetails.customer;
                    newFields.signatories = [{
                        name: prefetchDealDetails.customer.name,
                        email: prefetchDealDetails.customer.email
                    }]
                }
                if (prefetchDealDetails.deal) {
                    newFields.title = prefetchDealDetails.deal.title;
                    if (prefetchDealDetails.deal.product_pricings) {
                        const prefetchConfig = {};
                        const prefetchDiscounts = {};
                        _.each(prefetchDealDetails.deal.product_pricings, (dealPP) => {
                            const pp = _.find(productPricings, p => p.id === dealPP.product_pricing_id);
                            if (pp) {
                                let percentageDiscount = null;
                                if (dealPP.discounts && dealPP.discounts.length > 0) {
                                    if (dealPP.discounts[0].type === "DISCOUNT_PERCENT") {
                                        percentageDiscount = dealPP.discounts[0].percent * 100
                                    }
                                }
                                _.each(pp.product_metric_pricings, (pmp) => {
                                    prefetchConfig[pmp.id] = {
                                        num_licenses: dealPP.num_licenses
                                    }
                                    if (percentageDiscount) {
                                        prefetchDiscounts[pmp.item_pricing_id] = {
                                            percentage: percentageDiscount
                                        }
                                    }
                                })
                            }
                        })
                        newFields.phases = [{
                            product_pricing_ids: _.map(prefetchDealDetails.deal.product_pricings, pp => pp.product_pricing_id),
                            config: prefetchConfig,
                            discounts: prefetchDiscounts
                        }]
                        if (pricingsRef.current) {
                            pricingsRef.current.setInitialData(newFields);
                        }
                    }
                }
                return newFields;
            });
        }
    }, [prefetchDealDetails, productPricings])

    useEffect(() => {
        if (dealID && integrationID) {
            serverFetch(getApiUrl(`/proposals/prefetch/${integrationID}/${dealID}`)).then((res) => {
                if (res) {
                    setPrefetchDealDetails(res);
                }
            })
        }
    }, [dealID, integrationID]);

    useEffect(() => {
        if (defaultCustomerId) {
            serverFetch(getApiUrl(`/customers/${defaultCustomerId}`), { skipCache: true }).then((res) => {
                setDefaultCustomer(res);
                setSelectedCustomer(res);
                setInitialFields(prevFields => {
                    const newFields = {...prevFields};
                    newFields.customer = res;
                    newFields.signatories = [{
                        name: res.name,
                        title: res.title,
                        email: res.email,
                        position: 0
                    }]
                    return newFields;
                });
            });
        }
    }, [defaultCustomerId])

    useEffect(() => {
        if (previousSubscriptionId) {
            serverFetch(getApiUrl(`/subscriptions/${previousSubscriptionId}`), { skipCache: true }).then((res) => {
                setPreviousSubscription(res);
                setInitialFields(prevFields => {
                    const newFields = {...prevFields};
                    newFields.phases = [{
                        product_pricing_ids: res.product_pricing_ids,
                        config: _.keyBy(res.config_items, 'product_metric_pricing_id'),
                        discounts: _.mapValues(_.keyBy(res.discounts, "item_pricing_id"), (v) => {
                            return {
                                percentage: v.percent * 100,
                                amount: v.amount.value_in_cents / 100,
                                type: v.type,
                                expiration_date: v.expiration_date,
                            };
                        }),
                        minimum_spend: res.minimum_spend && res.minimum_spend.value_in_cents/100,
                    }]
                    newFields.title = `${res.customer.org_name || res.customer.name} - ${company.name} Renewal Contract`
                    newFields.customer = res.customer;
                    newFields.signatories = [{
                        name: res.customer.name,
                        title: res.customer.title,
                        email: res.customer.email,
                        position: 0
                    }]
                    newFields.term = res.term;
                    return newFields;
                })
            });
        }
    }, [previousSubscriptionId]);

    useEffect(() => {
        if (userInfo) {
            const currentUser = _.find(nonPendingMembers, (m) => String(m.user.id) === String(userInfo.id));
            if (currentUser) {
                setInitialFields(prevFields => {
                    const newFields = {...prevFields};
                    newFields.owner = currentUser.id;
                    return newFields;
                })
            }
        }

        const teamOptions = _.map(nonPendingMembers, (member) => {
            return {
                value: member.id,
                label: member.user.name || member.user.email
            }
        })
        teamOptions.sort((a, b) => {
            if (a.label < b.label) {
                return -1
            } else {
                return 1
            }
        })
        const counterSignOptions = teamOptions.map((option) => option)

        teamOptions.unshift({value: null, label: "No one"})
        counterSignOptions.unshift({value: null, label: "Skip counter sign"})

        setTeamMembers(nonPendingMembers)
        setTeamMemberOptions(teamOptions)
        setCounterSignatoryOptions(counterSignOptions)
    }, [nonPendingMembers])

    useEffect(() => {
        const limit = 100
        const params = {
            company_id: company.id,
            sort_key: sort,
            pagination: {
                limit: limit
            },
        }
        serverPost(getApiUrl("/product_pricings/find"), params).then((res) => {
            if (res) {
                const results = res.results || [];
                setProductPricings(results);
            }
        });

        serverPost(getApiUrl("/proposals/templates/find"), {}).then((res) => {
            setLoading(false);
            setTemplates(res);
        });

        fetchMembers();
    }, [])

    useEffect(() => {
        if (!_.isNil(uuid)) {
            serverFetch(getApiUrl(`/proposals/${uuid}`)).then((res) => {
                setContract(res);
                setInitialFields({
                    ...res,
                    ...defaultFields,
                    owner: res.owner.id || null
                });
            })
            setIsEditing(true);
        } else {
            setInitialFields(defaultFields);
            setIsEditing(false);
        }
    }, [uuid])

    const contractErrorHandler = async (res, errorResponseJson, defaultErrorHandler) => {
        const errorJson = await res.json();
        console.log("The error json is " + JSON.stringify(errorJson))
        if (errorJson.error_message) {
            if (_.includes(errorJson.error_message, "Customer needs phone number")) {
                setAskForPhoneNumber(true);
            }
        }
        defaultErrorHandler(res, errorJson);
    }

    const createContract = async (data, errorHandler) => {
        setSignatoryError(null);
        if (askForPhoneNumber) {
            const customerUpdateData = {
                phone: data.customer_phone
            }
            const customerResult = await serverPatch(getApiUrl(`/customers/${data.customer.id}`), customerUpdateData);
            if (!customerResult) {
                console.log("The customer update didn't work.");
            }
        }

        let pricingData = {};
        if (pricingsRef.current) {
            const validationResult = pricingsRef.current.validate(data);
            if (!_.isNil(validationResult)) {
                setError(validationResult)
                return;
            }

            pricingData = pricingsRef.current.getPricingSelectionFields(data);
        }
        setError(null);
        let isToday = false;
        let startDate = null;
        if (data.start_date_type === "SPECIFIC_DATE") {
            isToday = moment(0, "HH").diff(data.start_date, "days") == 0;
            if (isToday) {
                startDate = moment();
            } else {
                startDate = moment(data.start_date).hour(12);
            }
        }
        let require_payment_method = data.auto_charges;
        if (!_.isNil(data.require_payment_method)) {
            require_payment_method = data.require_payment_method
        }
        const contractData = {
            ...pricingData,
            company_id: data.company_id,
            status: "DRAFT",
            auto_charges: data.auto_charges,
            require_payment_method: require_payment_method,
            title: data.title,
            owner_id: data.owner,
            term: data.term ? {
                frequency: data.term.frequency,
                count: parseInt(data.term.count)
            }: {
                frequency: "YEAR",
                count: 1
            },
            start_date: startDate && startDate.format(),
            expiration_date: data.expiration_date || null,
            template_id: data.template_id,
            type: "CONTRACT",
            invoice_due_date_from_creation: data.invoice_due_date_from_creation,
            po_number: data.po_number,
            custom_variables: data.custom_variables,
            parent_billed: data.parent_billed || false,
            trial: data.trial,
            start_type: startDateType,
        }
        if (data.counter_signatories) {
            data.counter_signatories = Object.keys(data.counter_signatories).reduce((acc, key) => {
                if (data.counter_signatories[key] && data.counter_signatories[key].length > 0) {
                    acc[key] = data.counter_signatories[key]
                }
                return acc
            }, {})
            contractData.counter_signatories = Object.keys(data.counter_signatories).map(key => {
                return {
                    company_user_id: (data.counter_signatories[key]),
                    position: parseInt(key)
                }
            })
        }
        if (data.signatories) {
            data.signatories = Object.keys(data.signatories).reduce((acc, key) => {
                if (data.signatories[key] && (data.signatories[key].name || data.signatories[key].email)) {
                    acc[key] = data.signatories[key]
                }
                return acc
            }, {})
            const err = validateSignatories(data.signatories)
            if (!_.isNil(err)) {
                setSignatoryError(err)
                return
            }
            contractData.signatories = Object.keys(data.signatories).map(key => {
                return {
                    name: (data.signatories[key].name),
                    title: data.signatories[key].title,
                    email: data.signatories[key].email,
                    position: parseInt(key)
                }
            })
        }
        if (data.trial) {
            contractData['trial_term'] = {
                frequency: 'DAY',
                count: data.num_trial_days,
            }
        }
        if (!_.isNil(data.invoice_payment_terms)) {
            if (data.invoice_payment_terms !== "custom") {
                contractData.invoice_due_date_from_creation = parseInt(data.invoice_payment_terms);
            }
        }
        if (dealID && integrationID) {
            contractData['integration_deal'] = {
                integration_id: integrationID,
                remote_id: dealID
            };
        }
        if (data.customer.integration_id) {
            contractData['integration_customer'] = {
                import_id: data.customer.id,
                integration_id: data.customer.integration_id,
                integration_object_type: data.customer.integration_object_type
            }
        } else {
            contractData['customer_id'] = data.customer.id;
        }

        if (previousSubscription) {
            contractData['previous_subscription_id'] = previousSubscription.id
            contractData['change_timing'] = "RENEWAL"
            contractData['change_proration_type'] = "NONE"
            contractData['change_reset_billing_anchor'] = false
        }

        const contractResult = await serverPost(getApiUrl('/proposals'), contractData, {}, (r, j) => contractErrorHandler(r, j, errorHandler))
        if (window.parent) {
            window.parent.postMessage(JSON.stringify({"action": "DONE"}), "*");
        }
        if (contractResult) {
            navigate(getCompanySpecificUrl(`/contract/view/${contractResult.id}`), { replace: true })
        }
    }

    const onFieldChange = (name, value) => {
        if (name === "customer") {
            if (value.__new__) {
                setShowAddOrEditCustomerModal(true);
            } else {
                if (formRef.current) {
                    const formData = formRef.current.getFormData();
                    let dataToUpdate = {
                        ...formData,
                        signatories: formData.signatories,
                        title: formData.title,
                        customer: value
                    };
                    if (!signatoryNameUpdatedRef.current) {
                        dataToUpdate.signatories[0].name = value.name;
                    }
                    if (!signatoryEmailUpdatedRef.current) {
                        dataToUpdate.signatories[0].email = value.email;
                    }
                    if (!signatoryTitleUpdatedRef.current) {
                        dataToUpdate.signatories[0].title = value.title;
                    }
                    if (!titleUpdatedRef.current) {
                        dataToUpdate.title = `${value.org_name || value.name} - ${company.name} Contract`;
                    }
                    setInitialFields(prevFields => {
                        return {...prevFields, ...dataToUpdate};
                    })
                }
                setSelectedCustomer(value);
            }
        } else if (name === "signatory.name") {
            signatoryNameUpdatedRef.current = true;
        } else if (name === "signatory.email") {
            signatoryEmailUpdatedRef.current = true;
        } else if (name === "signatory.title") {
            signatoryTitleUpdatedRef.current = true;
        } else if (name === "title") {
            titleUpdatedRef.current = true;
        } else if (name === "start_date_type") {
            setStartDateType(value);
        } else if (name === "invoice_payment_terms") {
            setInvoicePaymentTerms(value);
        } else if (name === "auto_charges") {
            setAutoCharges(value);
        } else if (name === "template_id") {
            const selectedTemplate = _.find(templates, (t) => t.id === value);
            if (selectedTemplate) {
                setCustomVariables(findCustomVariables(selectedTemplate.content));
                setNumberOfCounterSignatories(findNumberOfCounterSignatories(selectedTemplate.content));
                setNumberOfSignatories(findNumberOfSignatories(selectedTemplate.content));
            }
        } else if (name === "trial") {
            setIsTrial(value);
        }
        pricingsRef.current.onFieldChange(name, value);
    }

    const onCustomerAdded = (customer) => {
        if (formRef.current) {
            const formData = formRef.current.getFormData();
            let dataToUpdate = {
                ...formData,
                signatories: formData.signatories,
                title: formData.title,
                customer: customer
            };
            if (!signatoryNameUpdatedRef.current) {
                dataToUpdate.signatories[0].name = customer.name;
            }
            if (!signatoryEmailUpdatedRef.current) {
                dataToUpdate.signatories[0].email = customer.email;
            }
            if (!titleUpdatedRef.current) {
                dataToUpdate.title = `${customer.org_name || customer.name} - ${company.name} Contract`;
            }
            setInitialFields(prevFields => {
                return {...prevFields, ...dataToUpdate};
            })
        }
        setSelectedCustomer(customer);
    }

    const onProductPricingSelectionChange = (phase, selectedProductPricings) => {
        setHasNonOneTimeItems(_.some(selectedProductPricings, (spp) => {
            const flag = _.some(spp.product_metric_pricings, (pmp) => {
                return pmp.item.type !== "ONETIME_ITEM";
            })
            return flag
        }))
    }

    const onProductPricingCreated = (productPricing) => {
        if (pricingsRef.current) {
            pricingsRef.current.onProductPricingCreated(productPricing)
        }
    }

    const onModalClose = () => {
        setShowAddProductPricingModal(false);
        setShowAddOrEditCustomerModal(false);
    }

    const templateOptions = _.map(templates, (template) => {
        return {
            value: template.id,
            label: template.name
        }
    })

    const paymentMechanismOptions = [
        { value: true, label: "Collect payment information and charge automatically.",
            disabled: _.isNil(settings.payment_config), disabledDescription: "* Payment Provider needs to be setup" },
        { value: false, label: "Email invoice to customer" },
    ]

    const startDateTypeOptions = [
        { value: "ON_COMPLETION", label: 'When contract is completed' },
        { value: "SPECIFIC_DATE", label: 'On a specific date' },
        { value: "MANUAL_ACTION", label: 'On manual confirmation'}
    ]

    const changeDateOptions = [
        { value: "RENEWAL", label: `At renewal - ${ previousSubscription ? moment(previousSubscription.renewal_date).format("MMM D, YYYY h:mm:ssa"): ""}` },
        isMapleUser && { value: "CUSTOM", label: 'Custom Date' },
    ]

    const parentBilledOptions = [
        selectedCustomer && selectedCustomer.parent_customer && { value: true, label: `Parent - ${getCustomerNameOrEmail(selectedCustomer.parent_customer)}` },
        selectedCustomer && { value: false, label: `Customer - ${getCustomerNameOrEmail(selectedCustomer)}` },
    ]

    const prefetchedExistingCustomer =  prefetchDealDetails && prefetchDealDetails.customer && !_.isNil(prefetchDealDetails.customer.maple_customer_id);

    const getCounterSignatoryInputRows = (num) => {
        return (
            <Row>
                <div className="body2">Counter Signatory</div>
                <div className="body1 mb-3">{`Please choose up to ${num} company user(s) to counter sign if necessary.`}</div>
                {_.map(_.range(num), (_, i) => (
                    <BaseForm.Input key={i} colSpan="4" name={`counter_signatories.${i}`} type="select" options={counterSignatoryOptions} showSearch={counterSignatoryOptions.length > 5}/>
                ))}
            </Row>
        )
    }

    const getSignatoryInputRows = (num) => {
        return (
            <Row>
                <div className="text-lg font-semibold text-gray-700">Signatories</div>
                <div className="body1 mb-3">Enter details about who is going to be signing the contract on behalf of the
                    customer.
                </div>
                {_.map(_.range(num), (_, i) => (
                    <React.Fragment key={i}>
                        <BaseForm.Input colSpan="4" name={`signatories.${i}.name`} label={num > 1 ? `Signatory ${i+1} Name` : "Name"}
                                        type="text" required={num === 1}/>
                        <BaseForm.Input colSpan="4" name={`signatories.${i}.title`} label={"Title"} type="text"/>
                        <BaseForm.Input colSpan="4" name={`signatories.${i}.email`} label={t('common.email')}
                                        type="text" transformations={["lowercase", "trim"]} required={num === 1}
                                        validations={{validEmail: true}}/>
                    </React.Fragment>
                ))}
            </Row>
        )
    }

    const renderContractCreation = () => {
        return (
            <BaseForm ref={formRef} initialFormFields={initialFields} onSubmit={createContract} onFieldChange={onFieldChange}>
                {
                    previousSubscription &&
                        <div className="mb-3">
                            <div>
                                <p className="text-sm font-semibold">Changing existing subscription</p>
                                <div className="body1">Current Plan Details</div>
                                {
                                    previousSubscription.minimum_spend.value_in_cents > 0 &&
                                    <div className="body1">Minimum Spend: { currencyFormatFromPrice(previousSubscription.minimum_spend) }</div>
                                }
                                <BundlePricingTable
                                    bundlePricing={previousSubscription.bundle_pricing}
                                    configItems={previousSubscription.config_items}
                                    discounts={previousSubscription.discounts}
                                    showUsage={false}
                                    showPricingLinks={false}
                                    subscription={previousSubscription}
                                    hideDiscountAdditionalDetails={true}
                                    displayDiscountExpiration={true}
                                />
                            </div>
                            <p className="-mt-2">
                                Please enter the contract details and the new plan details. Once the contract is complete, the
                                existing subscription will be changed to the new plan.
                            </p>
                        </div>
                }
                <ContentBox>
                    <ContentBox.Body>
                        <Row>
                            <div className="text-lg font-semibold text-gray-700">Contract Details</div>
                            <div className="body1 mb-3">Enter details about the contract.</div>
                            <CustomerInput
                                colSpan="6" defaultCustomer={defaultCustomer} allowLeads required
                                defaultCustomer={prefetchedExistingCustomer ? prefetchDealDetails.customer: null}
                                allowCreation
                            />
                            <BaseForm.Input colSpan="6" name="owner" label={"Owner"} type="select" options={teamMemberOptions}/>
                            <Col>
                                <BaseForm.Input colSpan="12" name="title" label="Title" type="text" required />
                                {
                                    !showPOField &&
                                    <div className="mb-2">
                                        <Link onClick={() => setShowPOField(true)}>Add PO Number</Link>
                                    </div>
                                }
                                {
                                    showPOField &&
                                    <Row>
                                        <BaseForm.Input colSpan="5" name="po_number" label="PO Number" type="text" />
                                    </Row>
                                }
                            </Col>
                            <Col>
                                <BaseForm.Input colSpan="12" name="template_id" label={"Template"} type="select"
                                                options={templateOptions} showSearch={false}/>
                            </Col>

                        </Row>
                        <Row>
                            <BaseForm.Input colSpan="6" name="expiration_date" label={"Expiration Date"} type="date" includeTime={true} minDate={moment()} />
                            <BaseForm.Input colSpan="6" name="auto_charges" label={t('subscriptions.payment_mechanism')} type="select"
                                            options={paymentMechanismOptions} showSearch={false}/>
                            {
                                !autoCharges ?
                                    <>
                                        <Col md="6"></Col>
                                        <BaseForm.Input type="select" colSpan="3" name="invoice_payment_terms" label="Invoice Payment Terms" options={getInvoicePaymentTermOptions()} showSearch={false} />
                                        {
                                            invoicePaymentTerms === "custom" &&
                                            <BaseForm.Input type="number" colSpan="3" name="invoice_due_date_from_creation" label="Due Date (in days)" step="1" min="0" />
                                        }
                                    </>
                                    : <>
                                        <Col md="6"></Col>
                                        <BaseForm.Input type="switch" colSpan="6" name="require_payment_method" label="Require payment method before execution" />
                                    </>
                            }
                        </Row>
                        <br/>
                        {
                            !_.isEmpty(customVariables) &&
                            <div className="mt-2">
                                <hr className="mb-2"/>
                                <div className="body2">Custom Variables</div>
                                <div className="body1">Fill out the values for the custom variables to be used in the contract.</div>
                                <Row className="mt-3">
                                    {
                                        _.map(customVariables, (cv, i) => renderCustomVariableInput(cv, i, availableCustomVariables))
                                    }
                                </Row>
                            </div>
                        }
                    </ContentBox.Body>
                </ContentBox>
                <ContentBox>
                    <ContentBox.Body>
                        {numberOfSignatories > 0 && getSignatoryInputRows(numberOfSignatories)}
                        {signatoryError && <div className="form-error-message">{signatoryError}</div>}
                        <br/>
                        {numberOfCounterSignatories > 0 && getCounterSignatoryInputRows(numberOfCounterSignatories)}
                    </ContentBox.Body>
                </ContentBox>
                <ContentBox>
                    <ContentBox.Body>
                        {
                            previousSubscription ?
                                <Row>
                                    <BaseForm.Input
                                        colSpan="6" name="change_timing" label={"Change Date"} type="select"
                                        options={changeDateOptions} showSearch={false}/>
                                </Row>
                            : <Row>
                                <div className="text-lg font-semibold text-gray-700">Plan Details</div>
                                <div className="body1 mb-3">Enter the start date and pricing details for this contract.</div>
                                <BaseForm.Input colSpan="6" name="start_date_type" label={t('subscriptions.start_date')} type="select"
                                                options={startDateTypeOptions} showSearch={false}/>
                                {
                                    startDateType === "SPECIFIC_DATE" &&
                                    <BaseForm.Input colSpan="6" name="start_date" label={'Specific Date'} type="date"
                                                    required />
                                }
                            </Row>
                        }
                        {
                            hasNonOneTimeItems &&
                                <Row>
                                    <Col md="6">
                                        <BaseForm.InputGroup label="Term">
                                            <BaseForm.Number name="term.count" min="1" required label="Count" hideLabel/>
                                            <BaseForm.Divider />
                                            <BaseForm.SingleSelect
                                                name="term.frequency" options={getPlanLengthOptions()} showSearch={false} fullWidth
                                            />
                                        </BaseForm.InputGroup>
                                    </Col>
                                    <Col lg="3" className="flex items-end">
                                        <BaseForm.Input
                                            type="switch" name="trial" label={"Free trial days"} outerInputClassName="grow"
                                        />
                                    </Col>
                                    {
                                        isTrial &&
                                        <BaseForm.Input colSpan={3} type="number" name="num_trial_days" label="Trial Days" step="1" min="0"/>
                                    }
                                </Row>
                        }
                        <br/>
                        <ProductPricingSelection
                            ref={pricingsRef} productPricings={productPricings} allowDiscounts={true}
                            initialFields={initialFields} setInitialFields={setInitialFields}
                            allowMinimumSpend menuPlacement={"top"} allowPhases
                            onSelectionChange={onProductPricingSelectionChange} allowCreation
                            onCreateProductPricing={() => setShowAddProductPricingModal(true)}
                        />
                        {
                            error &&
                                <div className="form-error-message">{ error }</div>
                        }
                        {
                            askForPhoneNumber &&
                                <Row>
                                    <div className="body2">Additional Information</div>
                                    <p className="my-2 text-sm text-gray-500">A phone number for the customer is required to process any recurring payments with Razorpay. Please enter a phone number for the customer before proceeding.</p>
                                    <BaseForm.Input colSpan="5" type="text" name="customer_phone" label="Phone Number" required />
                                </Row>
                        }
                        <Row>
                            <Col md="12">
                                <br/>
                                <SubmitButton errorOnRight>{ isEditing ? "Update": "Create" } Contract</SubmitButton>
                                {!_.isNil(signatoryError) && <div className="form-error-message">{signatoryError}</div>}
                            </Col>
                        </Row>
                    </ContentBox.Body>
                </ContentBox>
            </BaseForm>
        );
    }

    const renderEmpty = () => {
        return (
            <ContentBox>
                <ContentBox.Body>
                    <div className="d-flex flex-column">
                        <div className="align-items-center justify-content-center text-center" style={{ marginTop: "30px" }}>
                            <Row>
                                <Col md={{ span: 8, offset: 2 }}>
                                    <div className="body2">Hi there!</div>
                                    <br/>
                                    <p>Before we create a contract, we will need to create at least one contract template.
                                    You can get <Link href={getCompanySpecificUrl("/settings/contracts")}>started here</Link></p>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </ContentBox.Body>
            </ContentBox>
        )
    }

    return (
        <BaseContainer>
            <ContentContainer>
                <Breadcrumb>
                    <Breadcrumb.Item href={ getCompanySpecificUrl(`/contracts`) } linkAs={Link}>Contracts</Breadcrumb.Item>
                    <Breadcrumb.Item active>{ isEditing ? (contract.customer && contract.customer.name): "New Contract" }</Breadcrumb.Item>
                </Breadcrumb>
                <Loader loading={loading}>
                {
                    () => {
                        if (_.isEmpty(templates)) {
                            return renderEmpty();
                        } else {
                            return renderContractCreation();
                        }
                    }
                }
                </Loader>
                <AddOrEditProductPricingModal2
                    show={showAddProductPricingModal}
                    onProductPricingCreated={onProductPricingCreated}
                    onClose={onModalClose}
                />
                <AddOrEditCustomerModal
                    show={showAddOrEditCustomerModal}
                    onAdded={onCustomerAdded}
                    onClose={onModalClose}
                />
            </ContentContainer>
        </BaseContainer>
    )
}

export default ContractCreate;
