import '../App.scss';
import React, { useContext } from 'react';
import {Outlet, Link} from "react-router-dom";
import { BaseContext, UserContext } from "../helpers/common";
import BaseContainer from "./BaseContainer";
import { Col, Row } from "react-bootstrap";
import Button from './common/buttons/Button';

function RequirePermission(props) {
    const { hasAccess, getCompanySpecificUrl } = useContext(BaseContext);
    const { userInfo } = useContext(UserContext);

    const hasPermission = hasAccess(props.feature, userInfo, "read");

    const renderNoPermissions = () => {
        return (
            <BaseContainer>
                <Row>
                    <Col md={{ offset: 1, span:10 }} className="text-center">
                        <div className="content-box">
                            <div className="content-body tall">
                                <Row>
                                    <Col md="12" className="text-center">
                                        <h4>Oops! You don't have permissions to view this page.</h4>
                                        <br/>
                                    </Col>
                                    <Col md="12" className="text-center">
                                        <div className="body1">Not to worry, simply ask your company admin to provide you with the right permissions.</div>
                                        <br/><br/>
                                    </Col>
                                    <Col md="12" className="d-flex flex-column align-items-center">
                                        <div className="d-flex flex-row gap-3">
                                            <Link to={getCompanySpecificUrl("/")}>
                                                <Button variant="primary">Go to Dashboard</Button>
                                            </Link>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </BaseContainer>
        )
    }

    return (
        <>
            {
                hasPermission && <Outlet />
            }
            {
                !hasPermission && renderNoPermissions()
            }
        </>
    );
}

export default RequirePermission;
