import '../App.scss';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from './common/buttons/Button';
import Link from "./Link";
const _ = require("lodash");

function ErrorComponent(props) {
    const navigate = useNavigate();
    const [title, setTitle] = useState("Page Not Found");
    const [description, setDescription] = useState("Sorry, we couldn’t find the page you’re looking for.");
    const [primaryAction, setPrimaryAction] = useState({ href: "/user/home", label: "Go Back Home" });
    const [hideSupport, setHideSupport] = useState(false);

    useEffect(() => {
        if (!_.isNil(props.title)) {
            setTitle(props.title);
        }
    }, [props.title]);

    useEffect(() => {
        if (!_.isNil(props.description)) {
            setDescription(props.description);
        }
    }, [props.description]);

    useEffect(() => {
        if (!_.isNil(props.primaryAction)) {
            setPrimaryAction(props.primaryAction);
        }
    }, [props.primaryAction]);

    useEffect(() => {
        if (!_.isNil(props.hideSupport)) {
            setHideSupport(props.hideSupport);
        }
    }, [props.hideSupport]);

    return (
        <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
            <div className="text-center">
                <h1 className="mt-4 text-2xl font-bold text-gray-700 sm:text-3xl">{ title }</h1>
                <p className="mt-6 text-base leading-7 text-gray-600">{ description }</p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                    {
                        primaryAction &&
                            <Link href={primaryAction.href}>
                                <Button>
                                    {primaryAction.label}
                                </Button>
                            </Link>
                    }
                    {
                        !hideSupport &&
                            <a href="mailto:support@maplebilling.com" className="text-sm font-semibold text-gray-900">
                                <Button variant="text">
                                    Contact Support
                                </Button>
                            </a>
                    }
                </div>
            </div>
        </main>
    );
}

export default ErrorComponent;
